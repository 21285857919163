import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MatTabGroup} from "@angular/material/tabs";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AoContractorFacade} from "../../../api/gen/models/ao-contractor-facade";
import {
  ContractorQualification,
  ContractorQualificationRequest,
  ContractorQualificationRequestInformation,
  Entreprise,
  PrivateBuyerCreate
} from "../../../api/gen/models";
import {SelectEntrepriseComponent} from "../select-entreprise/select-entreprise.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import PatchContractorQualificationRequestParams = FirstAOInternServicesService.PatchContractorQualificationRequestParams;
import {FirstAOCustomerServicesService} from "../../../api/gen/services";
import {EntrepriseCreationConfirmartionComponent} from "../entreprise-creation-confirmartion/entreprise-creation-confirmartion.component";
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-qualification-modal',
  templateUrl: './qualification-modal.component.html',
  styleUrls: ['./qualification-modal.component.css']
})
export class QualificationModalComponent implements OnInit {

  contractor: AoContractorFacade;
  currentQualificationRequest: ContractorQualificationRequestInformation;
  suggestions: Entreprise [];
  siren: string;
  entrepriseName: string = "";
  switchSirenOrName: boolean;
  sirenToCreate: string = "";

  @ViewChild("suggestionsTabsContainer", { static: false }) suggestionsTabsContainer : any;
  @ViewChild('tabGroup') tabGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
      private firstAOInternServicesService: FirstAOInternServicesService,
              private dialog: MatDialog,
              private snackBarService: MatSnackBar,
              private dialogRef: MatDialogRef<QualificationModalComponent>) {
    this.currentQualificationRequest = this.data.currentQualificationRequest;
    this.contractor = this.currentQualificationRequest.contractor;
    this.siren = this.convertSiren(this.contractor.siren);
    this.entrepriseName = this.contractor.name;
    this.switchSirenOrName = this.contractor.siren == null;
  }

  ngOnInit(): void {
    this.searchEntreprises(null);
    // $(document).ready(function() {
    //   alert('I am Called From jQuery');
    // });
    let that = this;
    $( '.mat-tab-header' ).on( 'mousewheel', function ( e ) {
      // alert("aaaaaaaaaaaaaaaaa");
      var event = e.originalEvent,
          d = event.wheelDelta || -event.detail;

      this.scrollTop += ( d < 0 ? 1 : -1 ) * 30;
      e.preventDefault();

      that.scrollTabs(event);
    });
  }


  selectEntreprise(suggestion: Entreprise) {
    const dialogRef = this.dialog.open(SelectEntrepriseComponent, {
      data: {
        contractor: this.contractor,
        suggestion: suggestion
      }
    });
    //
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result.accept) {
        this.dialogRef.close({
          accept: true,
          suggestion: suggestion
        });
      }
    });
  }

  searchEntreprises(sourceisSiren: boolean) {
    if (sourceisSiren == null || sourceisSiren != this.switchSirenOrName) {
      let valueToSearch = this.switchSirenOrName ? this.entrepriseName : this.siren;
      this.firstAOInternServicesService.editorEnterprisesSearch(valueToSearch).subscribe(value => {
        this.suggestions = value.entreprises;
      });
    }
  }

  createSocieteWithSiren() {
    const dialog = this.dialog.open(EntrepriseCreationConfirmartionComponent, {
      data: {
        siren: this.siren
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result != null && result.accept) {
        this.firstAOInternServicesService.createNewEnterprise(this.convertSiren(this.contractor.siren)).subscribe(value => {
          this.suggestions.push(value);
          this.suggestionsTabsContainer.selectedIndex = this.suggestions.length - 1;
        });
      }
    });
  }

  createSocieteWithcustomSiren() {
    this.sirenToCreate = this.convertSiren(this.sirenToCreate);
    if (this.sirenToCreate != null && this.sirenToCreate.length == 9 && !isNaN(Number(this.sirenToCreate))) {
      const dialog = this.dialog.open(EntrepriseCreationConfirmartionComponent, {
        data: {
          siren: this.sirenToCreate
        }
      });

      dialog.afterClosed().subscribe(result => {
        if (result.accept) {
          this.firstAOInternServicesService.createNewEnterprise(this.convertSiren(this.sirenToCreate)).subscribe(value => {
            this.sirenToCreate = "";
            this.suggestions.push(value);
            this.suggestionsTabsContainer.selectedIndex = this.suggestions.length - 1;
          });
        }
      });

    } else {
      this.snackBarService.open("Le SIREN doit être composé de 9 chiffres !", "Fermer", {duration: 10000});
    }
  }

  createSocieteWithoutSiren() {
    this.firstAOInternServicesService.createNewEnterprise(this.contractor.name).subscribe(value => {
      this.suggestions.push(value);
      this.suggestionsTabsContainer.selectedIndex = this.suggestions.length - 1;
    });
  }

  extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    }
    else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  scrollTabs(event) {
    const children = this.tabGroup._tabHeader._elementRef.nativeElement.children;
    const back = children[0];
    const forward = children[2];
    if (event.deltaY > 0) {
      forward.click();
    } else {
      back.click();
    }
  }

  convertSiren(siren: string) {
    if (siren != null) {
      let sirenConverted = siren;
      sirenConverted = sirenConverted.replace(/\s/g, "");
      if (sirenConverted.length > 9)
        sirenConverted = sirenConverted.substring(0, 9);
      return sirenConverted;
    } else {
      return siren;
    }
  }
}
