<h1 mat-dialog-title>Création d'une source</h1>
<div mat-dialog-content>
    <mat-form-field class="flexGrow1">
        <input matInput placeholder="Libellé" type="text" [(ngModel)]="sourceName">
    </mat-form-field>
    <mat-form-field class="flexGrow1">
        <input matInput placeholder="URL" type="text" [(ngModel)]="sourceURL">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button class="cancelButton" (click)="onNoClick()">Annuler</button>
    <button mat-button class="saveBtn" (click)="onSave()" cdkFocusInitial>Enregisrer</button>
</div>