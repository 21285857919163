import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MissionService} from "../../../mission.service";

@Component({
  selector: 'app-doublons-displayer',
  templateUrl: './doublons-displayer.component.html',
  styleUrls: ['./doublons-displayer.component.css']
})
export class DoublonsDisplayerComponent implements OnInit {

  even: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<DoublonsDisplayerComponent>,
              private missionService: MissionService) { }

  ngOnInit() {
  }

  updateEven() {
    this.even = !this.even;
  }

  openDoublon(aoId) {
    this.missionService.changeCurrentNoticeId(aoId);
    this.dialogRef.close();
  }

}
