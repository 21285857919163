import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {MissionService} from "../../../mission.service";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import EditorEditNoticeLieuParams = FirstAOInternServicesService.EditorEditNoticeLieuParams;
import EditorAddNoticeLieuParams = FirstAOInternServicesService.EditorAddNoticeLieuParams;
import {AoLieu} from "../../../api/gen/models";
import EditorDeleteNoticeLieuParams = FirstAOInternServicesService.EditorDeleteNoticeLieuParams;

@Component({
  selector: 'app-lieux-manager',
  templateUrl: './lieux-manager.component.html',
  styleUrls: ['./lieux-manager.component.css']
})
export class LieuxManagerComponent implements OnInit {

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    currentLieu;
    lieuEditing: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                private missionService: MissionService,
                private firstAOInternServicesService: FirstAOInternServicesService) {
        this.readOnly = data.readOnly;
    }

    ngOnInit() {
    }

    editLieu() {
        this.lieuEditing = true;
    }

    saveLieuModification() {
        const aoLieu: AoLieu = {
            aoId: this.data.aoId,
            adresse: this.currentLieu.adresse,
            city: this.currentLieu.city,
            codeNuts: this.currentLieu.codeNuts,
            zip: this.currentLieu.zip,
            id: this.currentLieu.id
        };

        const param: EditorEditNoticeLieuParams = {
            noticeLieuID: this.currentLieu.id,
            noticeID: this.data.aoId,
            actualisSharedBeanFirstecoAoLieu: aoLieu
        };

        this.firstAOInternServicesService.editorEditNoticeLieu(param).subscribe(value => {
            this.lieuEditing = false;
        });
    }

    openLieu(lieu) {
        this.lieuEditing = false;
        this.currentLieu = lieu;

        this.removeAllSelected();

        if (lieu.noticeLieuID != null) {
            document.getElementById('lieu' + lieu.noticeLieuID).classList.add('selectedLieu');
        }
    }

    removeAllSelected() {
        let elems = document.getElementsByClassName('noselect');

        [].forEach.call(elems, function (el) {
            el.classList.remove("selectedLieu");
        });
    }

    addNewLieu() {
        const aoLieu: AoLieu = {
            aoId: this.data.aoId,
            adresse: "Nouvelle adresse",
            city: "Nouvelle Ville",
        };

        const param: EditorAddNoticeLieuParams = {
            noticeID: this.data.aoId,
            actualisSharedBeanFirstecoAoLieu: aoLieu
        };

        this.firstAOInternServicesService.editorAddNoticeLieu(param).subscribe(value => {
            this.data.lieux.push(value);
        });
    }

    deleteLieu() {
        const param: EditorDeleteNoticeLieuParams = {
            noticeLieuID: this.currentLieu.id,
            noticeID: this.data.aoId
        };

        this.firstAOInternServicesService.editorDeleteNoticeLieu(param).subscribe(value => {
            this.lieuEditing = false;


            const index: number = this.data.lieux.indexOf(this.currentLieu);
            if (index !== -1) {
                this.data.lieux.splice(index, 1);
            }

            this.currentLieu = null;

        });
    }
}
