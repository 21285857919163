<script>
    $( '.suggestionsTabsContainer' ).on( 'mousewheel', function ( e ) {
        var event = e.originalEvent,
            d = event.wheelDelta || -event.detail;

        this.scrollTop += ( d < 0 ? 1 : -1 ) * 30;
        e.preventDefault();
    });
</script>

<div style="min-width: 1100px; display: flex; flex-direction: column; height: 100%;">
    <div style="overflow: auto; flex-direction: column; padding-right: 14px;">
        <div style="position: relative">
            <a *ngIf="siren && siren.length == 9" href="https://www.societe.com/societe/-{{siren}}.html"
               style="background: #1a4f84; padding: 5px 10px; color: white; position: absolute;"
               target="_blank" class="awadrdTitle">Lien société.com</a>
            <mat-label style="display: block; text-align: center; font-size: 20px;">Rechercher par :</mat-label>
            <div style="width: fit-content; margin: 5px auto 25px;">
                <mat-label style="margin-right: 10px;">SIREN</mat-label>
                <mat-slide-toggle [(ngModel)]="switchSirenOrName" (ngModelChange)="searchEntreprises(null)"></mat-slide-toggle>
                <mat-label style="margin-left: 10px;">Nom</mat-label>
            </div>

        </div>
        <div style="display: flex; flex-direction: row; margin-bottom: 10px;">
            <mat-form-field style="width: 100%; margin-right: 20px">
                <input matInput placeholder="SIREN" [(ngModel)]="siren" (ngModelChange)="searchEntreprises(true)">
            </mat-form-field>

            <mat-form-field style="width: 100%; margin-left: 20px;">
                <input matInput placeholder="Nom de l'entreprise" [(ngModel)]="entrepriseName"
                       (ngModelChange)="searchEntreprises(false)">
            </mat-form-field>
        </div>


        <mat-card class="recapCard">
            <mat-card-title class="recapCardTitle">
                Récapitulatif de l'attributeur
            </mat-card-title>

            <div class="editorLine" style="margin: -10px 0;">
                <div class="editorCol6">
                    <div class="editorLineInput" style="margin-right: 5px;">
                        <mat-form-field style="width: 100%;">
                            <input [readonly]="true" matInput placeholder="Libellé"
                                   [(ngModel)]="currentQualificationRequest.contractor.name">
                        </mat-form-field>
                    </div>
                </div>

                <div class="editorCol6" style="margin-left: 5px;" *ngIf="currentQualificationRequest.contractor.siren">
                    <div class="editorLineInput">
                        <mat-form-field style="width: 100%;">
                            <input [readonly]="true" matInput placeholder="SIRET"
                                   [(ngModel)]="currentQualificationRequest.contractor.siren">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="editorLine" style="margin-bottom: -20px;" *ngIf="currentQualificationRequest.contractor.address
            && currentQualificationRequest.contractor.city && currentQualificationRequest.contractor.zip">
                <div class="editorCol6">
                    <div class="editorLineInput" style="margin-right: 5px;">
                        <mat-form-field style="width: 100%;">
                            <input [readonly]="true" matInput placeholder="Adresse"
                                   [(ngModel)]="currentQualificationRequest.contractor.address">
                        </mat-form-field>
                    </div>
                </div>

                <div class="editorCol6" style="margin-left: 5px;">
                    <div class="editorLineInput">
                        <mat-form-field style="width: 100%;">
                            <input [readonly]="true" matInput placeholder="Ville"
                                   [(ngModel)]="currentQualificationRequest.contractor.city">
                        </mat-form-field>
                    </div>
                </div>

                <div class="editorCol6" style="margin-left: 5px;">
                    <div class="editorLineInput">
                        <mat-form-field style="width: 100%;">
                            <input [readonly]="true" matInput placeholder="Code postal"
                                   [(ngModel)]="currentQualificationRequest.contractor.zip">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-card>


        <div style="margin-bottom: 40px; background: #efefef;">
            <mat-tab-group class="suggestionsTabsContainer" #suggestionsTabsContainer #tabGroup>
                <mat-tab *ngFor="let suggestion of suggestions" label="{{suggestion.name}}">
                    <div style="padding: 20px;">
                        <div class="editorLine" style="flex-direction: column">
                            <div style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <mat-label class="awardDescLabel" style="height: fit-content; margin: auto 10px auto 0;">
                                    Nom de la société :</mat-label>
                                <mat-label style="font-size: 25px; font-weight: 500;">{{suggestion.name}}</mat-label>
                            </div>


                            <div style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">SIREN :</mat-label>
                                    <a *ngIf="suggestion.siren" href="https://www.societe.com/societe/-{{suggestion.siren}}.html"
                                       target="_blank" class="awadrdTitle">{{suggestion.siren}}</a>
                                    <mat-label *ngIf="!suggestion.siren" class="awadrdTitle">NC</mat-label>
                                </div>

                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">SIRET :</mat-label>
                                    <mat-label *ngIf="suggestion.siret" class="awadrdTitle">{{suggestion.siret}}</mat-label>
                                    <mat-label *ngIf="!suggestion.siret" class="awadrdTitle">NC</mat-label>
                                </div>

                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Statut juridique :</mat-label>
                                    <mat-label *ngIf="suggestion.legalStatus" class="awadrdTitle">{{suggestion.legalStatus}}</mat-label>
                                    <mat-label *ngIf="!suggestion.legalStatus" class="awadrdTitle">NC</mat-label>
                                </div>
                            </div>


                            <div style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Date de création :</mat-label>
                                    <mat-label *ngIf="suggestion.dateCreation" class="awadrdTitle">{{suggestion.dateCreation}}</mat-label>
                                    <mat-label *ngIf="!suggestion.dateCreation" class="awadrdTitle">NC</mat-label>
                                </div>

                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Capital social :</mat-label>
                                    <mat-label *ngIf="suggestion.capital" class="awadrdTitle">{{suggestion.capital}}</mat-label>
                                    <mat-label *ngIf="!suggestion.capital" class="awadrdTitle">NC</mat-label>
                                </div>

                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Effectif :</mat-label>
                                    <mat-label *ngIf="suggestion.effectif" class="awadrdTitle">{{suggestion.effectif}} personne(s)</mat-label>
                                    <mat-label *ngIf="!suggestion.effectif" class="awadrdTitle">NC</mat-label>
                                </div>
                            </div>


                            <div style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Adresse :</mat-label>
                                    <mat-label *ngIf="suggestion.adresse || suggestion.cp || suggestion.pays || suggestion.ville"
                                               class="awadrdTitle">
                                        <span style="text-transform: lowercase;">{{suggestion.adresse}}</span>
                                        {{suggestion.cp}}
                                        {{suggestion.ville}}
                                        <span *ngIf="suggestion.ville && suggestion.pays">-</span>
                                        {{suggestion.pays}}
                                    </mat-label>
                                    <mat-label *ngIf="!suggestion.adresse && !suggestion.cp && !suggestion.pays">NC</mat-label>
                                </div>
                            </div>


                            <div style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Code APE :</mat-label>
                                    <mat-label *ngIf="suggestion.ape || suggestion.apeLabel" class="awadrdTitle">{{suggestion.ape}}
                                        <span *ngIf="suggestion.ape && suggestion.apeLabel"> - </span>
                                        {{suggestion.apeLabel}}</mat-label>
                                    <mat-label *ngIf="!suggestion.ape && !suggestion.apeLabel">NC</mat-label>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Site Web :</mat-label>
                                    <a *ngIf="suggestion.website" href="{{suggestion.website}}" target="_blank"
                                       class="awadrdTitle">{{extractHostname(suggestion.website)}}</a>
                                    <mat-label *ngIf="!suggestion.website" class="awadrdTitle">NC</mat-label>
                                </div>

                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Téléphone :</mat-label>
                                    <mat-label *ngIf="suggestion.telephone" class="awadrdTitle">{{suggestion.telephone}}</mat-label>
                                    <mat-label *ngIf="!suggestion.telephone" class="awadrdTitle">NC</mat-label>
                                </div>

                                <div style="flex: 1;">
                                    <mat-label class="awardDescLabel">Mail :</mat-label>
                                    <mat-label *ngIf="suggestion.mail" class="awadrdTitle">{{suggestion.mail}}</mat-label>
                                    <mat-label *ngIf="!suggestion.mail" class="awadrdTitle">NC</mat-label>
                                </div>
                            </div>


                            <div *ngIf="suggestion.descriptif" style="display: flex; flex-direction: row; margin-bottom: 30px;">
                                <div style="flex: 1; flex-direction: column;">
                                    <mat-label class="awardDescLabel" style="display: block; margin-bottom: 5px;">Description :</mat-label>
                                    <mat-label class="awadrdTitle">{{suggestion.descriptif}}</mat-label>
                                </div>
                            </div>



                        </div>


                        <div>
                            <button style="background: green" (click)="selectEntreprise(suggestion)">
                                Choisir cette société</button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div style="flex-basis: 35px; margin: 10px 0 -10px; padding-right: 14px;">
        <div style="display: inline-block;" *ngIf="contractor.siren">
            <span style="margin-right: 10px;">SIREN trouvé ({{convertSiren(contractor.siren)}}): </span>
            <button (click)="createSocieteWithSiren()">Création automatique</button>
        </div>

        <div style="display: inline-block;" *ngIf="!contractor.siren">
            <span style="margin-right: 10px;">SIREN non trouvé: </span>
            <button (click)="createSocieteWithoutSiren()">Créer sans SIREN</button>
        </div>

        <div style="float: right; display: inline-block;">
            <span style="margin-right: 10px;">Créer avec un SIREN particulier :</span>
            <input [(ngModel)]="sirenToCreate" style="margin-right: 10px; margin-bottom: 0px;">
            <button (click)="createSocieteWithcustomSiren()">Création manuelle</button>
        </div>
    </div>
</div>
