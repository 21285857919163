<mat-form-field class="example-chip-list" style="width: 100%;" id="cpvCodesId">
    <mat-chip-list  #chipList>
            <mat-chip
                    *ngFor="let cpv of cpvCodes"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(cpv)">
                {{cpv.libelle}} ({{cpv.cpv}})
                <mat-icon matChipRemove *ngIf="!readOnly">cancel</mat-icon>
            </mat-chip>
        <input [readonly]="readOnly"
                placeholder="Code CPV *"
                [(ngModel)]="search"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
               #cpvInput
                (ngModelChange)="scheduleSearch()">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let cpv of cpvSearched" [value]="cpv">
            {{cpv.libelle}} ({{cpv.cpvId}})
        </mat-option>
        <mat-option *ngIf="isSearching" style="height: 125px;"><mat-spinner style="margin: auto;"></mat-spinner></mat-option>
    </mat-autocomplete>
</mat-form-field>
