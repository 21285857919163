<div class="contactContainer" id="buyerProfilContact">
    <div class="containerTitle">
        <div class="containerTitleText">Contact</div>
        <mat-form-field style="width: 100%;">
            <mat-select #contactSelect (selectionChange)="onContactSelected($event)"
                        [(ngModel)]="contactId" [disabled]="readOnly || entrepriseId == 0"
                        [formControl]="contactsControl"
                        required>
                <mat-option *ngFor="let contact of buyersContacts" [value]="contact.id">
                    {{contact.nom}} ({{contact.email}})
                </mat-option>
                <button class="adderButton" (click)="openCreateContactModal()">
                    Ajouter un nouveau contact
                </button>
            </mat-select>
            <mat-error *ngIf="contactsControl.hasError('required')">Choisissez un contact
            </mat-error>
        </mat-form-field>
    </div>

    <div style="display: flex;">
        <mat-form-field class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Nom Prénom *" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.nom" (ngModelChange)="currentContact.nom= $event; contactChanged.emit()">
        </mat-form-field>

        <mat-form-field class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Adresse mail *" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.email" (ngModelChange)="currentContact.email= $event; contactChanged.emit()">
        </mat-form-field>

        <mat-form-field class="inputContainer flexGrow1">
            <input matInput placeholder="Téléphone" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.telephone" (ngModelChange)="currentContact.telephone= $event; contactChanged.emit()">
        </mat-form-field>
    </div>

    <div style="display: flex;">
        <mat-form-field *ngIf="isPublic" class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Fax" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.fax" (ngModelChange)="currentContact.fax= $event; contactChanged.emit()">
        </mat-form-field>

        <mat-form-field *ngIf="!isPublic" class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Fonction" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.service" (ngModelChange)="currentContact.service= $event; contactChanged.emit()">
        </mat-form-field>

        <mat-form-field *ngIf="!isPublic" class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Lien LinkedIn" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.linkedin_profil" (ngModelChange)="currentContact.linkedin_profil= $event; contactChanged.emit()">
        </mat-form-field>

        <mat-form-field *ngIf="!isPublic" class="inputContainer flexGrow1">
            <input matInput placeholder="Lien Viadeo" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentContact?.viadeo_profil" (ngModelChange)="currentContact.viadeo_profil= $event; contactChanged.emit()">
        </mat-form-field>
    </div>
</div>
