<mat-form-field class="example-chip-list" style="width: 100%;">
    <mat-chip-list  #chipList>
        <mat-chip
                *ngFor="let acheteur of acheteurs"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(acheteur)">
            {{acheteur.denomination}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
               placeholder="Acheteurs"
               [(ngModel)]="search"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               #acheteursInput
               (ngModelChange)="scheduleSearch()">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let acheteur of acheteursSearched" [value]="acheteur">
            {{acheteur.denomination}}
        </mat-option>
        <mat-option *ngIf="isSearching" style="height: 125px;"><mat-spinner style="margin: auto;"></mat-spinner></mat-option>
    </mat-autocomplete>
</mat-form-field>
