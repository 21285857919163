import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

/**
 * Ajout de l'authentification basic (a refaire en oauth)
 */
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic cm9tYWluLnRyaWJvdHRlQGZpcnN0ZWNvLmZyOnNjb3Bl'
    })
};

@Injectable({
    providedIn: 'root'
})
export class AoApiService {

    API_KEY = 'YOUR_API_KEY';
    apiBaseUrl: string = 'https://api.firsteco.fr/firstao/';

    constructor(private httpClient: HttpClient) {
    }

    public getNotices() {
        return this.httpClient.get(this.apiBaseUrl + `editor/notices/search?aoSortingMethod=deadlineAsc&page=1&pageSize=10`, httpOptions);
    }

    public getNoticeFromId(noticeId: number) {
        return this.httpClient.get(this.apiBaseUrl + 'notice/' + noticeId, httpOptions);
    }

    getNoticesUsingPromise() {
        return this.httpClient.get("https://api.firsteco.fr/firstao/notice/222", httpOptions).toPromise();
    }
}

// ------  Regénéré les models et services  ------

// -------------- PROD --------------
// ng-swagger-gen -i src/app/ressources/api-docs.json -o src/app/api/gen

// -------------- DEV ---------------
// ng-swagger-gen -i src/app/ressources/dev-api-docs.json -o src/app/api/gen


// ------ Commande pour build ------

// ng build --prod --outputPath=/var/www/html/aoeditor --base-href /aoeditor/
// ng build --prod --base-href /aoeditor/

/**
 * Attention si il y a une erreur :
 *
 * Access to XMLHttpRequest at 'http://api.firsteco.fr/firstao/editor/notices/search'
 * from origin 'http://localhost:4200' has been blocked by CORS policy: Response to
 * preflight request doesn't pass access control check: Redirect is not allowed for a preflight request.
 *
 *
 * Il faut aller dans le dossier Gen, ouvrir le fichier api-configuration.ts et modifier la ligne suivante
 * rootUrl: string = '//api.firsteco.fr';
 * en
 * rootUrl: string = 'https://api.firstao.fr';
 */