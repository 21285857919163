import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-error-publication',
  templateUrl: './error-publication.component.html',
  styleUrls: ['./error-publication.component.css']
})
export class ErrorPublicationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

}
