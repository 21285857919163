import {Component, OnInit} from '@angular/core';
import {FirstAOInternServicesService} from "../../api/gen/services";
import EditorSourcesEditParams = FirstAOInternServicesService.EditorSourcesEditParams;
import {Timestamp} from "../../api/gen/models/timestamp";
import {AoSourcesListe} from "../../api/gen/models";

@Component({
    selector: 'app-source-edition',
    templateUrl: './source-edition.component.html',
    styleUrls: ['./source-edition.component.css']
})
export class SourceEditionComponent implements OnInit {

    allSources;
    currentSource;
    showLoading = false;
    readonly = true;

    constructor(private firstAOInternServicesService: FirstAOInternServicesService) {
    }

    ngOnInit() {
        this.firstAOInternServicesService.editorSources().subscribe(sources => {
            this.allSources = sources;
        });
    }

    openSource($event) {
        this.readonly = true;
        this.showLoading = false;

        if ($event != this.currentSource) {
            this.currentSource = $event;
        } else {
            this.currentSource = null;
        }

        this.highlightSelectedSource();
    }

    editSource() {
        this.readonly = false;
    }

    saveSource() {
        const params: EditorSourcesEditParams = {
            noticeSourceID: this.currentSource.id,
            actualisSharedBeanFirstecoSourcesListe: this.currentSource
        }

        this.showLoading = true;

        this.firstAOInternServicesService.editorSourcesEdit(params).subscribe(value => {
            this.readonly = true;
            this.showLoading = false;
        });
    }

    addNewSource() {
        const params: AoSourcesListe = {
            libelle: "Nouvelle source",
            status: false,
            typeSource: 'web',
            urlPrincipal: "https://firsteco.fr",
            checkInterval: 0
        };

        this.showLoading = true;

        this.firstAOInternServicesService.editorSourcesAdd(params).subscribe(source => {
            this.readonly = false;
            this.showLoading = false;
            this.allSources.push(source);
            this.currentSource = source;
        });
    }

    highlightSelectedSource() {
        let elems = document.getElementsByClassName('source');

        [].forEach.call(elems, function (el) {
            el.classList.remove("selectedSource");
        });

        if (this.currentSource != null) {
            let noticeToHighlight = document.getElementById('source-' + this.currentSource.id);
            if (noticeToHighlight != null)
                noticeToHighlight.classList.add('selectedSource');
        }
    }
}
