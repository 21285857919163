import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-buyer-search-bar',
    templateUrl: './buyer-search-bar.component.html',
    styleUrls: ['./buyer-search-bar.component.css']
})
export class BuyerSearchBarComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    loadBuyer() {

    }

    addNewBuyer() {

    }

}
