import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {FirstAOCustomerServicesService, FirstAOInternServicesService} from "../../../api/gen/services";
import {CommanditaireCreate} from "../../../api/gen/models/commanditaire-create";
import {MatDialog} from "@angular/material/dialog";
import {FilesManagerComponent} from "../../modal/files-manager/files-manager.component";
import {ComplementaryAddressesManagerComponent} from "../../modal/complementary-addresses-manager/complementary-addresses-manager.component";

@Component({
    selector: 'app-commanditaire-chooser',
    templateUrl: './commanditaire-chooser.component.html',
    styleUrls: ['./commanditaire-chooser.component.css']
})
export class CommanditaireChooserComponent implements OnInit {

    // ----------------------------------------- ReadOnly -----------------------------------------
    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }
    // ----------------------------------------- ReadOnly -----------------------------------------



    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------
    private _noticeSavingInProgress: boolean;

    get noticeSavingInProgress(): boolean {
        return this._noticeSavingInProgress;
    }

    @Input("noticeSavingInProgress")
    set noticeSavingInProgress(val: boolean) {
        this._noticeSavingInProgress = val;
        if (this.noticeSavingInProgress) {
            this.saveCommanditaire();
        }
    }
    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------

    currentAddress;
    currentContact;

    currentBuyer;

    @Output() commanditaireChanged = new EventEmitter<any>();

    @Output() showLoading = new EventEmitter<any>();

    @Input("currentNotice") currentNotice;

    // Ids
    private selectedContactId: number;
    private selectedAddressId: number;

    // Buyer Information
    buyersContacts;
    buyersAddresses;

    constructor(private firstAOInternServicesService: FirstAOInternServicesService,
                public dialog: MatDialog) {
    }

    ngOnInit() {
        this.getAndSetBuyer(false);
    }

    public getAndSetBuyer(resetCAndA) {
        if (this.currentNotice.identiteAcheteurId != null) {
            this.firstAOInternServicesService.getCommanditaireInformationsResponse(this.currentNotice.identiteAcheteurId)
                .subscribe(
                    (buyer) => {
                        this.currentBuyer = buyer.body;
                        this.buyersContacts = buyer.body.contacts;
                        this.buyersAddresses = buyer.body.adresses;

                        if (this.buyersContacts == null)
                            this.buyersContacts = [];
                        if (this.buyersAddresses == null)
                            this.buyersAddresses = [];

                        if (resetCAndA) {
                            this.currentContact = this.buyersContacts[0];
                            this.currentNotice.commanditaireContactId = this.buyersContacts[0].id;
                            this.currentAddress = this.buyersAddresses[0];
                            this.currentNotice.commanditaireAdresseId = this.buyersAddresses[0].id;
                            this.showLoading.emit(false);
                            this.commanditaireChanged.emit();
                        }

                        this.selectedContactId = this.currentNotice.commanditaireContactId;
                        this.selectedAddressId = this.currentNotice.commanditaireAdresseId;

                        this.currentAddress = this.getAddress(this.selectedAddressId);
                        this.currentContact = this.getContact(this.selectedContactId);
                    });
        }
    }

    onAcheteurSelected($event) {
        if ($event != null && $event.buyerID != null && $event.buyerID != this.currentNotice.identiteAcheteurId) {
            this.showLoading.emit(true);
            this.currentNotice.commanditaire = $event;
            this.currentNotice.identiteAcheteurId = $event.buyerID;
            this.getAndSetBuyer(true);
            this.commanditaireChanged.emit();
        }
    }

    addNewBuyer() {
        const params = {
            denomination: "Collectivité",
            status: "active"
        };

        this.showLoading.emit(true);
        this.firstAOInternServicesService.createCommanditaire(params).subscribe(buyer => {
            this.currentNotice.commanditaire = buyer;
            this.currentNotice.identiteAcheteurId = buyer.buyerID;
            let contactAdded: boolean = false;
            let addressAdded: boolean = false;

            // Création d'un nouveau contact
            this.firstAOInternServicesService.createCommanditaireContacts({
                    commanditaireContact: {
                        nom: "Nouveau Contact",
                        status: 1
                    },
                    buyerID: buyer.buyerID
                }
            ).subscribe(value => {
                contactAdded = true;
                if (contactAdded && addressAdded) {
                    this.getAndSetBuyer(true);
                }
            });

            // Création d'une nouvelle adresse
            this.firstAOInternServicesService.createCommanditaireAdresse({
                    commanditaireAdresse: {
                        libelle: "Nouvelle adresse",
                        status: 1
                    },
                    buyerID: buyer.buyerID
                }
            ).subscribe(value => {
                addressAdded = true;
                if (contactAdded && addressAdded) {
                    this.getAndSetBuyer(true);
                }
            });
        });
    }

    getAddress(addressId) {
        let addressToReturn = null;
        let isSearching = this.buyersAddresses != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.buyersAddresses.length) {
                if (this.buyersAddresses[index].id === addressId) {
                    addressToReturn = this.buyersAddresses[index];
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return addressToReturn;
    }

    getContact(contactId) {
        let contactToReturn = null;
        let isSearching = this.buyersContacts != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.buyersContacts.length) {
                if (this.buyersContacts[index].id === contactId) {
                    contactToReturn = this.buyersContacts[index];
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return contactToReturn;
    }

    saveCommanditaire() {
        const commanditaireCreate: CommanditaireCreate = {
            buyerID: this.currentBuyer.buyerID,
            denomination: this.currentBuyer.denomination,
            typeOrganisme: this.currentBuyer.typeOrganisme,
        };

        const params: FirstAOInternServicesService.EditCommanditaireParams = {
            commanditaireCreate: commanditaireCreate,
            buyerID: this.currentBuyer.buyerID
        };

        this.firstAOInternServicesService.editCommanditaire(params).subscribe(value => {
        });
    }

    openComplementaryAddressesManager() {
        const dialogRef = this.dialog.open(ComplementaryAddressesManagerComponent, {
            data: {
                notice: this.currentNotice,
                readOnly: false,
            }
        });
    }

    cAndAReadOnly(): boolean {
        if (this.readOnly || (this.currentBuyer !== null && this.currentBuyer.buyerID === 0))
            return true;
        return false;
    }

    getEntrepriseId(): number {
        if (this.currentBuyer && this.currentBuyer.buyerID != null)
            return this.currentBuyer.buyerID;
        else
            return 0
    }
}
