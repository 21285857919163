<mat-accordion>
    <mat-expansion-panel [expanded]="true" style="background: #ffe9e9; margin-top: 20px;">
        <mat-expansion-panel-header>
            Commanditaire
        </mat-expansion-panel-header>

        <div id="buyerProfil" class="buyerProfilContainer">


            <!-- COMMANDITAIRE -->
            <div class="contactContainer">
                <div class="containerTitle">
                    <div class="containerTitleText">Acheteur</div>
                    <app-ao-search-bar style="display: grid;" class="flexGrow1" [placeHolder]="'Rechercher un acheteur'"
                                       [addItemLabel]="'Ajouter un nouvel acheteur'"
                                       [searchBarType]="'buyer'" (itemSelected)="onAcheteurSelected($event)"
                                       (newItem)="addNewBuyer()" [readonly]="readOnly">
                    </app-ao-search-bar>
                </div>

                <div style="display: flex;">
                    <mat-form-field class="flexGrow1 margR10">
                        <input [readonly]="readOnly" matInput placeholder="Raison sociale *" type="text"
                               [ngModel]="currentBuyer?.denomination"
                               (ngModelChange)="currentBuyer.denomination = $event; commanditaireChanged.emit()">
                    </mat-form-field>

                    <mat-form-field class="flexGrow1 margR10">
                        <input [readonly]="readOnly" matInput placeholder="Type de commanditaire" type="text"
                               [ngModel]="currentBuyer?.typeOrganisme"
                               (ngModelChange)="currentBuyer.typeOrganisme = $event; commanditaireChanged.emit()">
                    </mat-form-field>

                    <mat-form-field class="flexGrow1">
                        <input [readonly]="readOnly" matInput placeholder="URL site web acheteur" type="text"
                               id="noticeBuyerProfilUrl" [ngModel]="currentNotice?.commanditaire?.url"
                               (ngModelChange)="commanditaireChanged.emit()">
                    </mat-form-field>
                </div>
            </div>
            <!-- COMMANDITAIRE -->


            <!-- CONTACT -->
            <app-contact-chooser [currentNotice]="currentNotice" [readonly]="cAndAReadOnly()" [buyersContacts]="buyersContacts"
                                 [entrepriseId]="getEntrepriseId()"
                                 (contactChanged)="commanditaireChanged.emit()" [noticeSavingInProgress]="noticeSavingInProgress"
                                 *ngIf="currentContact" [currentContact]="currentContact" [isPublic]="true">
            </app-contact-chooser>
            <!-- CONTACT -->


            <!-- ADDRESS -->
            <app-address-chooser [currentNotice]="currentNotice" [readonly]="cAndAReadOnly()" [buyersAddresses]="buyersAddresses"
                                 [entrepriseId]="getEntrepriseId()"
                                 (addressChanged)="commanditaireChanged.emit()" [noticeSavingInProgress]="noticeSavingInProgress"
                                 *ngIf="currentAddress" [currentAddress]="currentAddress" [isPublic]="true">
            </app-address-chooser>
            <!-- ADDRESS -->


            <!-- COMPLEMENTARY ADDRESS -->
            <button style="width: 100%" mat-raised-button (click)="openComplementaryAddressesManager()">
                Adresses complémentaires ({{currentNotice.adresses_complementaires?.length}})
            </button>
            <!-- COMPLEMENTARY ADDRESS -->

        </div>

    </mat-expansion-panel>
</mat-accordion>
