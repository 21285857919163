<div class="searchMainContainer">
    <div class="searchList">
        <div style="font-size: 30px; display: block; margin: 10px auto">
            Critères de recherche
        </div>

        <div class="searchCriteresContainer">

            <mat-form-field class="searchCriteresInputContainer">
                <input class="searchCriteresInput" placeholder="Identifiant de l'Appel d'Offres" matInput
                       [(ngModel)]="searchedAoId" (keyup.enter)="searchNotices(true)">
            </mat-form-field>

            <mat-form-field class="searchCriteresInputContainer">
                <input class="searchCriteresInput" placeholder="Identifiant de la source de référence" matInput
                       [(ngModel)]="searchedRefSource" (keyup.enter)="searchNotices(true)">
            </mat-form-field>

            <mat-form-field class="searchCriteresInputContainer">
                <input class="searchCriteresInput" placeholder="Titre de L'Appel d'Offres" matInput
                       [(ngModel)]="searchedTitle" (keyup.enter)="searchNotices(true)">
            </mat-form-field>


            <div class="searchCriteresInputContainer">
                <app-cpv-code-chooser [cpvCodes]="searchedCpvs">
                </app-cpv-code-chooser>
            </div>


            <div class="searchCriteresInputContainer">
                <app-departement-chooser [departements]="searchedDepartements">
                </app-departement-chooser>
            </div>


            <div class="searchCriteresInputContainer">
                <app-acheteur-chooser [acheteurs]="searchedAcheteurs">
                </app-acheteur-chooser>
            </div>


            <div class="dateContainer">
                <mat-form-field style="flex-grow: 1; margin-right: 5px;">
                    <input matInput [matDatepicker]="startPubPick" [(ngModel)]="searchedSPublicationDate"
                           placeholder="Début Publication">
                    <mat-datepicker-toggle matSuffix [for]="startPubPick"></mat-datepicker-toggle>
                    <mat-datepicker #startPubPick></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="flex-grow: 1; margin-left: 5px;">
                    <input matInput [matDatepicker]="endPubPick" [(ngModel)]="searchedEPublicationDate"
                           placeholder="Fin Publication">
                    <mat-datepicker-toggle matSuffix [for]="endPubPick"></mat-datepicker-toggle>
                    <mat-datepicker #endPubPick></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="dateContainer">
                <mat-form-field style="flex-grow: 1; margin-right: 5px;">
                    <input matInput [matDatepicker]="startLimitPick" [(ngModel)]="searchedSLimitDate"
                           placeholder="Début Limite">
                    <mat-datepicker-toggle matSuffix [for]="startLimitPick"></mat-datepicker-toggle>
                    <mat-datepicker #startLimitPick></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="flex-grow: 1; margin-left: 5px;">
                    <input matInput [matDatepicker]="endLimitPick" [(ngModel)]="searchedELimitDate"
                           placeholder="Fin Limite">
                    <mat-datepicker-toggle matSuffix [for]="endLimitPick"></mat-datepicker-toggle>
                    <mat-datepicker #endLimitPick></mat-datepicker>
                </mat-form-field>
            </div>


            <mat-form-field id="typeMarcheSel" class="searchCriteresInputContainer">
                <mat-select #typeMarcheSel placeholder="Type de marché" [(ngModel)]="searchedTypeMarche" multiple
                            matTooltipPosition="above">
                    <mat-option value="works">Travaux</mat-option>
                    <mat-option value="supplies">Fournitures</mat-option>
                    <mat-option value="services">Services</mat-option>
                    <mat-option value="other">Autres</mat-option>
                </mat-select>
            </mat-form-field>


            <div class="searchCriteresInputContainer" style="text-align: center;">
                <mat-checkbox style="margin-right: 10px;" [(ngModel)]="searchedWithLotsAllowed">Recherche par lot</mat-checkbox>
                <mat-slide-toggle [disabled]="!searchedWithLotsAllowed" class="searchCriteresToggle"
                                  [(ngModel)]="searchedWithLots">Avec Lots</mat-slide-toggle>
            </div>



            <mat-form-field class="searchCriteresInputContainer">
                <mat-label>Résumé</mat-label>
                <textarea matInput [(ngModel)]="searchedResume"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>


            <mat-form-field class="searchCriteresInputContainer">
                <mat-label>Corps de L'Appel d'Offres</mat-label>
                <textarea matInput [(ngModel)]="searchedCorps"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
        </div>

        <div class="buttonContainer">
            <button (click)="resetSearchParams()" style="margin-left: 10px; background: #be0000;">Réinitialiser la recherche</button>
            <button (click)="searchNotices(true)" style="margin-right: 10px;">Lancer la recherche</button>
        </div>
    </div>


    <div class="buyerProfilEditorContainer" fxLayout="column" fxFlex="1 1 0" style="height: 100%;">
        <div class="closeArticleButtonContainer">
            <button class="closeArticleButton" *ngIf="currentNoticeId" (click)="currentNoticeId = null">X</button>
        </div>

        <div *ngIf="!currentNoticeId && notices.length > 0" style="height: 100%; display: flex; flex-direction: column;">
            <div style="overflow: auto; flex-grow: 1;">
                <app-notice-resume *ngFor="let notice of notices" [notice]="notice" (noticeClicked)="openNotice($event)"></app-notice-resume>
            </div>
            <div>
                <mat-paginator [length]="totalNotice"
                               [pageSize]="25"
                               [pageIndex]="currentPage"
                               (page)="changePagination($event)"
                               [pageSizeOptions]="[25]">
                </mat-paginator>
            </div>
        </div>

        <app-ao-editor *ngIf="currentNoticeId" style="height: 100%; position: relative;" [isPublic]="isPublic"
                       [isSearchingMode]="true">
        </app-ao-editor>

        <div class="spinnerContainer" *ngIf="showLoading">
            <mat-spinner style="margin: auto;"></mat-spinner>
        </div>
    </div>
</div>
