/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrivateBuyerCreate } from '../models/private-buyer-create';
import { PrivateBuyer } from '../models/private-buyer';
import { CommanditaireAdresse } from '../models/commanditaire-adresse';
import { CommanditaireContact } from '../models/commanditaire-contact';
import { CommanditaireCreate } from '../models/commanditaire-create';
import { Commanditaire } from '../models/commanditaire';
import { CommanditaireUrl } from '../models/commanditaire-url';
import { ContractorQualificationRequestSearch } from '../models/contractor-qualification-request-search';
import { ContractorQualificationRequest } from '../models/contractor-qualification-request';
import { ContractorQualification } from '../models/contractor-qualification';
import { NoticeFacade } from '../models/notice-facade';
import { EntreprisesSearchResults } from '../models/entreprises-search-results';
import { Entreprise } from '../models/entreprise';
import { EntrepriseContact } from '../models/entreprise-contact';
import { AoAdressesComplementaires } from '../models/ao-adresses-complementaires';
import { NoticeAttachment } from '../models/notice-attachment';
import { ResponseWithErrorAndMessages } from '../models/response-with-error-and-messages';
import { AoLieu } from '../models/ao-lieu';
import { AoLots } from '../models/ao-lots';
import { AoSources } from '../models/ao-sources';
import { NoticeUrl } from '../models/notice-url';
import { NoticesEditor } from '../models/notices-editor';
import { QueryCriterions } from '../models/query-criterions';
import { NoticesSearchByTitle } from '../models/notices-search-by-title';
import { AoSourcesListe } from '../models/ao-sources-liste';
import { CustomerUserAo } from '../models/customer-user-ao';
@Injectable({
  providedIn: 'root',
})
class FirstAOInternServicesService extends __BaseService {
  static readonly createEntreprisePath = '/firstao/buyers/private';
  static readonly getEntrepriseInformationsPath = '/firstao/buyers/private/{buyerID}';
  static readonly editEntreprisePath = '/firstao/buyers/private/{buyerID}';
  static readonly createEntrepriseAdressePath = '/firstao/buyers/private/{buyerID}/addresses';
  static readonly editEntrepriseAdressePath = '/firstao/buyers/private/{buyerID}/addresses/{buyerAdresseID}';
  static readonly createEntrepriseContactsPath = '/firstao/buyers/private/{buyerID}/contacts';
  static readonly editEntrepriseContactsPath = '/firstao/buyers/private/{buyerID}/contacts/{contactID}';
  static readonly createCommanditairePath = '/firstao/buyers/public';
  static readonly getCommanditaireInformationsPath = '/firstao/buyers/public/{buyerID}';
  static readonly editCommanditairePath = '/firstao/buyers/public/{buyerID}';
  static readonly createCommanditaireAdressePath = '/firstao/buyers/public/{buyerID}/addresses';
  static readonly editCommanditaireAdressePath = '/firstao/buyers/public/{buyerID}/addresses/{buyerAdresseID}';
  static readonly createCommanditaireContactsPath = '/firstao/buyers/public/{buyerID}/contacts';
  static readonly editCommanditaireContactsPath = '/firstao/buyers/public/{buyerID}/contacts/{contactID}';
  static readonly createCommanditaireUrlPath = '/firstao/buyers/public/{buyerID}/urls';
  static readonly editCommanditaireUrlPath = '/firstao/buyers/public/{buyerID}/urls/{buyerUrlID}';
  static readonly getContractorQualificationRequestPath = '/firstao/contractor_qualification_request';
  static readonly patchContractorQualificationRequestPath = '/firstao/contractor_qualification_request/{requestID}';
  static readonly crawlerAddNoticePath = '/firstao/crawler/notices';
  static readonly editorEnterprisesSearchPath = '/firstao/editor/enterprises/search';
  static readonly editorEditEntreprisePath = '/firstao/editor/enterprises/{entrepriseId}';
  static readonly editorEditEntrepriseFieldPath = '/firstao/editor/enterprises/{entrepriseId}';
  static readonly createNewContactPath = '/firstao/editor/enterprises/{entrepriseId}/contacts';
  static readonly editorEditContactEntreprisePath = '/firstao/editor/enterprises/{entrepriseId}/contacts/{contactID}';
  static readonly createNewEnterprisePath = '/firstao/editor/entreprises';
  static readonly editorShowAoPath = '/firstao/editor/notice/{noticeID}';
  static readonly editorEditNoticePath = '/firstao/editor/notice/{noticeID}';
  static readonly editorAddNoticeAdresseComplementairePath = '/firstao/editor/notice/{noticeID}/adressesComplementaires';
  static readonly editorEditNoticeAdresseComplementairePath = '/firstao/editor/notice/{noticeID}/adressesComplementaires/{noticeAdresseComplementaireID}';
  static readonly editorDeleteNoticeAdresseComplementairePath = '/firstao/editor/notice/{noticeID}/adressesComplementaires/{noticeAdresseComplementaireID}';
  static readonly editorAddNoticeAttachmentPath = '/firstao/editor/notice/{noticeID}/attachments';
  static readonly editorEditNoticeAttachmentPath = '/firstao/editor/notice/{noticeID}/attachments/{attachmentID}';
  static readonly editorDeleteNoticeAttachmentPath = '/firstao/editor/notice/{noticeID}/attachments/{attachmentID}';
  static readonly editorEditNoticeAttachmentStatusPath = '/firstao/editor/notice/{noticeID}/attachmentsStatus/{attachmentID}/{attachmentStatus}';
  static readonly editorChangePublicationStateNoticePath = '/firstao/editor/notice/{noticeID}/changeState';
  static readonly editorCheckNoticePath = '/firstao/editor/notice/{noticeID}/checkIsOk';
  static readonly editorAddNoticeLieuPath = '/firstao/editor/notice/{noticeID}/lieux';
  static readonly editorEditNoticeLieuPath = '/firstao/editor/notice/{noticeID}/lieux/{noticeLieuID}';
  static readonly editorDeleteNoticeLieuPath = '/firstao/editor/notice/{noticeID}/lieux/{noticeLieuID}';
  static readonly editorAddNoticeLotsPath = '/firstao/editor/notice/{noticeID}/lots';
  static readonly editorEditNoticeLotsPath = '/firstao/editor/notice/{noticeID}/lots/{noticeLotID}';
  static readonly editorDeleteNoticeLotsPath = '/firstao/editor/notice/{noticeID}/lots/{noticeLotID}';
  static readonly editorAddNoticeSourcePath = '/firstao/editor/notice/{noticeID}/sources';
  static readonly editorEditNoticeSourcePath = '/firstao/editor/notice/{noticeID}/sources/{noticeSourceID}';
  static readonly editorDeleteNoticeSourcePath = '/firstao/editor/notice/{noticeID}/sources/{noticeSourceID}';
  static readonly editorEditNoticeUrlsPath = '/firstao/editor/notice/{noticeID}/urls';
  static readonly editorLockNoticePath = '/firstao/editor/notice_lock';
  static readonly editorUnLockNoticePath = '/firstao/editor/notice_lock';
  static readonly editorAddNoticePath = '/firstao/editor/notices';
  static readonly editorNoticesLastAddedPath = '/firstao/editor/notices/lastadded';
  static readonly editorSearchNoticesPath = '/firstao/editor/notices/search';
  static readonly editorSearchByTitleNoticesPath = '/firstao/editor/notices/searchByTitle';
  static readonly editorNoticesToProcessPath = '/firstao/editor/notices/toprocess';
  static readonly editorSourcesEditPath = '/firstao/editor/source/{noticeSourceID}';
  static readonly editorSourcesPath = '/firstao/editor/sources';
  static readonly editorSourcesAddPath = '/firstao/editor/sources';
  static readonly postUserCreatePath = '/firstao/users/create';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Créer un acheteur privé
   * @param privateBuyerCreate Un objet représentant un acheteur privé
   * @return Acheteur privé
   */
  createEntrepriseResponse(privateBuyerCreate: PrivateBuyerCreate): __Observable<__StrictHttpResponse<PrivateBuyerCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = privateBuyerCreate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/private`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrivateBuyerCreate>;
      })
    );
  }
  /**
   * Créer un acheteur privé
   * @param privateBuyerCreate Un objet représentant un acheteur privé
   * @return Acheteur privé
   */
  createEntreprise(privateBuyerCreate: PrivateBuyerCreate): __Observable<PrivateBuyerCreate> {
    return this.createEntrepriseResponse(privateBuyerCreate).pipe(
      __map(_r => _r.body as PrivateBuyerCreate)
    );
  }

  /**
   * Retourne les informations associées à un acheteur privé.
   * @param buyerID L'identifiant d'un commanditaire
   * @return Acheteur privé
   */
  getEntrepriseInformationsResponse(buyerID: number): __Observable<__StrictHttpResponse<PrivateBuyer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/buyers/private/${buyerID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrivateBuyer>;
      })
    );
  }
  /**
   * Retourne les informations associées à un acheteur privé.
   * @param buyerID L'identifiant d'un commanditaire
   * @return Acheteur privé
   */
  getEntrepriseInformations(buyerID: number): __Observable<PrivateBuyer> {
    return this.getEntrepriseInformationsResponse(buyerID).pipe(
      __map(_r => _r.body as PrivateBuyer)
    );
  }

  /**
   * Editer un acheteur privé
   * @param params The `FirstAOInternServicesService.EditEntrepriseParams` containing the following parameters:
   *
   * - `privateBuyerCreate`: Un objet représentant un acheteur privé
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Acheteur privé
   */
  editEntrepriseResponse(params: FirstAOInternServicesService.EditEntrepriseParams): __Observable<__StrictHttpResponse<PrivateBuyerCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.privateBuyerCreate;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/private/${params.buyerID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrivateBuyerCreate>;
      })
    );
  }
  /**
   * Editer un acheteur privé
   * @param params The `FirstAOInternServicesService.EditEntrepriseParams` containing the following parameters:
   *
   * - `privateBuyerCreate`: Un objet représentant un acheteur privé
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Acheteur privé
   */
  editEntreprise(params: FirstAOInternServicesService.EditEntrepriseParams): __Observable<PrivateBuyerCreate> {
    return this.editEntrepriseResponse(params).pipe(
      __map(_r => _r.body as PrivateBuyerCreate)
    );
  }

  /**
   * Création d'une adresse d'un acheteur privé.
   * @param params The `FirstAOInternServicesService.CreateEntrepriseAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  createEntrepriseAdresseResponse(params: FirstAOInternServicesService.CreateEntrepriseAdresseParams): __Observable<__StrictHttpResponse<CommanditaireAdresse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireAdresse;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/private/${params.buyerID}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireAdresse>;
      })
    );
  }
  /**
   * Création d'une adresse d'un acheteur privé.
   * @param params The `FirstAOInternServicesService.CreateEntrepriseAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  createEntrepriseAdresse(params: FirstAOInternServicesService.CreateEntrepriseAdresseParams): __Observable<CommanditaireAdresse> {
    return this.createEntrepriseAdresseResponse(params).pipe(
      __map(_r => _r.body as CommanditaireAdresse)
    );
  }

  /**
   * Edition une adresse d'un acheteur privé
   * @param params The `FirstAOInternServicesService.EditEntrepriseAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * - `buyerAdresseID`: L'identifiant d'une adresse d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  editEntrepriseAdresseResponse(params: FirstAOInternServicesService.EditEntrepriseAdresseParams): __Observable<__StrictHttpResponse<CommanditaireAdresse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireAdresse;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/private/${params.buyerID}/addresses/${params.buyerAdresseID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireAdresse>;
      })
    );
  }
  /**
   * Edition une adresse d'un acheteur privé
   * @param params The `FirstAOInternServicesService.EditEntrepriseAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * - `buyerAdresseID`: L'identifiant d'une adresse d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  editEntrepriseAdresse(params: FirstAOInternServicesService.EditEntrepriseAdresseParams): __Observable<CommanditaireAdresse> {
    return this.editEntrepriseAdresseResponse(params).pipe(
      __map(_r => _r.body as CommanditaireAdresse)
    );
  }

  /**
   * Création d'un contact d'un acheteur privé.
   * @param params The `FirstAOInternServicesService.CreateEntrepriseContactsParams` containing the following parameters:
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  createEntrepriseContactsResponse(params: FirstAOInternServicesService.CreateEntrepriseContactsParams): __Observable<__StrictHttpResponse<CommanditaireContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireContact;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/private/${params.buyerID}/contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireContact>;
      })
    );
  }
  /**
   * Création d'un contact d'un acheteur privé.
   * @param params The `FirstAOInternServicesService.CreateEntrepriseContactsParams` containing the following parameters:
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  createEntrepriseContacts(params: FirstAOInternServicesService.CreateEntrepriseContactsParams): __Observable<CommanditaireContact> {
    return this.createEntrepriseContactsResponse(params).pipe(
      __map(_r => _r.body as CommanditaireContact)
    );
  }

  /**
   * Edition d'un contact d'un acheteur privé.
   * @param params The `FirstAOInternServicesService.EditEntrepriseContactsParams` containing the following parameters:
   *
   * - `contactID`: L'identifiant d'un contact d'un commanditaire
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  editEntrepriseContactsResponse(params: FirstAOInternServicesService.EditEntrepriseContactsParams): __Observable<__StrictHttpResponse<CommanditaireContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.commanditaireContact;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/private/${params.buyerID}/contacts/${params.contactID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireContact>;
      })
    );
  }
  /**
   * Edition d'un contact d'un acheteur privé.
   * @param params The `FirstAOInternServicesService.EditEntrepriseContactsParams` containing the following parameters:
   *
   * - `contactID`: L'identifiant d'un contact d'un commanditaire
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  editEntrepriseContacts(params: FirstAOInternServicesService.EditEntrepriseContactsParams): __Observable<CommanditaireContact> {
    return this.editEntrepriseContactsResponse(params).pipe(
      __map(_r => _r.body as CommanditaireContact)
    );
  }

  /**
   * Créer un commanditaire public.
   * @param commanditaireCreate Un objet représentant un commanditaire
   * @return Acheteur public
   */
  createCommanditaireResponse(commanditaireCreate: CommanditaireCreate): __Observable<__StrictHttpResponse<CommanditaireCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = commanditaireCreate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireCreate>;
      })
    );
  }
  /**
   * Créer un commanditaire public.
   * @param commanditaireCreate Un objet représentant un commanditaire
   * @return Acheteur public
   */
  createCommanditaire(commanditaireCreate: CommanditaireCreate): __Observable<CommanditaireCreate> {
    return this.createCommanditaireResponse(commanditaireCreate).pipe(
      __map(_r => _r.body as CommanditaireCreate)
    );
  }

  /**
   * Retourne les informations associées à un commanditaire.
   * @param buyerID L'identifiant d'un commanditaire
   * @return Acheteur public
   */
  getCommanditaireInformationsResponse(buyerID: number): __Observable<__StrictHttpResponse<Commanditaire>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/buyers/public/${buyerID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Commanditaire>;
      })
    );
  }
  /**
   * Retourne les informations associées à un commanditaire.
   * @param buyerID L'identifiant d'un commanditaire
   * @return Acheteur public
   */
  getCommanditaireInformations(buyerID: number): __Observable<Commanditaire> {
    return this.getCommanditaireInformationsResponse(buyerID).pipe(
      __map(_r => _r.body as Commanditaire)
    );
  }

  /**
   * Editer un commanditaire public
   * @param params The `FirstAOInternServicesService.EditCommanditaireParams` containing the following parameters:
   *
   * - `commanditaireCreate`: Un objet représentant un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Acheteur public
   */
  editCommanditaireResponse(params: FirstAOInternServicesService.EditCommanditaireParams): __Observable<__StrictHttpResponse<CommanditaireCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireCreate;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireCreate>;
      })
    );
  }
  /**
   * Editer un commanditaire public
   * @param params The `FirstAOInternServicesService.EditCommanditaireParams` containing the following parameters:
   *
   * - `commanditaireCreate`: Un objet représentant un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Acheteur public
   */
  editCommanditaire(params: FirstAOInternServicesService.EditCommanditaireParams): __Observable<CommanditaireCreate> {
    return this.editCommanditaireResponse(params).pipe(
      __map(_r => _r.body as CommanditaireCreate)
    );
  }

  /**
   * Création d'une adresse d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.CreateCommanditaireAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  createCommanditaireAdresseResponse(params: FirstAOInternServicesService.CreateCommanditaireAdresseParams): __Observable<__StrictHttpResponse<CommanditaireAdresse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireAdresse;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireAdresse>;
      })
    );
  }
  /**
   * Création d'une adresse d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.CreateCommanditaireAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  createCommanditaireAdresse(params: FirstAOInternServicesService.CreateCommanditaireAdresseParams): __Observable<CommanditaireAdresse> {
    return this.createCommanditaireAdresseResponse(params).pipe(
      __map(_r => _r.body as CommanditaireAdresse)
    );
  }

  /**
   * Edition une adresse d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.EditCommanditaireAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * - `buyerAdresseID`: L'identifiant d'une adresse d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  editCommanditaireAdresseResponse(params: FirstAOInternServicesService.EditCommanditaireAdresseParams): __Observable<__StrictHttpResponse<CommanditaireAdresse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireAdresse;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}/addresses/${params.buyerAdresseID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireAdresse>;
      })
    );
  }
  /**
   * Edition une adresse d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.EditCommanditaireAdresseParams` containing the following parameters:
   *
   * - `commanditaireAdresse`: Un objet représentant une adresse d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * - `buyerAdresseID`: L'identifiant d'une adresse d'un commanditaire
   *
   * @return Adresse d'un commanditaire
   */
  editCommanditaireAdresse(params: FirstAOInternServicesService.EditCommanditaireAdresseParams): __Observable<CommanditaireAdresse> {
    return this.editCommanditaireAdresseResponse(params).pipe(
      __map(_r => _r.body as CommanditaireAdresse)
    );
  }

  /**
   * Création d'un contact d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.CreateCommanditaireContactsParams` containing the following parameters:
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  createCommanditaireContactsResponse(params: FirstAOInternServicesService.CreateCommanditaireContactsParams): __Observable<__StrictHttpResponse<CommanditaireContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireContact;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}/contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireContact>;
      })
    );
  }
  /**
   * Création d'un contact d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.CreateCommanditaireContactsParams` containing the following parameters:
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  createCommanditaireContacts(params: FirstAOInternServicesService.CreateCommanditaireContactsParams): __Observable<CommanditaireContact> {
    return this.createCommanditaireContactsResponse(params).pipe(
      __map(_r => _r.body as CommanditaireContact)
    );
  }

  /**
   * Edition d'un contact d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.EditCommanditaireContactsParams` containing the following parameters:
   *
   * - `contactID`: L'identifiant d'un contact d'un commanditaire
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  editCommanditaireContactsResponse(params: FirstAOInternServicesService.EditCommanditaireContactsParams): __Observable<__StrictHttpResponse<CommanditaireContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.commanditaireContact;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}/contacts/${params.contactID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireContact>;
      })
    );
  }
  /**
   * Edition d'un contact d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.EditCommanditaireContactsParams` containing the following parameters:
   *
   * - `contactID`: L'identifiant d'un contact d'un commanditaire
   *
   * - `commanditaireContact`: Un objet représentant un contact d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Contact d'un commanditaire
   */
  editCommanditaireContacts(params: FirstAOInternServicesService.EditCommanditaireContactsParams): __Observable<CommanditaireContact> {
    return this.editCommanditaireContactsResponse(params).pipe(
      __map(_r => _r.body as CommanditaireContact)
    );
  }

  /**
   * Création d'une url d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.CreateCommanditaireUrlParams` containing the following parameters:
   *
   * - `commanditaireUrl`: Un objet représentant une url d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Url d'un commanditaire public
   */
  createCommanditaireUrlResponse(params: FirstAOInternServicesService.CreateCommanditaireUrlParams): __Observable<__StrictHttpResponse<CommanditaireUrl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireUrl;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}/urls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireUrl>;
      })
    );
  }
  /**
   * Création d'une url d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.CreateCommanditaireUrlParams` containing the following parameters:
   *
   * - `commanditaireUrl`: Un objet représentant une url d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Url d'un commanditaire public
   */
  createCommanditaireUrl(params: FirstAOInternServicesService.CreateCommanditaireUrlParams): __Observable<CommanditaireUrl> {
    return this.createCommanditaireUrlResponse(params).pipe(
      __map(_r => _r.body as CommanditaireUrl)
    );
  }

  /**
   * Edition une url d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.EditCommanditaireUrlParams` containing the following parameters:
   *
   * - `commanditaireUrl`: Un objet représentant une url d'un commanditaire
   *
   * - `buyerUrlID`: L'identifiant d'une url d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Url d'un commanditaire public
   */
  editCommanditaireUrlResponse(params: FirstAOInternServicesService.EditCommanditaireUrlParams): __Observable<__StrictHttpResponse<CommanditaireUrl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.commanditaireUrl;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/buyers/public/${params.buyerID}/urls/${params.buyerUrlID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommanditaireUrl>;
      })
    );
  }
  /**
   * Edition une url d'un commanditaire public.
   * @param params The `FirstAOInternServicesService.EditCommanditaireUrlParams` containing the following parameters:
   *
   * - `commanditaireUrl`: Un objet représentant une url d'un commanditaire
   *
   * - `buyerUrlID`: L'identifiant d'une url d'un commanditaire
   *
   * - `buyerID`: L'identifiant d'un commanditaire
   *
   * @return Url d'un commanditaire public
   */
  editCommanditaireUrl(params: FirstAOInternServicesService.EditCommanditaireUrlParams): __Observable<CommanditaireUrl> {
    return this.editCommanditaireUrlResponse(params).pipe(
      __map(_r => _r.body as CommanditaireUrl)
    );
  }

  /**
   * Retourne la liste des demandes de qualification d'un titulaire de marché
   * @param params The `FirstAOInternServicesService.GetContractorQualificationRequestParams` containing the following parameters:
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `isProcessed`: Indique le statut à traiter/déjà traité
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return Demandes de qualification d'un titulaire de marché
   */
  getContractorQualificationRequestResponse(params: FirstAOInternServicesService.GetContractorQualificationRequestParams): __Observable<__StrictHttpResponse<ContractorQualificationRequestSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.isProcessed != null) __params = __params.set('isProcessed', params.isProcessed.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/contractor_qualification_request`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContractorQualificationRequestSearch>;
      })
    );
  }
  /**
   * Retourne la liste des demandes de qualification d'un titulaire de marché
   * @param params The `FirstAOInternServicesService.GetContractorQualificationRequestParams` containing the following parameters:
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `isProcessed`: Indique le statut à traiter/déjà traité
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return Demandes de qualification d'un titulaire de marché
   */
  getContractorQualificationRequest(params: FirstAOInternServicesService.GetContractorQualificationRequestParams): __Observable<ContractorQualificationRequestSearch> {
    return this.getContractorQualificationRequestResponse(params).pipe(
      __map(_r => _r.body as ContractorQualificationRequestSearch)
    );
  }

  /**
   * Envoie une mise à jour de qualification d'un titulaire de marché
   * @param params The `FirstAOInternServicesService.PatchContractorQualificationRequestParams` containing the following parameters:
   *
   * - `requestID`: L'identifiant interne d'une requête de qualification de titulaire de marché
   *
   * - `contractorQualification`: Un objet représentant une qualification de titulaire de marché
   *
   * @return Demande de qualification d'un titulaire de marché
   */
  patchContractorQualificationRequestResponse(params: FirstAOInternServicesService.PatchContractorQualificationRequestParams): __Observable<__StrictHttpResponse<ContractorQualificationRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.contractorQualification;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/firstao/contractor_qualification_request/${params.requestID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContractorQualificationRequest>;
      })
    );
  }
  /**
   * Envoie une mise à jour de qualification d'un titulaire de marché
   * @param params The `FirstAOInternServicesService.PatchContractorQualificationRequestParams` containing the following parameters:
   *
   * - `requestID`: L'identifiant interne d'une requête de qualification de titulaire de marché
   *
   * - `contractorQualification`: Un objet représentant une qualification de titulaire de marché
   *
   * @return Demande de qualification d'un titulaire de marché
   */
  patchContractorQualificationRequest(params: FirstAOInternServicesService.PatchContractorQualificationRequestParams): __Observable<ContractorQualificationRequest> {
    return this.patchContractorQualificationRequestResponse(params).pipe(
      __map(_r => _r.body as ContractorQualificationRequest)
    );
  }

  /**
   * Créer un avis de marché.
   * @param actualisApiFacadeNoticeFacade Un objet représentant un avis de marché
   * @return Les données d'un avis de marché
   */
  crawlerAddNoticeResponse(actualisApiFacadeNoticeFacade: NoticeFacade): __Observable<__StrictHttpResponse<NoticeFacade>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = actualisApiFacadeNoticeFacade;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/crawler/notices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeFacade>;
      })
    );
  }
  /**
   * Créer un avis de marché.
   * @param actualisApiFacadeNoticeFacade Un objet représentant un avis de marché
   * @return Les données d'un avis de marché
   */
  crawlerAddNotice(actualisApiFacadeNoticeFacade: NoticeFacade): __Observable<NoticeFacade> {
    return this.crawlerAddNoticeResponse(actualisApiFacadeNoticeFacade).pipe(
      __map(_r => _r.body as NoticeFacade)
    );
  }

  /**
   * Recherche des entreprises.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return La liste des entreprises
   */
  editorEnterprisesSearchResponse(query?: string): __Observable<__StrictHttpResponse<EntreprisesSearchResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/editor/enterprises/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntreprisesSearchResults>;
      })
    );
  }
  /**
   * Recherche des entreprises.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return La liste des entreprises
   */
  editorEnterprisesSearch(query?: string): __Observable<EntreprisesSearchResults> {
    return this.editorEnterprisesSearchResponse(query).pipe(
      __map(_r => _r.body as EntreprisesSearchResults)
    );
  }

  /**
   * Editer les informations d’une entreprise
   * @param params The `FirstAOInternServicesService.EditorEditEntrepriseParams` containing the following parameters:
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * - `entreprise`: Un objet représentant une entreprise
   *
   * @return Une entreprise
   */
  editorEditEntrepriseResponse(params: FirstAOInternServicesService.EditorEditEntrepriseParams): __Observable<__StrictHttpResponse<Entreprise>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entreprise;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/enterprises/${params.entrepriseId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Entreprise>;
      })
    );
  }
  /**
   * Editer les informations d’une entreprise
   * @param params The `FirstAOInternServicesService.EditorEditEntrepriseParams` containing the following parameters:
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * - `entreprise`: Un objet représentant une entreprise
   *
   * @return Une entreprise
   */
  editorEditEntreprise(params: FirstAOInternServicesService.EditorEditEntrepriseParams): __Observable<Entreprise> {
    return this.editorEditEntrepriseResponse(params).pipe(
      __map(_r => _r.body as Entreprise)
    );
  }

  /**
   * Editer des champs spécifiques d’une entreprise
   * @param params The `FirstAOInternServicesService.EditorEditEntrepriseFieldParams` containing the following parameters:
   *
   * - `value`: Valeur d'un champ édité
   *
   * - `field`: Champ spécifique édité
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * @return Une entreprise
   */
  editorEditEntrepriseFieldResponse(params: FirstAOInternServicesService.EditorEditEntrepriseFieldParams): __Observable<__StrictHttpResponse<Entreprise>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.value != null) __params = __params.set('value', params.value.toString());
    if (params.field != null) __params = __params.set('field', params.field.toString());

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/firstao/editor/enterprises/${params.entrepriseId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Entreprise>;
      })
    );
  }
  /**
   * Editer des champs spécifiques d’une entreprise
   * @param params The `FirstAOInternServicesService.EditorEditEntrepriseFieldParams` containing the following parameters:
   *
   * - `value`: Valeur d'un champ édité
   *
   * - `field`: Champ spécifique édité
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * @return Une entreprise
   */
  editorEditEntrepriseField(params: FirstAOInternServicesService.EditorEditEntrepriseFieldParams): __Observable<Entreprise> {
    return this.editorEditEntrepriseFieldResponse(params).pipe(
      __map(_r => _r.body as Entreprise)
    );
  }

  /**
   * Crée un contact d'une entreprise
   * @param params The `FirstAOInternServicesService.CreateNewContactParams` containing the following parameters:
   *
   * - `searchValue`: Valeur envoyée pour la création
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * @return Un contact dans une entreprise
   */
  createNewContactResponse(params: FirstAOInternServicesService.CreateNewContactParams): __Observable<__StrictHttpResponse<EntrepriseContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchValue != null) __params = __params.set('searchValue', params.searchValue.toString());

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/enterprises/${params.entrepriseId}/contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntrepriseContact>;
      })
    );
  }
  /**
   * Crée un contact d'une entreprise
   * @param params The `FirstAOInternServicesService.CreateNewContactParams` containing the following parameters:
   *
   * - `searchValue`: Valeur envoyée pour la création
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * @return Un contact dans une entreprise
   */
  createNewContact(params: FirstAOInternServicesService.CreateNewContactParams): __Observable<EntrepriseContact> {
    return this.createNewContactResponse(params).pipe(
      __map(_r => _r.body as EntrepriseContact)
    );
  }

  /**
   * Editer les informations d’un contact d'une entreprise
   * @param params The `FirstAOInternServicesService.EditorEditContactEntrepriseParams` containing the following parameters:
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * - `entrepriseContact`: Un contact dans une entreprise
   *
   * - `contactID`: L'identifiant d'un contact d'un commanditaire
   *
   * @return Un contact dans une entreprise
   */
  editorEditContactEntrepriseResponse(params: FirstAOInternServicesService.EditorEditContactEntrepriseParams): __Observable<__StrictHttpResponse<EntrepriseContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entrepriseContact;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/enterprises/${params.entrepriseId}/contacts/${params.contactID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntrepriseContact>;
      })
    );
  }
  /**
   * Editer les informations d’un contact d'une entreprise
   * @param params The `FirstAOInternServicesService.EditorEditContactEntrepriseParams` containing the following parameters:
   *
   * - `entrepriseId`: L'identifiant d'une entreprise
   *
   * - `entrepriseContact`: Un contact dans une entreprise
   *
   * - `contactID`: L'identifiant d'un contact d'un commanditaire
   *
   * @return Un contact dans une entreprise
   */
  editorEditContactEntreprise(params: FirstAOInternServicesService.EditorEditContactEntrepriseParams): __Observable<EntrepriseContact> {
    return this.editorEditContactEntrepriseResponse(params).pipe(
      __map(_r => _r.body as EntrepriseContact)
    );
  }

  /**
   * Crée une nouvelle entreprise
   * @param searchValue Valeur envoyée pour la création
   * @return Une entreprise
   */
  createNewEnterpriseResponse(searchValue: string): __Observable<__StrictHttpResponse<Entreprise>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (searchValue != null) __params = __params.set('searchValue', searchValue.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/entreprises`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Entreprise>;
      })
    );
  }
  /**
   * Crée une nouvelle entreprise
   * @param searchValue Valeur envoyée pour la création
   * @return Une entreprise
   */
  createNewEnterprise(searchValue: string): __Observable<Entreprise> {
    return this.createNewEnterpriseResponse(searchValue).pipe(
      __map(_r => _r.body as Entreprise)
    );
  }

  /**
   * Consulter les détails d'un appel d'offre
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Les données d'un avis de marché
   */
  editorShowAoResponse(noticeID: number): __Observable<__StrictHttpResponse<NoticeFacade>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/editor/notice/${noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeFacade>;
      })
    );
  }
  /**
   * Consulter les détails d'un appel d'offre
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Les données d'un avis de marché
   */
  editorShowAo(noticeID: number): __Observable<NoticeFacade> {
    return this.editorShowAoResponse(noticeID).pipe(
      __map(_r => _r.body as NoticeFacade)
    );
  }

  /**
   * Editer un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisApiFacadeNoticeFacade`: Un objet représentant un avis de marché
   *
   * @return Les données d'un avis de marché
   */
  editorEditNoticeResponse(params: FirstAOInternServicesService.EditorEditNoticeParams): __Observable<__StrictHttpResponse<NoticeFacade>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.actualisApiFacadeNoticeFacade;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeFacade>;
      })
    );
  }
  /**
   * Editer un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisApiFacadeNoticeFacade`: Un objet représentant un avis de marché
   *
   * @return Les données d'un avis de marché
   */
  editorEditNotice(params: FirstAOInternServicesService.EditorEditNoticeParams): __Observable<NoticeFacade> {
    return this.editorEditNoticeResponse(params).pipe(
      __map(_r => _r.body as NoticeFacade)
    );
  }

  /**
   * Ajouter une adresse complémentaire à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeAdresseComplementaireParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoAdressesComplementaires`: Un objet représentant une adresse complémentaire d'un avis de marché
   *
   * @return Les données d'une adresse complémentaire d'un avis de marché
   */
  editorAddNoticeAdresseComplementaireResponse(params: FirstAOInternServicesService.EditorAddNoticeAdresseComplementaireParams): __Observable<__StrictHttpResponse<AoAdressesComplementaires>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.actualisSharedBeanFirstecoAoAdressesComplementaires;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/adressesComplementaires`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoAdressesComplementaires>;
      })
    );
  }
  /**
   * Ajouter une adresse complémentaire à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeAdresseComplementaireParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoAdressesComplementaires`: Un objet représentant une adresse complémentaire d'un avis de marché
   *
   * @return Les données d'une adresse complémentaire d'un avis de marché
   */
  editorAddNoticeAdresseComplementaire(params: FirstAOInternServicesService.EditorAddNoticeAdresseComplementaireParams): __Observable<AoAdressesComplementaires> {
    return this.editorAddNoticeAdresseComplementaireResponse(params).pipe(
      __map(_r => _r.body as AoAdressesComplementaires)
    );
  }

  /**
   * Editer une adresse complémentaire d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeAdresseComplementaireParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `noticeAdresseComplementaireID`: L'identifiant interne d'une adresse complémentaire d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoAdressesComplementaires`: Un objet représentant une adresse complémentaire d'un avis de marché
   *
   * @return Les données d'une adresse complémentaire d'un avis de marché
   */
  editorEditNoticeAdresseComplementaireResponse(params: FirstAOInternServicesService.EditorEditNoticeAdresseComplementaireParams): __Observable<__StrictHttpResponse<AoAdressesComplementaires>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.actualisSharedBeanFirstecoAoAdressesComplementaires;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/adressesComplementaires/${params.noticeAdresseComplementaireID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoAdressesComplementaires>;
      })
    );
  }
  /**
   * Editer une adresse complémentaire d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeAdresseComplementaireParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `noticeAdresseComplementaireID`: L'identifiant interne d'une adresse complémentaire d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoAdressesComplementaires`: Un objet représentant une adresse complémentaire d'un avis de marché
   *
   * @return Les données d'une adresse complémentaire d'un avis de marché
   */
  editorEditNoticeAdresseComplementaire(params: FirstAOInternServicesService.EditorEditNoticeAdresseComplementaireParams): __Observable<AoAdressesComplementaires> {
    return this.editorEditNoticeAdresseComplementaireResponse(params).pipe(
      __map(_r => _r.body as AoAdressesComplementaires)
    );
  }

  /**
   * Supprimer une adresse complémentaire d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeAdresseComplementaireParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `noticeAdresseComplementaireID`: L'identifiant interne d'une adresse complémentaire d'un avis de marché
   */
  editorDeleteNoticeAdresseComplementaireResponse(params: FirstAOInternServicesService.EditorDeleteNoticeAdresseComplementaireParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/adressesComplementaires/${params.noticeAdresseComplementaireID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprimer une adresse complémentaire d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeAdresseComplementaireParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `noticeAdresseComplementaireID`: L'identifiant interne d'une adresse complémentaire d'un avis de marché
   */
  editorDeleteNoticeAdresseComplementaire(params: FirstAOInternServicesService.EditorDeleteNoticeAdresseComplementaireParams): __Observable<null> {
    return this.editorDeleteNoticeAdresseComplementaireResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Ajouter un fichier à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `file`: file detail
   *
   * @return Les données d'un fichier joint d'un avis de marché
   */
  editorAddNoticeAttachmentResponse(params: FirstAOInternServicesService.EditorAddNoticeAttachmentParams): __Observable<__StrictHttpResponse<NoticeAttachment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeAttachment>;
      })
    );
  }
  /**
   * Ajouter un fichier à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `file`: file detail
   *
   * @return Les données d'un fichier joint d'un avis de marché
   */
  editorAddNoticeAttachment(params: FirstAOInternServicesService.EditorAddNoticeAttachmentParams): __Observable<NoticeAttachment> {
    return this.editorAddNoticeAttachmentResponse(params).pipe(
      __map(_r => _r.body as NoticeAttachment)
    );
  }

  /**
   * Editer un fichier d'un avis de marché. Permet d'écraser le fichier et de changer son nom.
   * @param params The `FirstAOInternServicesService.EditorEditNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   *
   * - `filename`: filename
   *
   * - `file`: file detail
   *
   * @return Les données d'un fichier joint d'un avis de marché
   */
  editorEditNoticeAttachmentResponse(params: FirstAOInternServicesService.EditorEditNoticeAttachmentParams): __Observable<__StrictHttpResponse<NoticeAttachment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.filename != null) __params = __params.set('filename', params.filename.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/attachments/${params.attachmentID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeAttachment>;
      })
    );
  }
  /**
   * Editer un fichier d'un avis de marché. Permet d'écraser le fichier et de changer son nom.
   * @param params The `FirstAOInternServicesService.EditorEditNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   *
   * - `filename`: filename
   *
   * - `file`: file detail
   *
   * @return Les données d'un fichier joint d'un avis de marché
   */
  editorEditNoticeAttachment(params: FirstAOInternServicesService.EditorEditNoticeAttachmentParams): __Observable<NoticeAttachment> {
    return this.editorEditNoticeAttachmentResponse(params).pipe(
      __map(_r => _r.body as NoticeAttachment)
    );
  }

  /**
   * Supprimer un fichier d'un avis de marché.
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   */
  editorDeleteNoticeAttachmentResponse(params: FirstAOInternServicesService.EditorDeleteNoticeAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/attachments/${params.attachmentID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprimer un fichier d'un avis de marché.
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   */
  editorDeleteNoticeAttachment(params: FirstAOInternServicesService.EditorDeleteNoticeAttachmentParams): __Observable<null> {
    return this.editorDeleteNoticeAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Editer le statut d'un fichier d'un avis de marché. 1 : actif, 0 : désactivé, 2 : supprimé
   * @param params The `FirstAOInternServicesService.EditorEditNoticeAttachmentStatusParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `attachmentStatus`: Le statut d'un fichier d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   *
   * @return Les données d'un fichier joint d'un avis de marché
   */
  editorEditNoticeAttachmentStatusResponse(params: FirstAOInternServicesService.EditorEditNoticeAttachmentStatusParams): __Observable<__StrictHttpResponse<NoticeAttachment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/attachmentsStatus/${params.attachmentID}/${params.attachmentStatus}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeAttachment>;
      })
    );
  }
  /**
   * Editer le statut d'un fichier d'un avis de marché. 1 : actif, 0 : désactivé, 2 : supprimé
   * @param params The `FirstAOInternServicesService.EditorEditNoticeAttachmentStatusParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `attachmentStatus`: Le statut d'un fichier d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   *
   * @return Les données d'un fichier joint d'un avis de marché
   */
  editorEditNoticeAttachmentStatus(params: FirstAOInternServicesService.EditorEditNoticeAttachmentStatusParams): __Observable<NoticeAttachment> {
    return this.editorEditNoticeAttachmentStatusResponse(params).pipe(
      __map(_r => _r.body as NoticeAttachment)
    );
  }

  /**
   * Editer le statut de publication d'un avis de marché. Si demande de publication de l'avis de marché, le système vérifie si l'AO est complet et autorise ou non le changement de statut.
   * @param params The `FirstAOInternServicesService.EditorChangePublicationStateNoticeParams` containing the following parameters:
   *
   * - `publicationState`: L'état de publication d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * @return Le retour d'une demande de changement de statut d'un avis de marché
   */
  editorChangePublicationStateNoticeResponse(params: FirstAOInternServicesService.EditorChangePublicationStateNoticeParams): __Observable<__StrictHttpResponse<ResponseWithErrorAndMessages>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.publicationState != null) __params = __params.set('publicationState', params.publicationState.toString());

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/changeState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseWithErrorAndMessages>;
      })
    );
  }
  /**
   * Editer le statut de publication d'un avis de marché. Si demande de publication de l'avis de marché, le système vérifie si l'AO est complet et autorise ou non le changement de statut.
   * @param params The `FirstAOInternServicesService.EditorChangePublicationStateNoticeParams` containing the following parameters:
   *
   * - `publicationState`: L'état de publication d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * @return Le retour d'une demande de changement de statut d'un avis de marché
   */
  editorChangePublicationStateNotice(params: FirstAOInternServicesService.EditorChangePublicationStateNoticeParams): __Observable<ResponseWithErrorAndMessages> {
    return this.editorChangePublicationStateNoticeResponse(params).pipe(
      __map(_r => _r.body as ResponseWithErrorAndMessages)
    );
  }

  /**
   * Editer le statut de publication d'un avis de marché. Si demande de publication de l'avis de marché, le système vérifie si l'AO est complet et autorise ou non le changement de statut.
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Le retour d'une demande de changement de statut d'un avis de marché
   */
  editorCheckNoticeResponse(noticeID: number): __Observable<__StrictHttpResponse<ResponseWithErrorAndMessages>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${noticeID}/checkIsOk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseWithErrorAndMessages>;
      })
    );
  }
  /**
   * Editer le statut de publication d'un avis de marché. Si demande de publication de l'avis de marché, le système vérifie si l'AO est complet et autorise ou non le changement de statut.
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Le retour d'une demande de changement de statut d'un avis de marché
   */
  editorCheckNotice(noticeID: number): __Observable<ResponseWithErrorAndMessages> {
    return this.editorCheckNoticeResponse(noticeID).pipe(
      __map(_r => _r.body as ResponseWithErrorAndMessages)
    );
  }

  /**
   * Ajouter un lieu à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeLieuParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLieu`: Un objet représentant un lieu d'un avis de marché
   *
   * @return Les données d'un lieu d'un avis de marché
   */
  editorAddNoticeLieuResponse(params: FirstAOInternServicesService.EditorAddNoticeLieuParams): __Observable<__StrictHttpResponse<AoLieu>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.actualisSharedBeanFirstecoAoLieu;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/lieux`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoLieu>;
      })
    );
  }
  /**
   * Ajouter un lieu à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeLieuParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLieu`: Un objet représentant un lieu d'un avis de marché
   *
   * @return Les données d'un lieu d'un avis de marché
   */
  editorAddNoticeLieu(params: FirstAOInternServicesService.EditorAddNoticeLieuParams): __Observable<AoLieu> {
    return this.editorAddNoticeLieuResponse(params).pipe(
      __map(_r => _r.body as AoLieu)
    );
  }

  /**
   * Editer un lieu d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeLieuParams` containing the following parameters:
   *
   * - `noticeLieuID`: L'identifiant interne d'un lieu d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLieu`: Un objet représentant un lieu d'un avis de marché
   *
   * @return Les données d'un lieu d'un avis de marché
   */
  editorEditNoticeLieuResponse(params: FirstAOInternServicesService.EditorEditNoticeLieuParams): __Observable<__StrictHttpResponse<AoLieu>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.actualisSharedBeanFirstecoAoLieu;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/lieux/${params.noticeLieuID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoLieu>;
      })
    );
  }
  /**
   * Editer un lieu d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeLieuParams` containing the following parameters:
   *
   * - `noticeLieuID`: L'identifiant interne d'un lieu d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLieu`: Un objet représentant un lieu d'un avis de marché
   *
   * @return Les données d'un lieu d'un avis de marché
   */
  editorEditNoticeLieu(params: FirstAOInternServicesService.EditorEditNoticeLieuParams): __Observable<AoLieu> {
    return this.editorEditNoticeLieuResponse(params).pipe(
      __map(_r => _r.body as AoLieu)
    );
  }

  /**
   * Supprimer un lieu d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeLieuParams` containing the following parameters:
   *
   * - `noticeLieuID`: L'identifiant interne d'un lieu d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   */
  editorDeleteNoticeLieuResponse(params: FirstAOInternServicesService.EditorDeleteNoticeLieuParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/lieux/${params.noticeLieuID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprimer un lieu d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeLieuParams` containing the following parameters:
   *
   * - `noticeLieuID`: L'identifiant interne d'un lieu d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   */
  editorDeleteNoticeLieu(params: FirstAOInternServicesService.EditorDeleteNoticeLieuParams): __Observable<null> {
    return this.editorDeleteNoticeLieuResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Ajouter un lot à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeLotsParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLots`: Un objet représentant un lot d'un avis de marché
   *
   * @return Les données d'un lot d'un avis de marché
   */
  editorAddNoticeLotsResponse(params: FirstAOInternServicesService.EditorAddNoticeLotsParams): __Observable<__StrictHttpResponse<AoLots>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.actualisSharedBeanFirstecoAoLots;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/lots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoLots>;
      })
    );
  }
  /**
   * Ajouter un lot à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeLotsParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLots`: Un objet représentant un lot d'un avis de marché
   *
   * @return Les données d'un lot d'un avis de marché
   */
  editorAddNoticeLots(params: FirstAOInternServicesService.EditorAddNoticeLotsParams): __Observable<AoLots> {
    return this.editorAddNoticeLotsResponse(params).pipe(
      __map(_r => _r.body as AoLots)
    );
  }

  /**
   * Editer un lot d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeLotsParams` containing the following parameters:
   *
   * - `noticeLotID`: L'identifiant interne d'un lot d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLots`: Un objet représentant un lot d'un avis de marché
   *
   * @return Les données d'un lot d'un avis de marché
   */
  editorEditNoticeLotsResponse(params: FirstAOInternServicesService.EditorEditNoticeLotsParams): __Observable<__StrictHttpResponse<AoLots>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.actualisSharedBeanFirstecoAoLots;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/lots/${params.noticeLotID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoLots>;
      })
    );
  }
  /**
   * Editer un lot d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeLotsParams` containing the following parameters:
   *
   * - `noticeLotID`: L'identifiant interne d'un lot d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoLots`: Un objet représentant un lot d'un avis de marché
   *
   * @return Les données d'un lot d'un avis de marché
   */
  editorEditNoticeLots(params: FirstAOInternServicesService.EditorEditNoticeLotsParams): __Observable<AoLots> {
    return this.editorEditNoticeLotsResponse(params).pipe(
      __map(_r => _r.body as AoLots)
    );
  }

  /**
   * Supprimer un lot d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeLotsParams` containing the following parameters:
   *
   * - `noticeLotID`: L'identifiant interne d'un lot d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   */
  editorDeleteNoticeLotsResponse(params: FirstAOInternServicesService.EditorDeleteNoticeLotsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/lots/${params.noticeLotID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprimer un lot d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeLotsParams` containing the following parameters:
   *
   * - `noticeLotID`: L'identifiant interne d'un lot d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   */
  editorDeleteNoticeLots(params: FirstAOInternServicesService.EditorDeleteNoticeLotsParams): __Observable<null> {
    return this.editorDeleteNoticeLotsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Ajouter une source à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeSourceParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoSources`: Un objet représentant une source d'un avis de marché
   *
   * @return Les données d'une source d'un avis de marché
   */
  editorAddNoticeSourceResponse(params: FirstAOInternServicesService.EditorAddNoticeSourceParams): __Observable<__StrictHttpResponse<AoSources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.actualisSharedBeanFirstecoAoSources;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoSources>;
      })
    );
  }
  /**
   * Ajouter une source à un avis de marché
   * @param params The `FirstAOInternServicesService.EditorAddNoticeSourceParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoSources`: Un objet représentant une source d'un avis de marché
   *
   * @return Les données d'une source d'un avis de marché
   */
  editorAddNoticeSource(params: FirstAOInternServicesService.EditorAddNoticeSourceParams): __Observable<AoSources> {
    return this.editorAddNoticeSourceResponse(params).pipe(
      __map(_r => _r.body as AoSources)
    );
  }

  /**
   * Editer une source d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeSourceParams` containing the following parameters:
   *
   * - `noticeSourceID`: L'identifiant interne d'une source d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoSources`: Un objet représentant une source d'un avis de marché
   *
   * @return Les données d'une source d'un avis de marché
   */
  editorEditNoticeSourceResponse(params: FirstAOInternServicesService.EditorEditNoticeSourceParams): __Observable<__StrictHttpResponse<AoSources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.actualisSharedBeanFirstecoAoSources;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/sources/${params.noticeSourceID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoSources>;
      })
    );
  }
  /**
   * Editer une source d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeSourceParams` containing the following parameters:
   *
   * - `noticeSourceID`: L'identifiant interne d'une source d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoAoSources`: Un objet représentant une source d'un avis de marché
   *
   * @return Les données d'une source d'un avis de marché
   */
  editorEditNoticeSource(params: FirstAOInternServicesService.EditorEditNoticeSourceParams): __Observable<AoSources> {
    return this.editorEditNoticeSourceResponse(params).pipe(
      __map(_r => _r.body as AoSources)
    );
  }

  /**
   * Supprimer une source d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeSourceParams` containing the following parameters:
   *
   * - `noticeSourceID`: L'identifiant interne d'une source d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   */
  editorDeleteNoticeSourceResponse(params: FirstAOInternServicesService.EditorDeleteNoticeSourceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/sources/${params.noticeSourceID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprimer une source d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorDeleteNoticeSourceParams` containing the following parameters:
   *
   * - `noticeSourceID`: L'identifiant interne d'une source d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   */
  editorDeleteNoticeSource(params: FirstAOInternServicesService.EditorDeleteNoticeSourceParams): __Observable<null> {
    return this.editorDeleteNoticeSourceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Editer une source d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeUrlsParams` containing the following parameters:
   *
   * - `noticeUrl`: Un objet représentant les urls d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * @return Les données des urls d'un avis de marché
   */
  editorEditNoticeUrlsResponse(params: FirstAOInternServicesService.EditorEditNoticeUrlsParams): __Observable<__StrictHttpResponse<Array<NoticeUrl>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.noticeUrl;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice/${params.noticeID}/urls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NoticeUrl>>;
      })
    );
  }
  /**
   * Editer une source d'un avis de marché
   * @param params The `FirstAOInternServicesService.EditorEditNoticeUrlsParams` containing the following parameters:
   *
   * - `noticeUrl`: Un objet représentant les urls d'un avis de marché
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * @return Les données des urls d'un avis de marché
   */
  editorEditNoticeUrls(params: FirstAOInternServicesService.EditorEditNoticeUrlsParams): __Observable<Array<NoticeUrl>> {
    return this.editorEditNoticeUrlsResponse(params).pipe(
      __map(_r => _r.body as Array<NoticeUrl>)
    );
  }

  /**
   * Pose un verrou d'édition sur un avis de marché.
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return La liste des IDS des avis de marché
   */
  editorLockNoticeResponse(noticeID?: number): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (noticeID != null) __params = __params.set('noticeID', noticeID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/notice_lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Pose un verrou d'édition sur un avis de marché.
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return La liste des IDS des avis de marché
   */
  editorLockNotice(noticeID?: number): __Observable<Array<number>> {
    return this.editorLockNoticeResponse(noticeID).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Retire un verrou d'édition sur un avis de marché.
   * @param noticeID L'identifiant interne d'un avis de marché
   */
  editorUnLockNoticeResponse(noticeID?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (noticeID != null) __params = __params.set('noticeID', noticeID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/editor/notice_lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Retire un verrou d'édition sur un avis de marché.
   * @param noticeID L'identifiant interne d'un avis de marché
   */
  editorUnLockNotice(noticeID?: number): __Observable<null> {
    return this.editorUnLockNoticeResponse(noticeID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Créer un avis de marché. Besoin uniquement : title, type (public, private).
   * @param actualisApiFacadeNoticeFacade Un objet représentant un avis de marché
   * @return Les données d'un avis de marché
   */
  editorAddNoticeResponse(actualisApiFacadeNoticeFacade: NoticeFacade): __Observable<__StrictHttpResponse<NoticeFacade>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = actualisApiFacadeNoticeFacade;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeFacade>;
      })
    );
  }
  /**
   * Créer un avis de marché. Besoin uniquement : title, type (public, private).
   * @param actualisApiFacadeNoticeFacade Un objet représentant un avis de marché
   * @return Les données d'un avis de marché
   */
  editorAddNotice(actualisApiFacadeNoticeFacade: NoticeFacade): __Observable<NoticeFacade> {
    return this.editorAddNoticeResponse(actualisApiFacadeNoticeFacade).pipe(
      __map(_r => _r.body as NoticeFacade)
    );
  }

  /**
   * Liste des derniers appels d'offres rédigés par les rédacteurs
   * @param params The `FirstAOInternServicesService.EditorNoticesLastAddedParams` containing the following parameters:
   *
   * - `type`: Le type d'avis de marché (public ou privé).
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return La liste des avis de marché
   */
  editorNoticesLastAddedResponse(params: FirstAOInternServicesService.EditorNoticesLastAddedParams): __Observable<__StrictHttpResponse<NoticesEditor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/editor/notices/lastadded`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticesEditor>;
      })
    );
  }
  /**
   * Liste des derniers appels d'offres rédigés par les rédacteurs
   * @param params The `FirstAOInternServicesService.EditorNoticesLastAddedParams` containing the following parameters:
   *
   * - `type`: Le type d'avis de marché (public ou privé).
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return La liste des avis de marché
   */
  editorNoticesLastAdded(params: FirstAOInternServicesService.EditorNoticesLastAddedParams): __Observable<NoticesEditor> {
    return this.editorNoticesLastAddedResponse(params).pipe(
      __map(_r => _r.body as NoticesEditor)
    );
  }

  /**
   * Rechercher des appels d’offres suivant des critères pour l'éditeur
   * @param params The `FirstAOInternServicesService.EditorSearchNoticesParams` containing the following parameters:
   *
   * - `title`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `sdeadlinedate`: La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `resume`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `reference`: Référence de l'avis de marché dans la source
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edeadlinedate`: La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `content`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `buyers`: Les identifiants des commanditaires séparés par une virgule
   *
   * - `alloti`: Recherche sur un marché alloti ou non
   *
   * @return La liste des avis de marché
   */
  editorSearchNoticesResponse(params: FirstAOInternServicesService.EditorSearchNoticesParams): __Observable<__StrictHttpResponse<NoticesEditor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.title != null) __params = __params.set('title', params.title.toString());
    if (params.sdeadlinedate != null) __params = __params.set('sdeadlinedate', params.sdeadlinedate.toString());
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.resume != null) __params = __params.set('resume', params.resume.toString());
    if (params.reference != null) __params = __params.set('reference', params.reference.toString());
    __body = params.queryCriterions;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.edeadlinedate != null) __params = __params.set('edeadlinedate', params.edeadlinedate.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    if (params.content != null) __params = __params.set('content', params.content.toString());
    if (params.buyers != null) __params = __params.set('buyers', params.buyers.toString());
    if (params.alloti != null) __params = __params.set('alloti', params.alloti.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/notices/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticesEditor>;
      })
    );
  }
  /**
   * Rechercher des appels d’offres suivant des critères pour l'éditeur
   * @param params The `FirstAOInternServicesService.EditorSearchNoticesParams` containing the following parameters:
   *
   * - `title`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `sdeadlinedate`: La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `resume`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `reference`: Référence de l'avis de marché dans la source
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edeadlinedate`: La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `content`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `buyers`: Les identifiants des commanditaires séparés par une virgule
   *
   * - `alloti`: Recherche sur un marché alloti ou non
   *
   * @return La liste des avis de marché
   */
  editorSearchNotices(params: FirstAOInternServicesService.EditorSearchNoticesParams): __Observable<NoticesEditor> {
    return this.editorSearchNoticesResponse(params).pipe(
      __map(_r => _r.body as NoticesEditor)
    );
  }

  /**
   * Rechercher des appels d’offres sur le titre pour l'éditeur
   * @param params The `FirstAOInternServicesService.EditorSearchByTitleNoticesParams` containing the following parameters:
   *
   * - `type`: Le type d'avis de marché (public ou privé).
   *
   * - `query`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * @return La liste des avis de marché
   */
  editorSearchByTitleNoticesResponse(params: FirstAOInternServicesService.EditorSearchByTitleNoticesParams): __Observable<__StrictHttpResponse<NoticesSearchByTitle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/editor/notices/searchByTitle`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticesSearchByTitle>;
      })
    );
  }
  /**
   * Rechercher des appels d’offres sur le titre pour l'éditeur
   * @param params The `FirstAOInternServicesService.EditorSearchByTitleNoticesParams` containing the following parameters:
   *
   * - `type`: Le type d'avis de marché (public ou privé).
   *
   * - `query`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * @return La liste des avis de marché
   */
  editorSearchByTitleNotices(params: FirstAOInternServicesService.EditorSearchByTitleNoticesParams): __Observable<NoticesSearchByTitle> {
    return this.editorSearchByTitleNoticesResponse(params).pipe(
      __map(_r => _r.body as NoticesSearchByTitle)
    );
  }

  /**
   * Liste des appels d'offres à traiter par les rédacteurs. redactionToProcess = null renvoie les AO en cours de traitement par les rédacteurs. redactionToProcess = true renvoie les AO à traiter. redactionToProcess = false renvoie les AO déjà traités.
   * @param params The `FirstAOInternServicesService.EditorNoticesToProcessParams` containing the following parameters:
   *
   * - `shour`: L'heure de début de la période de sélection, au format h
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `redactionToProcess`: Indique le statut à traiter/déjà traité
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `ehour`: L'heure de fin de la période de sélection, au format h
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return La liste des avis de marché
   */
  editorNoticesToProcessResponse(params: FirstAOInternServicesService.EditorNoticesToProcessParams): __Observable<__StrictHttpResponse<NoticesEditor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.shour != null) __params = __params.set('shour', params.shour.toString());
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.redactionToProcess != null) __params = __params.set('redactionToProcess', params.redactionToProcess.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ehour != null) __params = __params.set('ehour', params.ehour.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/editor/notices/toprocess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticesEditor>;
      })
    );
  }
  /**
   * Liste des appels d'offres à traiter par les rédacteurs. redactionToProcess = null renvoie les AO en cours de traitement par les rédacteurs. redactionToProcess = true renvoie les AO à traiter. redactionToProcess = false renvoie les AO déjà traités.
   * @param params The `FirstAOInternServicesService.EditorNoticesToProcessParams` containing the following parameters:
   *
   * - `shour`: L'heure de début de la période de sélection, au format h
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `redactionToProcess`: Indique le statut à traiter/déjà traité
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `ehour`: L'heure de fin de la période de sélection, au format h
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return La liste des avis de marché
   */
  editorNoticesToProcess(params: FirstAOInternServicesService.EditorNoticesToProcessParams): __Observable<NoticesEditor> {
    return this.editorNoticesToProcessResponse(params).pipe(
      __map(_r => _r.body as NoticesEditor)
    );
  }

  /**
   * Editer une source de collecte des avis de marché. Type de source : boamp, ted, jal, web, profil_acheteur
   * @param params The `FirstAOInternServicesService.EditorSourcesEditParams` containing the following parameters:
   *
   * - `noticeSourceID`: L'identifiant interne d'une source d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoSourcesListe`: Un objet représentant une source de collecte d'un avis de marché
   *
   * @return Une source de collecte d'un avis de marché
   */
  editorSourcesEditResponse(params: FirstAOInternServicesService.EditorSourcesEditParams): __Observable<__StrictHttpResponse<AoSourcesListe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.actualisSharedBeanFirstecoSourcesListe;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/editor/source/${params.noticeSourceID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoSourcesListe>;
      })
    );
  }
  /**
   * Editer une source de collecte des avis de marché. Type de source : boamp, ted, jal, web, profil_acheteur
   * @param params The `FirstAOInternServicesService.EditorSourcesEditParams` containing the following parameters:
   *
   * - `noticeSourceID`: L'identifiant interne d'une source d'un avis de marché
   *
   * - `actualisSharedBeanFirstecoSourcesListe`: Un objet représentant une source de collecte d'un avis de marché
   *
   * @return Une source de collecte d'un avis de marché
   */
  editorSourcesEdit(params: FirstAOInternServicesService.EditorSourcesEditParams): __Observable<AoSourcesListe> {
    return this.editorSourcesEditResponse(params).pipe(
      __map(_r => _r.body as AoSourcesListe)
    );
  }

  /**
   * Liste des sources de collecte des avis de marché
   * @return Les sources de collecte des avis de marché
   */
  editorSourcesResponse(): __Observable<__StrictHttpResponse<Array<AoSourcesListe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/editor/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AoSourcesListe>>;
      })
    );
  }
  /**
   * Liste des sources de collecte des avis de marché
   * @return Les sources de collecte des avis de marché
   */
  editorSources(): __Observable<Array<AoSourcesListe>> {
    return this.editorSourcesResponse().pipe(
      __map(_r => _r.body as Array<AoSourcesListe>)
    );
  }

  /**
   * Ajouter une source de collecte des avis de marché. Type de source : boamp, ted, jal, web, profil_acheteur
   * @param actualisSharedBeanFirstecoSourcesListe Un objet représentant une source de collecte d'un avis de marché
   * @return Une source de collecte d'un avis de marché
   */
  editorSourcesAddResponse(actualisSharedBeanFirstecoSourcesListe: AoSourcesListe): __Observable<__StrictHttpResponse<AoSourcesListe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = actualisSharedBeanFirstecoSourcesListe;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/editor/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoSourcesListe>;
      })
    );
  }
  /**
   * Ajouter une source de collecte des avis de marché. Type de source : boamp, ted, jal, web, profil_acheteur
   * @param actualisSharedBeanFirstecoSourcesListe Un objet représentant une source de collecte d'un avis de marché
   * @return Une source de collecte d'un avis de marché
   */
  editorSourcesAdd(actualisSharedBeanFirstecoSourcesListe: AoSourcesListe): __Observable<AoSourcesListe> {
    return this.editorSourcesAddResponse(actualisSharedBeanFirstecoSourcesListe).pipe(
      __map(_r => _r.body as AoSourcesListe)
    );
  }

  /**
   * Crée un compte utilisateur FirstAO.
   * @param params The `FirstAOInternServicesService.PostUserCreateParams` containing the following parameters:
   *
   * - `lastname`: Le nom du client
   *
   * - `firstname`: Le prénom du client
   *
   * - `email`: L'adresse email du client
   *
   * - `customerToken`: Le token du client
   *
   * - `companyToken`: Le token de la société
   *
   * - `civility`: La civilité du client (M. ou Mme.)
   *
   * - `departements`: Les départements de la société séparés par des virgules, sans espace entre les valeurs. Peut être vide si la société a déjà été créée et qu'il s'agit de créer un utilisateur a une société existante en base.
   *
   * - `companyName`: Le nom de la société du client. Si rajout d'un client à une société, peut rester vide.
   *
   * @return Un utilisateur FirstAO
   */
  postUserCreateResponse(params: FirstAOInternServicesService.PostUserCreateParams): __Observable<__StrictHttpResponse<CustomerUserAo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastname != null) __params = __params.set('lastname', params.lastname.toString());
    if (params.firstname != null) __params = __params.set('firstname', params.firstname.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.customerToken != null) __params = __params.set('customerToken', params.customerToken.toString());
    if (params.companyToken != null) __params = __params.set('companyToken', params.companyToken.toString());
    if (params.civility != null) __params = __params.set('civility', params.civility.toString());
    if (params.departements != null) __params = __params.set('departements', params.departements.toString());
    if (params.companyName != null) __params = __params.set('companyName', params.companyName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/users/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerUserAo>;
      })
    );
  }
  /**
   * Crée un compte utilisateur FirstAO.
   * @param params The `FirstAOInternServicesService.PostUserCreateParams` containing the following parameters:
   *
   * - `lastname`: Le nom du client
   *
   * - `firstname`: Le prénom du client
   *
   * - `email`: L'adresse email du client
   *
   * - `customerToken`: Le token du client
   *
   * - `companyToken`: Le token de la société
   *
   * - `civility`: La civilité du client (M. ou Mme.)
   *
   * - `departements`: Les départements de la société séparés par des virgules, sans espace entre les valeurs. Peut être vide si la société a déjà été créée et qu'il s'agit de créer un utilisateur a une société existante en base.
   *
   * - `companyName`: Le nom de la société du client. Si rajout d'un client à une société, peut rester vide.
   *
   * @return Un utilisateur FirstAO
   */
  postUserCreate(params: FirstAOInternServicesService.PostUserCreateParams): __Observable<CustomerUserAo> {
    return this.postUserCreateResponse(params).pipe(
      __map(_r => _r.body as CustomerUserAo)
    );
  }
}

module FirstAOInternServicesService {

  /**
   * Parameters for editEntreprise
   */
  export interface EditEntrepriseParams {

    /**
     * Un objet représentant un acheteur privé
     */
    privateBuyerCreate: PrivateBuyerCreate;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for createEntrepriseAdresse
   */
  export interface CreateEntrepriseAdresseParams {

    /**
     * Un objet représentant une adresse d'un commanditaire
     */
    commanditaireAdresse: CommanditaireAdresse;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for editEntrepriseAdresse
   */
  export interface EditEntrepriseAdresseParams {

    /**
     * Un objet représentant une adresse d'un commanditaire
     */
    commanditaireAdresse: CommanditaireAdresse;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;

    /**
     * L'identifiant d'une adresse d'un commanditaire
     */
    buyerAdresseID: number;
  }

  /**
   * Parameters for createEntrepriseContacts
   */
  export interface CreateEntrepriseContactsParams {

    /**
     * Un objet représentant un contact d'un commanditaire
     */
    commanditaireContact: CommanditaireContact;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for editEntrepriseContacts
   */
  export interface EditEntrepriseContactsParams {

    /**
     * L'identifiant d'un contact d'un commanditaire
     */
    contactID: number;

    /**
     * Un objet représentant un contact d'un commanditaire
     */
    commanditaireContact: CommanditaireContact;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for editCommanditaire
   */
  export interface EditCommanditaireParams {

    /**
     * Un objet représentant un commanditaire
     */
    commanditaireCreate: CommanditaireCreate;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for createCommanditaireAdresse
   */
  export interface CreateCommanditaireAdresseParams {

    /**
     * Un objet représentant une adresse d'un commanditaire
     */
    commanditaireAdresse: CommanditaireAdresse;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for editCommanditaireAdresse
   */
  export interface EditCommanditaireAdresseParams {

    /**
     * Un objet représentant une adresse d'un commanditaire
     */
    commanditaireAdresse: CommanditaireAdresse;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;

    /**
     * L'identifiant d'une adresse d'un commanditaire
     */
    buyerAdresseID: number;
  }

  /**
   * Parameters for createCommanditaireContacts
   */
  export interface CreateCommanditaireContactsParams {

    /**
     * Un objet représentant un contact d'un commanditaire
     */
    commanditaireContact: CommanditaireContact;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for editCommanditaireContacts
   */
  export interface EditCommanditaireContactsParams {

    /**
     * L'identifiant d'un contact d'un commanditaire
     */
    contactID: number;

    /**
     * Un objet représentant un contact d'un commanditaire
     */
    commanditaireContact: CommanditaireContact;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for createCommanditaireUrl
   */
  export interface CreateCommanditaireUrlParams {

    /**
     * Un objet représentant une url d'un commanditaire
     */
    commanditaireUrl: CommanditaireUrl;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for editCommanditaireUrl
   */
  export interface EditCommanditaireUrlParams {

    /**
     * Un objet représentant une url d'un commanditaire
     */
    commanditaireUrl: CommanditaireUrl;

    /**
     * L'identifiant d'une url d'un commanditaire
     */
    buyerUrlID: number;

    /**
     * L'identifiant d'un commanditaire
     */
    buyerID: number;
  }

  /**
   * Parameters for getContractorQualificationRequest
   */
  export interface GetContractorQualificationRequestParams {

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * Indique le statut à traiter/déjà traité
     */
    isProcessed?: boolean;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;
  }

  /**
   * Parameters for patchContractorQualificationRequest
   */
  export interface PatchContractorQualificationRequestParams {

    /**
     * L'identifiant interne d'une requête de qualification de titulaire de marché
     */
    requestID: number;

    /**
     * Un objet représentant une qualification de titulaire de marché
     */
    contractorQualification?: ContractorQualification;
  }

  /**
   * Parameters for editorEditEntreprise
   */
  export interface EditorEditEntrepriseParams {

    /**
     * L'identifiant d'une entreprise
     */
    entrepriseId: number;

    /**
     * Un objet représentant une entreprise
     */
    entreprise: Entreprise;
  }

  /**
   * Parameters for editorEditEntrepriseField
   */
  export interface EditorEditEntrepriseFieldParams {

    /**
     * Valeur d'un champ édité
     */
    value: string;

    /**
     * Champ spécifique édité
     */
    field: string;

    /**
     * L'identifiant d'une entreprise
     */
    entrepriseId: number;
  }

  /**
   * Parameters for createNewContact
   */
  export interface CreateNewContactParams {

    /**
     * Valeur envoyée pour la création
     */
    searchValue: string;

    /**
     * L'identifiant d'une entreprise
     */
    entrepriseId: number;
  }

  /**
   * Parameters for editorEditContactEntreprise
   */
  export interface EditorEditContactEntrepriseParams {

    /**
     * L'identifiant d'une entreprise
     */
    entrepriseId: number;

    /**
     * Un contact dans une entreprise
     */
    entrepriseContact: EntrepriseContact;

    /**
     * L'identifiant d'un contact d'un commanditaire
     */
    contactID: number;
  }

  /**
   * Parameters for editorEditNotice
   */
  export interface EditorEditNoticeParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant un avis de marché
     */
    actualisApiFacadeNoticeFacade: NoticeFacade;
  }

  /**
   * Parameters for editorAddNoticeAdresseComplementaire
   */
  export interface EditorAddNoticeAdresseComplementaireParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant une adresse complémentaire d'un avis de marché
     */
    actualisSharedBeanFirstecoAoAdressesComplementaires: AoAdressesComplementaires;
  }

  /**
   * Parameters for editorEditNoticeAdresseComplementaire
   */
  export interface EditorEditNoticeAdresseComplementaireParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant interne d'une adresse complémentaire d'un avis de marché
     */
    noticeAdresseComplementaireID: number;

    /**
     * Un objet représentant une adresse complémentaire d'un avis de marché
     */
    actualisSharedBeanFirstecoAoAdressesComplementaires: AoAdressesComplementaires;
  }

  /**
   * Parameters for editorDeleteNoticeAdresseComplementaire
   */
  export interface EditorDeleteNoticeAdresseComplementaireParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant interne d'une adresse complémentaire d'un avis de marché
     */
    noticeAdresseComplementaireID: number;
  }

  /**
   * Parameters for editorAddNoticeAttachment
   */
  export interface EditorAddNoticeAttachmentParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * file detail
     */
    file?: Blob;
  }

  /**
   * Parameters for editorEditNoticeAttachment
   */
  export interface EditorEditNoticeAttachmentParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant interne d'un fichier d'un avis de marché
     */
    attachmentID: number;

    /**
     * filename
     */
    filename?: string;

    /**
     * file detail
     */
    file?: Blob;
  }

  /**
   * Parameters for editorDeleteNoticeAttachment
   */
  export interface EditorDeleteNoticeAttachmentParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant interne d'un fichier d'un avis de marché
     */
    attachmentID: number;
  }

  /**
   * Parameters for editorEditNoticeAttachmentStatus
   */
  export interface EditorEditNoticeAttachmentStatusParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Le statut d'un fichier d'un avis de marché
     */
    attachmentStatus: number;

    /**
     * L'identifiant interne d'un fichier d'un avis de marché
     */
    attachmentID: number;
  }

  /**
   * Parameters for editorChangePublicationStateNotice
   */
  export interface EditorChangePublicationStateNoticeParams {

    /**
     * L'état de publication d'un avis de marché
     */
    publicationState: 'written' | 'issued';

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;
  }

  /**
   * Parameters for editorAddNoticeLieu
   */
  export interface EditorAddNoticeLieuParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant un lieu d'un avis de marché
     */
    actualisSharedBeanFirstecoAoLieu: AoLieu;
  }

  /**
   * Parameters for editorEditNoticeLieu
   */
  export interface EditorEditNoticeLieuParams {

    /**
     * L'identifiant interne d'un lieu d'un avis de marché
     */
    noticeLieuID: number;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant un lieu d'un avis de marché
     */
    actualisSharedBeanFirstecoAoLieu: AoLieu;
  }

  /**
   * Parameters for editorDeleteNoticeLieu
   */
  export interface EditorDeleteNoticeLieuParams {

    /**
     * L'identifiant interne d'un lieu d'un avis de marché
     */
    noticeLieuID: number;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;
  }

  /**
   * Parameters for editorAddNoticeLots
   */
  export interface EditorAddNoticeLotsParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant un lot d'un avis de marché
     */
    actualisSharedBeanFirstecoAoLots: AoLots;
  }

  /**
   * Parameters for editorEditNoticeLots
   */
  export interface EditorEditNoticeLotsParams {

    /**
     * L'identifiant interne d'un lot d'un avis de marché
     */
    noticeLotID: number;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant un lot d'un avis de marché
     */
    actualisSharedBeanFirstecoAoLots: AoLots;
  }

  /**
   * Parameters for editorDeleteNoticeLots
   */
  export interface EditorDeleteNoticeLotsParams {

    /**
     * L'identifiant interne d'un lot d'un avis de marché
     */
    noticeLotID: number;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;
  }

  /**
   * Parameters for editorAddNoticeSource
   */
  export interface EditorAddNoticeSourceParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant une source d'un avis de marché
     */
    actualisSharedBeanFirstecoAoSources: AoSources;
  }

  /**
   * Parameters for editorEditNoticeSource
   */
  export interface EditorEditNoticeSourceParams {

    /**
     * L'identifiant interne d'une source d'un avis de marché
     */
    noticeSourceID: number;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant une source d'un avis de marché
     */
    actualisSharedBeanFirstecoAoSources: AoSources;
  }

  /**
   * Parameters for editorDeleteNoticeSource
   */
  export interface EditorDeleteNoticeSourceParams {

    /**
     * L'identifiant interne d'une source d'un avis de marché
     */
    noticeSourceID: number;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;
  }

  /**
   * Parameters for editorEditNoticeUrls
   */
  export interface EditorEditNoticeUrlsParams {

    /**
     * Un objet représentant les urls d'un avis de marché
     */
    noticeUrl: Array<NoticeUrl>;

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;
  }

  /**
   * Parameters for editorNoticesLastAdded
   */
  export interface EditorNoticesLastAddedParams {

    /**
     * Le type d'avis de marché (public ou privé).
     */
    type: 'public' | 'private';

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;
  }

  /**
   * Parameters for editorSearchNotices
   */
  export interface EditorSearchNoticesParams {

    /**
     * Requête plein texte - OR, AND et groupement par parenthèses supportées
     */
    title?: string;

    /**
     * La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
     */
    sdeadlinedate?: string;

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Requête plein texte - OR, AND et groupement par parenthèses supportées
     */
    resume?: string;

    /**
     * Référence de l'avis de marché dans la source
     */
    reference?: string;

    /**
     * Un objet représentant un filtre de recherche
     */
    queryCriterions?: QueryCriterions;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
     */
    edeadlinedate?: string;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;

    /**
     * Requête plein texte - OR, AND et groupement par parenthèses supportées
     */
    content?: string;

    /**
     * Les identifiants des commanditaires séparés par une virgule
     */
    buyers?: string;

    /**
     * Recherche sur un marché alloti ou non
     */
    alloti?: boolean;
  }

  /**
   * Parameters for editorSearchByTitleNotices
   */
  export interface EditorSearchByTitleNoticesParams {

    /**
     * Le type d'avis de marché (public ou privé).
     */
    type: 'public' | 'private';

    /**
     * Requête plein texte - OR, AND et groupement par parenthèses supportées
     */
    query?: string;
  }

  /**
   * Parameters for editorNoticesToProcess
   */
  export interface EditorNoticesToProcessParams {

    /**
     * L'heure de début de la période de sélection, au format h
     */
    shour?: string;

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Indique le statut à traiter/déjà traité
     */
    redactionToProcess?: boolean;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * L'heure de fin de la période de sélection, au format h
     */
    ehour?: string;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;
  }

  /**
   * Parameters for editorSourcesEdit
   */
  export interface EditorSourcesEditParams {

    /**
     * L'identifiant interne d'une source d'un avis de marché
     */
    noticeSourceID: number;

    /**
     * Un objet représentant une source de collecte d'un avis de marché
     */
    actualisSharedBeanFirstecoSourcesListe: AoSourcesListe;
  }

  /**
   * Parameters for postUserCreate
   */
  export interface PostUserCreateParams {

    /**
     * Le nom du client
     */
    lastname: string;

    /**
     * Le prénom du client
     */
    firstname: string;

    /**
     * L'adresse email du client
     */
    email: string;

    /**
     * Le token du client
     */
    customerToken: string;

    /**
     * Le token de la société
     */
    companyToken: string;

    /**
     * La civilité du client (M. ou Mme.)
     */
    civility: string;

    /**
     * Les départements de la société séparés par des virgules, sans espace entre les valeurs. Peut être vide si la société a déjà été créée et qu'il s'agit de créer un utilisateur a une société existante en base.
     */
    departements?: string;

    /**
     * Le nom de la société du client. Si rajout d'un client à une société, peut rester vide.
     */
    companyName?: string;
  }
}

export { FirstAOInternServicesService }
