import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-source-manager',
    templateUrl: './source-manager.component.html',
    styleUrls: ['./source-manager.component.css']
})
export class SourceManagerComponent implements OnInit {

    private title: string;
    private aoId: number;
    showLoading: boolean;
    private isCreating: boolean;

    private url;
    private source;
    private adresse;
    private city;
    private zip;

    constructor() {
    }

    ngOnInit() {
    }

}
