<div fxLayout="row wrap" fxFlex="1 1 0" fxFlexFill="1" fxLayoutAlign="start" *ngIf="!isSearching else loading">
  <mat-sidenav-container style="margin-right: 0 !important;">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened">
      <div fxLayout="column" fxFlex="400px" class="leftSideBar">
        <mat-tab-group class="avisEditionMatTab" (selectedTabChange)="changeListTab($event)">
          <mat-tab  label="À traiter">
          </mat-tab>
          <mat-tab label="En attente">
          </mat-tab>
          <mat-tab label="Déjà traité">
          </mat-tab>
        </mat-tab-group>
        <app-ao-list class="aoList" [showDCE]="true" [collectedMode]="true" [processMode]="processMode"></app-ao-list>
        <mat-paginator [length]="2000"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="changePagination($event)">
        </mat-paginator>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
  <div class="sideNavButtonContainer">
    <button class="sideNavButton" *ngIf="opened" (click)="sidenav.toggle()"><<</button>
    <button class="sideNavButton" *ngIf="!opened" (click)="sidenav.toggle()">>></button>
  </div>


  <div fxLayout="column" fxFlex="1 1 0" fxLayoutGap="10px" style="height: 100%;">
    <app-ao-editor [collectedMode]="true" [isPublic]="true" [processMode]="processMode" class="appAoEditor"
                   style="height: 100%;" *ngIf="currentNoticeId">
    </app-ao-editor>
  </div>
</div>

<ng-template #loading>
  <div style="height: 100%">
    <mat-spinner style="margin: auto;"></mat-spinner>
  </div>
</ng-template>