import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {FirstAOCustomerServicesService} from "../../../api/gen/services/first-aocustomer-services.service";
import {EnterpriseAdderComponent} from "../../modal/enterprise-adder/enterprise-adder.component";
import { MatDialog } from "@angular/material/dialog";
import {PrivateBuyerCreate} from "../../../api/gen/models/private-buyer-create";
import {ComplementaryAddressesManagerComponent} from "../../modal/complementary-addresses-manager/complementary-addresses-manager.component";

@Component({
    selector: 'app-enterprise-choose',
    templateUrl: './enterprise-choose.component.html',
    styleUrls: ['./enterprise-choose.component.css']
})
export class EnterpriseChooseComponent implements OnInit {

    // ----------------------------------------- ReadOnly -----------------------------------------
    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    // ----------------------------------------- ReadOnly -----------------------------------------


    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------
    private _noticeSavingInProgress: boolean;

    get noticeSavingInProgress(): boolean {
        return this._noticeSavingInProgress;
    }

    @Input("noticeSavingInProgress")
    set noticeSavingInProgress(val: boolean) {
        this._noticeSavingInProgress = val;
        if (this.noticeSavingInProgress) {
            this.saveEnterprise();
        }
    }

    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------


    @Output() enterpriseChanged = new EventEmitter<any>();

    @Output() showLoading = new EventEmitter<any>();

    @Input("currentNotice") currentNotice;

    currentAddress;
    currentContact;
    currentEnterprise;

    // Ids
    private selectedContactId: number;
    private selectedAddressId: number;

    // Enterprise Information
    enterpriseContacts;
    enterpriseAddresses;

    constructor(private firstAOInternServicesService: FirstAOInternServicesService,
                private firstAOCustomerServicesService: FirstAOCustomerServicesService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.getAndSetEnterprise(false);
    }

    public getAndSetEnterprise(resetCAndA) {
        if (this.currentNotice.commanditaire.buyerID != null) {
            this.firstAOInternServicesService.getEntrepriseInformationsResponse(this.currentNotice.commanditaire.buyerID)
                .subscribe(
                    (buyer) => {
                        this.currentEnterprise = buyer.body;
                        this.enterpriseContacts = buyer.body.contacts;
                        this.enterpriseAddresses = buyer.body.adresses;

                        if (this.enterpriseContacts == null)
                            this.enterpriseContacts = [];
                        if (this.enterpriseAddresses == null)
                            this.enterpriseAddresses = [];

                        if (resetCAndA) {
                            if (this.currentNotice.commanditaire_contact != null) {
                                this.currentContact = this.enterpriseContacts[0];
                                this.currentNotice.commanditaireContactId = this.enterpriseContacts[0].id;
                            }
                            if (this.currentNotice.commanditaire_adresse != null) {
                                this.currentAddress = this.enterpriseAddresses[0];
                                this.currentNotice.commanditaireAdresseId = this.enterpriseAddresses[0].id;
                            }
                            this.showLoading.emit(false);
                            this.enterpriseChanged.emit();
                        }

                        this.selectedContactId = this.currentNotice.commanditaireContactId;
                        this.selectedAddressId = this.currentNotice.commanditaireAdresseId;

                        if (!resetCAndA) {
                            this.currentAddress = this.getAddress(this.selectedAddressId);
                            this.currentContact = this.getContact(this.selectedContactId);
                        }
                    });
        }
    }

    onEnterpriseSelected($event) {
        if ($event != null && $event.buyerID != null && $event.buyerID != this.currentNotice.identiteAcheteurId) {
            this.showLoading.emit(true);
            this.currentNotice.commanditaire = $event;
            this.currentNotice.entrepriseId = $event.buyerID;
            this.getAndSetEnterprise(true);
            this.enterpriseChanged.emit();
        }
    }

    addNewEnterprise() {
        const dialogRef = this.dialog.open(EnterpriseAdderComponent);

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != "") {
                const params: PrivateBuyerCreate = {
                    denomination: result.name,
                    siren: result.siren,
                    ape: result.ape,
                };

                this.showLoading.emit(true);

                this.firstAOInternServicesService.createEntreprise(params).subscribe(buyer => {
                    this.currentNotice.commanditaire = buyer;
                    this.currentNotice.entrepriseId = buyer.entreprise_id;
                    let contactAdded: boolean = false;
                    let addressAdded: boolean = false;

                    // Création d'un nouveau contact
                    this.firstAOInternServicesService.createEntrepriseContacts({
                            commanditaireContact: {
                                nom: "Nouveau Contact",
                                status: 1
                            },
                            buyerID: buyer.entreprise_id
                        }
                    ).subscribe(value => {
                        contactAdded = true;
                        if (contactAdded && addressAdded) {
                            this.getAndSetEnterprise(true);
                        }
                    });

                    // Création d'une nouvelle adresse
                    this.firstAOInternServicesService.createEntrepriseAdresse({
                            commanditaireAdresse: {
                                libelle: "Nouvelle adresse",
                                status: 1
                            },
                            buyerID: buyer.entreprise_id
                        }
                    ).subscribe(value => {
                        addressAdded = true;
                        if (contactAdded && addressAdded) {
                            this.getAndSetEnterprise(true);
                        }
                    });
                }, error => {
                    this.showLoading.emit(false);
                });
            }
        });
    }

    addNewEnterprise2() {
        const params = {
            denomination: "Collectivité",
            status: "active"
        };

        this.showLoading.emit(true);
        this.firstAOInternServicesService.createCommanditaire(params).subscribe(buyer => {
            this.currentNotice.commanditaire = buyer;
            this.currentNotice.identiteAcheteurId = buyer.buyerID;
            let contactAdded: boolean = false;
            let addressAdded: boolean = false;

            // Création d'un nouveau contact
            this.firstAOInternServicesService.createCommanditaireContacts({
                    commanditaireContact: {
                        nom: "Nouveau Contact",
                        status: 1
                    },
                    buyerID: buyer.buyerID
                }
            ).subscribe(value => {
                contactAdded = true;
                if (contactAdded && addressAdded) {
                    // this.getAndSetBuyer(true);
                }
            });

            // Création d'une nouvelle adresse
            this.firstAOInternServicesService.createCommanditaireAdresse({
                    commanditaireAdresse: {
                        libelle: "Nouvelle adresse",
                        status: 1
                    },
                    buyerID: buyer.buyerID
                }
            ).subscribe(value => {
                addressAdded = true;
                if (contactAdded && addressAdded) {
                    // this.getAndSetBuyer(true);
                }
            });
        });
    }

    getAddress(addressId) {
        let addressToReturn = null;
        let isSearching = this.enterpriseAddresses != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.enterpriseAddresses.length) {
                if (this.enterpriseAddresses[index].id === addressId) {
                    addressToReturn = this.enterpriseAddresses[index];
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return addressToReturn;
    }

    getContact(contactId) {
        let contactToReturn = null;
        let isSearching = this.enterpriseContacts != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.enterpriseContacts.length) {
                if (this.enterpriseContacts[index].id === contactId) {
                    contactToReturn = this.enterpriseContacts[index];
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return contactToReturn;
    }

    saveEnterprise() {
        const privateBuyerCreate: PrivateBuyerCreate = {
            entreprise_id: this.currentEnterprise.entreprise_id,
            denomination: this.currentEnterprise.denomination,
            ape: this.currentEnterprise.ape,
            siren: this.currentEnterprise.siren
        };

        const params: FirstAOInternServicesService.EditEntrepriseParams = {
            privateBuyerCreate: privateBuyerCreate,
            buyerID: this.currentEnterprise.entreprise_id
        };

        this.firstAOInternServicesService.editEntreprise(params).subscribe(value => {
        });
    }

    openComplementaryAddressesManager() {
        const dialogRef = this.dialog.open(ComplementaryAddressesManagerComponent, {
            data: {
                notice: this.currentNotice,
                readOnly: false,
            }
        });
    }

    cAndAReadOnly(): boolean {
        if (this.readOnly || (this.currentEnterprise !== null && this.currentEnterprise.entreprise_id === 0))
            return true;
        return false;
    }

    getEntrepriseId(): number {
        if (this.currentEnterprise && this.currentEnterprise.entreprise_id != null)
            return this.currentEnterprise.entreprise_id;
        else
            return 0
    }
}
