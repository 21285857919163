import {Component, OnInit} from '@angular/core';
import {FirstAOInternServicesService} from "../../api/gen/services/first-aointern-services.service";
import {MissionService} from "../../mission.service";
import {Subscription} from "rxjs";
import {FirstAOCustomerServicesService} from "../../api/gen/services/first-aocustomer-services.service";

@Component({
    selector: 'app-acheteur-list',
    templateUrl: './acheteur-list.component.html',
    styleUrls: ['./acheteur-list.component.css']
})
export class AcheteurListComponent implements OnInit {

    subscription: Subscription;
    profilsAcheteurs;

    constructor(private missionService: MissionService,
                private firstAOCustomerServicesService: FirstAOCustomerServicesService) {
    }

    ngOnInit() {
        this.loadProfilsAcheteurs();
    }

    loadProfilsAcheteurs() {
        // this.firstAOCustomerServicesService.showSearchBuyerSuggest().subscribe( (buyers) => {
        //     buyers.forEach(function (value) {
        //         if (value.denomination !== "") {
        //             // value.cpv.split(",").forEach(function (splitedvalue) {
        //             //     self.noticeCPVCodes.push(splitedvalue);
        //             // })
        //         }
        //     });
        // });

        // this.firstAOCustomerServicesService.showSearchBuyerSuggest().subscribe((notices) => {
        //     // this.notices = notices.notices;
        // });
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.subscription.unsubscribe();
    }
}
