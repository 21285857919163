import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {FormControl, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {CommanditaireContact} from "../../../api/gen/models/commanditaire-contact";
import {CommanditaireAdresse} from "../../../api/gen/models/commanditaire-adresse";
import {CommanditaireCreate} from "../../../api/gen/models/commanditaire-create";
import {EnterpriseAdderComponent} from "../enterprise-adder/enterprise-adder.component";
import {PrivateBuyerCreate} from "../../../api/gen/models/private-buyer-create";

@Component({
    selector: 'app-buyers-manager',
    templateUrl: './buyers-manager.component.html',
    styleUrls: ['./buyers-manager.component.css']
})
export class BuyersManagerComponent implements OnInit {

    subscription: Subscription;

    currentBuyer;
    buyerUrl;

    buyerEditing: boolean = false;
    contactEditing: boolean = false;
    addressEditing: boolean = false;

    isPublic;

    contactsControl = new FormControl('', [Validators.required]);
    buyersContacts;
    selectedContactId;
    contactName;
    contactMail;
    contactPhone;
    contactFax;

    addressesControl = new FormControl('', [Validators.required]);
    buyersAddresses;
    selectedAddressId;
    addressName;
    addressPath;
    addressCity;
    addressZip;

    aoEditorData;


    constructor(private dialogRef: MatDialogRef<BuyersManagerComponent>,
                @Inject(MAT_DIALOG_DATA) public data,
                private firstAOInternServicesService: FirstAOInternServicesService,
                public dialog: MatDialog) {
        this.aoEditorData = data;
        this.isPublic = data.public;
    }

    ngOnInit() {
        this.getBuyer(this.aoEditorData.buyerId);
    }

    setBuyer(buyer, resetSelectedIds: boolean) {
        this.currentBuyer = buyer;
        if (this.currentBuyer.urls != null)
            this.buyerUrl = this.currentBuyer.urls[this.currentBuyer.urls.length - 1].url;

        if (this.currentBuyer.contacts != null)
            this.buyersContacts = this.currentBuyer.contacts;
        else
            this.buyersContacts = [];

        if (this.currentBuyer.adresses != null)
            this.buyersAddresses = this.currentBuyer.adresses;
        else
            this.buyersAddresses = [];

        if (resetSelectedIds) {
            this.selectedAddressId = null;
            this.selectedContactId = null;
        } else {
            this.selectedAddressId = this.aoEditorData.adresseId;
            this.selectedContactId = this.aoEditorData.contactId;
        }
    }

    addNewBuyer() {
        if (this.isPublic) {
            const params: CommanditaireCreate = {
                denomination: "Collectivité",
                status: "active"
            };


            this.firstAOInternServicesService.createCommanditaire(params).subscribe(buyer => {
                this.buyerEditing = true;
                this.setBuyer(buyer, true);
            });
        } else {
            const dialogRef = this.dialog.open(EnterpriseAdderComponent);

            dialogRef.afterClosed().subscribe(result => {
                if (result != null && result != "") {
                    const params: PrivateBuyerCreate = {
                        denomination: result.name,
                        siren: result.siren,
                        ape: result.ape,
                    };
                    this.firstAOInternServicesService.createEntreprise(params).subscribe(buyer => {
                        this.buyerEditing = true;
                        this.setBuyer(buyer, true);
                    });
                }
            });


        }
    }

    editBuyer() {
        this.buyerEditing = true;
    }

    saveBuyer() {
        if (this.isPublic) {
            const params: FirstAOInternServicesService.EditCommanditaireParams = {
                commanditaireCreate: this.getCommanditaireBuyer(),
                buyerID: this.currentBuyer.buyer_id
            };

            this.firstAOInternServicesService.editCommanditaire(params).subscribe(value => {
                this.buyerEditing = false;
            });
        } else {
            const params: FirstAOInternServicesService.EditEntrepriseParams = {
                privateBuyerCreate: this.getEnterpriseBuyer(),
                buyerID: this.currentBuyer.buyer_id
            };

            this.firstAOInternServicesService.editEntreprise(params).subscribe(value => {
                this.buyerEditing = false;
            });
        }
    }

    addNewContact() {
        const commanditaireContact: CommanditaireContact = {
            nom: "Nouveau contact"
        };


        if (this.isPublic) {
            const params: FirstAOInternServicesService.CreateCommanditaireContactsParams = {
                commanditaireContact: commanditaireContact,
                buyerID: this.currentBuyer.buyer_id
            };

            this.firstAOInternServicesService.createCommanditaireContacts(params).subscribe(contact => {
                this.buyersContacts.push(contact);
                this.selectedContactId = contact.id;
                this.contactEditing = true;
            });
        } else {
            const params: FirstAOInternServicesService.CreateCommanditaireContactsParams = {
                commanditaireContact: commanditaireContact,
                buyerID: this.currentBuyer.buyer_id
            };

            this.firstAOInternServicesService.createEntrepriseContacts(params).subscribe(contact => {
                this.buyersContacts.push(contact);
                this.selectedContactId = contact.id;
                this.contactEditing = true;
            });
        }
    }

    editContact() {
        this.contactEditing = true;
    }

    saveContact() {
        const params: FirstAOInternServicesService.EditCommanditaireContactsParams = {
            contactID: this.selectedContactId,
            commanditaireContact: this.getCommanditaireContact(),
            buyerID: this.currentBuyer.buyer_id
        };

        this.firstAOInternServicesService.editCommanditaireContacts(params).subscribe(value => {
            this.contactEditing = false;
        });
    }

    addNewAddress() {
        const commanditaireAdresse: CommanditaireAdresse = {
            libelle: "Nouvelle adresse",
            status: 1
        };

        const params: FirstAOInternServicesService.CreateCommanditaireAdresseParams = {
            commanditaireAdresse: commanditaireAdresse,
            buyerID: this.currentBuyer.buyer_id
        };

        this.firstAOInternServicesService.createCommanditaireAdresse(params).subscribe(address => {
            this.buyersAddresses.push(address);
            this.selectedAddressId = address.id;
            this.addressEditing = true;
        });
    }

    editAddress() {
        this.addressEditing = true;
    }

    saveAddress() {
        const params: FirstAOInternServicesService.EditEntrepriseAdresseParams = {
            buyerAdresseID: this.selectedAddressId,
            commanditaireAdresse: this.getCommanditaireAddress(),
            buyerID: this.currentBuyer.buyer_id
        };

        this.firstAOInternServicesService.editCommanditaireAdresse(params).subscribe(value => {
            this.addressEditing = false;
        });
    }

    loadContact($event) {
        this.contactEditing = false;
        if ($event != null && this.buyersContacts != null) {
            let self = this;
            this.buyersContacts.forEach(function (contact) {
                if (contact.id == $event) {
                    self.contactName = contact.nom;
                    self.contactMail = contact.email;
                    self.contactPhone = contact.telephone;
                    self.contactFax = contact.fax;
                }
            });
        } else {
            this.contactName = null;
            this.contactMail = null;
            this.contactPhone = null;
            this.contactFax = null;
        }
    }

    loadAddress($event) {
        this.addressEditing = false;
        if ($event != null && this.buyersAddresses != null) {
            let self = this;
            this.buyersAddresses.forEach(function (address) {
                if (address.id == $event) {
                    self.addressName = address.libelle;
                    self.addressPath = address.adresse;
                    self.addressCity = address.city;
                    self.addressZip = address.zip;
                }
            });

        } else {
            this.addressName = null;
            this.addressPath = null;
            this.addressCity = null;
            this.addressZip = null;
        }
    }

    closeAndSave() {
        this.dialogRef.close({
            buyer: this.getCommanditaireBuyer(),
            contact: this.getCommanditaireContact(),
            address: this.getCommanditaireAddress()
        });
    }

    getCommanditaireBuyer() {
        return {
            buyer_id: this.currentBuyer.buyer_id,
            dateExtraction: this.currentBuyer.dateExtraction,
            denomination: this.currentBuyer.denomination,
            slug: this.currentBuyer.slug,
            status: this.currentBuyer.status,
            typeOrganisme: this.currentBuyer.typeOrganisme
        };
    }

    getEnterpriseBuyer() {
        return {
            ape: this.currentBuyer.ape,
            denomination: this.currentBuyer.denomination,
            entreprise_id: this.currentBuyer.buyer_id,
            siren: this.currentBuyer.siren,
        }
    }

    getCommanditaireContact() {
        return {
            email: this.contactMail,
            fax: this.contactFax,
            id: this.selectedContactId,
            nom: this.contactName,
            telephone: this.contactPhone
        };
    }

    getCommanditaireAddress() {
        return {
            adresse: this.addressPath,
            city: this.addressCity,
            id: this.selectedAddressId,
            libelle: this.addressName,
            zip: this.addressZip
        };
    }

    getBuyer(buyerId) {
        this.firstAOInternServicesService.getCommanditaireInformationsResponse(buyerId)
            .subscribe(
                (buyer) => {
                    this.setBuyer(buyer.body, false);
                });
    }

    onItemSelected(itemSelected: any) {
        this.currentBuyer = null;
        this.aoEditorData.adresseId = null;
        this.aoEditorData.contactId = null;
        this.getBuyer(itemSelected.buyer_id);
    }
}
