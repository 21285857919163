<mat-form-field class="example-chip-list" style="width: 100%;" id="departementsId">
    <mat-chip-list  #chipList>
        <mat-chip
                *ngFor="let departement of departements"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(departement)">
            {{departement.libelle}}
            <mat-icon matChipRemove *ngIf="!readOnly">cancel</mat-icon>
        </mat-chip>
        <input [readonly]="readOnly"
               placeholder="Départements *"
               [(ngModel)]="search"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               #departementsInput
               (ngModelChange)="scheduleSearch()">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let departement of departementsSearched" [value]="departement">
            {{departement.libelle}} ({{departement.zip}})
        </mat-option>
        <mat-option *ngIf="isSearching" style="height: 125px;"><mat-spinner style="margin: auto;"></mat-spinner></mat-option>
    </mat-autocomplete>
</mat-form-field>
