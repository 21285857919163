import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-acheteur-modal',
  templateUrl: './acheteur-modal.component.html',
  styleUrls: ['./acheteur-modal.component.css']
})
export class AcheteurModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
