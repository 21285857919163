import { Component, OnInit } from '@angular/core';
import {MissionService} from "../../mission.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  constructor(public missionService: MissionService,
              private router: Router) { }

  ngOnInit() {
  }

  disconnect() {
    localStorage.removeItem("redacSession");
    this.missionService.userSession = null;
    this.router.navigate(['/login']);
  }
}


