<h2 mat-dialog-title>Ajout d'un {{aoFileType}} ou ZIP</h2>
<mat-dialog-content class="mat-typography" style="width: 500px;">

    <div class="form-group">
        <!--<label for="file"></label>-->
        <input *ngIf="!readOnly" type="file" multiple [(ngModel)]="fileInput" class="fileInputZone"
               (change)="handleFileInput($event.target.files)" [disabled]="readOnly">
    </div>

    <h3>Fichier(s) lié(s) à cet appel d'offres</h3>


    <div *ngIf="data.notice.attachments.length > 0">
        <div class="fileItem" *ngFor="let attachment of data.notice.attachments">
            <!--TODO Ajouter une chips avec pour action openFile()-->
            <div class="fileName" (click)="openFile(attachment)">
                {{attachment.filename}}
            </div>
            <button mat-icon-button *ngIf="!readOnly" (click)="removeFile(attachment)">
                <mat-icon class="fileRmvButton">cancel</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="data.notice.attachments.length == 0">
        <p>Pas de fichier lié à cet appel d'offres</p>
    </div>

    <mat-form-field style="width: 100%;">
        <input matInput [(ngModel)]="urlDocument" placeholder="Lien {{aoFileType}}" [readonly]="readOnly">
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!readOnly">
    <button style="background: green; color: white;" (click)="close()">Enregistrer</button>
</mat-dialog-actions>
