import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AcheteurEditionComponent} from "./views/acheteur-edition/acheteur-edition.component";
import {AvisEditionComponent} from "./views/avis-edition/avis-edition.component";
import {PublicRedactionComponent} from "./views/public-redaction/public-redaction.component";
import {SourceEditionComponent} from "./views/source-edition/source-edition.component";
import {PrivateRedactionComponent} from "./views/private-redaction/private-redaction.component";
import {SearchComponent} from "./views/search/search.component";
import {AuthGuard} from "./guard/auth.guard";
import {LoginComponent} from "./views/login/login.component";
import {AttributaireEditionComponent} from "./views/attributaire-edition/attributaire-edition.component";

const routes: Routes = [
  // { path: '',
  //   component: AvisEditionComponent,
  //   // canActivate: [ AuthGuard ],
  //   children: [
  //     { path: 'ao', component: AvisEditionComponent },
  //     { path: 'ao/:id', component: AvisEditionComponent },
  //     { path: 'ao-pub', component: PublicRedactionComponent },
  //     { path: 'ao-pub/:id', component: PublicRedactionComponent },
  //     { path: 'ao-priv', component: PrivateRedactionComponent },
  //     { path: 'ao-priv/:id', component: PrivateRedactionComponent },
  //     { path: 'profil-ach', component: AcheteurEditionComponent },
  //     { path: 'profil-ach/:id', component: AcheteurEditionComponent },
  //     { path: 'search', component: SearchComponent },
  //     { path: 'sources', component: SourceEditionComponent }
  //   ],
  // },



  { path: '', component: AvisEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'ao', component: AvisEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'ao/:id', component: AvisEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'ao-pub', component: PublicRedactionComponent, canActivate: [ AuthGuard ] },
  { path: 'ao-pub/:id', component: PublicRedactionComponent, canActivate: [ AuthGuard ] },
  { path: 'ao-priv', component: PrivateRedactionComponent, canActivate: [ AuthGuard ] },
  { path: 'ao-priv/:id', component: PrivateRedactionComponent, canActivate: [ AuthGuard ] },
  { path: 'profil-ach', component: AcheteurEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'profil-ach/:id', component: AcheteurEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'profil-att', component: AttributaireEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'profil-att/:id', component: AttributaireEditionComponent, canActivate: [ AuthGuard ] },
  { path: 'search', component: SearchComponent, canActivate: [ AuthGuard ] },
  { path: 'sources', component: SourceEditionComponent, canActivate: [ AuthGuard ] },

  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
