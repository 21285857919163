import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MissionService} from "../../mission.service";
import {FirstAOCustomerServicesService} from "../../api/gen/services/first-aocustomer-services.service";
import {Subscription} from "rxjs";
import {FirstAOInternServicesService} from "../../api/gen/services";
import {CommanditaireCreate} from "../../api/gen/models/commanditaire-create";
import {EnterpriseAdderComponent} from "../../widgets/modal/enterprise-adder/enterprise-adder.component";
import {CommanditaireContact} from "../../api/gen/models/commanditaire-contact";
import {CommanditaireAdresse} from "../../api/gen/models/commanditaire-adresse";
import {PrivateBuyerCreate} from "../../api/gen/models/private-buyer-create";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BuyerCriterion} from "../../api/gen/models/buyer-criterion";

@Component({
    selector: 'app-acheteur-edition',
    templateUrl: './acheteur-edition.component.html',
    styleUrls: ['./acheteur-edition.component.css'],
    providers: [MissionService]
})

export class AcheteurEditionComponent implements OnInit {

    showLoading = false;
    showEditorLoading = false;
    isPublic = true;
    readonly = true;
    searchEnded = false;

    buyerEditing: boolean = false;



    buyersContacts;
    currentContact;
    contactEditing: boolean = false;

    buyersAddresses;
    currentAddress;
    addressEditing: boolean = false;

    private currentHttpRequest;
    currentBuyerProfilId;
    currentBuyerProfil;

    privateBuyerProfils: BuyerCriterion[] = null;
    publicBuyerProfils: BuyerCriterion[] = null;
    search: string = "";
    private searchTimeOut;

    constructor(public dialog: MatDialog,
                private firstAOInternServicesService: FirstAOInternServicesService,
                private firstAOCustomerServicesService: FirstAOCustomerServicesService,
                private _snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    openBuyerProfil(buyerProfilId) {
        this.resetParams();
        this.readonly = true;
        if (this.currentBuyerProfilId != buyerProfilId) {
            this.currentBuyerProfilId = buyerProfilId;
            this.currentBuyerProfil = null;
            this.currentHttpRequest.unsubscribe();
            this.showEditorLoading = true;
            if (this.isPublic) {
                this.currentHttpRequest = this.firstAOInternServicesService
                    .getCommanditaireInformations(this.currentBuyerProfilId)
                    .subscribe(buyer => {
                        this.buyersContacts = buyer.contacts;
                        this.buyersAddresses = buyer.adresses;
                        this.currentBuyerProfil = buyer;
                        this.showEditorLoading = false;
                    });
            } else {
                this.currentHttpRequest = this.firstAOInternServicesService
                    .getEntrepriseInformations(this.currentBuyerProfilId)
                    .subscribe(buyer => {
                        this.currentBuyerProfil = buyer;
                        this.showEditorLoading = false;
                    });
            }
        } else {
            this.currentBuyerProfilId = null;
            this.currentBuyerProfil = null;
            this.currentContact = null;
            this.currentAddress = null;
            this.buyersContacts = null;
            this.buyersAddresses = null;
        }
        this.highlightSelectedBuyerProfil();
    }

    searchBuyers() {
        this.resetParams();
        this.showEditorLoading = false;
        if (this.currentHttpRequest != null)
            this.currentHttpRequest.unsubscribe();

        if (this.search != null && this.search.length > 2) {
            this.showLoading = true;
            this.currentHttpRequest = this.firstAOCustomerServicesService.showSearchBuyerSuggestResponse(this.search)
                .subscribe((buyers) => {
                    var self = this;

                    buyers.body.prives.forEach(function (value) {
                        self.privateBuyerProfils.push(value);
                    });

                    buyers.body.publics.forEach(function (value) {
                        self.publicBuyerProfils.push(value)
                    });

                    this.searchEnded = true;
                    this.showLoading = false;
                    this.showEditorLoading = false;
                });
        }
    }

    addNewBuyerProfil() {
        this.resetParams();
        this.currentBuyerProfil = null;
        this.currentHttpRequest.unsubscribe();
        this.showEditorLoading = true;
        if (this.isPublic) {

            const params: CommanditaireCreate = {
                denomination: "Collectivité",
                status: "active"
            };

            this.currentHttpRequest = this.firstAOInternServicesService
                .createCommanditaire(params)
                .subscribe(buyer => {
                    this.currentBuyerProfilId = buyer.buyerID;
                    this.currentBuyerProfil = buyer;
                    this.showEditorLoading = false;
                    this.readonly = false;
                });

        } else {

            const dialogRef = this.dialog.open(EnterpriseAdderComponent);

            dialogRef.afterClosed().subscribe(result => {
                if (result != null && result != "") {
                    const params: PrivateBuyerCreate = {
                        denomination: result.name,
                        siren: result.siren,
                        ape: result.ape,
                    };
                    this.firstAOInternServicesService.createEntreprise(params).subscribe(buyer => {
                        this.currentBuyerProfilId = buyer.entreprise_id;
                        this.currentBuyerProfil = buyer;
                        this.showEditorLoading = false;
                        this.readonly = false;
                    }, error => {
                        this._snackBar.open("Une erreur est survenue lors de la création de l'entreprise",
                            "Fermer", {duration: 5000});
                    });
                }
            });
        }
        this.highlightSelectedBuyerProfil();
    }

    setBuyerType($event) {
        this.showEditorLoading = false;
        if ($event.index == 0) {
            this.isPublic = true;
        } else {
            this.isPublic = false;
        }
    }

    scheduleSearch() {
        var self = this;
        clearTimeout(this.searchTimeOut);
        this.searchEnded = false;
        this.readonly = true;
        this.privateBuyerProfils = [];
        this.publicBuyerProfils = [];
        if (this.search.length >= 3) {
            console.log("Lancement de la recherche");
            this.searchTimeOut = setTimeout(function () {
                console.log("search : " + self.search);
                self.searchBuyers();
            }, 1000);
        } else {
            console.log("Pas de recherche");
        }
    }

    editBuyerProfil() {
        this.readonly = false;
    }

    saveBuyerProfil() {
        this.readonly = true;
        this.showEditorLoading = true;
        if (this.isPublic) {
            const params: FirstAOInternServicesService.EditCommanditaireParams = {
                commanditaireCreate: this.currentBuyerProfil,
                buyerID: this.currentBuyerProfilId
            };

            this.firstAOInternServicesService.editCommanditaire(params).subscribe(value => {
                this.showEditorLoading = false;
            });
        } else {
            const params: FirstAOInternServicesService.EditEntrepriseParams = {
                privateBuyerCreate: this.currentBuyerProfil(),
                buyerID: this.currentBuyerProfilId
            };

            this.firstAOInternServicesService.editEntreprise(params).subscribe(value => {
                this.showEditorLoading = false;
            });
        }
    }

    highlightSelectedBuyerProfil() {
        let elems = document.getElementsByClassName('buyerProfil');

        [].forEach.call(elems, function (el) {
            el.classList.remove("selectedBuyerProfil");
        });

        if (this.currentBuyerProfilId != null) {

            let noticeToHighlight;
            if (this.isPublic)
                noticeToHighlight = document.getElementById('publicBuyerProfil-' + this.currentBuyerProfilId);
            else
                noticeToHighlight = document.getElementById('priveBuyerProfil-' + this.currentBuyerProfilId);

            if (noticeToHighlight != null)
                noticeToHighlight.classList.add('selectedBuyerProfil');
        }
    }

    resetParams() {
        this.buyerEditing = false;
        this.contactEditing = false;
        this.addressEditing = false;
    }

    /** ------------------------ START CONTACT PART ------------------------ **/

    addNewContact() {
        this.resetParams();
        const commanditaireContact: CommanditaireContact = {
            nom: "Nouveau contact"
        };


        if (this.isPublic) {
            const params: FirstAOInternServicesService.CreateCommanditaireContactsParams = {
                commanditaireContact: commanditaireContact,
                buyerID: this.currentBuyerProfil.buyer_id
            };

            this.firstAOInternServicesService.createCommanditaireContacts(params).subscribe(contact => {
                this.buyersContacts.push(contact);
                this.contactEditing = true;
            });
        } else {
            const params: FirstAOInternServicesService.CreateCommanditaireContactsParams = {
                commanditaireContact: commanditaireContact,
                buyerID: this.currentBuyerProfil.buyer_id
            };

            this.firstAOInternServicesService.createEntrepriseContacts(params).subscribe(contact => {
                this.buyersContacts.push(contact);
                this.contactEditing = true;
            });
        }
    }

    editContact() {
        this.contactEditing = true;
    }

    saveContact() {
        const params: FirstAOInternServicesService.EditCommanditaireContactsParams = {
            contactID: this.currentContact.id,
            commanditaireContact: {
                email: this.currentContact.nom,
                fax: this.currentContact.fax,
                id: this.currentContact.id,
                nom: this.currentContact.nom,
                telephone: this.currentContact.telephone
            },
            buyerID: this.currentBuyerProfil.buyer_id
        };

        this.firstAOInternServicesService.editCommanditaireContacts(params).subscribe(value => {
            this.contactEditing = false;
        });
    }

    loadContact($event) {
        this.resetParams();
        if ($event != null && this.buyersContacts != null) {
            let self = this;
            this.buyersContacts.forEach(function (contact) {
                if (contact.id == $event) {
                    self.currentContact = contact;
                }
            });
        } else {
            this.currentContact = null;
        }
    }

    /** ------------------------ END CONTACT PART ------------------------ **/


    /** ------------------------ START ADDRESS PART ------------------------ **/

    addNewAddress() {
        this.resetParams();
        const commanditaireAdresse: CommanditaireAdresse = {
            libelle: "Nouvelle adresse",
            status: 1
        };

        const params: FirstAOInternServicesService.CreateCommanditaireAdresseParams = {
            commanditaireAdresse: commanditaireAdresse,
            buyerID: this.currentBuyerProfil.buyer_id
        };

        this.firstAOInternServicesService.createCommanditaireAdresse(params).subscribe(address => {
            this.buyersAddresses.push(address);
            this.addressEditing = true;
        });
    }

    editAddress() {
        this.addressEditing = true;
    }

    saveAddress() {
        const params: FirstAOInternServicesService.EditEntrepriseAdresseParams = {
            buyerAdresseID: null,
            commanditaireAdresse: null,
            buyerID: this.currentBuyerProfil.buyer_id
        };

        this.firstAOInternServicesService.editCommanditaireAdresse(params).subscribe(value => {
            this.addressEditing = false;
        });
    }

    // loadAddress($event) {
    //     this.addressEditing = false;
    //     if ($event != null && this.buyersAddresses != null) {
    //         let self = this;
    //         this.buyersAddresses.forEach(function (address) {
    //             if (address.id == $event) {
    //                 self.addressName = address.libelle;
    //                 self.addressPath = address.adresse;
    //                 self.addressCity = address.city;
    //                 self.addressZip = address.zip;
    //             }
    //         });
    //
    //     } else {
    //         this.addressName = null;
    //         this.addressPath = null;
    //         this.addressCity = null;
    //         this.addressZip = null;
    //     }
    // }

    /** ------------------------ END ADDRESS PART ------------------------ **/
}
