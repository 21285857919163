import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MissionService} from "../../mission.service";

@Component({
    selector: 'app-avis-edition',
    templateUrl: './avis-edition.component.html',
    styleUrls: ['./avis-edition.component.css'],
    providers: [MissionService]
})
export class AvisEditionComponent implements OnInit {

    currentTab = "todo";
    subscription: Subscription;
    currentNoticeId = null;
    isSearching = false;
    processMode: boolean = true;
    opened = true;

    constructor(private missionService: MissionService) {
        this.subscription = missionService.currentNoticeId$.subscribe(
            value => {
                // if (value != null) {
                //     document.getElementById('aoEditor').classList.remove('displayNone');
                // } else {
                //     if (document.getElementById('aoEditor') != null)
                //         document.getElementById('aoEditor').classList.add('displayNone');
                // }
                this.currentNoticeId = value;
            });
    }

    ngOnInit() {
    }

    changeListTab(event) {
        if (event.index == 0) {
            this.processMode = true;
        } else if (event.index == 1) {
            this.processMode = null;
        } else {
            this.processMode = false;
        }
        // this.missionService.changeAlreadyDoneMode(event.index != 0);
    }

    changePagination(event) {
        this.missionService.changePagination(event.pageSize, event.pageIndex, true);
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.subscription.unsubscribe();
    }
}
