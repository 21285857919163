<div *ngIf="!showLoading else loading">

    <h2 style="margin-top: 0; font-weight: bold;">{{title}}</h2>
    <div>
        <mat-form-field class="inputContainer margR10">
            <input matInput placeholder="Libellé" type="text" [(ngModel)]="libelle">
        </mat-form-field>

        <mat-form-field class="inputContainer">
            <input matInput placeholder="URL" type="text" [(ngModel)]="adresse">
        </mat-form-field>
    </div>

    <div>
        <button class="saveBtn" (click)="closeAndSave()" id="closeAndSaveBuyerButton">Enregistrer les modifications
        </button>
        <button class="cancelBtn" mat-dialog-close id="closeAndCancelBuyerButton">Annuler les modifications</button>
    </div>

</div>

<ng-template #loading>
    <div style="height: 100%">
        <mat-spinner style="margin: auto;"></mat-spinner>
    </div>
</ng-template>