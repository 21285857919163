import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AoContractorFacade} from "../../../api/gen/models/ao-contractor-facade";
import {Entreprise} from "../../../api/gen/models/entreprise";

@Component({
  selector: 'app-select-entreprise',
  templateUrl: './select-entreprise.component.html',
  styleUrls: ['./select-entreprise.component.css']
})
export class SelectEntrepriseComponent implements OnInit {

  contractor: AoContractorFacade;
  suggestion: Entreprise;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<SelectEntrepriseComponent>) {
    this.contractor = this.data.contractor;
    this.suggestion = this.data.suggestion;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close({
      accept: false
    });
  }

  accept() {
    this.dialogRef.close({
      accept: true,
      suggestion: this.suggestion
    });
  }
}
