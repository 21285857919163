<div class="lieuxManager">

    <h2 mat-dialog-title>Gestion des adresses complémentaires</h2>

    <div class="lieuxManagerContent">

        <div class="leftLieuxPart">

            <div class="lieuxList">
                <div class="noselect" *ngFor="let adresse of data.notice.adresses_complementaires">
                    <a (click)="openLieu(adresse)">
                        <div id="addressCompl{{adresse.id}}" class="notice noselect">{{adresse.denomination}}</div>
                    </a>
                </div>
            </div>

            <button id="addLieuButton" *ngIf="!readOnly" (click)="addNewAddress()">
                <mat-icon aria-hidden="false" class="addCircle">add_circle</mat-icon>
                Ajouter une adresse
            </button>

        </div>

        <div class="rightLieuxPart" *ngIf="currrentAddress != null">
            <!--<app-cpv-code-chooser [cpvCodes]="currentCPVCodes" [readonly]="!lieuEditing"></app-cpv-code-chooser>-->

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Type</div>
                <mat-form-field id="typeAddressSel" class="selectAoEditor flexGrow1">
                    <mat-select #typeAddressSel [(ngModel)]="currrentAddress.type" [disabled]="!addressEditing"
                                [matTooltip]="typeAddressSel.selected?.viewValue" matTooltipPosition="above">
                        <mat-option value="url_administratif">Renseignement d'ordre administratif</mat-option>
                        <mat-option value="url_tech">Renseignement d'ordre technique</mat-option>
                        <mat-option value="url_technique">Renseignement d'ordre administratif et technique</mat-option>
                        <mat-option value="url_renseignement">Renseignements complémentaires</mat-option>
                        <mat-option value="url_envoi_offre">Renseignement envoi candidature</mat-option>
                        <mat-option value="url_document">Renseignement obtention document</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Dénomination :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.denomination">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Contact :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.contact">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Correspondant :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.correspondant">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Email :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.email">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Fax :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.fax">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Téléphone :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.telephone">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Adresse :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.adresse">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Ville :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.ville">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">ZIP :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.zip">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Pays :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!addressEditing" matInput [(ngModel)]="currrentAddress.pays">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine" *ngIf="!readOnly" style="display: block;">

                <button *ngIf="!addressEditing" (click)="editAddress()" id="editLieuButton">
                    Modifier l'adresse complémentaire
                </button>
                <button class="saveBtn" *ngIf="addressEditing" (click)="saveAddressModification()" id="saveLieuButton">
                    Enregistrer
                </button>
                <button class="cancelButton" style="float: right;" (click)="deleteAddress()" id="deleteLieuButton">
                    Supprimer l'adresse complémentaire
                </button>


            </div>

        </div>

    </div>

</div>