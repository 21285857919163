import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MissionService} from "../../../mission.service";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {AoLieu} from "../../../api/gen/models/ao-lieu";
import EditorAddNoticeAdresseComplementaireParams = FirstAOInternServicesService.EditorAddNoticeAdresseComplementaireParams;
import {AoAdressesComplementaires} from "../../../api/gen/models/ao-adresses-complementaires";
import EditorDeleteNoticeAdresseComplementaireParams = FirstAOInternServicesService.EditorDeleteNoticeAdresseComplementaireParams;
import EditorEditNoticeAdresseComplementaireParams = FirstAOInternServicesService.EditorEditNoticeAdresseComplementaireParams;

@Component({
    selector: 'app-complementary-addresses-manager',
    templateUrl: './complementary-addresses-manager.component.html',
    styleUrls: ['./complementary-addresses-manager.component.css']
})
export class ComplementaryAddressesManagerComponent implements OnInit {

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    currrentAddress;
    addressEditing: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                private missionService: MissionService,
                private firstAOInternServicesService: FirstAOInternServicesService) {
        this.readOnly = data.readOnly;
    }

    ngOnInit() {
    }

    editAddress() {
        this.addressEditing = true;
    }

    saveAddressModification() {
        const adressesComplementaires: AoAdressesComplementaires = {
            aoId: this.data.notice.aoId,
            id: this.currrentAddress.id,
            type: this.currrentAddress.type,
            denomination: this.currrentAddress.denomination,
            contact: this.currrentAddress.contact,
            correspondant: this.currrentAddress.correspondant,
            email: this.currrentAddress.email,
            telephone: this.currrentAddress.telephone,
            fax: this.currrentAddress.fax,
            adresse: this.currrentAddress.adresse,
            ville: this.currrentAddress.ville,
            zip: this.currrentAddress.zip,
            pays: this.currrentAddress.pays
        };

        const param: EditorEditNoticeAdresseComplementaireParams = {
            noticeID: this.data.notice.aoId,
            noticeAdresseComplementaireID: this.currrentAddress.id,
            actualisSharedBeanFirstecoAoAdressesComplementaires: adressesComplementaires
        };

        this.firstAOInternServicesService.editorEditNoticeAdresseComplementaire(param).subscribe(value => {
            this.addressEditing = false;
        });
    }

    openLieu(address) {
        this.addressEditing = false;
        this.currrentAddress = address;

        this.removeAllSelected();

        if (address.id != null) {
            document.getElementById('addressCompl' + address.id).classList.add('selectedLieu');
        }
    }

    removeAllSelected() {
        let elems = document.getElementsByClassName('noselect');

        [].forEach.call(elems, function (el) {
            el.classList.remove("selectedLieu");
        });
    }

    addNewAddress() {
        const aoLieu: AoAdressesComplementaires = {
            aoId: this.data.notice.aoId,
            denomination: "Nouvelle adresse complémentaire",
            type: 'url_administratif'
        };

        const param: EditorAddNoticeAdresseComplementaireParams = {
            noticeID: this.data.notice.aoId,
            actualisSharedBeanFirstecoAoAdressesComplementaires: aoLieu
        };

        this.firstAOInternServicesService.editorAddNoticeAdresseComplementaire(param).subscribe(value => {
            this.data.notice.adresses_complementaires.push(value);
        });
    }

    deleteAddress() {
        const param: EditorDeleteNoticeAdresseComplementaireParams = {
            noticeID: this.data.notice.aoId,
            noticeAdresseComplementaireID: this.currrentAddress.id
        };

        this.firstAOInternServicesService.editorDeleteNoticeAdresseComplementaire(param).subscribe(value => {
            this.addressEditing = false;


            const index: number = this.data.notice.adresses_complementaires.indexOf(this.currrentAddress);
            if (index !== -1) {
                this.data.notice.adresses_complementaires.splice(index, 1);
            }

            this.currrentAddress = null;

        });
    }

}
