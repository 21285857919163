import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {AddressManagerComponent} from "../../modal/address-manager/address-manager.component";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {CommanditaireContact} from "../../../api/gen/models/commanditaire-contact";
import {CommanditaireAdresse} from "../../../api/gen/models/commanditaire-adresse";

@Component({
    selector: 'app-address-chooser',
    templateUrl: './address-chooser.component.html',
    styleUrls: ['./address-chooser.component.css']
})
export class AddressChooserComponent implements OnInit {

    // ----------------------------------------- ReadOnly -----------------------------------------
    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }
    // ----------------------------------------- ReadOnly -----------------------------------------


    // ----------------------------------------- EntrepriseId -----------------------------------------
    private _entrepriseId: number;

    get entrepriseId(): number {
        return this._entrepriseId;
    }

    @Input("entrepriseId")
    set entrepriseId(val: number) {
        this._entrepriseId = val;
    }
    // ----------------------------------------- EntrepriseId -----------------------------------------


    // ----------------------------------------- BuyersAddresses -----------------------------------------
    private _buyersAddresses;

    get buyersAddresses() {
        return this._buyersAddresses;
    }

    @Input("buyersAddresses")
    set buyersAddresses(val) {
        this._buyersAddresses = val;
    }
    // ----------------------------------------- BuyersAddresses -----------------------------------------



    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------
    private _noticeSavingInProgress: boolean;

    get noticeSavingInProgress(): boolean {
        return this._noticeSavingInProgress;
    }

    @Input("noticeSavingInProgress")
    set noticeSavingInProgress(val: boolean) {
        this._noticeSavingInProgress = val;
        if (this.noticeSavingInProgress) {
            this.saveAddress();
        }
    }
    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------


    adresseId: number;

    addressesControl = new FormControl('', [Validators.required]);

    @Output() addressChanged = new EventEmitter<any>();

    @Input("currentNotice") currentNotice;

    @Input("currentAddress") currentAddress: CommanditaireAdresse;

    @Input("isPublic") isPublic;

    @ViewChild('addressSelect') addressSelect;

    constructor(public dialog: MatDialog,
                private firstAOInternServicesService: FirstAOInternServicesService) {
    }

    ngOnInit() {
        this.adresseId = this.currentNotice.commanditaireAdresseId;
    }

    onAddressSelected($event) {
        if ($event != null && $event.value != null) {
            if ($event.value != this.currentNotice.commanditaireAdresseId) {
                this.currentAddress = this.getAddress($event.value);
                this.currentNotice.commanditaire_adresse = this.currentAddress;
                this.currentNotice.commanditaireAdresseId = $event.value;
                this.addressChanged.emit();
            }
        }
    }

    openCreateAddressModal() {
        this.addressSelect.close();
        const dialogRef = this.dialog.open(AddressManagerComponent, {
            data: {
                title: "Création d'une adresse",
                buyerId: this.currentNotice.identiteAcheteurId,
                isCreating: true,
                isPublic: this.isPublic
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != "") {
                if (this.buyersAddresses == null)
                    this.buyersAddresses = [];

                this.currentNotice.commanditaire_adresse = result.address;
                this.currentNotice.commanditaireAdresseId = result.address.id;

                this.currentAddress = result.address;
                this.buyersAddresses.push(result.address);
                this.currentAddress.id = result.address.id;

                this.addressChanged.emit();
            }
        });
    }

    getAddress(addressId) {
        let contactToReturn = null;
        let isSearching = this.buyersAddresses != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.buyersAddresses.length) {
                if (this.buyersAddresses[index].id === addressId) {
                    contactToReturn = this.buyersAddresses[index];
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return contactToReturn;
    }

    saveAddress() {
        const address: CommanditaireAdresse = {
            id: this.currentAddress.id,
            libelle: this.currentAddress.libelle,
            city: this.currentAddress.city,
            zip: this.currentAddress.zip,
            adresse: this.currentAddress.adresse
        };

        if (this.isPublic) {
            const params: FirstAOInternServicesService.EditCommanditaireAdresseParams = {
                buyerAdresseID: this.currentAddress.id,
                commanditaireAdresse: address,
                buyerID: this.currentNotice.commanditaire.buyerID
            };

            this.firstAOInternServicesService.editCommanditaireAdresse(params).subscribe(value => {
            });
        } else {
            const params: FirstAOInternServicesService.EditCommanditaireAdresseParams = {
                buyerAdresseID: this.currentAddress.id,
                commanditaireAdresse: address,
                buyerID: this.currentNotice.commanditaire.buyerID
            };

            this.firstAOInternServicesService.editEntrepriseAdresse(params).subscribe(value => {
            });
        }
    }
}
