<div id="aoEditor" class="aoEditor" *ngIf="currentNotice && buyersContacts && buyersAddresses else loading">
    <div class="aoEditorTopButtonBar">
        <div class="aoEditorFirstTopButtonBarLine">
            <div class="aoNumber">Appel d'offres n°{{currentNotice.aoId}}</div>

            <button *ngIf="isPublic" [class]="(currentNotice.attachments.length > 0 || getUrlDce() != null)
            ? 'dceButtonAdded' : 'dceButtonNotAdded'" (click)="openFileManager()">DCE</button>
            <button *ngIf="!isPublic" [class]="(currentNotice.attachments.length || getUrlDce() != null)
            ? 'dceButtonAdded' : 'dceButtonNotAdded'" (click)="openFileManager()">CDC</button>

            <button *ngIf="readOnly" style="height: fit-content" (click)="editAO()" mat-raised-button>
                Modifier
            </button>

            <button *ngIf="readOnly && currentNotice.state == 'issued'"
                    class="publishButton" (click)="publishAO(false)" mat-raised-button>
                Dépublication
            </button>

            <div class="noticeTitleContainer">
                <input [readonly]="readOnly" placeholder="Titre de l'Appel d'Offres *" type="text" id="noticeTitle"
                       [(ngModel)]="currentNotice.titre" (ngModelChange)="titleUpdate()">
            </div>

            <button *ngIf="!collectedMode && !isSearchingMode" id="checkDoublonsButton" (click)="openDoublonsDisplayer()"
                mat-mini-fab [matTooltip]="potentialDoublons.length > 0 ? 'Doublons potentiels' : 'Pas de doublons'">
                <mat-icon *ngIf="!isChekingDoublon && potentialDoublons == null">
                    search
                </mat-icon>
                <mat-icon *ngIf="isChekingDoublon" class="rotating">
                    sync
                </mat-icon>
                <mat-icon *ngIf="!isChekingDoublon && potentialDoublons != null && potentialDoublons.length > 0">
                    warning
                </mat-icon>
                <mat-icon *ngIf="!isChekingDoublon && potentialDoublons != null && potentialDoublons.length == 0">
                    check
                </mat-icon>
            </button>

            <i class="material-icons aoEditorIcons floatR" style="color: green;" matTooltip="L'AO est à jour"
               *ngIf="!isdNoticeUnsaved && !readOnly && !noticeSavingInProgress">
                check
            </i>
            <i class="material-icons aoEditorIcons floatR rotating" style="color: black;"
               matTooltip="Enregistrement en cours"
               *ngIf="noticeSavingInProgress && !readOnly">
                sync
            </i>
            <i class="material-icons aoEditorIcons floatR" style="color: blue;"
               matTooltip="L'AO n'est pas encore à jour"
               *ngIf="isdNoticeUnsaved && !readOnly && !noticeSavingInProgress">
                create
            </i>
            <i class="material-icons aoEditorIcons floatR" style="color: red;" matTooltip="L'AO n'est pas encore à jour"
               *ngIf="noticeSavingFailed && !readOnly && !noticeSavingInProgress">
                cancel
            </i>
        </div>


        <!--------------------------------- START LIENS EXTERNES SECTION -------------------------------->
        <mat-accordion *ngIf="isPublic">
            <mat-expansion-panel [expanded]="true" style="background: #fffef0; margin-top: 20px;">
                <mat-expansion-panel-header>
                    Liens externes
                </mat-expansion-panel-header>


                <!-- START SOURCE SECTION-->
                <mat-accordion id="sourcesContainer">
                    <mat-expansion-panel [expanded]="true" style="background: #e8e8e8;">
                        <mat-expansion-panel-header>
                            <span style="height: fit-content; margin: auto 0;">
                                Sources ({{currentNotice?.sources.length}}) <span style="color: red">*</span>
                            </span>
                            <button class="saveBtn" [disabled]="readOnly && overrideCollectedMode" (click)="addNewSource()">
                                Ajouter une source
                            </button>
                        </mat-expansion-panel-header>

                        <div class="sourcesContainer">
                            <div *ngFor="let noticeSource of currentNotice.sources" class="sourceItemsContainer">
                                <div class="sourceItem">
                                    <mat-form-field class="selectAoEditor flexGrow1 margB-12">
                                        <mat-select [(value)]="noticeSource.sourceListeId" [disabled]="readOnly && overrideCollectedMode"
                                                    (valueChange)="updateSource(noticeSource, $event)">
                                            <mat-option *ngFor="let source of allSources"
                                                        [value]="source.id">{{source.libelle}}</mat-option>
                                            <button mat-button (click)="createNewSource()" class="adderButton">
                                                Ajouter une source
                                            </button>
                                        </mat-select>
                                    </mat-form-field>


                                    <mat-form-field class="flexGrow1 margR10 margB-12">
                                        <input [readonly]="readOnly && overrideCollectedMode" matInput [(ngModel)]="noticeSource.urlSource"
                                               placeholder="URL"
                                               (ngModelChange)="scheduleSourceSave(noticeSource, null)">
                                    </mat-form-field>

                                    <button mat-icon-button matTooltip="Accéder à la source"
                                            (click)="goToLink(noticeSource.urlSource)">
                                        <mat-icon style="color: blue;">link</mat-icon>
                                    </button>

                                    <mat-form-field class="flexGrow1 margR10 margB-12">
                                        <input [readonly]="readOnly && overrideCollectedMode" matInput [(ngModel)]="noticeSource.reference"
                                               placeholder="Référence"
                                               (ngModelChange)="scheduleSourceSave(noticeSource, null)">
                                    </mat-form-field>

                                    <button mat-icon-button matTooltip="Retirer la source"
                                            (click)="removeSource(noticeSource)"
                                            *ngIf="!readOnly && overrideCollectedMode">
                                        <mat-icon style="color: red;">cancel</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- END SOURCE SECTION-->


                <!-- START URL SECTION-->
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true" style="background: #e8e8e8; margin-top: 15px;">
                        <mat-expansion-panel-header>
                            URL <span style="color: red">(au moins une obligatoire)</span>
                        </mat-expansion-panel-header>

                        <div class="sourcesContainer" id="urlsContainer">
                            <div class="sourceItemsContainer">
                                <div class="sourceItem">
                                    <mat-form-field class="flexGrow1 margR10 margB-12">
                                        <input [readonly]="readOnly" matInput
                                               [(ngModel)]="urlProfilAcheteur" (ngModelChange)="scheduleUrlsSave()"
                                               placeholder="Lien du profil d'acheteur">
                                    </mat-form-field>

                                    <a mat-icon-button [href]="urlProfilAcheteur" target="_blank">
                                        <mat-icon style="color: blue;">link</mat-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="sourceItemsContainer">
                                <div class="sourceItem">
                                    <mat-form-field class="flexGrow1 margR10 margB-12">
                                        <input [readonly]="readOnly" matInput
                                               [(ngModel)]="urlParticipation" (ngModelChange)="scheduleUrlsSave()"
                                               placeholder="Lien de participation">
                                    </mat-form-field>

                                    <a mat-icon-button [href]="urlParticipation" target="_blank">
                                        <mat-icon style="color: blue;">link</mat-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="sourceItemsContainer">
                                <div class="sourceItem">
                                    <mat-form-field class="flexGrow1 margR10 margB-12">
                                        <input [readonly]="readOnly" matInput
                                               [(ngModel)]="urlDocument" (ngModelChange)="scheduleUrlsSave()"
                                               placeholder="Lien d'obtention des documents">
                                    </mat-form-field>

                                    <a mat-icon-button [href]="urlDocument" target="_blank">
                                        <mat-icon style="color: blue;">link</mat-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="sourceItemsContainer">
                                <div class="sourceItem">
                                    <mat-form-field class="flexGrow1 margR10 margB-12">
                                        <input [readonly]="readOnly" matInput
                                               [(ngModel)]="urlWebsite" (ngModelChange)="scheduleUrlsSave()"
                                               placeholder="Adresse internet">
                                    </mat-form-field>

                                    <a mat-icon-button [href]="urlWebsite" target="_blank">
                                        <mat-icon style="color: blue;">link</mat-icon>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- END URL SECTION-->


            </mat-expansion-panel>
        </mat-accordion>

        <!--------------------------------- END LIENS EXTERNES SECTION -------------------------------->
    </div>
    <div class="aoEditorNotice">


        <!--------------------------------- START CPVS SECTION -------------------------------->
        <div class="cpvCodesCntainer">
            <app-cpv-code-chooser (cpvsChanged)="scheduleSave()" [cpvCodes]="noticeCPVCodes" [readonly]="readOnly">
            </app-cpv-code-chooser>
        </div>
        <!--------------------------------- END CPVS SECTION -------------------------------->


        <!--------------------------------- START DEPARTEMENTS SECTION -------------------------------->
        <div class="cpvCodesCntainer">
            <app-departement-chooser (departementsChanged)="scheduleSave()" [departements]="noticeDepartements"
                                     [readonly]="readOnly && overrideCollectedMode">
            </app-departement-chooser>
        </div>
        <!--------------------------------- END DEPARTEMENTS SECTION -------------------------------->


        <!---------------------------- START CHAMPS AO PRIVE URLS SECTION --------------------------->
        <div *ngIf="!isPublic" style="display: flex;">
            <mat-form-field class="flexGrow1 margR10">
                <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleUrlsSave()" matInput
                       [(ngModel)]="urlRfi" placeholder="URL RFI">
            </mat-form-field>

            <mat-form-field class="flexGrow1 margR10">
                <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleUrlsSave()" matInput
                       [(ngModel)]="urlRfq" placeholder="URL RFQ">
            </mat-form-field>

            <mat-form-field class="flexGrow1">
                <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleUrlsSave()" matInput
                       [(ngModel)]="urlConfidentialite" placeholder="URL Certificat de confidentialité">
            </mat-form-field>
        </div>
        <!----------------------------- END CHAMPS AO PRIVE SECTION URLS ---------------------------->


        <!--------------------------------- START BUYER SECTION -------------------------------->
        <app-commanditaire-chooser *ngIf="isPublic" [currentNotice]="currentNotice" (commanditaireChanged)="scheduleSave()"
                                   [readonly]="readOnly && overrideCollectedMode" (showLoading)="showLoadingFrame($event)"
                                   [noticeSavingInProgress]="noticeSavingInProgress">
        </app-commanditaire-chooser>

        <app-enterprise-choose *ngIf="!isPublic" [currentNotice]="currentNotice" (enterpriseChanged)="scheduleSave()"
                               [readonly]="readOnly && overrideCollectedMode" (showLoading)="showLoadingFrame($event)"
                               [noticeSavingInProgress]="noticeSavingInProgress">
        </app-enterprise-choose>
        <!--------------------------------- END BUYER SECTION -------------------------------->


        <!--------------------------------- START NOTICE SECTION -------------------------------->
        <mat-accordion>
            <mat-expansion-panel [expanded]="true" style="background: #daf7ef; margin-top: 20px; margin-bottom: 20px;">
                <mat-expansion-panel-header>
                    Appel d'offres n°{{currentNotice.aoId}}
                </mat-expansion-panel-header>

                <div class="noticeBlocContainer">

                    <div class="noticeLineContainer">

                        <mat-form-field class="flexGrow1 margR10" id="deadlineDP">
                            <input [readonly]="readOnly" matInput [matDatepicker]="picker" [(ngModel)]="noticeEndDate"
                                  placeholder="Date limite de candidature *" (dateChange)="onChangeEndDate()">
                            <mat-datepicker-toggle [disabled]="readOnly" matSuffix
                                                   [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="flexGrow1 margR10" id="publicationDateDP">
                            <input [readonly]="readOnly && overrideCollectedMode" matInput [matDatepicker]="spicker"
                                   [(ngModel)]="noticeStartDate"
                                   placeholder="Date de publication *" (dateChange)="onChangeStartDate()">
                            <mat-datepicker-toggle [disabled]="readOnly && overrideCollectedMode" matSuffix
                                                   [for]="spicker"></mat-datepicker-toggle>
                            <mat-datepicker #spicker></mat-datepicker>
                        </mat-form-field>

                    </div>

                    <div class="noticeLineContainer" *ngIf="isPublic">
                        <div class="noticeSelectLabel">Nature de l'avis <span style="color: red">*</span></div>
                        <mat-form-field id="natureAvisSel" class="selectAoEditor flexGrow1">
                            <mat-select #natureAvisSel [(ngModel)]="currentNotice.natureAvis"
                                        (ngModelChange)="scheduleSave()" [disabled]="readOnly && overrideCollectedMode"
                                        [matTooltip]="currentNotice.natureAvis" matTooltipPosition="above">
                                <mat-option value="appel_offre">Avis de marché</mat-option>
                                <mat-option value="appel_offre_standard">Avis de marché</mat-option>
                                <mat-option value="appel_offre_concours">Avis de marché - Concours</mat-option>
                                <mat-option value="appel_offre_periodique">Avis de marché - Périodique</mat-option>
                                <mat-option value="appel_offre_qualification">Avis de marché - Qualification
                                </mat-option>
                                <mat-option value="appel_offre_sad">Avis de marché - SAD</mat-option>
                                <mat-option value="appel_offre_concession">Avis de marché - Concession</mat-option>
                                <mat-option value="appel_offre_concessionnaire">Avis de marché - Concessionnaire
                                </mat-option>
                                <mat-option value="attribution">Avis d'attribution</mat-option>
                                <mat-option value="autre">Autre</mat-option>
                                <mat-option value="intention_conclure">Intention de conclure</mat-option>
                                <mat-option value="modification">Modification</mat-option>
                                <mat-option value="periodique">Périodique</mat-option>
                                <mat-option value="preinformation">Préinformation</mat-option>
                                <mat-option value="qualification">Qualification</mat-option>
                                <mat-option value="rectificatif">Avis rectificatif</mat-option>
                                <mat-option value="soustraitance">Sous Traitance</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="noticeLineContainer">
                        <div class="noticeSelectLabel">Type de marché <span style="color: red">*</span></div>
                        <mat-form-field id="typeMarcheSel" class="selectAoEditor flexGrow1">
                            <mat-select #typeMarcheSel [(ngModel)]="currentNotice.typeMarche"
                                        (ngModelChange)="scheduleSave()" [disabled]="readOnly && overrideCollectedMode"
                                        [matTooltip]="currentNotice.typeMarche" matTooltipPosition="above">
                                <mat-option value="works">Travaux</mat-option>
                                <mat-option value="supplies">Fournitures</mat-option>
                                <mat-option value="services">Services</mat-option>
                                <mat-option value="other">Autres</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="noticeLineContainer" *ngIf="isPublic">
                        <div class="noticeSelectLabel">Statut <span style="color: red">*</span></div>
                        <mat-form-field id="statutSel" class="selectAoEditor flexGrow1">
                            <mat-select #statutSel [(ngModel)]="currentNotice.statutAvis"
                                        (ngModelChange)="scheduleSave()" [disabled]="readOnly && overrideCollectedMode"
                                        [matTooltip]="currentNotice.statutAvis" matTooltipPosition="above">
                                <mat-option value="firstPublication">Première publication</mat-option>
                                <mat-option value="rectificatif">Avis rectificatif</mat-option>
                                <mat-option value="attribution">Avis d'attribution</mat-option>
                                <mat-option value="modification">Modification</mat-option>
                                <mat-option value="annulation">Annulation</mat-option>
                                <mat-option value="other">Autre</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="noticeLineContainer" *ngIf="isPublic">
                        <div class="noticeSelectLabel">Famille d'avis <span style="color: red">*</span></div>
                        <mat-form-field id="familleAvisSel" class="selectAoEditor flexGrow1">
                            <mat-select #familleAvisSel [(ngModel)]="currentNotice.familleAvis"
                                        (ngModelChange)="scheduleSave()" [disabled]="readOnly && overrideCollectedMode"
                                        [matTooltip]="currentNotice.familleAvis" matTooltipPosition="above">
                                <mat-option value="divers">Divers</mat-option>
                                <mat-option value="dsp">DSP</mat-option>
                                <mat-option value="fns">FNS</mat-option>
                                <mat-option value="joue">JOUE</mat-option>
                                <mat-option value="mapa">MAPA</mat-option>
                                <mat-option value="prive">Privé</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="noticeLineContainer" *ngIf="isPublic">
                        <div class="noticeSelectLabel">Type de procédure <span style="color: red">*</span></div>
                        <mat-form-field id="typeProcedureSel" class="selectAoEditor flexGrow1">
                            <mat-select #typeProcedureSel [(ngModel)]="currentNotice.procedureType"
                                        (ngModelChange)="scheduleSave()" [disabled]="readOnly && overrideCollectedMode"
                                        [matTooltip]="currentNotice.procedureType" matTooltipPosition="above">
                                <mat-option value="mapaGreater90k">Procédure adaptée (MAPA) > 90k</mat-option>
                                <mat-option value="mapaLess90k">Procédure adaptée (MAPA) < 90k</mat-option>
                                <mat-option value="mapa">Procédure adaptée (MAPA)</mat-option>
                                <mat-option value="openNotice">Procédure ouverte</mat-option>
                                <mat-option value="restrictedNotice">Procédure restreinte</mat-option>
                                <mat-option value="negociationNotice">Procédure avec négociation/Marché négocié
                                </mat-option>
                                <mat-option value="openContest">Concours ouvert</mat-option>
                                <mat-option value="restrictedContest">Concours restreint</mat-option>
                                <mat-option value="formalised">Formalisée</mat-option>
                                <mat-option value="dsp">Délégation service public (DSP)</mat-option>
                                <mat-option value="otherProcedures">Autre procédure</mat-option>
                                <mat-option value="concession">Concession</mat-option>
                                <mat-option value="competitiveDialog">Dialogue compétitif</mat-option>
                                <mat-option value="nc">Procédure NC</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="noticeLineContainer" style="margin: 20px 0 0" *ngIf="isPublic">
                        <mat-checkbox [disabled]="readOnly && overrideCollectedMode" class="cbEditor" (ngModelChange)="scheduleSave()"
                                      [(ngModel)]="currentNotice.withAccordCadre">
                            Avec accord cadre
                        </mat-checkbox>

                        <mat-checkbox [disabled]="readOnly && overrideCollectedMode" class="cbEditor" (ngModelChange)="scheduleSave()"
                                      [(ngModel)]="currentNotice.withCriteresEnvironnementaux">
                            Avec critères environnementaux
                        </mat-checkbox>

                        <mat-checkbox [disabled]="readOnly && overrideCollectedMode" class="cbEditor" (ngModelChange)="scheduleSave()"
                                      [(ngModel)]="currentNotice.withCriteresSociaux">
                            Avec critères sociaux
                        </mat-checkbox>

                        <mat-checkbox [disabled]="readOnly && overrideCollectedMode" class="cbEditor" (ngModelChange)="scheduleSave()"
                                      [(ngModel)]="currentNotice.withVariantes">
                            Variantes autorisées
                        </mat-checkbox>
                    </div>

                    <div class="noticeLineContainer"
                         style="padding-top: 20px; padding-left: 10px; background: #cccccc; border-radius: 10px;">

                        <mat-form-field *ngIf="isPublic" class="flexGrow1 margR10">
                            <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleSave()" matInput
                                   [(ngModel)]="currentNotice.valeur" placeholder="Budget">
                        </mat-form-field>

                        <mat-form-field *ngIf="isPublic" class="flexGrow1 margR10">
                            <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleSave()" matInput
                                   [(ngModel)]="currentNotice.valeurMin" placeholder="Budget minimum">
                        </mat-form-field>

                        <mat-form-field *ngIf="isPublic" class="flexGrow1 margR10">
                            <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleSave()" matInput
                                   [(ngModel)]="currentNotice.valeurMax" placeholder="Budget maximum">
                        </mat-form-field>

                        <mat-form-field class="flexGrow1 margR10">
                            <input [readonly]="readOnly && overrideCollectedMode" (ngModelChange)="scheduleSave()" matInput
                                   [(ngModel)]="currentNotice.valeurTotale" placeholder="Budget total">
                        </mat-form-field>

                    </div>

                    <div class="noticeLineContainer" style="margin: 10px 0" *ngIf="isPublic">
                        <div class="noticeSelectLabel">Marché avec reconduction</div>
                        <div class="flexGrow1">
                            <mat-slide-toggle [disabled]="readOnly && overrideCollectedMode" matInput (ngModelChange)="scheduleSave()"
                                              [(ngModel)]="currentNotice.withReconduction"></mat-slide-toggle>
                        </div>
                    </div>

                    <div class="noticeLastLineContainer" style="margin: 25px 0">
                        <div *ngIf="isPublic" class="noticeSelectLabel">Prestation divisée en lots</div>
                        <div *ngIf="isPublic" class="flexGrow1">
                            <mat-slide-toggle [disabled]="readOnly && overrideCollectedMode" matInput (ngModelChange)="scheduleSave()"
                                              [(ngModel)]="currentNotice.withLots"></mat-slide-toggle>
                        </div>
                        <button id="lieuxButton" mat-raised-button (click)="openLieuxManager()">
                            Lieux d'éxécution ({{noticeLieux?.length}})
                        </button>
                        <button *ngIf="isPublic" id="lotsButton" mat-raised-button (click)="openLotsManager()">
                            Lots associés ({{noticeLots?.length}})
                        </button>
                    </div>

                    <div *ngIf="isPublic" class="noticeCorpsContainer">
                        <mat-form-field id="noticeResumeEdito">
                            <mat-label>Résumé <span style="color: red">*</span></mat-label>
                            <textarea matInput [readonly]="readOnly" matTextareaAutosize
                                      matAutosizeMinRows=3
                                      [(ngModel)]="currentNotice.resume" (ngModelChange)="scheduleSave()"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="noticeCorpsContainer">
                        <mat-form-field id="noticeCorps">
                            <mat-label>Corps de l'appel d'offres <span style="color: red">*</span></mat-label>
                            <textarea matInput [readonly]="readOnly" matTextareaAutosize
                                      matAutosizeMinRows=3
                                      [(ngModel)]="currentNotice.objetComplet"
                                      (ngModelChange)="scheduleSave()"></textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isPublic" class="noticeCorpsContainer">
                        <mat-form-field id="noticeCaracteristiques">
                            <mat-label>Caractéristiques de l'appel d'offres</mat-label>
                            <textarea matInput [readonly]="readOnly" matTextareaAutosize
                                      matAutosizeMinRows=3
                                      [(ngModel)]="currentNotice.caracteristiques"
                                      (ngModelChange)="scheduleSave()"></textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isPublic" class="noticeCorpsContainer">
                        <mat-form-field id="noticeInfoAdditional">
                            <mat-label>Informations additionnelles de l'appel d'offres </mat-label>
                            <textarea matInput [readonly]="readOnly" matTextareaAutosize
                                      matAutosizeMinRows=3
                                      [(ngModel)]="currentNotice.infoAdditional"
                                      (ngModelChange)="scheduleSave()"></textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isPublic" class="noticeCorpsContainer">
                        <mat-form-field>
                            <mat-label>Renseignement éditorial</mat-label>
                            <textarea matInput [readonly]="readOnly" id="noticeRenseignemntEdito" matTextareaAutosize
                                      matAutosizeMinRows=3
                                      [(ngModel)]="currentNotice.renseignementsEditoriales"
                                      (ngModelChange)="scheduleSave()"></textarea>
                        </mat-form-field>
                    </div>

                </div>

            </mat-expansion-panel>
        </mat-accordion>
        <!--------------------------------- END NOTICE SECTION -------------------------------->


    </div>
    <div class="aoEditorBottomButtonBar">
        <label type="text" id="noticeInEditionFromUser">{{ noticeInEditionFromUser }}</label>
        <button *ngIf="!readOnly && collectedMode" style="margin-right: 30px;" id="noticePassInTreatmentButton"
                (click)="updateAo(true, false)">Passer en attente</button>
        <button *ngIf="!readOnly && collectedMode" style="" id="noticeValidateButton"
                (click)="updateAo(true, true)">Valider</button>
        <button *ngIf="!readOnly && !collectedMode" style="margin-right: 30px;" id="noticeSaveButton"
                (click)="updateAo(true, false)">Enregistrer</button>
        <button *ngIf="!readOnly && currentNotice.state == 'written' && !collectedMode" id="noticeSaveAndPublishButton"
                (click)="updateAo(true, true)">Enregistrer et Publier</button>
    </div>
</div>

<div *ngIf="showLoading" class="loadingFrame" style="height: 100%; width: 100%">
    <mat-spinner style="margin: auto;"></mat-spinner>
</div>

<ng-template #loading>
    <div style="height: 100%">
        <mat-spinner style="margin: auto;"></mat-spinner>
    </div>
</ng-template>
