/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AddressBookAo } from '../models/address-book-ao';
import { AddressAo } from '../models/address-ao';
import { Alerts } from '../models/alerts';
import { AlertsHistory } from '../models/alerts-history';
import { AlertAO } from '../models/alert-ao';
import { AlertAOBody } from '../models/alert-aobody';
import { ContractorQualificationRequest } from '../models/contractor-qualification-request';
import { AoCpv } from '../models/ao-cpv';
import { BuyersSuggestion } from '../models/buyers-suggestion';
import { CitySuggestion } from '../models/city-suggestion';
import { ContractorCriterion } from '../models/contractor-criterion';
import { KeywordsActivityArea } from '../models/keywords-activity-area';
import { CpvCategorieWithoutDetails } from '../models/cpv-categorie-without-details';
import { DceRequest } from '../models/dce-request';
import { EntrepriseEntity } from '../models/entreprise-entity';
import { FavorisList } from '../models/favoris-list';
import { FavorisFolder } from '../models/favoris-folder';
import { Favoris } from '../models/favoris';
import { FavorisAoMetadata } from '../models/favoris-ao-metadata';
import { AllCriterions } from '../models/all-criterions';
import { QueryCriterions } from '../models/query-criterions';
import { MainCategoriesTree } from '../models/main-categories-tree';
import { Localisations } from '../models/localisations';
import { LocalisationTree } from '../models/localisation-tree';
import { CpvCategorie } from '../models/cpv-categorie';
import { Notices } from '../models/notices';
import { NoticeFacade } from '../models/notice-facade';
import { Resource } from '../models/resource';
import { Searches } from '../models/searches';
import { SearchAO } from '../models/search-ao';
import { SearchesHistory } from '../models/searches-history';
import { AoSession } from '../models/ao-session';
import { SharesHistory } from '../models/shares-history';
import { EmailNoticeShare } from '../models/email-notice-share';
import { EmailNoticesToShare } from '../models/email-notices-to-share';
@Injectable({
  providedIn: 'root',
})
class FirstAOCustomerServicesService extends __BaseService {
  static readonly getAddressBooksPath = '/firstao/addressbooks';
  static readonly createAddressBooksPath = '/firstao/addressbooks';
  static readonly getAddressBookPath = '/firstao/addressbooks/{addressBookID}';
  static readonly updateAddressBookPath = '/firstao/addressbooks/{addressBookID}';
  static readonly deleteAddressBookPath = '/firstao/addressbooks/{addressBookID}';
  static readonly addContactAddressInAddressBookPath = '/firstao/addressbooks/{addressBookID}/addresses/{addressID}';
  static readonly deleteContactAddressFromAddressBookPath = '/firstao/addressbooks/{addressBookID}/addresses/{addressID}';
  static readonly getAddressesPath = '/firstao/addresses';
  static readonly createAddressPath = '/firstao/addresses';
  static readonly updateContactAddressPath = '/firstao/addresses/{addressID}';
  static readonly deleteContactAddressPath = '/firstao/addresses/{addressID}';
  static readonly getAlertsPath = '/firstao/alerts';
  static readonly getAlertsHistoryPath = '/firstao/alerts/history';
  static readonly getAlertPath = '/firstao/alerts/{alertID}';
  static readonly updateAlertPath = '/firstao/alerts/{alertID}';
  static readonly postContractorQualificationRequestPath = '/firstao/contractor_qualification_request/add/{noticeID}/{contractorID}';
  static readonly showSearchActivityAreaPath = '/firstao/datasearch/activity_area';
  static readonly showSearchBuyerSuggestPath = '/firstao/datasearch/buyers';
  static readonly showSearchCitiesSuggestPath = '/firstao/datasearch/cities';
  static readonly showSearchContractorSuggestPath = '/firstao/datasearch/contractors';
  static readonly showSearchKeyWordsSuggestPath = '/firstao/datasearch/keywords';
  static readonly showSearchKeyWordsActivityAreaSuggestPath = '/firstao/datasearch/keywords_activity_area';
  static readonly getSearchMainActivityAreaCategoriesFromCpvPath = '/firstao/datasearch/main_activity_area_categories_from_cpv';
  static readonly postDceRequestPath = '/firstao/dce_request/{noticeID}';
  static readonly getEntreprisePath = '/firstao/entreprise/{entrepriseId}';
  static readonly getFavoritesFromFolderPath = '/firstao/favorites/folder/{folderID}';
  static readonly editFavoriteFolderPath = '/firstao/favorites/folder/{folderID}';
  static readonly deleteFavoriteFolderPath = '/firstao/favorites/folder/{folderID}';
  static readonly addAoToFavoritesInFolderPath = '/firstao/favorites/folder/{folderID}/notice/{noticeID}';
  static readonly moveAoInFavoritesToOtherFolderPath = '/firstao/favorites/folder/{folderID}/notice/{noticeID}';
  static readonly getFavoritesFolderPath = '/firstao/favorites/folders';
  static readonly addFavoritesFolderPath = '/firstao/favorites/folders';
  static readonly editAoDataFromFavorisPath = '/firstao/favorites/notice/{noticeID}';
  static readonly deleteAoFromFavoritesPath = '/firstao/favorites/notice/{noticeID}';
  static readonly showFilterscriterionsPath = '/firstao/filter/criterions';
  static readonly editMainFilterPath = '/firstao/filter/main';
  static readonly postLogsUiPath = '/firstao/logs/ui';
  static readonly getNomenclatureCPVPath = '/firstao/nomenclature/activity_area';
  static readonly showActivityAreasDataPath = '/firstao/nomenclature/activity_areas_data';
  static readonly getCPVTreePath = '/firstao/nomenclature/cpvTree';
  static readonly getNomenclatureLocalisationsPath = '/firstao/nomenclature/localisations';
  static readonly getNomenclatureLocalisationsTreePath = '/firstao/nomenclature/localisations_tree';
  static readonly showMainSearchActivityAreaCategoriesPath = '/firstao/nomenclature/main_activity_area_categories';
  static readonly searchNoticesPath = '/firstao/notices/search';
  static readonly searchNoticesPostPath = '/firstao/notices/search';
  static readonly showAoPath = '/firstao/notices/{noticeID}';
  static readonly downloadNoticeAttachmentPath = '/firstao/notices/{noticeID}/attachments/{hashAttachmentID}/{attachmentID}';
  static readonly showSearchesPath = '/firstao/searches';
  static readonly saveSearchPath = '/firstao/searches';
  static readonly showSearchesHistoryPath = '/firstao/searches/history';
  static readonly getSearchDetailsPath = '/firstao/searches/{searchID}';
  static readonly editSearchPath = '/firstao/searches/{searchID}';
  static readonly deleteSearchPath = '/firstao/searches/{searchID}';
  static readonly editSearchFilterPath = '/firstao/searches/{searchID}/filter';
  static readonly getUserSessionPath = '/firstao/session';
  static readonly getSharesPath = '/firstao/shares';
  static readonly shareAOPath = '/firstao/shares';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Récupération des carnets d'adresses. Ne retourne pas les adresses contenues dans un carnet.
   * @return Les carnets d'adresses du client
   */
  getAddressBooksResponse(): __Observable<__StrictHttpResponse<Array<AddressBookAo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/addressbooks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddressBookAo>>;
      })
    );
  }
  /**
   * Récupération des carnets d'adresses. Ne retourne pas les adresses contenues dans un carnet.
   * @return Les carnets d'adresses du client
   */
  getAddressBooks(): __Observable<Array<AddressBookAo>> {
    return this.getAddressBooksResponse().pipe(
      __map(_r => _r.body as Array<AddressBookAo>)
    );
  }

  /**
   * Création d'un carnet d'adresse. Le libellé est obligatoire ainsi que le champ oneClick (carnet en favoris).. La description est optionnelle. Les autres paramètres ne sont pas à définir.
   * @param addressBook Un objet représentant un carnet d'adresse du client
   * @return Un carnet des adresses email du client
   */
  createAddressBooksResponse(addressBook: AddressBookAo): __Observable<__StrictHttpResponse<AddressBookAo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = addressBook;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/addressbooks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddressBookAo>;
      })
    );
  }
  /**
   * Création d'un carnet d'adresse. Le libellé est obligatoire ainsi que le champ oneClick (carnet en favoris).. La description est optionnelle. Les autres paramètres ne sont pas à définir.
   * @param addressBook Un objet représentant un carnet d'adresse du client
   * @return Un carnet des adresses email du client
   */
  createAddressBooks(addressBook: AddressBookAo): __Observable<AddressBookAo> {
    return this.createAddressBooksResponse(addressBook).pipe(
      __map(_r => _r.body as AddressBookAo)
    );
  }

  /**
   * Récupération des adresses d'un carnet d'addresses. Permet de rechercher un contact ou une adresse au sein du carnet.
   * @param params The `FirstAOCustomerServicesService.GetAddressBookParams` containing the following parameters:
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   *
   * - `searchAddress`: Recherche plein texte d'une adresse du carnet par son email
   *
   * - `oneClick`: N'afficher que les adresses disponibles en 1-Click
   *
   * @return Un carnet des adresses email du client
   */
  getAddressBookResponse(params: FirstAOCustomerServicesService.GetAddressBookParams): __Observable<__StrictHttpResponse<AddressBookAo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.searchAddress != null) __params = __params.set('searchAddress', params.searchAddress.toString());
    if (params.oneClick != null) __params = __params.set('oneClick', params.oneClick.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/addressbooks/${params.addressBookID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddressBookAo>;
      })
    );
  }
  /**
   * Récupération des adresses d'un carnet d'addresses. Permet de rechercher un contact ou une adresse au sein du carnet.
   * @param params The `FirstAOCustomerServicesService.GetAddressBookParams` containing the following parameters:
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   *
   * - `searchAddress`: Recherche plein texte d'une adresse du carnet par son email
   *
   * - `oneClick`: N'afficher que les adresses disponibles en 1-Click
   *
   * @return Un carnet des adresses email du client
   */
  getAddressBook(params: FirstAOCustomerServicesService.GetAddressBookParams): __Observable<AddressBookAo> {
    return this.getAddressBookResponse(params).pipe(
      __map(_r => _r.body as AddressBookAo)
    );
  }

  /**
   * Mise à jour d'un carnet d'adresses (libellé, oneClick, description)
   * @param params The `FirstAOCustomerServicesService.UpdateAddressBookParams` containing the following parameters:
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   *
   * - `addressBookAo`: Un objet représentant un carnet d'adresse du client
   *
   * @return Un carnet des adresses email du client
   */
  updateAddressBookResponse(params: FirstAOCustomerServicesService.UpdateAddressBookParams): __Observable<__StrictHttpResponse<AddressBookAo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.addressBookAo;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/addressbooks/${params.addressBookID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddressBookAo>;
      })
    );
  }
  /**
   * Mise à jour d'un carnet d'adresses (libellé, oneClick, description)
   * @param params The `FirstAOCustomerServicesService.UpdateAddressBookParams` containing the following parameters:
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   *
   * - `addressBookAo`: Un objet représentant un carnet d'adresse du client
   *
   * @return Un carnet des adresses email du client
   */
  updateAddressBook(params: FirstAOCustomerServicesService.UpdateAddressBookParams): __Observable<AddressBookAo> {
    return this.updateAddressBookResponse(params).pipe(
      __map(_r => _r.body as AddressBookAo)
    );
  }

  /**
   * Suppression d'un carnet d'adresses
   * @param addressBookID L'identifiant d'un carnet d'adresses d'un client
   */
  deleteAddressBookResponse(addressBookID: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/addressbooks/${addressBookID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Suppression d'un carnet d'adresses
   * @param addressBookID L'identifiant d'un carnet d'adresses d'un client
   */
  deleteAddressBook(addressBookID: number): __Observable<null> {
    return this.deleteAddressBookResponse(addressBookID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Ajouter un contact existant dans un carnet d'adresses
   * @param params The `FirstAOCustomerServicesService.AddContactAddressInAddressBookParams` containing the following parameters:
   *
   * - `addressID`: L'identifiant d'un contact
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   *
   * @return Les contacts du client
   */
  addContactAddressInAddressBookResponse(params: FirstAOCustomerServicesService.AddContactAddressInAddressBookParams): __Observable<__StrictHttpResponse<Array<AddressAo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/addressbooks/${params.addressBookID}/addresses/${params.addressID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddressAo>>;
      })
    );
  }
  /**
   * Ajouter un contact existant dans un carnet d'adresses
   * @param params The `FirstAOCustomerServicesService.AddContactAddressInAddressBookParams` containing the following parameters:
   *
   * - `addressID`: L'identifiant d'un contact
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   *
   * @return Les contacts du client
   */
  addContactAddressInAddressBook(params: FirstAOCustomerServicesService.AddContactAddressInAddressBookParams): __Observable<Array<AddressAo>> {
    return this.addContactAddressInAddressBookResponse(params).pipe(
      __map(_r => _r.body as Array<AddressAo>)
    );
  }

  /**
   * Suppression d'un contact d'un carnet d'adresses du client
   * @param params The `FirstAOCustomerServicesService.DeleteContactAddressFromAddressBookParams` containing the following parameters:
   *
   * - `addressID`: L'identifiant d'un contact
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   */
  deleteContactAddressFromAddressBookResponse(params: FirstAOCustomerServicesService.DeleteContactAddressFromAddressBookParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/addressbooks/${params.addressBookID}/addresses/${params.addressID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Suppression d'un contact d'un carnet d'adresses du client
   * @param params The `FirstAOCustomerServicesService.DeleteContactAddressFromAddressBookParams` containing the following parameters:
   *
   * - `addressID`: L'identifiant d'un contact
   *
   * - `addressBookID`: L'identifiant d'un carnet d'adresses d'un client
   */
  deleteContactAddressFromAddressBook(params: FirstAOCustomerServicesService.DeleteContactAddressFromAddressBookParams): __Observable<null> {
    return this.deleteContactAddressFromAddressBookResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Retourne la liste de tous les contacts.
   * @param params The `FirstAOCustomerServicesService.GetAddressesParams` containing the following parameters:
   *
   * - `searchAddress`: Recherche plein texte d'une adresse du carnet par son email
   *
   * - `oneClick`: N'afficher que les adresses disponibles en 1-Click
   *
   * @return Les contacts du client
   */
  getAddressesResponse(params: FirstAOCustomerServicesService.GetAddressesParams): __Observable<__StrictHttpResponse<Array<AddressAo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchAddress != null) __params = __params.set('searchAddress', params.searchAddress.toString());
    if (params.oneClick != null) __params = __params.set('oneClick', params.oneClick.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddressAo>>;
      })
    );
  }
  /**
   * Retourne la liste de tous les contacts.
   * @param params The `FirstAOCustomerServicesService.GetAddressesParams` containing the following parameters:
   *
   * - `searchAddress`: Recherche plein texte d'une adresse du carnet par son email
   *
   * - `oneClick`: N'afficher que les adresses disponibles en 1-Click
   *
   * @return Les contacts du client
   */
  getAddresses(params: FirstAOCustomerServicesService.GetAddressesParams): __Observable<Array<AddressAo>> {
    return this.getAddressesResponse(params).pipe(
      __map(_r => _r.body as Array<AddressAo>)
    );
  }

  /**
   * Création d'un contact. addressID est retournée après la création.
   * @param addressAo Un objet représentant un contact du client
   * @return Un contact
   */
  createAddressResponse(addressAo: AddressAo): __Observable<__StrictHttpResponse<AddressAo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = addressAo;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddressAo>;
      })
    );
  }
  /**
   * Création d'un contact. addressID est retournée après la création.
   * @param addressAo Un objet représentant un contact du client
   * @return Un contact
   */
  createAddress(addressAo: AddressAo): __Observable<AddressAo> {
    return this.createAddressResponse(addressAo).pipe(
      __map(_r => _r.body as AddressAo)
    );
  }

  /**
   * Mise à jour d'un contact.
   * @param params The `FirstAOCustomerServicesService.UpdateContactAddressParams` containing the following parameters:
   *
   * - `addressID`: L'identifiant d'un contact
   *
   * - `addressAo`: Un objet représentant un contact du client
   *
   * @return Un contact
   */
  updateContactAddressResponse(params: FirstAOCustomerServicesService.UpdateContactAddressParams): __Observable<__StrictHttpResponse<AddressAo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.addressAo;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/addresses/${params.addressID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddressAo>;
      })
    );
  }
  /**
   * Mise à jour d'un contact.
   * @param params The `FirstAOCustomerServicesService.UpdateContactAddressParams` containing the following parameters:
   *
   * - `addressID`: L'identifiant d'un contact
   *
   * - `addressAo`: Un objet représentant un contact du client
   *
   * @return Un contact
   */
  updateContactAddress(params: FirstAOCustomerServicesService.UpdateContactAddressParams): __Observable<AddressAo> {
    return this.updateContactAddressResponse(params).pipe(
      __map(_r => _r.body as AddressAo)
    );
  }

  /**
   * Supprime un contact. Il sera aussi supprimé de tous les carnets d'adresses dans lequel il est.
   * @param addressID L'identifiant d'un contact
   */
  deleteContactAddressResponse(addressID: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/addresses/${addressID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprime un contact. Il sera aussi supprimé de tous les carnets d'adresses dans lequel il est.
   * @param addressID L'identifiant d'un contact
   */
  deleteContactAddress(addressID: number): __Observable<null> {
    return this.deleteContactAddressResponse(addressID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Récupération des alertes
   * @return Les alertes du client
   */
  getAlertsResponse(): __Observable<__StrictHttpResponse<Alerts>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alerts>;
      })
    );
  }
  /**
   * Récupération des alertes
   * @return Les alertes du client
   */
  getAlerts(): __Observable<Alerts> {
    return this.getAlertsResponse().pipe(
      __map(_r => _r.body as Alerts)
    );
  }

  /**
   * Historique des alertes envoyées
   * @param params The `FirstAOCustomerServicesService.GetAlertsHistoryParams` containing the following parameters:
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return Historique des alertes envoyées
   */
  getAlertsHistoryResponse(params: FirstAOCustomerServicesService.GetAlertsHistoryParams): __Observable<__StrictHttpResponse<AlertsHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/alerts/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertsHistory>;
      })
    );
  }
  /**
   * Historique des alertes envoyées
   * @param params The `FirstAOCustomerServicesService.GetAlertsHistoryParams` containing the following parameters:
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return Historique des alertes envoyées
   */
  getAlertsHistory(params: FirstAOCustomerServicesService.GetAlertsHistoryParams): __Observable<AlertsHistory> {
    return this.getAlertsHistoryResponse(params).pipe(
      __map(_r => _r.body as AlertsHistory)
    );
  }

  /**
   * Consulter une alerte
   * @param alertID L'identifiant d'une alerte
   * @return Une alerte du client
   */
  getAlertResponse(alertID: number): __Observable<__StrictHttpResponse<AlertAO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/alerts/${alertID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertAO>;
      })
    );
  }
  /**
   * Consulter une alerte
   * @param alertID L'identifiant d'une alerte
   * @return Une alerte du client
   */
  getAlert(alertID: number): __Observable<AlertAO> {
    return this.getAlertResponse(alertID).pipe(
      __map(_r => _r.body as AlertAO)
    );
  }

  /**
   * Mise à jour d'une alerte. diffusion_period n'est à proposer que sur les alertes de type "my_notices" (Mes avis de marché). Les alertes sur les recherches ne peuvent être éditées que sur les destinataires. Les alertes de type "my_notices" et "favorites" sont envoyées uniquement à l'utilisateur.
   * @param params The `FirstAOCustomerServicesService.UpdateAlertParams` containing the following parameters:
   *
   * - `alertID`: L'identifiant d'une alerte
   *
   * - `alertAOBody`: Un objet représentant une alerte enregistrée du client
   *
   * @return Une alerte du client
   */
  updateAlertResponse(params: FirstAOCustomerServicesService.UpdateAlertParams): __Observable<__StrictHttpResponse<AlertAO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.alertAOBody;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/alerts/${params.alertID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertAO>;
      })
    );
  }
  /**
   * Mise à jour d'une alerte. diffusion_period n'est à proposer que sur les alertes de type "my_notices" (Mes avis de marché). Les alertes sur les recherches ne peuvent être éditées que sur les destinataires. Les alertes de type "my_notices" et "favorites" sont envoyées uniquement à l'utilisateur.
   * @param params The `FirstAOCustomerServicesService.UpdateAlertParams` containing the following parameters:
   *
   * - `alertID`: L'identifiant d'une alerte
   *
   * - `alertAOBody`: Un objet représentant une alerte enregistrée du client
   *
   * @return Une alerte du client
   */
  updateAlert(params: FirstAOCustomerServicesService.UpdateAlertParams): __Observable<AlertAO> {
    return this.updateAlertResponse(params).pipe(
      __map(_r => _r.body as AlertAO)
    );
  }

  /**
   * Envoie une demande de qualification d'un titulaire de marché
   * @param params The `FirstAOCustomerServicesService.PostContractorQualificationRequestParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `contractorID`: L'identifiant interne d'un titulaire de marché
   *
   * @return Demande de qualification d'un titulaire de marché
   */
  postContractorQualificationRequestResponse(params: FirstAOCustomerServicesService.PostContractorQualificationRequestParams): __Observable<__StrictHttpResponse<ContractorQualificationRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/contractor_qualification_request/add/${params.noticeID}/${params.contractorID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContractorQualificationRequest>;
      })
    );
  }
  /**
   * Envoie une demande de qualification d'un titulaire de marché
   * @param params The `FirstAOCustomerServicesService.PostContractorQualificationRequestParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `contractorID`: L'identifiant interne d'un titulaire de marché
   *
   * @return Demande de qualification d'un titulaire de marché
   */
  postContractorQualificationRequest(params: FirstAOCustomerServicesService.PostContractorQualificationRequestParams): __Observable<ContractorQualificationRequest> {
    return this.postContractorQualificationRequestResponse(params).pipe(
      __map(_r => _r.body as ContractorQualificationRequest)
    );
  }

  /**
   * Recherche des secteurs d’activités sur la nomenclature CPV pour suggestion dans la recherche. Le paramètre query (optionnel) permet de rechercher des secteurs par leur nom (minimum 3 caractères). Pour rechercher les codes CPV contenus dans les niveaux hiérarchiques inférieurs il faut utiliser le champ cpv. Pour avoir les codes CPV correspondant à un niveau hiérarchique précis, il faut définir le champ hierarchical_level (de 1 à 7).
   * @param params The `FirstAOCustomerServicesService.ShowSearchActivityAreaParams` containing the following parameters:
   *
   * - `query`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `hierarchical_level`: Le niveau hiérarchique de la nomenclature CPV
   *
   * - `cpvID`: Identifiant d'un code CPV
   *
   * - `cpv`: Liste des codes CPV associés aux secteurs d'activités, séparés par des virgules
   *
   * @return CPV suggérés
   */
  showSearchActivityAreaResponse(params: FirstAOCustomerServicesService.ShowSearchActivityAreaParams): __Observable<__StrictHttpResponse<Array<AoCpv>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.hierarchicalLevel != null) __params = __params.set('hierarchical_level', params.hierarchicalLevel.toString());
    if (params.cpvID != null) __params = __params.set('cpvID', params.cpvID.toString());
    if (params.cpv != null) __params = __params.set('cpv', params.cpv.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/activity_area`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AoCpv>>;
      })
    );
  }
  /**
   * Recherche des secteurs d’activités sur la nomenclature CPV pour suggestion dans la recherche. Le paramètre query (optionnel) permet de rechercher des secteurs par leur nom (minimum 3 caractères). Pour rechercher les codes CPV contenus dans les niveaux hiérarchiques inférieurs il faut utiliser le champ cpv. Pour avoir les codes CPV correspondant à un niveau hiérarchique précis, il faut définir le champ hierarchical_level (de 1 à 7).
   * @param params The `FirstAOCustomerServicesService.ShowSearchActivityAreaParams` containing the following parameters:
   *
   * - `query`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `hierarchical_level`: Le niveau hiérarchique de la nomenclature CPV
   *
   * - `cpvID`: Identifiant d'un code CPV
   *
   * - `cpv`: Liste des codes CPV associés aux secteurs d'activités, séparés par des virgules
   *
   * @return CPV suggérés
   */
  showSearchActivityArea(params: FirstAOCustomerServicesService.ShowSearchActivityAreaParams): __Observable<Array<AoCpv>> {
    return this.showSearchActivityAreaResponse(params).pipe(
      __map(_r => _r.body as Array<AoCpv>)
    );
  }

  /**
   * Recherche des commanditaires pour suggestion dans la recherche. Minimum 3 caractères.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Acheteurs suggérés
   */
  showSearchBuyerSuggestResponse(query?: string): __Observable<__StrictHttpResponse<BuyersSuggestion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/buyers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BuyersSuggestion>;
      })
    );
  }
  /**
   * Recherche des commanditaires pour suggestion dans la recherche. Minimum 3 caractères.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Acheteurs suggérés
   */
  showSearchBuyerSuggest(query?: string): __Observable<BuyersSuggestion> {
    return this.showSearchBuyerSuggestResponse(query).pipe(
      __map(_r => _r.body as BuyersSuggestion)
    );
  }

  /**
   * Recherche des villes pour suggestion dans la recherche. Minimum 3 caractères. Recherche par nom de commune et code postal.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Villes suggérées
   */
  showSearchCitiesSuggestResponse(query?: string): __Observable<__StrictHttpResponse<Array<CitySuggestion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/cities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CitySuggestion>>;
      })
    );
  }
  /**
   * Recherche des villes pour suggestion dans la recherche. Minimum 3 caractères. Recherche par nom de commune et code postal.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Villes suggérées
   */
  showSearchCitiesSuggest(query?: string): __Observable<Array<CitySuggestion>> {
    return this.showSearchCitiesSuggestResponse(query).pipe(
      __map(_r => _r.body as Array<CitySuggestion>)
    );
  }

  /**
   * Recherche des titulaires pour suggestion dans la recherche. Minimum 3 caractères.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Liste de titulaires
   */
  showSearchContractorSuggestResponse(query?: string): __Observable<__StrictHttpResponse<Array<ContractorCriterion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/contractors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ContractorCriterion>>;
      })
    );
  }
  /**
   * Recherche des titulaires pour suggestion dans la recherche. Minimum 3 caractères.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Liste de titulaires
   */
  showSearchContractorSuggest(query?: string): __Observable<Array<ContractorCriterion>> {
    return this.showSearchContractorSuggestResponse(query).pipe(
      __map(_r => _r.body as Array<ContractorCriterion>)
    );
  }

  /**
   * Recherche des mots clés pour suggestion dans la recherche. Le paramètre query permet de chercher des mots clés existants (minimum 3 caractères).Retourne 30 résultats maximum.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Mots clés suggérés
   */
  showSearchKeyWordsSuggestResponse(query?: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/keywords`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Recherche des mots clés pour suggestion dans la recherche. Le paramètre query permet de chercher des mots clés existants (minimum 3 caractères).Retourne 30 résultats maximum.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Mots clés suggérés
   */
  showSearchKeyWordsSuggest(query?: string): __Observable<Array<string>> {
    return this.showSearchKeyWordsSuggestResponse(query).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Recherche des mots clés ou secteurs d'activités pour suggestion dans la recherche. Le paramètre query nécessite un minimum 3 caractères. Retourne 30 résultats maximum.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Mots clés suggérés
   */
  showSearchKeyWordsActivityAreaSuggestResponse(query?: string): __Observable<__StrictHttpResponse<KeywordsActivityArea>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/keywords_activity_area`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KeywordsActivityArea>;
      })
    );
  }
  /**
   * Recherche des mots clés ou secteurs d'activités pour suggestion dans la recherche. Le paramètre query nécessite un minimum 3 caractères. Retourne 30 résultats maximum.
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Mots clés suggérés
   */
  showSearchKeyWordsActivityAreaSuggest(query?: string): __Observable<KeywordsActivityArea> {
    return this.showSearchKeyWordsActivityAreaSuggestResponse(query).pipe(
      __map(_r => _r.body as KeywordsActivityArea)
    );
  }

  /**
   * Retourne les catégories principales de classement des secteurs d'activité qui contiennent les CPV cherchés
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Catégories de classement des CPV simplifié
   */
  getSearchMainActivityAreaCategoriesFromCpvResponse(query?: string): __Observable<__StrictHttpResponse<Array<CpvCategorieWithoutDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/datasearch/main_activity_area_categories_from_cpv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CpvCategorieWithoutDetails>>;
      })
    );
  }
  /**
   * Retourne les catégories principales de classement des secteurs d'activité qui contiennent les CPV cherchés
   * @param query Requête plein texte - OR, AND et groupement par parenthèses supportées
   * @return Catégories de classement des CPV simplifié
   */
  getSearchMainActivityAreaCategoriesFromCpv(query?: string): __Observable<Array<CpvCategorieWithoutDetails>> {
    return this.getSearchMainActivityAreaCategoriesFromCpvResponse(query).pipe(
      __map(_r => _r.body as Array<CpvCategorieWithoutDetails>)
    );
  }

  /**
   * Envoi une demande de DCE à la production pour un avis de marché
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Demande de DCE d'un avis de marché
   */
  postDceRequestResponse(noticeID: number): __Observable<__StrictHttpResponse<DceRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/dce_request/${noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DceRequest>;
      })
    );
  }
  /**
   * Envoi une demande de DCE à la production pour un avis de marché
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Demande de DCE d'un avis de marché
   */
  postDceRequest(noticeID: number): __Observable<DceRequest> {
    return this.postDceRequestResponse(noticeID).pipe(
      __map(_r => _r.body as DceRequest)
    );
  }

  /**
   * Récupération des informations d'une entreprise.
   * @param entrepriseId L'identifiant d'une entreprise
   * @return La fiche d'une entreprise
   */
  getEntrepriseResponse(entrepriseId: number): __Observable<__StrictHttpResponse<EntrepriseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/entreprise/${entrepriseId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntrepriseEntity>;
      })
    );
  }
  /**
   * Récupération des informations d'une entreprise.
   * @param entrepriseId L'identifiant d'une entreprise
   * @return La fiche d'une entreprise
   */
  getEntreprise(entrepriseId: number): __Observable<EntrepriseEntity> {
    return this.getEntrepriseResponse(entrepriseId).pipe(
      __map(_r => _r.body as EntrepriseEntity)
    );
  }

  /**
   * Récupération des avis de marché en favoris d'un dossier du client
   * @param params The `FirstAOCustomerServicesService.GetFavoritesFromFolderParams` containing the following parameters:
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `aoSortingMethod`: La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
   *
   * - `aoGroupByMethod`: La méthode de regroupement des annonces:      * `deadline` - Par date limite de réponse (groupement sur une période d'un mois)      * `buyer` - Par acheteur (classé par ordre alphabétique croissant)      * `localisation` - Par secteur géographique (classé par ordre alphabétique croissant, région puis département)      * `activityArea` - Par secteur (classé par ordre alphabétique croissant)      * `informationStatus ` - Par statut de mise en favoris (classé par ordre alphabétique croissant)
   *
   * @return Liste des favoris dans un dossier de classement
   */
  getFavoritesFromFolderResponse(params: FirstAOCustomerServicesService.GetFavoritesFromFolderParams): __Observable<__StrictHttpResponse<FavorisList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    if (params.aoSortingMethod != null) __params = __params.set('aoSortingMethod', params.aoSortingMethod.toString());
    if (params.aoGroupByMethod != null) __params = __params.set('aoGroupByMethod', params.aoGroupByMethod.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/favorites/folder/${params.folderID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FavorisList>;
      })
    );
  }
  /**
   * Récupération des avis de marché en favoris d'un dossier du client
   * @param params The `FirstAOCustomerServicesService.GetFavoritesFromFolderParams` containing the following parameters:
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `aoSortingMethod`: La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
   *
   * - `aoGroupByMethod`: La méthode de regroupement des annonces:      * `deadline` - Par date limite de réponse (groupement sur une période d'un mois)      * `buyer` - Par acheteur (classé par ordre alphabétique croissant)      * `localisation` - Par secteur géographique (classé par ordre alphabétique croissant, région puis département)      * `activityArea` - Par secteur (classé par ordre alphabétique croissant)      * `informationStatus ` - Par statut de mise en favoris (classé par ordre alphabétique croissant)
   *
   * @return Liste des favoris dans un dossier de classement
   */
  getFavoritesFromFolder(params: FirstAOCustomerServicesService.GetFavoritesFromFolderParams): __Observable<FavorisList> {
    return this.getFavoritesFromFolderResponse(params).pipe(
      __map(_r => _r.body as FavorisList)
    );
  }

  /**
   * Modification d'un dossier de classement de favoris.
   * @param params The `FirstAOCustomerServicesService.EditFavoriteFolderParams` containing the following parameters:
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * - `favorisFolder`: Un objet représentant un dossier de classement de favoris
   *
   * @return Un dossier de classement des favoris
   */
  editFavoriteFolderResponse(params: FirstAOCustomerServicesService.EditFavoriteFolderParams): __Observable<__StrictHttpResponse<FavorisFolder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.favorisFolder;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/favorites/folder/${params.folderID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FavorisFolder>;
      })
    );
  }
  /**
   * Modification d'un dossier de classement de favoris.
   * @param params The `FirstAOCustomerServicesService.EditFavoriteFolderParams` containing the following parameters:
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * - `favorisFolder`: Un objet représentant un dossier de classement de favoris
   *
   * @return Un dossier de classement des favoris
   */
  editFavoriteFolder(params: FirstAOCustomerServicesService.EditFavoriteFolderParams): __Observable<FavorisFolder> {
    return this.editFavoriteFolderResponse(params).pipe(
      __map(_r => _r.body as FavorisFolder)
    );
  }

  /**
   * Suppression d'un dossier de classement de favoris
   * @param folderID L'identifiant du dossier du client
   */
  deleteFavoriteFolderResponse(folderID: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/favorites/folder/${folderID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Suppression d'un dossier de classement de favoris
   * @param folderID L'identifiant du dossier du client
   */
  deleteFavoriteFolder(folderID: number): __Observable<null> {
    return this.deleteFavoriteFolderResponse(folderID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Ajouter un avis de marché dans les favoris dans un dossier de classement.
   * @param params The `FirstAOCustomerServicesService.AddAoToFavoritesInFolderParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * @return Un avis de marché en favoris
   */
  addAoToFavoritesInFolderResponse(params: FirstAOCustomerServicesService.AddAoToFavoritesInFolderParams): __Observable<__StrictHttpResponse<Favoris>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/favorites/folder/${params.folderID}/notice/${params.noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Favoris>;
      })
    );
  }
  /**
   * Ajouter un avis de marché dans les favoris dans un dossier de classement.
   * @param params The `FirstAOCustomerServicesService.AddAoToFavoritesInFolderParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * @return Un avis de marché en favoris
   */
  addAoToFavoritesInFolder(params: FirstAOCustomerServicesService.AddAoToFavoritesInFolderParams): __Observable<Favoris> {
    return this.addAoToFavoritesInFolderResponse(params).pipe(
      __map(_r => _r.body as Favoris)
    );
  }

  /**
   * Déplacer un avis de marché aux favoris dans un autre dossier de classement. Le nouveau dossier de classement est à indiquer dans favorisFolderID.
   * @param params The `FirstAOCustomerServicesService.MoveAoInFavoritesToOtherFolderParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * - `favorisFolderID`: L'identifiant du dossier de classement des favoris du client
   *
   * @return Un avis de marché en favoris
   */
  moveAoInFavoritesToOtherFolderResponse(params: FirstAOCustomerServicesService.MoveAoInFavoritesToOtherFolderParams): __Observable<__StrictHttpResponse<Favoris>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.favorisFolderID != null) __params = __params.set('favorisFolderID', params.favorisFolderID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/favorites/folder/${params.folderID}/notice/${params.noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Favoris>;
      })
    );
  }
  /**
   * Déplacer un avis de marché aux favoris dans un autre dossier de classement. Le nouveau dossier de classement est à indiquer dans favorisFolderID.
   * @param params The `FirstAOCustomerServicesService.MoveAoInFavoritesToOtherFolderParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `folderID`: L'identifiant du dossier du client
   *
   * - `favorisFolderID`: L'identifiant du dossier de classement des favoris du client
   *
   * @return Un avis de marché en favoris
   */
  moveAoInFavoritesToOtherFolder(params: FirstAOCustomerServicesService.MoveAoInFavoritesToOtherFolderParams): __Observable<Favoris> {
    return this.moveAoInFavoritesToOtherFolderResponse(params).pipe(
      __map(_r => _r.body as Favoris)
    );
  }

  /**
   * Liste des dossiers de classement des favoris
   * @return Liste des dossiers de classement des favoris
   */
  getFavoritesFolderResponse(): __Observable<__StrictHttpResponse<Array<FavorisFolder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/favorites/folders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FavorisFolder>>;
      })
    );
  }
  /**
   * Liste des dossiers de classement des favoris
   * @return Liste des dossiers de classement des favoris
   */
  getFavoritesFolder(): __Observable<Array<FavorisFolder>> {
    return this.getFavoritesFolderResponse().pipe(
      __map(_r => _r.body as Array<FavorisFolder>)
    );
  }

  /**
   * Créer un dossier de classement des favoris. folderID est retourné à la création. folderSortOrder est calculé automatiquement à la création, ne pas remplir également.
   * @param favorisFolder Un objet représentant un dossier de classement de favoris
   * @return Un dossier de classement des favoris
   */
  addFavoritesFolderResponse(favorisFolder: FavorisFolder): __Observable<__StrictHttpResponse<FavorisFolder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = favorisFolder;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/favorites/folders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FavorisFolder>;
      })
    );
  }
  /**
   * Créer un dossier de classement des favoris. folderID est retourné à la création. folderSortOrder est calculé automatiquement à la création, ne pas remplir également.
   * @param favorisFolder Un objet représentant un dossier de classement de favoris
   * @return Un dossier de classement des favoris
   */
  addFavoritesFolder(favorisFolder: FavorisFolder): __Observable<FavorisFolder> {
    return this.addFavoritesFolderResponse(favorisFolder).pipe(
      __map(_r => _r.body as FavorisFolder)
    );
  }

  /**
   * Mise à jour des données d'un avis de marché en favoris.  Modifiable = informationStatus, comment.
   * @param params The `FirstAOCustomerServicesService.EditAoDataFromFavorisParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `favorisAoMetadata`: Un objet représentant les métadonnées d'un avis de marché en favoris
   *
   * @return Métadonnées d'un avis de marché en favoris
   */
  editAoDataFromFavorisResponse(params: FirstAOCustomerServicesService.EditAoDataFromFavorisParams): __Observable<__StrictHttpResponse<FavorisAoMetadata>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.favorisAoMetadata;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/favorites/notice/${params.noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FavorisAoMetadata>;
      })
    );
  }
  /**
   * Mise à jour des données d'un avis de marché en favoris.  Modifiable = informationStatus, comment.
   * @param params The `FirstAOCustomerServicesService.EditAoDataFromFavorisParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `favorisAoMetadata`: Un objet représentant les métadonnées d'un avis de marché en favoris
   *
   * @return Métadonnées d'un avis de marché en favoris
   */
  editAoDataFromFavoris(params: FirstAOCustomerServicesService.EditAoDataFromFavorisParams): __Observable<FavorisAoMetadata> {
    return this.editAoDataFromFavorisResponse(params).pipe(
      __map(_r => _r.body as FavorisAoMetadata)
    );
  }

  /**
   * Suppression d'un avis de marché de tous les dossier de classement.
   * @param noticeID L'identifiant interne d'un avis de marché
   */
  deleteAoFromFavoritesResponse(noticeID: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/favorites/notice/${noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Suppression d'un avis de marché de tous les dossier de classement.
   * @param noticeID L'identifiant interne d'un avis de marché
   */
  deleteAoFromFavorites(noticeID: number): __Observable<null> {
    return this.deleteAoFromFavoritesResponse(noticeID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Récupère tous les critères pour pouvoir créer un filtre (nom des catégories et valeurs)
   * @return Les critères disponibles
   */
  showFilterscriterionsResponse(): __Observable<__StrictHttpResponse<AllCriterions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/filter/criterions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllCriterions>;
      })
    );
  }
  /**
   * Récupère tous les critères pour pouvoir créer un filtre (nom des catégories et valeurs)
   * @return Les critères disponibles
   */
  showFilterscriterions(): __Observable<AllCriterions> {
    return this.showFilterscriterionsResponse().pipe(
      __map(_r => _r.body as AllCriterions)
    );
  }

  /**
   * Mise à jour du filtre de recherche principal du profil utilisateur. filterID n'est pas à définir. Il est retourné dans la réponse.
   * @param params The `FirstAOCustomerServicesService.EditMainFilterParams` containing the following parameters:
   *
   * - `reset`: reset
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * @return Critères de recherche
   */
  editMainFilterResponse(params: FirstAOCustomerServicesService.EditMainFilterParams): __Observable<__StrictHttpResponse<QueryCriterions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.reset != null) __params = __params.set('reset', params.reset.toString());
    __body = params.queryCriterions;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/filter/main`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryCriterions>;
      })
    );
  }
  /**
   * Mise à jour du filtre de recherche principal du profil utilisateur. filterID n'est pas à définir. Il est retourné dans la réponse.
   * @param params The `FirstAOCustomerServicesService.EditMainFilterParams` containing the following parameters:
   *
   * - `reset`: reset
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * @return Critères de recherche
   */
  editMainFilter(params: FirstAOCustomerServicesService.EditMainFilterParams): __Observable<QueryCriterions> {
    return this.editMainFilterResponse(params).pipe(
      __map(_r => _r.body as QueryCriterions)
    );
  }

  /**
   * Enregistrement d'événéments utilisateurs sur l'UI en logging. L'API log par défaut tous les appels fait dessus mais certaines interactions utilisateurs sur l'UI peuvent ne pas faire une requête sur l'API mais être le résultat d'une requête (par exemple la navigation dans les différentes pages de "Mon compte").
   * @param requestBody Un objet représentant plusieurs logs de l'UI
   */
  postLogsUiResponse(requestBody: Array<{}>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = requestBody;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/logs/ui`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enregistrement d'événéments utilisateurs sur l'UI en logging. L'API log par défaut tous les appels fait dessus mais certaines interactions utilisateurs sur l'UI peuvent ne pas faire une requête sur l'API mais être le résultat d'une requête (par exemple la navigation dans les différentes pages de "Mon compte").
   * @param requestBody Un objet représentant plusieurs logs de l'UI
   */
  postLogsUi(requestBody: Array<{}>): __Observable<null> {
    return this.postLogsUiResponse(requestBody).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Récupère la nomenclature CPV des secteurs d'activité pour affichage sous forme d'arbre. Retourne pour un CPV parent ses enfants du niveau hiérarchique inférieur direct.
   * @param cpv Code CPV parent de la catégorie
   * @return CPV suggérés
   */
  getNomenclatureCPVResponse(cpv?: string): __Observable<__StrictHttpResponse<Array<AoCpv>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (cpv != null) __params = __params.set('cpv', cpv.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/nomenclature/activity_area`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AoCpv>>;
      })
    );
  }
  /**
   * Récupère la nomenclature CPV des secteurs d'activité pour affichage sous forme d'arbre. Retourne pour un CPV parent ses enfants du niveau hiérarchique inférieur direct.
   * @param cpv Code CPV parent de la catégorie
   * @return CPV suggérés
   */
  getNomenclatureCPV(cpv?: string): __Observable<Array<AoCpv>> {
    return this.getNomenclatureCPVResponse(cpv).pipe(
      __map(_r => _r.body as Array<AoCpv>)
    );
  }

  /**
   * Retourne les informations de secteurs d'activités (CPV) envoyés en paramètre.
   * @param cpv Liste des codes CPV associés aux secteurs d'activités, séparés par des virgules
   * @return CPV suggérés
   */
  showActivityAreasDataResponse(cpv?: string): __Observable<__StrictHttpResponse<Array<AoCpv>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (cpv != null) __params = __params.set('cpv', cpv.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/nomenclature/activity_areas_data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AoCpv>>;
      })
    );
  }
  /**
   * Retourne les informations de secteurs d'activités (CPV) envoyés en paramètre.
   * @param cpv Liste des codes CPV associés aux secteurs d'activités, séparés par des virgules
   * @return CPV suggérés
   */
  showActivityAreasData(cpv?: string): __Observable<Array<AoCpv>> {
    return this.showActivityAreasDataResponse(cpv).pipe(
      __map(_r => _r.body as Array<AoCpv>)
    );
  }

  /**
   * Récupère la nomenclature CPV des secteurs d'activité sous forme d'arbre.
   * @return Arbre CPV
   */
  getCPVTreeResponse(): __Observable<__StrictHttpResponse<MainCategoriesTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/nomenclature/cpvTree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MainCategoriesTree>;
      })
    );
  }
  /**
   * Récupère la nomenclature CPV des secteurs d'activité sous forme d'arbre.
   * @return Arbre CPV
   */
  getCPVTree(): __Observable<MainCategoriesTree> {
    return this.getCPVTreeResponse().pipe(
      __map(_r => _r.body as MainCategoriesTree)
    );
  }

  /**
   * Récupère la nomenclature des lieux (régions ou départements). Si pays vide, par défaut "FR". Pour avoir les départements d'une région, localisationType "departement" et "localisationParentRegion" avec le code Zip de la région. Pour avoir les régions, localisationType "region". query permet de rechercher un lieu par son nom.
   * @param params The `FirstAOCustomerServicesService.GetNomenclatureLocalisationsParams` containing the following parameters:
   *
   * - `zip`: Code ZIP d'un lieu
   *
   * - `query`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `pays`: Code du Pays
   *
   * - `localisationType`: Type d'un lieu (region/departement)
   *
   * - `localisationParentRegion`: Le code ZIP d'une région
   *
   * @return Liste de lieux
   */
  getNomenclatureLocalisationsResponse(params: FirstAOCustomerServicesService.GetNomenclatureLocalisationsParams): __Observable<__StrictHttpResponse<Array<Localisations>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zip != null) __params = __params.set('zip', params.zip.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.pays != null) __params = __params.set('pays', params.pays.toString());
    if (params.localisationType != null) __params = __params.set('localisationType', params.localisationType.toString());
    if (params.localisationParentRegion != null) __params = __params.set('localisationParentRegion', params.localisationParentRegion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/nomenclature/localisations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Localisations>>;
      })
    );
  }
  /**
   * Récupère la nomenclature des lieux (régions ou départements). Si pays vide, par défaut "FR". Pour avoir les départements d'une région, localisationType "departement" et "localisationParentRegion" avec le code Zip de la région. Pour avoir les régions, localisationType "region". query permet de rechercher un lieu par son nom.
   * @param params The `FirstAOCustomerServicesService.GetNomenclatureLocalisationsParams` containing the following parameters:
   *
   * - `zip`: Code ZIP d'un lieu
   *
   * - `query`: Requête plein texte - OR, AND et groupement par parenthèses supportées
   *
   * - `pays`: Code du Pays
   *
   * - `localisationType`: Type d'un lieu (region/departement)
   *
   * - `localisationParentRegion`: Le code ZIP d'une région
   *
   * @return Liste de lieux
   */
  getNomenclatureLocalisations(params: FirstAOCustomerServicesService.GetNomenclatureLocalisationsParams): __Observable<Array<Localisations>> {
    return this.getNomenclatureLocalisationsResponse(params).pipe(
      __map(_r => _r.body as Array<Localisations>)
    );
  }

  /**
   * Récupère la nomenclature des lieux (régions ou départements) sous forme d'arbre.
   * @return Liste de lieux sous forme d'arbre
   */
  getNomenclatureLocalisationsTreeResponse(): __Observable<__StrictHttpResponse<Array<LocalisationTree>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/nomenclature/localisations_tree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocalisationTree>>;
      })
    );
  }
  /**
   * Récupère la nomenclature des lieux (régions ou départements) sous forme d'arbre.
   * @return Liste de lieux sous forme d'arbre
   */
  getNomenclatureLocalisationsTree(): __Observable<Array<LocalisationTree>> {
    return this.getNomenclatureLocalisationsTreeResponse().pipe(
      __map(_r => _r.body as Array<LocalisationTree>)
    );
  }

  /**
   * Retourne les catégories principales de classement des secteurs d'activité (CPV) pour le wizard de recherche et sa première page. Chaque catégorie contient une liste de codes CPV principaux de niveau hiérarchique 1 qui seront utilisés dans la deuxième phase de sélection des secteurs d'activités.
   * @return Catégories de classement des CPV
   */
  showMainSearchActivityAreaCategoriesResponse(): __Observable<__StrictHttpResponse<Array<CpvCategorie>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/nomenclature/main_activity_area_categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CpvCategorie>>;
      })
    );
  }
  /**
   * Retourne les catégories principales de classement des secteurs d'activité (CPV) pour le wizard de recherche et sa première page. Chaque catégorie contient une liste de codes CPV principaux de niveau hiérarchique 1 qui seront utilisés dans la deuxième phase de sélection des secteurs d'activités.
   * @return Catégories de classement des CPV
   */
  showMainSearchActivityAreaCategories(): __Observable<Array<CpvCategorie>> {
    return this.showMainSearchActivityAreaCategoriesResponse().pipe(
      __map(_r => _r.body as Array<CpvCategorie>)
    );
  }

  /**
   * Rechercher des appels d’offres suivant des critères définis dans un filtre de recherche. Ne pas mettre le label pour les critères, uniquement le type et les valeurs. Si aucun critère n'est envoyé, la recherche retourne les avis de marché correspondant au profil client si il est défini. Pour avoir les avis de marché d'un dossier de favoris, il faut utiliser le paramètre favorisFolderID. Pour obtenir les résultats d'une recherche préalablement enregistrée, il faut utiliser le paramètre searchID. Pour appliquer un filtre enregistré (pour l'historique des recherches), il faut utiliser le paramètre filterID.    Lors d'une recherche de type "Mes avis de marché", il faut également mettre à true le paramètre myProfileSearch.    L’envoi des critères de filtrage se fait dans une chaîne dans le champ “searchCriterionAOs”. {"searchCriterionAOs":{"type":["test1","test2"],"type2":["test1","test2"]}}    Exemple :   {"searchCriterionAOs":{"localisation":["32, “88"],"activityArea":["03000000"]}}    La liste des critères de filtrage possibles est dans /firstao/filter/criterions.
   * @param params The `FirstAOCustomerServicesService.SearchNoticesParams` containing the following parameters:
   *
   * - `searchType`: Précise une recherche sur les appels d'offres ou les avis d'attribution
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `sdeadlinedate`: La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `queryCriterions`: Critères de filtrage
   *
   * - `publicationSince`: La période de la date de publication
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `myProfileSearch`: Indique s'il s'agit d'une recherche de type Mes avis de marché
   *
   * - `filterID`: L'identifiant d'un filtre de recherche enregistrée du client
   *
   * - `favorisFolderID`: L'identifiant du dossier de classement des favoris du client
   *
   * - `edeadlinedate`: La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `deadlineIn`: La période de la date limite de réponse
   *
   * - `aoSortingMethod`: La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
   *
   * @return La liste des avis de marché
   */
  searchNoticesResponse(params: FirstAOCustomerServicesService.SearchNoticesParams): __Observable<__StrictHttpResponse<Notices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchType != null) __params = __params.set('searchType', params.searchType.toString());
    if (params.searchID != null) __params = __params.set('searchID', params.searchID.toString());
    if (params.sdeadlinedate != null) __params = __params.set('sdeadlinedate', params.sdeadlinedate.toString());
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.queryCriterions != null) __params = __params.set('queryCriterions', params.queryCriterions.toString());
    if (params.publicationSince != null) __params = __params.set('publicationSince', params.publicationSince.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.myProfileSearch != null) __params = __params.set('myProfileSearch', params.myProfileSearch.toString());
    if (params.filterID != null) __params = __params.set('filterID', params.filterID.toString());
    if (params.favorisFolderID != null) __params = __params.set('favorisFolderID', params.favorisFolderID.toString());
    if (params.edeadlinedate != null) __params = __params.set('edeadlinedate', params.edeadlinedate.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    if (params.deadlineIn != null) __params = __params.set('deadlineIn', params.deadlineIn.toString());
    if (params.aoSortingMethod != null) __params = __params.set('aoSortingMethod', params.aoSortingMethod.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/notices/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notices>;
      })
    );
  }
  /**
   * Rechercher des appels d’offres suivant des critères définis dans un filtre de recherche. Ne pas mettre le label pour les critères, uniquement le type et les valeurs. Si aucun critère n'est envoyé, la recherche retourne les avis de marché correspondant au profil client si il est défini. Pour avoir les avis de marché d'un dossier de favoris, il faut utiliser le paramètre favorisFolderID. Pour obtenir les résultats d'une recherche préalablement enregistrée, il faut utiliser le paramètre searchID. Pour appliquer un filtre enregistré (pour l'historique des recherches), il faut utiliser le paramètre filterID.    Lors d'une recherche de type "Mes avis de marché", il faut également mettre à true le paramètre myProfileSearch.    L’envoi des critères de filtrage se fait dans une chaîne dans le champ “searchCriterionAOs”. {"searchCriterionAOs":{"type":["test1","test2"],"type2":["test1","test2"]}}    Exemple :   {"searchCriterionAOs":{"localisation":["32, “88"],"activityArea":["03000000"]}}    La liste des critères de filtrage possibles est dans /firstao/filter/criterions.
   * @param params The `FirstAOCustomerServicesService.SearchNoticesParams` containing the following parameters:
   *
   * - `searchType`: Précise une recherche sur les appels d'offres ou les avis d'attribution
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `sdeadlinedate`: La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `queryCriterions`: Critères de filtrage
   *
   * - `publicationSince`: La période de la date de publication
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `myProfileSearch`: Indique s'il s'agit d'une recherche de type Mes avis de marché
   *
   * - `filterID`: L'identifiant d'un filtre de recherche enregistrée du client
   *
   * - `favorisFolderID`: L'identifiant du dossier de classement des favoris du client
   *
   * - `edeadlinedate`: La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `deadlineIn`: La période de la date limite de réponse
   *
   * - `aoSortingMethod`: La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
   *
   * @return La liste des avis de marché
   */
  searchNotices(params: FirstAOCustomerServicesService.SearchNoticesParams): __Observable<Notices> {
    return this.searchNoticesResponse(params).pipe(
      __map(_r => _r.body as Notices)
    );
  }

  /**
   * Rechercher des appels d’offres suivant des critères définis dans un filtre de recherche. Ne pas mettre le label pour les critères, uniquement le type et les valeurs. Si aucun critère n'est envoyé, la recherche retourne les avis de marché correspondant au profil client si il est défini. Indique myProfileSearch=true également dans ce cas pour avoir des critères d'affinage corrects. Pour avoir les avis de marché d'un dossier de favoris, il faut utiliser le paramètre favorisFolderID. Pour obtenir les résultats d'une recherche préalablement enregistrée, il faut utiliser le paramètre searchID. Pour appliquer un filtre enregistré (pour l'historique des recherches), il faut utiliser le paramètre filterID.    Lors d'une recherche de type "Mes avis de marché", il faut également mettre à true le paramètre myProfileSearch.
   * @param params The `FirstAOCustomerServicesService.SearchNoticesPostParams` containing the following parameters:
   *
   * - `searchType`: Précise une recherche sur les appels d'offres ou les avis d'attribution
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `sdeadlinedate`: La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * - `publicationSince`: La période de la date de publication
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `myProfileSearch`: Indique s'il s'agit d'une recherche de type Mes avis de marché
   *
   * - `filterID`: L'identifiant d'un filtre de recherche enregistrée du client
   *
   * - `favorisFolderID`: L'identifiant du dossier de classement des favoris du client
   *
   * - `edeadlinedate`: La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `deadlineIn`: La période de la date limite de réponse
   *
   * - `aoSortingMethod`: La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
   *
   * @return La liste des avis de marché
   */
  searchNoticesPostResponse(params: FirstAOCustomerServicesService.SearchNoticesPostParams): __Observable<__StrictHttpResponse<Notices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchType != null) __params = __params.set('searchType', params.searchType.toString());
    if (params.searchID != null) __params = __params.set('searchID', params.searchID.toString());
    if (params.sdeadlinedate != null) __params = __params.set('sdeadlinedate', params.sdeadlinedate.toString());
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    __body = params.queryCriterions;
    if (params.publicationSince != null) __params = __params.set('publicationSince', params.publicationSince.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.myProfileSearch != null) __params = __params.set('myProfileSearch', params.myProfileSearch.toString());
    if (params.filterID != null) __params = __params.set('filterID', params.filterID.toString());
    if (params.favorisFolderID != null) __params = __params.set('favorisFolderID', params.favorisFolderID.toString());
    if (params.edeadlinedate != null) __params = __params.set('edeadlinedate', params.edeadlinedate.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    if (params.deadlineIn != null) __params = __params.set('deadlineIn', params.deadlineIn.toString());
    if (params.aoSortingMethod != null) __params = __params.set('aoSortingMethod', params.aoSortingMethod.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/notices/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notices>;
      })
    );
  }
  /**
   * Rechercher des appels d’offres suivant des critères définis dans un filtre de recherche. Ne pas mettre le label pour les critères, uniquement le type et les valeurs. Si aucun critère n'est envoyé, la recherche retourne les avis de marché correspondant au profil client si il est défini. Indique myProfileSearch=true également dans ce cas pour avoir des critères d'affinage corrects. Pour avoir les avis de marché d'un dossier de favoris, il faut utiliser le paramètre favorisFolderID. Pour obtenir les résultats d'une recherche préalablement enregistrée, il faut utiliser le paramètre searchID. Pour appliquer un filtre enregistré (pour l'historique des recherches), il faut utiliser le paramètre filterID.    Lors d'une recherche de type "Mes avis de marché", il faut également mettre à true le paramètre myProfileSearch.
   * @param params The `FirstAOCustomerServicesService.SearchNoticesPostParams` containing the following parameters:
   *
   * - `searchType`: Précise une recherche sur les appels d'offres ou les avis d'attribution
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `sdeadlinedate`: La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * - `publicationSince`: La période de la date de publication
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `myProfileSearch`: Indique s'il s'agit d'une recherche de type Mes avis de marché
   *
   * - `filterID`: L'identifiant d'un filtre de recherche enregistrée du client
   *
   * - `favorisFolderID`: L'identifiant du dossier de classement des favoris du client
   *
   * - `edeadlinedate`: La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * - `deadlineIn`: La période de la date limite de réponse
   *
   * - `aoSortingMethod`: La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
   *
   * @return La liste des avis de marché
   */
  searchNoticesPost(params: FirstAOCustomerServicesService.SearchNoticesPostParams): __Observable<Notices> {
    return this.searchNoticesPostResponse(params).pipe(
      __map(_r => _r.body as Notices)
    );
  }

  /**
   * Consulter les détails d'un appel d'offre
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Les données d'un avis de marché
   */
  showAoResponse(noticeID: number): __Observable<__StrictHttpResponse<NoticeFacade>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/notices/${noticeID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NoticeFacade>;
      })
    );
  }
  /**
   * Consulter les détails d'un appel d'offre
   * @param noticeID L'identifiant interne d'un avis de marché
   * @return Les données d'un avis de marché
   */
  showAo(noticeID: number): __Observable<NoticeFacade> {
    return this.showAoResponse(noticeID).pipe(
      __map(_r => _r.body as NoticeFacade)
    );
  }

  /**
   * Récupérer la pièce jointe d'un appel d'offre.
   * @param params The `FirstAOCustomerServicesService.DownloadNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `hashAttachmentID`: Le hash d'un fichier d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   *
   * @return OK
   */
  downloadNoticeAttachmentResponse(params: FirstAOCustomerServicesService.DownloadNoticeAttachmentParams): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/notices/${params.noticeID}/attachments/${params.hashAttachmentID}/${params.attachmentID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * Récupérer la pièce jointe d'un appel d'offre.
   * @param params The `FirstAOCustomerServicesService.DownloadNoticeAttachmentParams` containing the following parameters:
   *
   * - `noticeID`: L'identifiant interne d'un avis de marché
   *
   * - `hashAttachmentID`: Le hash d'un fichier d'un avis de marché
   *
   * - `attachmentID`: L'identifiant interne d'un fichier d'un avis de marché
   *
   * @return OK
   */
  downloadNoticeAttachment(params: FirstAOCustomerServicesService.DownloadNoticeAttachmentParams): __Observable<Resource> {
    return this.downloadNoticeAttachmentResponse(params).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * Consulter les recherches enregistrées.
   * @return Les recherches du client
   */
  showSearchesResponse(): __Observable<__StrictHttpResponse<Searches>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/searches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Searches>;
      })
    );
  }
  /**
   * Consulter les recherches enregistrées.
   * @return Les recherches du client
   */
  showSearches(): __Observable<Searches> {
    return this.showSearchesResponse().pipe(
      __map(_r => _r.body as Searches)
    );
  }

  /**
   * Enregistrer une recherche d'appels d’offre. * On enregistre pour une recherche son libellé et le contenu du filtre de la recherche qui vient d'être effectuée. * Ne pas indiquer searchID ni filterID puisqu'il sera généré et retourné à la fin de l'enregistrement de la recherche. * creation_date, last_usage, alerts ne sont pas à définir ici. Une alerte s'active sur une recherche enregistrée, c'est une action supplémentaire.
   * @param searchAO Un objet représentant une recherche enregistrée du client
   * @return Une recherche du client
   */
  saveSearchResponse(searchAO: SearchAO): __Observable<__StrictHttpResponse<SearchAO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchAO;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/searches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchAO>;
      })
    );
  }
  /**
   * Enregistrer une recherche d'appels d’offre. * On enregistre pour une recherche son libellé et le contenu du filtre de la recherche qui vient d'être effectuée. * Ne pas indiquer searchID ni filterID puisqu'il sera généré et retourné à la fin de l'enregistrement de la recherche. * creation_date, last_usage, alerts ne sont pas à définir ici. Une alerte s'active sur une recherche enregistrée, c'est une action supplémentaire.
   * @param searchAO Un objet représentant une recherche enregistrée du client
   * @return Une recherche du client
   */
  saveSearch(searchAO: SearchAO): __Observable<SearchAO> {
    return this.saveSearchResponse(searchAO).pipe(
      __map(_r => _r.body as SearchAO)
    );
  }

  /**
   * Consulter l’historique des recherches effectuées
   * @return L'historique des recherches du client
   */
  showSearchesHistoryResponse(): __Observable<__StrictHttpResponse<SearchesHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/searches/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchesHistory>;
      })
    );
  }
  /**
   * Consulter l’historique des recherches effectuées
   * @return L'historique des recherches du client
   */
  showSearchesHistory(): __Observable<SearchesHistory> {
    return this.showSearchesHistoryResponse().pipe(
      __map(_r => _r.body as SearchesHistory)
    );
  }

  /**
   * Récupère les informations d’une recherche enregistrée.
   * @param searchID L'identifiant de la recherche enregistrée du client
   * @return Une recherche du client
   */
  getSearchDetailsResponse(searchID: number): __Observable<__StrictHttpResponse<SearchAO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/searches/${searchID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchAO>;
      })
    );
  }
  /**
   * Récupère les informations d’une recherche enregistrée.
   * @param searchID L'identifiant de la recherche enregistrée du client
   * @return Une recherche du client
   */
  getSearchDetails(searchID: number): __Observable<SearchAO> {
    return this.getSearchDetailsResponse(searchID).pipe(
      __map(_r => _r.body as SearchAO)
    );
  }

  /**
   * Éditer les informations d'une recherche enregistrée. * Editable : libelle, alertState, isFavorite. Si il y a des modifications sur le filtre de la recherche, il faut faire appel à /firstao/search/{searchID}/filter lors de l'édition du filtre.
   * @param params The `FirstAOCustomerServicesService.EditSearchParams` containing the following parameters:
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `searchAO`: Un objet représentant une recherche enregistrée du client
   *
   * @return Une recherche du client
   */
  editSearchResponse(params: FirstAOCustomerServicesService.EditSearchParams): __Observable<__StrictHttpResponse<SearchAO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.searchAO;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/searches/${params.searchID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchAO>;
      })
    );
  }
  /**
   * Éditer les informations d'une recherche enregistrée. * Editable : libelle, alertState, isFavorite. Si il y a des modifications sur le filtre de la recherche, il faut faire appel à /firstao/search/{searchID}/filter lors de l'édition du filtre.
   * @param params The `FirstAOCustomerServicesService.EditSearchParams` containing the following parameters:
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `searchAO`: Un objet représentant une recherche enregistrée du client
   *
   * @return Une recherche du client
   */
  editSearch(params: FirstAOCustomerServicesService.EditSearchParams): __Observable<SearchAO> {
    return this.editSearchResponse(params).pipe(
      __map(_r => _r.body as SearchAO)
    );
  }

  /**
   * Supprimer une recherche enregistrée
   * @param searchID L'identifiant de la recherche enregistrée du client
   */
  deleteSearchResponse(searchID: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/firstao/searches/${searchID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Supprimer une recherche enregistrée
   * @param searchID L'identifiant de la recherche enregistrée du client
   */
  deleteSearch(searchID: number): __Observable<null> {
    return this.deleteSearchResponse(searchID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Mise à jour du filtre de recherche d’une recherche enregistrée.
   * @param params The `FirstAOCustomerServicesService.EditSearchFilterParams` containing the following parameters:
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * @return Critères de recherche
   */
  editSearchFilterResponse(params: FirstAOCustomerServicesService.EditSearchFilterParams): __Observable<__StrictHttpResponse<QueryCriterions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryCriterions;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/firstao/searches/${params.searchID}/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryCriterions>;
      })
    );
  }
  /**
   * Mise à jour du filtre de recherche d’une recherche enregistrée.
   * @param params The `FirstAOCustomerServicesService.EditSearchFilterParams` containing the following parameters:
   *
   * - `searchID`: L'identifiant de la recherche enregistrée du client
   *
   * - `queryCriterions`: Un objet représentant un filtre de recherche
   *
   * @return Critères de recherche
   */
  editSearchFilter(params: FirstAOCustomerServicesService.EditSearchFilterParams): __Observable<QueryCriterions> {
    return this.editSearchFilterResponse(params).pipe(
      __map(_r => _r.body as QueryCriterions)
    );
  }

  /**
   * Récupération des données d'initialisation
   * @return Les données de session du client
   */
  getUserSessionResponse(): __Observable<__StrictHttpResponse<AoSession>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/session`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AoSession>;
      })
    );
  }
  /**
   * Récupération des données d'initialisation
   * @return Les données de session du client
   */
  getUserSession(): __Observable<AoSession> {
    return this.getUserSessionResponse().pipe(
      __map(_r => _r.body as AoSession)
    );
  }

  /**
   * Consulter les partages par email d’appels d’offre.
   * @param params The `FirstAOCustomerServicesService.GetSharesParams` containing the following parameters:
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return Historique des partages d'avis de marché
   */
  getSharesResponse(params: FirstAOCustomerServicesService.GetSharesParams): __Observable<__StrictHttpResponse<SharesHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sdate != null) __params = __params.set('sdate', params.sdate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.edate != null) __params = __params.set('edate', params.edate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firstao/shares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SharesHistory>;
      })
    );
  }
  /**
   * Consulter les partages par email d’appels d’offre.
   * @param params The `FirstAOCustomerServicesService.GetSharesParams` containing the following parameters:
   *
   * - `sdate`: La date de publication de début de la période de sélection, au format yyyy-MM-dd
   *
   * - `pageSize`: Le nombre d'élements par page
   *
   * - `page`: Le numéro de la page souhaitée, commence à 1
   *
   * - `edate`: La date de publication de fin de la période de sélection, au format yyyy-MM-dd
   *
   * @return Historique des partages d'avis de marché
   */
  getShares(params: FirstAOCustomerServicesService.GetSharesParams): __Observable<SharesHistory> {
    return this.getSharesResponse(params).pipe(
      __map(_r => _r.body as SharesHistory)
    );
  }

  /**
   * Partager un avis de marché par email
   * @param emailNoticesToShare Un objet représentant un email à envoyer pour partager des avis de marché
   * @return Partage d'avis de marché
   */
  shareAOResponse(emailNoticesToShare: EmailNoticesToShare): __Observable<__StrictHttpResponse<EmailNoticeShare>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = emailNoticesToShare;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/firstao/shares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmailNoticeShare>;
      })
    );
  }
  /**
   * Partager un avis de marché par email
   * @param emailNoticesToShare Un objet représentant un email à envoyer pour partager des avis de marché
   * @return Partage d'avis de marché
   */
  shareAO(emailNoticesToShare: EmailNoticesToShare): __Observable<EmailNoticeShare> {
    return this.shareAOResponse(emailNoticesToShare).pipe(
      __map(_r => _r.body as EmailNoticeShare)
    );
  }
}

module FirstAOCustomerServicesService {

  /**
   * Parameters for getAddressBook
   */
  export interface GetAddressBookParams {

    /**
     * L'identifiant d'un carnet d'adresses d'un client
     */
    addressBookID: number;

    /**
     * Recherche plein texte d'une adresse du carnet par son email
     */
    searchAddress?: string;

    /**
     * N'afficher que les adresses disponibles en 1-Click
     */
    oneClick?: boolean;
  }

  /**
   * Parameters for updateAddressBook
   */
  export interface UpdateAddressBookParams {

    /**
     * L'identifiant d'un carnet d'adresses d'un client
     */
    addressBookID: number;

    /**
     * Un objet représentant un carnet d'adresse du client
     */
    addressBookAo: AddressBookAo;
  }

  /**
   * Parameters for addContactAddressInAddressBook
   */
  export interface AddContactAddressInAddressBookParams {

    /**
     * L'identifiant d'un contact
     */
    addressID: number;

    /**
     * L'identifiant d'un carnet d'adresses d'un client
     */
    addressBookID: number;
  }

  /**
   * Parameters for deleteContactAddressFromAddressBook
   */
  export interface DeleteContactAddressFromAddressBookParams {

    /**
     * L'identifiant d'un contact
     */
    addressID: number;

    /**
     * L'identifiant d'un carnet d'adresses d'un client
     */
    addressBookID: number;
  }

  /**
   * Parameters for getAddresses
   */
  export interface GetAddressesParams {

    /**
     * Recherche plein texte d'une adresse du carnet par son email
     */
    searchAddress?: string;

    /**
     * N'afficher que les adresses disponibles en 1-Click
     */
    oneClick?: boolean;
  }

  /**
   * Parameters for updateContactAddress
   */
  export interface UpdateContactAddressParams {

    /**
     * L'identifiant d'un contact
     */
    addressID: number;

    /**
     * Un objet représentant un contact du client
     */
    addressAo: AddressAo;
  }

  /**
   * Parameters for getAlertsHistory
   */
  export interface GetAlertsHistoryParams {

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;
  }

  /**
   * Parameters for updateAlert
   */
  export interface UpdateAlertParams {

    /**
     * L'identifiant d'une alerte
     */
    alertID: number;

    /**
     * Un objet représentant une alerte enregistrée du client
     */
    alertAOBody: AlertAOBody;
  }

  /**
   * Parameters for postContractorQualificationRequest
   */
  export interface PostContractorQualificationRequestParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant interne d'un titulaire de marché
     */
    contractorID: number;
  }

  /**
   * Parameters for showSearchActivityArea
   */
  export interface ShowSearchActivityAreaParams {

    /**
     * Requête plein texte - OR, AND et groupement par parenthèses supportées
     */
    query?: string;

    /**
     * Le niveau hiérarchique de la nomenclature CPV
     */
    hierarchicalLevel?: number;

    /**
     * Identifiant d'un code CPV
     */
    cpvID?: string;

    /**
     * Liste des codes CPV associés aux secteurs d'activités, séparés par des virgules
     */
    cpv?: string;
  }

  /**
   * Parameters for getFavoritesFromFolder
   */
  export interface GetFavoritesFromFolderParams {

    /**
     * L'identifiant du dossier du client
     */
    folderID: number;

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;

    /**
     * La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
     */
    aoSortingMethod?: 'pertinence' | 'publicationDateAsc' | 'publicationDateDesc' | 'deadlineAsc' | 'deadlineDesc' | 'addDateAsc' | 'addDateDesc' | 'lastEditDateAsc' | 'lastEditDateDesc';

    /**
     * La méthode de regroupement des annonces:      * `deadline` - Par date limite de réponse (groupement sur une période d'un mois)      * `buyer` - Par acheteur (classé par ordre alphabétique croissant)      * `localisation` - Par secteur géographique (classé par ordre alphabétique croissant, région puis département)      * `activityArea` - Par secteur (classé par ordre alphabétique croissant)      * `informationStatus ` - Par statut de mise en favoris (classé par ordre alphabétique croissant)
     */
    aoGroupByMethod?: 'deadline' | 'buyer' | 'localisation' | 'activityArea' | 'informationStatus';
  }

  /**
   * Parameters for editFavoriteFolder
   */
  export interface EditFavoriteFolderParams {

    /**
     * L'identifiant du dossier du client
     */
    folderID: number;

    /**
     * Un objet représentant un dossier de classement de favoris
     */
    favorisFolder: FavorisFolder;
  }

  /**
   * Parameters for addAoToFavoritesInFolder
   */
  export interface AddAoToFavoritesInFolderParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant du dossier du client
     */
    folderID: number;
  }

  /**
   * Parameters for moveAoInFavoritesToOtherFolder
   */
  export interface MoveAoInFavoritesToOtherFolderParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * L'identifiant du dossier du client
     */
    folderID: number;

    /**
     * L'identifiant du dossier de classement des favoris du client
     */
    favorisFolderID?: number;
  }

  /**
   * Parameters for editAoDataFromFavoris
   */
  export interface EditAoDataFromFavorisParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Un objet représentant les métadonnées d'un avis de marché en favoris
     */
    favorisAoMetadata: FavorisAoMetadata;
  }

  /**
   * Parameters for editMainFilter
   */
  export interface EditMainFilterParams {

    /**
     * reset
     */
    reset?: boolean;

    /**
     * Un objet représentant un filtre de recherche
     */
    queryCriterions?: QueryCriterions;
  }

  /**
   * Parameters for getNomenclatureLocalisations
   */
  export interface GetNomenclatureLocalisationsParams {

    /**
     * Code ZIP d'un lieu
     */
    zip?: string;

    /**
     * Requête plein texte - OR, AND et groupement par parenthèses supportées
     */
    query?: string;

    /**
     * Code du Pays
     */
    pays?: string;

    /**
     * Type d'un lieu (region/departement)
     */
    localisationType?: string;

    /**
     * Le code ZIP d'une région
     */
    localisationParentRegion?: string;
  }

  /**
   * Parameters for searchNotices
   */
  export interface SearchNoticesParams {

    /**
     * Précise une recherche sur les appels d'offres ou les avis d'attribution
     */
    searchType?: 'notice' | 'award';

    /**
     * L'identifiant de la recherche enregistrée du client
     */
    searchID?: number;

    /**
     * La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
     */
    sdeadlinedate?: string;

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Critères de filtrage
     */
    queryCriterions?: string;

    /**
     * La période de la date de publication
     */
    publicationSince?: 'none' | 'today' | 'since_one_week' | 'since_two_week' | 'since_one_month' | 'since_three_month';

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * Indique s'il s'agit d'une recherche de type Mes avis de marché
     */
    myProfileSearch?: boolean;

    /**
     * L'identifiant d'un filtre de recherche enregistrée du client
     */
    filterID?: number;

    /**
     * L'identifiant du dossier de classement des favoris du client
     */
    favorisFolderID?: number;

    /**
     * La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
     */
    edeadlinedate?: string;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;

    /**
     * La période de la date limite de réponse
     */
    deadlineIn?: 'none' | 'today' | 'week' | 'in_one_week' | 'in_two_week' | 'in_one_month';

    /**
     * La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
     */
    aoSortingMethod?: 'pertinence' | 'publicationDateAsc' | 'publicationDateDesc' | 'deadlineAsc' | 'deadlineDesc' | 'addDateAsc' | 'addDateDesc' | 'lastEditDateAsc' | 'lastEditDateDesc';
  }

  /**
   * Parameters for searchNoticesPost
   */
  export interface SearchNoticesPostParams {

    /**
     * Précise une recherche sur les appels d'offres ou les avis d'attribution
     */
    searchType?: 'notice' | 'award';

    /**
     * L'identifiant de la recherche enregistrée du client
     */
    searchID?: number;

    /**
     * La date limite de réponse de début de la période de sélection, au format yyyy-MM-dd
     */
    sdeadlinedate?: string;

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Un objet représentant un filtre de recherche
     */
    queryCriterions?: QueryCriterions;

    /**
     * La période de la date de publication
     */
    publicationSince?: 'none' | 'today' | 'since_one_week' | 'since_two_week' | 'since_one_month' | 'since_three_month';

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * Indique s'il s'agit d'une recherche de type Mes avis de marché
     */
    myProfileSearch?: boolean;

    /**
     * L'identifiant d'un filtre de recherche enregistrée du client
     */
    filterID?: number;

    /**
     * L'identifiant du dossier de classement des favoris du client
     */
    favorisFolderID?: number;

    /**
     * La date limite de réponse de fin de la période de sélection, au format yyyy-MM-dd
     */
    edeadlinedate?: string;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;

    /**
     * La période de la date limite de réponse
     */
    deadlineIn?: 'none' | 'today' | 'week' | 'in_one_week' | 'in_two_week' | 'in_one_month';

    /**
     * La méthode de tri des annonces:      * `pertinence` - Pertinence dans une recherche mots clés      * `publicationDateAsc` - Annonces les plus anciennes en premier      * `publicationDateDesc` - Annonces les plus récentes en premier      * `deadlineAsc` - Annonces avec la date limite de réponse les plus proches en premier      * `deadlineDesc` - Annonces avec la date limite de réponse les plus lointaines en premier  Par défaut : publicationDateDesc
     */
    aoSortingMethod?: 'pertinence' | 'publicationDateAsc' | 'publicationDateDesc' | 'deadlineAsc' | 'deadlineDesc' | 'addDateAsc' | 'addDateDesc' | 'lastEditDateAsc' | 'lastEditDateDesc';
  }

  /**
   * Parameters for downloadNoticeAttachment
   */
  export interface DownloadNoticeAttachmentParams {

    /**
     * L'identifiant interne d'un avis de marché
     */
    noticeID: number;

    /**
     * Le hash d'un fichier d'un avis de marché
     */
    hashAttachmentID: string;

    /**
     * L'identifiant interne d'un fichier d'un avis de marché
     */
    attachmentID: number;
  }

  /**
   * Parameters for editSearch
   */
  export interface EditSearchParams {

    /**
     * L'identifiant de la recherche enregistrée du client
     */
    searchID: number;

    /**
     * Un objet représentant une recherche enregistrée du client
     */
    searchAO: SearchAO;
  }

  /**
   * Parameters for editSearchFilter
   */
  export interface EditSearchFilterParams {

    /**
     * L'identifiant de la recherche enregistrée du client
     */
    searchID: number;

    /**
     * Un objet représentant un filtre de recherche
     */
    queryCriterions?: QueryCriterions;
  }

  /**
   * Parameters for getShares
   */
  export interface GetSharesParams {

    /**
     * La date de publication de début de la période de sélection, au format yyyy-MM-dd
     */
    sdate?: string;

    /**
     * Le nombre d'élements par page
     */
    pageSize?: number;

    /**
     * Le numéro de la page souhaitée, commence à 1
     */
    page?: number;

    /**
     * La date de publication de fin de la période de sélection, au format yyyy-MM-dd
     */
    edate?: string;
  }
}

export { FirstAOCustomerServicesService }
