import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../services/snackbar.service";
import {AuthService} from "../../services/auth-service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  signupForm: FormGroup;

  constructor(
      private snackbarService: SnackbarService,
      private authService: AuthService,
      private fb: FormBuilder,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signupForm.controls;
  }

  createForm() {
    this.signupForm = this.fb.group(
        {
          email: [null, [Validators.required]],
          password: [null, [Validators.required]],
        },
    );
  }

  onSignIn() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      this.snackbarService.openSnackBar('Veuillez renseigner les champs Email et Mot de passe',
          'danger', '', 5000);
      return;
    }
    this.authService.login(this.signupForm.value.email, this.signupForm.value.password);
  }

}
