import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../api/gen/services/first-aointern-services.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PrivateBuyerCreate} from "../../api/gen/models/private-buyer-create";
import {ContractorQualificationRequestInformation} from "../../api/gen/models/contractor-qualification-request-information";
import {AoContractorFacade} from "../../api/gen/models/ao-contractor-facade";
import {Entreprise} from "../../api/gen/models/entreprise";
import {QualificationModalComponent} from "../../widgets/modal/qualification-modal/qualification-modal.component";
import {ContractorQualification} from "../../api/gen/models/contractor-qualification";
import {FirstAOCustomerServicesService} from "../../api/gen/services";
import {PreQualificationConfirmationComponent} from "../../widgets/modal/pre-qualification-confirmation/pre-qualification-confirmation.component";
import PatchContractorQualificationRequestParams = FirstAOInternServicesService.PatchContractorQualificationRequestParams;
import EditorEditEntrepriseParams = FirstAOInternServicesService.EditorEditEntrepriseParams;
import EditorEditEntrepriseFieldParams = FirstAOInternServicesService.EditorEditEntrepriseFieldParams;
import {DatePipe} from "@angular/common";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {EntrepriseContact} from "../../api/gen/models/entreprise-contact";

@Component({
  selector: 'app-attributaire-edition',
  templateUrl: './attributaire-edition.component.html',
  styleUrls: ['./attributaire-edition.component.css'],
  providers: [DatePipe]
})
export class AttributaireEditionComponent implements OnInit {
  showEditorLoading = false;
  isPublic = true;
  readonly = true;
  currentBuyerProfilId;



  // ---------------- NEO PARAMETERS ----------------
  qualificationRequests: ContractorQualificationRequestInformation[];
  currentQualificationRequest: ContractorQualificationRequestInformation;
  currentEntreprise: Entreprise;
  currentSiren: string;
  entrepriseToModify: Entreprise;
  @ViewChild("qualificationsPaginator", { static: false }) qualificationsPaginator : MatPaginator;

  constructor(public dialog: MatDialog,
              private firstAOInternServicesService: FirstAOInternServicesService,
              private firstAOCustomerServicesService: FirstAOCustomerServicesService,
              private snackBar: MatSnackBar,
              public datepipe: DatePipe) {

  }

  ngOnInit() {
    this.firstAOInternServicesService.getContractorQualificationRequest({pageSize: 25}).subscribe(value => {
      this.qualificationRequests = value.requests;
      this.qualificationsPaginator.length = value.count;
    });
  }

  openContractor(qualificationRequest: ContractorQualificationRequestInformation) {
    this.currentEntreprise = null;
    // this.resetParams();
    this.readonly = true;
    if (!this.currentQualificationRequest || this.currentQualificationRequest.contractor.contractorId != qualificationRequest.contractor.contractorId) {
      this.currentQualificationRequest = qualificationRequest;
      this.currentSiren = this.convertSiren(this.currentQualificationRequest.contractor.siren);
      this.showEditorLoading = false;
    }
    this.highlightSelectedBuyerProfil();
  }


  editBuyerProfil() {
    this.readonly = false;
  }

  createContact() {
    this.firstAOInternServicesService.createNewContact({entrepriseId: this.currentEntreprise.entrepriseId, searchValue: "Nouveau contact"}).subscribe(value => {
      if (this.currentEntreprise.contacts == null)
        this.currentEntreprise.contacts = [];
      this.currentEntreprise.contacts.push(value);
    });
  }

  saveContact(contact: EntrepriseContact) {
    this.firstAOInternServicesService.editorEditContactEntreprise({entrepriseId: this.currentEntreprise.entrepriseId,
      contactID: contact.contactId, entrepriseContact: contact}).subscribe(value => {
    });
  }

  openQualifyingModal() {
    this.readonly = true;

    const dialogRef = this.dialog.open(QualificationModalComponent, {
      data: {
        currentQualificationRequest: this.currentQualificationRequest
      },
      panelClass: ["mHForModal", "padR10ForModal"]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.currentEntreprise = null;
      this.entrepriseToModify = null
      if (result != null && result.accept) {
        this.firstAOCustomerServicesService.getEntreprise(result.suggestion.entrepriseId).subscribe(value => {
          this.currentEntreprise = value.entrepriseData;
          this.entrepriseToModify = {
            telephone: this.currentEntreprise.telephone,
            mail: this.currentEntreprise.mail,
            website: this.currentEntreprise.website,
            facebook: this.currentEntreprise.facebook,
            twitter: this.currentEntreprise.twitter,
            linkedin: this.currentEntreprise.linkedin,
          }
          if (this.currentEntreprise.siren || this.currentEntreprise.siren == "")
            this.currentEntreprise.siren = result.suggestion.siren;
          if (this.currentEntreprise.siret || this.currentEntreprise.siret == "")
            this.currentEntreprise.siret = result.suggestion.siret;
        })
      }
    });
  }

  saveEntrepriseModifications() {
    const params : EditorEditEntrepriseParams = {
      entreprise: this.currentEntreprise,
      entrepriseId : this.currentEntreprise.entrepriseId
    }

    // if (this.currentEntreprise.telephone != this.entrepriseToModify.telephone) {
    //   const params: EditorEditEntrepriseFieldParams = {
    //     entrepriseId: this.currentEntreprise.entrepriseId,
    //     field: "telephone",
    //     value: this.currentEntreprise.telephone
    //   };
    //
    //   this.firstAOInternServicesService.editorEditEntrepriseField(params).subscribe(value => {
    //     value.telephone;
    //   });
    // }


    this.firstAOInternServicesService.editorEditEntreprise(params).subscribe(value => {
      this.readonly = true;
    }, error => {
      this.snackBar.open("Une erreur est survenue lors de l'enregistrement de l'entreprise",
            "Fermer", {duration: 10000});
    });
  }

  neoSaveEntrepriseModifications() {
    const params : EditorEditEntrepriseParams = {
      entreprise: this.currentEntreprise,
      entrepriseId : this.currentEntreprise.entrepriseId
    }

    this.firstAOInternServicesService.editorEditEntreprise(params).subscribe(value => {
      this.readonly = true;
    }, error => {
      this.snackBar.open("Une erreur est survenue lors de l'enregistrement de l'entreprise",
            "Fermer", {duration: 10000});
    });
  }

  highlightSelectedBuyerProfil() {
    let elems = document.getElementsByClassName('attProfil');

    [].forEach.call(elems, function (el) {
      el.classList.remove("selectedAttProfil");
    });

    if (this.currentQualificationRequest != null) {

      let noticeToHighlight = document.getElementById('attProfil-' + this.currentQualificationRequest.contractor.contractorId);

      if (noticeToHighlight != null)
        noticeToHighlight.classList.add('selectedAttProfil');
    }
  }

  convertSiren(siren: string) {
    if (siren != null) {
      let sirenConverted = siren;
      sirenConverted = sirenConverted.replace(/\s/g, "");
      if (sirenConverted.length > 9)
        sirenConverted = sirenConverted.substring(0, 9);
      return sirenConverted;
    } else {
      return siren;
    }
  }

  openQualificationPreConfirmation() {
    const dialogRef = this.dialog.open(PreQualificationConfirmationComponent, {
      data: {
        contractor: this.currentQualificationRequest.contractor,
        suggestion: this.currentEntreprise
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result.accept) {
        const contractorQualificationParams : ContractorQualification = {
          entreprise_id: this.currentEntreprise.entrepriseId
        }

        const params : PatchContractorQualificationRequestParams = {
            requestID: this.currentQualificationRequest.request_id,
            contractorQualification: contractorQualificationParams
        }

        this.firstAOInternServicesService.patchContractorQualificationRequest(params).subscribe(buyer => {
          this.snackBar.open("L'association a bien été prise en compte !",
              "Fermer", {duration: 10000});
          this.qualificationRequests.splice(this.qualificationRequests.indexOf(this.currentQualificationRequest), 1);
          this.currentQualificationRequest = null;
        }, error => {
          this.snackBar.open("Une erreur est survenue lors de l'association de l'entreprise",
              "Fermer", {duration: 10000});
        });
      }
    });
  }

  getSAsValue(s: string) {
    const sToReturn: string = s;
    return sToReturn;
  }

  loadDemandeAttributaires($event: PageEvent) {
    //this.missionService.changePagination(event.pageSize, event.pageIndex, true);
    this.firstAOInternServicesService.getContractorQualificationRequest({page: $event.pageIndex, pageSize: $event.pageSize}).subscribe(value => {
      this.qualificationRequests = value.requests;
      this.qualificationsPaginator.length = value.count;

      this.currentEntreprise = null;
      this.readonly = true;
      this.currentQualificationRequest = null;
      this.currentSiren = null;
      this.highlightSelectedBuyerProfil();
    });
  }
}
