import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MissionService} from "../../../mission.service";
import {FirstAOCustomerServicesService} from "../../../api/gen/services";
import ShowSearchActivityAreaParams = FirstAOCustomerServicesService.ShowSearchActivityAreaParams;
import {AoCpv, AoIndexationFacade} from "../../../api/gen/models";

/**
 * @title Chips Autocomplete
 */
@Component({
    selector: 'app-cpv-code-chooser',
    templateUrl: './cpv-code-chooser.component.html',
    styleUrls: ['./cpv-code-chooser.component.css']
})
export class CpvCodeChooserComponent {
    selectable = true;
    removable = true;
    isSearching: boolean = false;
    private searchTimeOut;
    search: string = "";
    cpvSearched: AoCpv[] = [];

    @Output() cpvsChanged = new EventEmitter<any>();

    @ViewChild('cpvInput') cpvInput: ElementRef<HTMLInputElement>;

    private _cpvCodes;

    get cpvCodes(): any {
        return this._cpvCodes;
    }

    @Input("cpvCodes")
    set cpvCodes(val: any) {
        this._cpvCodes = val;
    }

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }


    constructor(missionService: MissionService,
                private firstAOCustomerServicesService: FirstAOCustomerServicesService) {
    }

    remove(fruit: string): void {
        const index = this.cpvCodes.indexOf(fruit);

        if (index >= 0) {
            this.cpvCodes.splice(index, 1);
            this.cpvsChanged.emit();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        let alreadyAdded: boolean = false;
        if (this.cpvCodes == null)
            this.cpvCodes = [];
        this.cpvCodes.forEach(function (value) {
            if (event.option.value.cpvId == value.cpv)
                alreadyAdded = true;
        });
        if (!alreadyAdded) {
            const cpvCode: AoIndexationFacade = {
                cpv: event.option.value.cpvId,
                libelle: event.option.value.libelle
            };
            this.cpvCodes.push(cpvCode);
            this.cpvCodes = this.cpvCodes;
            this.cpvsChanged.emit();
        }
        this.cpvInput.nativeElement.value = '';
        this.cpvSearched = [];
    }

    scheduleSearch() {
        this.cpvSearched = [];
        var self = this;
        clearTimeout(this.searchTimeOut);
        if (this.search != null && this.search.length >= 2) {
            this.searchTimeOut = setTimeout(function () {
                self.searchCpvCodes();
            }, 500);
        }
    }

    searchCpvCodes() {
        this.isSearching = true;
        let params: ShowSearchActivityAreaParams;
        if (this.search.length == 8 && !isNaN(Number(this.search))) {
            params = {
                cpvID: this.search
            };
        } else {
            params = {
                query: this.search
            };
        }

        this.firstAOCustomerServicesService.showSearchActivityArea(params)
            .subscribe((cpvs) => {
                var self = this;

                if (cpvs != null) {
                    if (cpvs.length > 10) {
                        for (let i = 0; i < 10; i++) {
                            self.cpvSearched.push(cpvs[i])
                        }
                    } else {
                        cpvs.forEach(function (value) {
                            self.cpvSearched.push(value);
                        });
                    }
                }
                this.isSearching = false;
            });
    }
}