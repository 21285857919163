import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-source-adder',
    templateUrl: './source-adder.component.html',
    styleUrls: ['./source-adder.component.css']
})
export class SourceAdderComponent implements OnInit {

    sourceName;
    sourceURL;

    constructor(public dialogRef: MatDialogRef<SourceAdderComponent>) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close({
            libelle: this.sourceName,
            url: this.sourceURL
        });
    }
}
