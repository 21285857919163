import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {

    public constructor( private snackBar: MatSnackBar ) {
    }

    /**
     * Open a snackbar
     * @param message
     * @param type
     * @param action
     * @param duration
     */
    openSnackBar( message: string, type: string = 'success', action: string = '', duration: number = 1000 ) {
        return this.snackBar.open( message, action, {
            duration: duration,
            verticalPosition: 'top',
            panelClass: [ type + '-snackbar' ],
        } );
    }
}
