import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {ContactManagerComponent} from "../../modal/contact-manager/contact-manager.component";
import { MatDialog } from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {CommanditaireContact} from "../../../api/gen/models/commanditaire-contact";

@Component({
    selector: 'app-contact-chooser',
    templateUrl: './contact-chooser.component.html',
    styleUrls: ['./contact-chooser.component.css']
})
export class ContactChooserComponent implements OnInit {

    // ----------------------------------------- ReadOnly -----------------------------------------
    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }
    // ----------------------------------------- ReadOnly -----------------------------------------


    // ----------------------------------------- EntrepriseId -----------------------------------------
    private _entrepriseId: number;

    get entrepriseId(): number {
        return this._entrepriseId;
    }

    @Input("entrepriseId")
    set entrepriseId(val: number) {
        this._entrepriseId = val;
    }
    // ----------------------------------------- EntrepriseId -----------------------------------------


    // ----------------------------------------- BuyerContacts -----------------------------------------
    private _buyersContacts;

    get buyersContacts() {
        return this._buyersContacts;
    }

    @Input("buyersContacts")
    set buyersContacts(val) {
        this._buyersContacts = val;
        if (this.currentContact != null) {
            this.contactsControl.setValue(this.currentContact.id);
        }
    }
    // ----------------------------------------- BuyerContacts -----------------------------------------



    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------
    private _noticeSavingInProgress: boolean;

    get noticeSavingInProgress(): boolean {
        return this._noticeSavingInProgress;
    }

    @Input("noticeSavingInProgress")
    set noticeSavingInProgress(val: boolean) {
        this._noticeSavingInProgress = val;
        if (this.noticeSavingInProgress) {
            this.saveContact();
        }
    }
    // ----------------------------------------- NoticeSavingInProgress -----------------------------------------


    contactId: number;

    contactsControl = new FormControl('', [Validators.required]);

    @Output() contactChanged = new EventEmitter<any>();

    @Input("currentNotice") currentNotice;

    @Input("currentContact") currentContact: CommanditaireContact;

    @Input("isPublic") isPublic;

    @ViewChild('contactSelect') contactSelect;

    constructor(public dialog: MatDialog,
                private firstAOInternServicesService: FirstAOInternServicesService) {
    }

    ngOnInit() {
        this.contactId = this.currentNotice.commanditaireContactId;
    }

    onContactSelected($event) {
        if ($event != null && $event.value != null) {
            if ($event.value != this.currentNotice.commanditaireContactId) {
                this.currentContact = this.getContact($event.value);
                this.currentNotice.commanditaire_contact = this.currentContact;
                this.currentNotice.commanditaireContactId = $event.value;
                this.contactChanged.emit();
            }
        }
    }

    openCreateContactModal() {
        this.contactSelect.close();
        let buyerId: number;
        if (this.isPublic)
            buyerId = this.currentNotice.identiteAcheteurId;
        else
            buyerId = this.currentNotice.entrepriseId;
        const dialogRef = this.dialog.open(ContactManagerComponent, {
            data: {
                title: "Création d'un contact",
                buyerId: buyerId,
                isCreating: true,
                isPublic: this.isPublic
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != "") {
                if (this.buyersContacts == null)
                    this.buyersContacts = [];

                this.currentNotice.commanditaire_contact = result.contact;
                this.currentNotice.commanditaireContactId = result.contact.id;

                this.currentContact = result.contact;
                this.buyersContacts.push(result.contact);
                this.currentContact.id = result.contact.id;

                this.contactChanged.emit();
            }
        });
    }

    getContact(contactId) {
        let contactToReturn = null;
        let isSearching = this.buyersContacts != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.buyersContacts.length) {
                if (this.buyersContacts[index].id === contactId) {
                    contactToReturn = this.buyersContacts[index];
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return contactToReturn;
    }

    saveContact() {
        const contact: CommanditaireContact = {
            id: this.currentContact.id,
            nom: this.currentContact.nom,
            email: this.currentContact.email,
            telephone: this.currentContact.telephone,
            fax: this.currentContact.fax,
            linkedin_profil: this.currentContact.linkedin_profil,
            viadeo_profil: this.currentContact.viadeo_profil
        };
        if (this.isPublic) {
            const params: FirstAOInternServicesService.EditCommanditaireContactsParams = {
                contactID: this.currentContact.id,
                commanditaireContact: contact,
                buyerID: this.currentNotice.commanditaire.buyerID
            };

            this.firstAOInternServicesService.editCommanditaireContacts(params).subscribe(value => {
            });
        } else {
            contact.service = this.currentContact.service;
            const params: FirstAOInternServicesService.EditCommanditaireContactsParams = {
                contactID: this.currentContact.id,
                commanditaireContact: contact,
                buyerID: this.currentNotice.commanditaire.buyerID
            };

            this.firstAOInternServicesService.editEntrepriseContacts(params).subscribe(value => {
            });
        }
    }
}
