import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {FirstAOCustomerServicesService, FirstAOInternServicesService} from "../../../api/gen/services";
import {AoManagerComponent} from "../../modal/ao-manager/ao-manager.component";
import {MissionService} from "../../../mission.service";

@Component({
    selector: 'app-ao-search-bar',
    templateUrl: './ao-search-bar.component.html',
    styleUrls: ['./ao-search-bar.component.css']
})
export class AoSearchBarComponent implements OnInit {

    @Input('placeHolder') placeHolder: string;
    @Input('searchBarType') searchBarType: string;
    @Input("addItemLabel") addItemLabel: string;

    private _item;

    get item(): any {
        return this._item;
    }

    @Input("cpvCodes")
    set item(val: any) {
        this._item = val;
    }

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    search: string = "";
    private searchTimeOut;
    isSearching: boolean = false;
    methodToCall;
    searchBarClass;
    results;

    @Output() itemSelected = new EventEmitter<any>();
    @Output() newItem = new EventEmitter<any>();

    constructor(public dialog: MatDialog,
                private firstAOCustomerServicesService: FirstAOCustomerServicesService,
                private firstAOInternServicesService: FirstAOInternServicesService,
                private missionService: MissionService) {
    }

    ngOnInit() {
        if (this.searchBarType == "ao")
            this.searchBarClass = 'aoSearchBar';
    }

    searchAo() {
        this.isSearching = true;
        this.makeTheCall().subscribe((results) => {
                this.results = this.transformCallResults(results);

                // buyers.body.prives.forEach(function (value) {
                //     self.privateBuyers.push(value);
                // });
                //
                // buyers.body.publics.forEach(function (value) {
                //     self.publicBuyers.push(value)
                // })

                this.isSearching = false;
            });
    }

    scheduleSearch() {
        var self = this;
        clearTimeout(this.searchTimeOut);
        this.results = [];
        if (this.search != null && this.search.length >= 3) {
            console.log("Lancement de la recherche");
            this.searchTimeOut = setTimeout(function () {
                console.log("search : " + self.search);
                self.searchAo();
            }, 1000);
        } else {
            console.log("Pas de recherche");
        }
    }

    makeTheCall() {
        if (this.searchBarType == "ao") {
            return this.methodToCall = this.firstAOCustomerServicesService.showSearchBuyerSuggestResponse(this.search);
        } else if (this.searchBarType == "buyer") {
            return this.methodToCall = this.firstAOCustomerServicesService.showSearchBuyerSuggestResponse(this.search);
        } else {
            return this.methodToCall = this.firstAOCustomerServicesService.showSearchBuyerSuggestResponse(this.search);
        }
    }

    transformCallResults(results) {
        let transformedResults = [];
        if (this.searchBarType == "ao") {
        } else if (this.searchBarType == "buyer") {
            results.body.publics.forEach(function (value) {
                transformedResults.push(new SearchResultItem(value.denomination, value));
            });
        } else {
            results.body.prives.forEach(function (value) {
                transformedResults.push(new SearchResultItem(value.denomination, value));
            });
        }
        return transformedResults;
    }

    openAoModal(aoId) {
        this.missionService.changeCurrentSearchNoticeId(aoId);

        let dialogRef = this.dialog.open(AoManagerComponent, {
        });
        // dialogRef.componentInstance.isSearchingMode = true;

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    actionOnClick(item) {
        this.itemSelected.emit(item);
    }
}

export class SearchResultItem {
    libelle: string;
    item: any;

    constructor(libelle: string, item: any) {
        this.libelle = libelle;
        this.item = item;
    }
}
