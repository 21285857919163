import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {UserSession} from "./commons/user-session";

export class PaginationData {
    private pageSize: number;
    private pageIndex: number;
    private fromUser: boolean;

    constructor(pageSize: number, pageIndex: number, fromUser: boolean) {
        this.pageSize = pageSize;
        this.pageIndex = pageIndex + 1;
        this.fromUser = fromUser;
    }

    public getPageSize() {
        return this.pageSize;
    }

    public getPageIndex() {
        return this.pageIndex;
    }

    public isFromUser() {
        return this.fromUser;
    }
}

@Injectable()
export class MissionService {
    get userSession() {
        return this._userSession;
    }

    set userSession(value) {
        this._userSession = value;
    }

    // Observable sources
    private paginationData = new BehaviorSubject <PaginationData>(new PaginationData(10, 0, false));
    private currentNoticeId = new BehaviorSubject <number>(null);
    private currentSearchNoticeId = new BehaviorSubject <number>(null);
    private viewMode = new Subject<string>();
    private alreadyDoneMode = new Subject<boolean>();
    private reloadNoticeListSource = new Subject();
    private _userSession: UserSession;

    // Observable streams
    paginationData$ = this.paginationData.asObservable();
    viewMode$ = this.viewMode.asObservable();
    alreadyDoneMode$ = this.alreadyDoneMode.asObservable();
    currentNoticeId$ = this.currentNoticeId.asObservable();
    currentSearchNoticeId$ = this.currentSearchNoticeId.asObservable();
    reloadNoticeList$ = this.reloadNoticeListSource.asObservable();

    private _isNewNotice: boolean = false;

    constructor() {
        this.alreadyDoneMode.next(false);
    }

    // Service message commands
    changeAlreadyDoneMode(alreadyDoneMode: boolean) {
        this.alreadyDoneMode.next(alreadyDoneMode);
        this.currentNoticeId.next(null);
    }

    changePagination(pageSize: number, pageIndex: number, fromUser: boolean) {
        this.paginationData.next(new PaginationData(pageSize, pageIndex, fromUser));
    }

    changeCurrentNoticeId(event: number) {
        if (event != this.currentNoticeId.getValue())
            this.currentNoticeId.next(event);
        else
            this.currentNoticeId.next(null);
    }
    changeCurrentSearchNoticeId(event: number) {
        this.currentSearchNoticeId.next(event);
    }

    getPaginationData() {
        return this.paginationData.getValue();
    }

    reloadNoticeList() {
        this.reloadNoticeListSource.next();
    }

    setIsNewNotice(value: boolean) {
        this._isNewNotice = value;
    }

    isNewNotice(): boolean {
        if (this._isNewNotice) {
            this._isNewNotice = false;
            return true;
        } else {
            return this._isNewNotice;
        }
    }
}
