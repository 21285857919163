<h1 mat-dialog-title>Création d'une entreprise</h1>
<div mat-dialog-content>
    <mat-form-field class="flexGrow1">
        <input matInput placeholder="Nom de l'entreprise" style="margin-right: 10px;" type="text" id="addEnterpriseName" [(ngModel)]="enterpriseName">
    </mat-form-field>
    <mat-form-field class="flexGrow1">
        <input matInput placeholder="SIREN" type="text" style="margin-right: 10px;" id="addEnterpriseSiren" [(ngModel)]="enterpriseSiren">
    </mat-form-field>
    <mat-form-field class="flexGrow1">
        <input matInput placeholder="Code APE" type="text" id="addEnterpriseAPE" [(ngModel)]="enterpriseAPE">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button class="cancelButton" (click)="onNoClick()" style="color: white;">Annuler</button>
    <button mat-button class="saveBtn" (click)="onSave()" cdkFocusInitial style="color: white;">Enregisrer</button>
</div>