import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-ao-manager',
  templateUrl: './ao-manager.component.html',
  styleUrls: ['./ao-manager.component.css']
})
export class AoManagerComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

}
