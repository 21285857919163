import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import {AoIndexationFacade} from "../../../api/gen/models/ao-indexation-facade";
import {FirstAOCustomerServicesService} from "../../../api/gen/services";
import GetNomenclatureLocalisationsParams = FirstAOCustomerServicesService.GetNomenclatureLocalisationsParams;
import {Localisations} from "../../../api/gen/models/localisations";

@Component({
  selector: 'app-departement-chooser',
  templateUrl: './departement-chooser.component.html',
  styleUrls: ['./departement-chooser.component.css']
})
export class DepartementChooserComponent implements OnInit {

    selectable = true;
    removable = true;
    isSearching: boolean = false;
    private searchTimeOut;
    search: string = "";
    departementsSearched = [];

    @Output() departementsChanged = new EventEmitter<any>();

    @ViewChild('departementsInput') departementInput: ElementRef<HTMLInputElement>;

    private _departements;

    get departements() {
        return this._departements;
    }

    @Input("departements")
    set departements(val) {
        this._departements = val;
    }

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }

  constructor(private firstAOCustomerServicesService: FirstAOCustomerServicesService) { }

  ngOnInit() {
  }

    remove(departement): void {
        const index = this.departements.indexOf(departement);

        if (index >= 0) {
            this.departements.splice(index, 1);
            this.departementsChanged.emit();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        let alreadyAdded: boolean = false;
        if (this.departements == null)
            this.departements = [];
        this.departements.forEach(function (value) {
            if (event.option.value.zip == value.zip)
                alreadyAdded = true;
        });
        if (!alreadyAdded) {
            const departement = {
                zip: event.option.value.zip,
                libelle: event.option.value.libelle + " (" + event.option.value.zip + ")",
            };
            this.departements.push(departement);
            this.departementsChanged.emit();
        }
        this.departementInput.nativeElement.value = '';
        this.search = "";
        this.departementsSearched = [];
    }

    scheduleSearch() {
        this.departementsSearched = [];
        var self = this;
        clearTimeout(this.searchTimeOut);
        if (this.search != null && this.search.length >= 1) {
            this.searchTimeOut = setTimeout(function () {
                self.searchDepartements();
            }, 500);
        }
    }

    searchDepartements() {
        this.isSearching = true;
            const params: GetNomenclatureLocalisationsParams = {
                query: this.search.trim(),
                localisationType: "departement"
            };

        this.firstAOCustomerServicesService.getNomenclatureLocalisations(params)
            .subscribe((departements) => {
                var self = this;

                if (departements != null) {
                    if (departements.length > 10) {
                        for (let i = 0; i < 10; i++) {
                            self.departementsSearched.push(departements[i])
                        }
                    } else {
                        departements.forEach(function (value) {
                            self.departementsSearched.push(value);
                        });
                    }
                }
                this.isSearching = false;
            });
    }

}
