import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import EditorAddNoticeAttachmentParams = FirstAOInternServicesService.EditorAddNoticeAttachmentParams;
import EditorDeleteNoticeAttachmentParams = FirstAOInternServicesService.EditorDeleteNoticeAttachmentParams;
import {FirstAOCustomerServicesService} from "../../../api/gen/services";
import DownloadNoticeAttachmentParams = FirstAOCustomerServicesService.DownloadNoticeAttachmentParams;
import * as FileSaver from 'file-saver';
import {LoadingDisplayerComponent} from "../loading-displayer/loading-displayer.component";
import {MessageDisplayerComponent} from "../message-displayer/message-displayer.component";
import {AoAttachmentFacade} from "../../../api/gen/models/ao-attachment-facade";

@Component({
    selector: 'app-files-manager',
    templateUrl: './files-manager.component.html',
    styleUrls: ['./files-manager.component.css']
})
export class FilesManagerComponent implements OnInit {

    fileToUpload: File = null;
    urlDocument;
    fileInput;
    aoFileType;
    inProgressFileCount: number;
    loadingModal;

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                private dialogRef: MatDialogRef<FilesManagerComponent>,
                private firstAOInternServicesService: FirstAOInternServicesService,
                private firstAOCustomerServicesService: FirstAOCustomerServicesService,
                public dialog: MatDialog) {
        this.urlDocument = data.urlDocument;
        this.readOnly = data.readOnly;
        if(data.isPublic)
            this.aoFileType = "DCE";
        else
            this.aoFileType = "CDC";
    }

    ngOnInit() {
    }

    handleFileInput(files: FileList) {
        if (files != null && files.length > 0) {
            this.inProgressFileCount = files.length;
            this.loadingModal = this.dialog.open(LoadingDisplayerComponent, {disableClose: true});
            for (var i = 0; i < files.length; i++) {
                this.uploadFileToActivity(files[i]);
            }
        }
    }

    openFile(attachement) {
        const params: DownloadNoticeAttachmentParams = {
            attachmentID: attachement.id,
            hashAttachmentID: attachement.hash,
            noticeID: this.data.notice.aoId
        };
        this.firstAOCustomerServicesService.downloadNoticeAttachment(params).subscribe(value => {
            FileSaver.saveAs(value, attachement.filename);
        });
    }

    uploadFileToActivity(file: File) {
        const params: EditorAddNoticeAttachmentParams = {
            noticeID: this.data.notice.aoId,
            file: file
        };

        this.firstAOInternServicesService.editorAddNoticeAttachment(params).subscribe(value => {
            let aoAttachment: AoAttachmentFacade = {
                filename: value.filename,
                filesize: Number(value.filesize),
                hash: value.hash,
                id: value.id
            };

            this.data.notice.attachments.push(aoAttachment);
            this.fileInput = "";

            this.inProgressFileCount --;
            if (this.inProgressFileCount <= 0) {
                this.loadingModal.close();
            }
        }, error1 => {
            this.inProgressFileCount --;
            if (this.inProgressFileCount <= 0) {
                this.loadingModal.close();
            }

            this.dialog.open(MessageDisplayerComponent, {
                data: {
                    title: "Error lors de l'upload",
                    message: "Le ou les fichiers n'ont pas réussis à être uploadé(s).",
                    type: "danger"
                }});
        });
    }

    removeFile(attachement) {
        const params: EditorDeleteNoticeAttachmentParams = {
            attachmentID: attachement.id,
            noticeID: this.data.notice.aoId
        };
        this.firstAOInternServicesService.editorDeleteNoticeAttachment(params).subscribe(value => {
            this.removeFileFromArray(attachement);
        });
    }

    close() {
        this.dialogRef.close(this.urlDocument);
    }

    removeFileFromArray(attachement) {
        let isSearching = this.data.notice.attachments != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.data.notice.attachments.length) {
                if (this.data.notice.attachments[index] == attachement) {
                    this.data.notice.attachments.splice(index, 1);
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }
    }

}
