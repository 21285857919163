<div class="noticeResumeContainer" (click)="openNotice()">
    <div class="noticeResumeLeftPart">

        <div><span style="font-weight: bold">AO n°</span> {{notice.aoId}}</div>

        <div style="margin-top: 5px;"><span style="font-weight: bold;">Référence :</span> 17-4694</div>

        <div class="noticeResumeSubLabel">Date de publication</div>
        <div>{{notice.publicationDateString}}</div>

        <div class="noticeResumeSubLabel">Date limite</div>
        <div>{{notice.deadlineString}}</div>

        <div class="noticeResumeSubLabel">Type de marché</div>
        <div>{{notice.typeMarche}}</div>

    </div>
    <div class="noticeResumeRightPart">
        <div class="noticeResumeTitle">{{notice.titre}}</div>

        <div class="noticeResumeInlineContainer">
            <div class="margR10 bold" style="min-width: 100px;">Acheteur :</div>
            <div>{{notice.commanditaire.denomination}}</div>
        </div>

        <div class="noticeResumeInlineContainer" style="margin-bottom: 9px;" *ngIf="notice.lots.length > 0">
            <div class="margR10 bold" style="min-width: 100px;">Nombre de lots :</div>
            <div>{{notice.lots.length}}</div>
        </div>

        <div class="noticeResumeInlineContainer" *ngIf="notice.indexation.length > 0">
            <div class="margR10 bold" style="min-width: 100px;">Codes CPV :</div>
            <div class="tagsContainer">
                <div class="noticeResumeCPV" *ngFor="let cpv of notice.indexation">{{cpv.libelle}} ({{cpv.cpv}})</div>
            </div>
        </div>

        <div class="noticeResumeInlineContainer" *ngIf="notice.departements.length > 0">
            <div class="margR10 bold" style="min-width: 100px;">Départements :</div>
            <div class="tagsContainer">
                <div class="noticeResumeDepartement" *ngFor="let dep of notice.departements">{{dep.libelle}} ({{dep.zip}})</div>
            </div>

        </div>

    </div>
</div>