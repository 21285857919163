import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-entreprise-creation-confirmartion',
  templateUrl: './entreprise-creation-confirmartion.component.html',
  styleUrls: ['./entreprise-creation-confirmartion.component.css']
})
export class EntrepriseCreationConfirmartionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<EntrepriseCreationConfirmartionComponent>) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close({
      accept: false
    });
  }

  accept() {
    this.dialogRef.close({
      accept: true,
      siren: this.data.siren
    });
  }
}
