<div class="contactContainer" id="buyerProfilAddress">
    <div class="containerTitle">
        <div class="containerTitleText">Adresse</div>
        <mat-form-field style="width: 100%;">
            <mat-select #addressSelect (selectionChange)="onAddressSelected($event)"
                        [(ngModel)]="adresseId" [disabled]="readOnly || entrepriseId == 0"
                        [formControl]="addressesControl"
                        required>
                <mat-option *ngFor="let address of buyersAddresses" [value]="address.id">
                    {{address.libelle}} ({{address.adresse}})
                </mat-option>
                <button class="adderButton" (click)="openCreateAddressModal()">Ajouter une nouvelle
                    adresse
                </button>
            </mat-select>
            <mat-error *ngIf="addressesControl.hasError('required')">Choisissez une adresse
            </mat-error>
        </mat-form-field>
    </div>


    <div style="display: flex;">
        <mat-form-field class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Libellé *" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentAddress.libelle"
                   (ngModelChange)="currentAddress.libelle= $event; addressChanged.emit()">
        </mat-form-field>

        <mat-form-field class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Adresse *" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentAddress.adresse"
                   (ngModelChange)="currentAddress.adresse= $event; addressChanged.emit()">
        </mat-form-field>

        <mat-form-field class="inputContainer margR10 flexGrow1">
            <input matInput placeholder="Ville *" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentAddress.city"
                   (ngModelChange)="currentAddress.city= $event; addressChanged.emit()">
        </mat-form-field>

        <mat-form-field class="inputContainer flexGrow1">
            <input matInput placeholder="ZIP *" type="text" [readonly]="readOnly || entrepriseId == 0"
                   [ngModel]="currentAddress.zip"
                   (ngModelChange)="currentAddress.zip= $event; addressChanged.emit()">
        </mat-form-field>
    </div>
</div>
