<mat-accordion>
    <mat-expansion-panel [expanded]="true" style="background: #ffe9e9; margin-top: 20px;">
        <mat-expansion-panel-header>
            Entreprise
        </mat-expansion-panel-header>

        <div id="buyerProfil" class="buyerProfilContainer">


            <!-- ENTREPRISE -->
            <div class="contactContainer">
                <div class="containerTitle">
                    <div class="containerTitleText">Entreprise</div>
                    <app-ao-search-bar style="display: grid;" class="flexGrow1" [placeHolder]="'Rechercher une entreprise'"
                                       [addItemLabel]="'Ajouter un nouvelle entreprise'"
                                       [searchBarType]="'enterprise'" (itemSelected)="onEnterpriseSelected($event)"
                                       (newItem)="addNewEnterprise()" [readonly]="readOnly">
                    </app-ao-search-bar>
                </div>

                <div style="display: flex;">
                    <mat-form-field class="flexGrow1 margR10">
                        <input [readonly]="readOnly" matInput placeholder="Raison sociale *" type="text"
                               [ngModel]="currentEnterprise?.denomination"
                               (ngModelChange)="currentEnterprise.denomination = $event; enterpriseChanged.emit()">
                    </mat-form-field>

<!--                    <mat-form-field class="flexGrow1 margR10">-->
<!--                        <input [readonly]="readOnly" matInput placeholder="SIREN" type="text"-->
<!--                               [ngModel]="currentEnterprise?.siren"-->
<!--                               (ngModelChange)="currentEnterprise.siren = $event; enterpriseChanged.emit()">-->
<!--                    </mat-form-field>-->

                    <mat-form-field class="flexGrow1 margR10">
                        <input [readonly]="readOnly" matInput placeholder="SIRET" type="text"
                               [ngModel]="currentEnterprise?.siret"
                               (ngModelChange)="currentEnterprise.siret = $event; enterpriseChanged.emit()">
                    </mat-form-field>

                    <mat-form-field class="flexGrow1 margR10">
                        <input [readonly]="readOnly" matInput placeholder="URL de l'entreprise" type="text"
                               id="noticeBuyerProfilUrl" [ngModel]="currentNotice?.commanditaire?.url"
                               (ngModelChange)="enterpriseChanged.emit()">
                    </mat-form-field>

                    <mat-form-field class="flexGrow1">
                        <input [readonly]="readOnly" matInput placeholder="Lien Societe.com" type="text"
                               [ngModel]="currentEnterprise?.societecom"
                               (ngModelChange)="currentEnterprise.societecom = $event; enterpriseChanged.emit()">
                    </mat-form-field>
                </div>

                <div style="display: flex;">
<!--                     <mat-form-field class="flexGrow1 margR10">-->
<!--                        <input [readonly]="readOnly" matInput placeholder="Lien LinkedIn" type="text"-->
<!--                               [ngModel]="currentEnterprise?.linkedin"-->
<!--                               (ngModelChange)="currentEnterprise.linkedin = $event; enterpriseChanged.emit()">-->
<!--                    </mat-form-field>-->

<!--                    <mat-form-field class="flexGrow1 margR10">-->
<!--                        <input [readonly]="readOnly" matInput placeholder="Lien Societe.com" type="text"-->
<!--                               [ngModel]="currentEnterprise?.viadeo"-->
<!--                               (ngModelChange)="currentEnterprise.viadeo = $event; enterpriseChanged.emit()">-->
<!--                    </mat-form-field>-->

<!--                    <mat-form-field class="flexGrow1">-->
<!--                        <input [readonly]="readOnly" matInput placeholder="Lien First Eco" type="text"-->
<!--                               [ngModel]="currentEnterprise"-->
<!--                               (ngModelChange)="enterpriseChanged.emit()">-->
<!--                    </mat-form-field>-->
                </div>
            </div>
            <!-- ENTREPRISE -->


            <!-- CONTACT -->
            <app-contact-chooser [currentNotice]="currentNotice" [readonly]="cAndAReadOnly()"
                                 [buyersContacts]="enterpriseContacts"
                                 (contactChanged)="enterpriseChanged.emit()"
                                 [noticeSavingInProgress]="noticeSavingInProgress"
                                 [currentContact]="currentContact"
                                 [isPublic]="false"
                                 [entrepriseId]="getEntrepriseId()"
                                 *ngIf="currentContact">
            </app-contact-chooser>
            <!-- CONTACT -->


            <!-- ADDRESS -->
            <app-address-chooser [currentNotice]="currentNotice" [readonly]="cAndAReadOnly()"
                                 [buyersAddresses]="enterpriseAddresses"
                                 (addressChanged)="enterpriseChanged.emit()"
                                 [noticeSavingInProgress]="noticeSavingInProgress"
                                 [currentAddress]="currentAddress"
                                 [isPublic]="false"
                                 [entrepriseId]="getEntrepriseId()"
                                 *ngIf="currentAddress">
            </app-address-chooser>
            <!-- ADDRESS -->

        </div>

    </mat-expansion-panel>
</mat-accordion>
