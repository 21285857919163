import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ao-thumbnail',
  templateUrl: './ao-thumbnail.component.html',
  styleUrls: ['./ao-thumbnail.component.css']
})
export class AoThumbnailComponent implements OnInit {

  title

  constructor() {
    // this.title = title;
  }

  ngOnInit() {
  }

}
