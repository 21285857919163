import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {CommanditaireContact} from "../../../api/gen/models/commanditaire-contact";
import {CommanditaireAdresse} from "../../../api/gen/models/commanditaire-adresse";

@Component({
  selector: 'app-address-manager',
  templateUrl: './address-manager.component.html',
  styleUrls: ['./address-manager.component.css']
})
export class AddressManagerComponent implements OnInit {

    private title: string;
    private buyerId: number;
    showLoading: boolean;
    private isCreating: boolean;

    private adressId;
    private libelle;
    private adresse;
    private city;
    private zip;

    constructor(private dialogRef: MatDialogRef<AddressManagerComponent>,
                private firstAOInternServicesService: FirstAOInternServicesService,
                @Inject(MAT_DIALOG_DATA) public data) {
        if (data.address != null)
        {
            this.adressId = data.address.id;
            this.libelle = data.address.libelle;
            this.adresse = data.address.adresse;
            this.city = data.address.city;
            this.zip = data.address.zip;
        }
        this.title = data.title;
        this.buyerId = data.buyerId;
        this.isCreating = data.isCreating;
        this.showLoading = false;
    }

    ngOnInit() {
    }

    closeAndSave() {
        this.dialogRef.disableClose = true;
        this.showLoading = true;

        const commanditaireAdresse: CommanditaireAdresse = {
            libelle: this.libelle,
            adresse: this.adresse,
            city: this.city,
            zip: this.zip,
            status: 1
        };

        if (this.isCreating) {

            const params: FirstAOInternServicesService.CreateCommanditaireAdresseParams = {
                commanditaireAdresse: commanditaireAdresse,
                buyerID: this.buyerId
            };

            this.firstAOInternServicesService.createCommanditaireAdresse(params).subscribe(value => {
                this.dialogRef.close({
                    address: value
                });
            });

        } else {

            const params: FirstAOInternServicesService.EditCommanditaireAdresseParams = {
                buyerAdresseID: this.adressId,
                commanditaireAdresse: commanditaireAdresse,
                buyerID: this.buyerId
            };

            this.firstAOInternServicesService.editCommanditaireAdresse(params).subscribe(value => {
                this.dialogRef.close({
                    address: value
                });
            });

        }
    }


}
