import {AppComponent} from './app.component';
import {TopBarComponent} from './widgets/top-bar/top-bar.component';
import {AoListComponent} from './widgets/ao-list/ao-list.component';
import {AoThumbnailComponent} from './widgets/ao-thumbnail/ao-thumbnail.component';
import {AoEditorComponent} from './widgets/ao-editor/ao-editor.component';
import {ProfilAcheteurEditorComponent} from './widgets/profil-acheteur-editor/profil-acheteur-editor.component';

import {DceEditionComponent} from './views/dce-edition/dce-edition.component';
import {AoEditionComponent} from './views/ao-edition/ao-edition.component';

import {AppRoutingModule} from './app-routing.module';


import {ApiInterceptor} from "./api/api-interceptor";
import { CpvCodeChooserComponent } from './widgets/editor-parts/cpv-code-chooser/cpv-code-chooser.component';
import { AoDatePickerComponent } from './widgets/ao-date-picker/ao-date-picker.component';
import { BasicInputComponent } from './widgets/basic-input/basic-input.component';
import { AcheteurEditionComponent } from './views/acheteur-edition/acheteur-edition.component';
import { AcheteurListComponent } from './widgets/acheteur-list/acheteur-list.component';
import { AcheteurModalComponent } from './widgets/acheteur-modal/acheteur-modal.component';
import { FilesManagerComponent } from './widgets/modal/files-manager/files-manager.component';
import { LotsManagerComponent } from './widgets/modal/lots-manager/lots-manager.component';
import { NutsCodeChooserComponent } from './widgets/editor-parts/nuts-code-chooser/nuts-code-chooser.component';
import {MissionService} from "./mission.service";
import { BuyersListComponent } from './widgets/buyers-list/buyers-list.component';
import { AvisEditionComponent } from './views/avis-edition/avis-edition.component';
import { AoSearchBarComponent } from './widgets/searchers/ao-search-bar/ao-search-bar.component';
import { AoManagerComponent } from './widgets/modal/ao-manager/ao-manager.component';
import { EnterpriseManagerComponent } from './widgets/modal/enterprise-manager/enterprise-manager.component';
import { BuyersManagerComponent } from './widgets/modal/buyers-manager/buyers-manager.component';
import { BuyerSearchBarComponent } from './widgets/searchers/buyer-search-bar/buyer-search-bar.component';
import { PublicRedactionComponent } from './views/public-redaction/public-redaction.component';
import { EnterpriseAdderComponent } from './widgets/modal/enterprise-adder/enterprise-adder.component';
import { NoticeAdderComponent } from './widgets/modal/notice-adder/notice-adder.component';
import { SourcesManagerComponent } from './widgets/modal/sources-manager/sources-manager.component';
import {MY_FORMAT} from "./commons/format-date";
import { ContactManagerComponent } from './widgets/modal/contact-manager/contact-manager.component';
import { AddressManagerComponent } from './widgets/modal/address-manager/address-manager.component';
import { SourceManagerComponent } from './widgets/modal/source-manager/source-manager.component';
import { SourceEditionComponent } from './views/source-edition/source-edition.component';
import { DepartementChooserComponent } from './widgets/editor-parts/departement-chooser/departement-chooser.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders} from "@angular/common/http";
import {forwardRef, NgModule, Provider} from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FlexLayoutModule} from "@angular/flex-layout";
import { CommanditaireChooserComponent } from './widgets/editor-parts/commanditaire-chooser/commanditaire-chooser.component';
import { ContactChooserComponent } from './widgets/editor-parts/contact-chooser/contact-chooser.component';
import { AddressChooserComponent } from './widgets/editor-parts/address-chooser/address-chooser.component';
import { EnterpriseChooseComponent } from './widgets/editor-parts/enterprise-choose/enterprise-choose.component';
import { PrivateRedactionComponent } from './views/private-redaction/private-redaction.component';
import { SearchComponent } from './views/search/search.component';
import { LieuxManagerComponent } from './widgets/modal/lieux-manager/lieux-manager.component';
import { ErrorPublicationComponent } from './widgets/modal/error-publication/error-publication.component';
import { MessageDisplayerComponent } from './widgets/modal/message-displayer/message-displayer.component';
import { LoadingDisplayerComponent } from './widgets/modal/loading-displayer/loading-displayer.component';
import { DoublonsDisplayerComponent } from './widgets/modal/doublons-displayer/doublons-displayer.component';
import { ComplementaryAddressesManagerComponent } from './widgets/modal/complementary-addresses-manager/complementary-addresses-manager.component';
import { SourceAdderComponent } from './widgets/modal/source-adder/source-adder.component';
import { AcheteurChooserComponent } from './widgets/editor-parts/acheteur-chooser/acheteur-chooser.component';
import { NoticeResumeComponent } from './widgets/notice-resume/notice-resume.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import { LoginComponent } from './views/login/login.component';
import {AppLoaderService} from "./services/app-loader/app-loader.service";
import {MatDividerModule} from "@angular/material/divider";
import {AppLoaderComponent} from "./services/app-loader/app-loader.component";
import {SnackbarService} from "./services/snackbar.service";
import {AuthService} from "./services/auth-service";
import { AttributaireEditionComponent } from './views/attributaire-edition/attributaire-edition.component';
import { QualificationModalComponent } from './widgets/modal/qualification-modal/qualification-modal.component';
import { SelectEntrepriseComponent } from './widgets/modal/select-entreprise/select-entreprise.component';
import { PreQualificationConfirmationComponent } from './widgets/modal/pre-qualification-confirmation/pre-qualification-confirmation.component';
import { QualificationConfirmationComponent } from './widgets/modal/qualification-confirmation/qualification-confirmation.component';
import { EntrepriseCreationConfirmartionComponent } from './widgets/modal/entreprise-creation-confirmartion/entreprise-creation-confirmartion.component';
import * as $ from "jquery";

export const API_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => ApiInterceptor),
    multi: true
};

@NgModule({
    providers: [
        ApiInterceptor,
        API_INTERCEPTOR_PROVIDER,
        MissionService,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMAT},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
        AppLoaderService,
        SnackbarService,
        AuthService
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatTabsModule,
        FlexLayoutModule,
        MatPaginatorModule,
        FormsModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTooltipModule,
        MatCardModule,
        MatExpansionModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatDividerModule,
    ],
    declarations: [
        AppComponent,
        TopBarComponent,
        AoListComponent,
        AoThumbnailComponent,
        AoEditorComponent,
        ProfilAcheteurEditorComponent,
        DceEditionComponent,
        AoEditionComponent,
        CpvCodeChooserComponent,
        AoDatePickerComponent,
        BasicInputComponent,
        AcheteurEditionComponent,
        AcheteurListComponent,
        AcheteurModalComponent,
        FilesManagerComponent,
        LotsManagerComponent,
        NutsCodeChooserComponent,
        BuyersListComponent,
        AvisEditionComponent,
        AoSearchBarComponent,
        AoManagerComponent,
        EnterpriseManagerComponent,
        BuyersManagerComponent,
        BuyerSearchBarComponent,
        PublicRedactionComponent,
        EnterpriseAdderComponent,
        NoticeAdderComponent,
        SourcesManagerComponent,
        ContactManagerComponent,
        AddressManagerComponent,
        SourceManagerComponent,
        SourceEditionComponent,
        DepartementChooserComponent,
        CommanditaireChooserComponent,
        ContactChooserComponent,
        AddressChooserComponent,
        EnterpriseChooseComponent,
        PrivateRedactionComponent,
        SearchComponent,
        LieuxManagerComponent,
        QualificationModalComponent,
        ErrorPublicationComponent,
        MessageDisplayerComponent,
        LoadingDisplayerComponent,
        DoublonsDisplayerComponent,
        ComplementaryAddressesManagerComponent,
        SourceAdderComponent,
        AcheteurChooserComponent,
        NoticeResumeComponent,
        LoginComponent,
        AppLoaderComponent,
        AttributaireEditionComponent,
        QualificationModalComponent,
        SelectEntrepriseComponent,
        PreQualificationConfirmationComponent,
        QualificationConfirmationComponent,
        EntrepriseCreationConfirmartionComponent
    ],
    entryComponents: [
        FilesManagerComponent,
        LotsManagerComponent,
        SourcesManagerComponent,
        CpvCodeChooserComponent,
        NutsCodeChooserComponent,
        AoManagerComponent,
        AoEditorComponent,
        BuyersManagerComponent,
        EnterpriseAdderComponent,
        NoticeAdderComponent,
        ContactManagerComponent,
        AddressManagerComponent,
        SourceManagerComponent,
        LieuxManagerComponent,
        QualificationModalComponent,
        ErrorPublicationComponent,
        MessageDisplayerComponent,
        LoadingDisplayerComponent,
        DoublonsDisplayerComponent,
        ComplementaryAddressesManagerComponent,
        SourceAdderComponent,
        SelectEntrepriseComponent,
        PreQualificationConfirmationComponent,
        QualificationConfirmationComponent,
        EntrepriseCreationConfirmartionComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {
        // let headers = new HttpHeaders().set('Content-Type', 'application/json')
        //     .set('Authorization', 'Basic cm9tYWluLnRyaWJvdHRlQGZpcnN0ZWNvLmZyOnNjb3Bl')
        //     .set('Access-Control-Allow-Headers', '');
    }

}



