<div>
    <mat-form-field style="width: 180px; margin: 0 10px;" id="deadlineDP">
        <input matInput [matDatepicker]="startPick" [(ngModel)]="startDate"
               placeholder="Date de début" (dateChange)="loadNotices()">
        <mat-datepicker-toggle matSuffix [for]="startPick"></mat-datepicker-toggle>
        <mat-datepicker #startPick></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 180px;" id="publicationDateDP">
        <input matInput [matDatepicker]="endPick"
               [(ngModel)]="endDate"
               placeholder="Date de fin" (dateChange)="loadNotices()">
        <mat-datepicker-toggle matSuffix [for]="endPick"></mat-datepicker-toggle>
        <mat-datepicker #endPick></mat-datepicker>
    </mat-form-field>
</div>
<div style="overflow: auto;">
    <div class="noselect" *ngFor="let notice of notices; let last = last"
         [class.notPriority]="notice.redactionProcessPriority < 1000 && collectedMode" >
        <a (click)="openNotice(notice.aoId)">
            <div id="not{{notice.aoId}}" class="notice noselect">{{notice.titre}}
                - <span style="color: #ff0024;">({{notice.aoId}})</span>
                - <span style="font-weight: bold">{{notice.publicationDateString}}</span> -
                <i id="notIcon-{{notice.aoId}}" *ngIf="showDCE"
                   class="material-icons dceIcon" matTooltip="Le DCE a été ajouté" [class.displayDceIcon]="checkIfUrlDceExists(notice)
               || notice.attachments?.length > 0">insert_drive_file</i>
            </div>
        </a>
        <ng-container *ngIf="last && highlightNewNotice">
            {{ highlightNotice() }}
        </ng-container>
    </div>
</div>
