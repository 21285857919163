import {Component, Inject, OnInit} from '@angular/core';
import {FirstAOInternServicesService} from "../../../api/gen/services";
import EditorAddNoticeSourceParams = FirstAOInternServicesService.EditorAddNoticeSourceParams;
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-sources-manager',
    templateUrl: './sources-manager.component.html',
    styleUrls: ['./sources-manager.component.css']
})
export class SourcesManagerComponent implements OnInit {

    constructor(private firstAOInternServicesService: FirstAOInternServicesService,
                @Inject(MAT_DIALOG_DATA) public data) {
    }

    ngOnInit() {
    }

    addNewSource() {
        // const params: EditorAddNoticeSourceParams = {
        //     noticeID: 600,
        // };
        //
        //
        // this.firstAOInternServicesService.editorAddNoticeSource(params).subscribe(buyer => {
        //     this.buyerEditing = true;
        //     this.setBuyer(buyer, true);
        // });
    }

}
