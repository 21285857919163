import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MissionService} from "../../mission.service";
import {Subscription} from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {FilesManagerComponent} from "../modal/files-manager/files-manager.component";
import {LotsManagerComponent} from "../modal/lots-manager/lots-manager.component";
import {FirstAOInternServicesService} from "../../api/gen/services";
import {DatePipe} from "@angular/common";
import {
    AoAttachmentFacade,
    AoSources, AoSourcesListe,
    NoticeUrl,
} from "../../api/gen/models";
import EditorAddNoticeSourceParams = FirstAOInternServicesService.EditorAddNoticeSourceParams;
import EditorEditNoticeSourceParams = FirstAOInternServicesService.EditorEditNoticeSourceParams;
import EditorDeleteNoticeSourceParams = FirstAOInternServicesService.EditorDeleteNoticeSourceParams;
import EditorEditNoticeUrlsParams = FirstAOInternServicesService.EditorEditNoticeUrlsParams;
import {LieuxManagerComponent} from "../modal/lieux-manager/lieux-manager.component";
import EditorChangePublicationStateNoticeParams = FirstAOInternServicesService.EditorChangePublicationStateNoticeParams;
import {ErrorPublicationComponent} from "../modal/error-publication/error-publication.component";
import {MessageDisplayerComponent} from "../modal/message-displayer/message-displayer.component";
import {DoublonsDisplayerComponent} from "../modal/doublons-displayer/doublons-displayer.component";
import EditorSearchByTitleNoticesParams = FirstAOInternServicesService.EditorSearchByTitleNoticesParams;
import {SourceAdderComponent} from "../modal/source-adder/source-adder.component";
import {LoadingDisplayerComponent} from "../modal/loading-displayer/loading-displayer.component";
import EditorAddNoticeAttachmentParams = FirstAOInternServicesService.EditorAddNoticeAttachmentParams;

export type URLTypes = 'url_dce' | 'profil_acheteur' | 'url_information' | 'url_participation' | 'url_document' |
    'url_outil_logiciel' | 'url' | 'url_technique' | 'url_envoi_offre' | 'url_renseignement';

@Component({
    selector: 'app-ao-editor',
    templateUrl: './ao-editor.component.html',
    styleUrls: ['./ao-editor.component.css'],
    providers: [DatePipe]
    // providers: [MissionService]
})

export class AoEditorComponent implements OnInit {

    subscription: Subscription;
    noticeTitle: string = "";
    noticeBuyerProfilUrl: string = "";
    noticeBuyerName: string = "";
    noticeBuyerPhone: string = "";
    noticeCorps = '';
    noticeInEditionFromUser = '';
    noticeStartDate;
    noticeEndDate: Date;
    noticeAttachement: any[] = [];
    noticeLots;
    noticeLieux;
    noticeSources;
    currentNotice;
    currentHttpRequest;
    currentNoticeId;

    noticeCPVCodes = [];
    noticeDepartements = [];
    potentialDoublons = [];


    // Champs URL
    noticeUrls;
    urlDce: string;
    urlProfilAcheteur;
    urlInformation;
    urlParticipation;
    urlDocument;
    urlOutilLogiciel;
    urlWebsite;
    urlTechnique;
    urlEnvoiOffre;
    urlRenseignement;
    urlRfi;
    urlRfq;
    urlConfidentialite;


    // Ids
    private selectedContactId: number;
    private selectedAddressId: number;

    // TimeOuts
    private saveTimeOut;
    private saveSourceTimeOut;
    private saveUrlsTimeOut;
    private noticeLockerTimeOut;
    private checkDoublonsTimeOut;

    // Status
    private noticeIsUpToDate: boolean = true;
    private isdNoticeUnsaved: boolean = false;
    private noticeSavingInProgress: boolean = false;
    private noticeSavingFailed: boolean = false;
    private isSearchingBuyer: boolean = false;
    private readOnly: boolean = true;
    isChekingDoublon: boolean = false;
    showLoading: boolean = false;

    // Buyer Information
    buyersContacts;
    buyersAddresses;

    incompletedFields: HTMLElement[] = [];

    allSources;

    noticeAcheteur: string = "";

    inProgressFileCount: number;
    loadingModal;

    private _isPublic: boolean;

    get isPublic(): boolean {
        return this._isPublic;
    }

    @Input("isPublic")
    set isPublic(val: boolean) {
        this._isPublic = val;
    }

    private _processMode: boolean;

    get processMode(): boolean {
        return this._processMode;
    }

    @Input("processMode")
    set processMode(val: boolean) {
        this._processMode = val;
    }

    @Input() isSearchingMode: boolean = false;

    @Input("collectedMode") collectedMode: boolean = false;
    overrideCollectedMode = true;

    constructor(private firstAOInternServicesService: FirstAOInternServicesService,
                private missionService: MissionService,
                public dialog: MatDialog) {


    }

    openFileManager() {
        const dialogRef = this.dialog.open(FilesManagerComponent, {
            data: {
                // attachments: this.noticeAttachement,
                notice: this.currentNotice,
                urlDocument: this.getUrlDce(),
                readOnly: this.readOnly,
                isPublic: this.isPublic
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!this.readOnly) {
                if (result != null) {
                    this.currentNotice.urlDce = result;
                    this.setUrlDce(result);
                    this.scheduleUrlsSave();
                }

                // Permet de mettre à jour l'icône de DCE de la liste de Gauche (Contenant les Notices)
                let iconToDisplay = document.getElementById('notIcon-' + this.currentNotice.aoId);
                if (iconToDisplay != null) {
                    if ((this.currentNotice.attachments != null && this.currentNotice.attachments.length > 0)
                        || this.getUrlDce() != null) {
                        iconToDisplay.classList.add('displayDceIcon');
                    } else {
                        iconToDisplay.classList.remove('displayDceIcon');
                    }
                }
            }
        });
    }

    setUrlDce(urlDce) {
        if (this.currentNotice.urls != null) {
            let isUrlDceExist = false;
            for (let i = 0; i < this.currentNotice.urls.length; i++) {
                if (this.currentNotice.urls[i].type == "url_dce") {
                    this.currentNotice.urls[i].libelle = urlDce;
                    this.currentNotice.urls[i].url = urlDce;
                    isUrlDceExist = true;
                }
            }
            if (!isUrlDceExist) {
                this.currentNotice.urls.push({url: urlDce, libelle: urlDce, type: 'url_dce'})
            }
        } else {
            this.currentNotice.urls = [];
            this.currentNotice.urls.push({url: urlDce, libelle: urlDce, type: 'url_dce'})
        }
    }

    getUrlDce() {
        if (this.currentNotice.urls != null) {
            for (let i = 0; i < this.currentNotice.urls.length; i++) {
                if (this.currentNotice.urls[i].type == "url_dce" && this.currentNotice.urls[i].libelle != null
                    && this.currentNotice.urls[i].libelle.length > 0) {
                    return this.currentNotice.urls[i].url;
                }
            }
        }
        return null;
    }

    openLotsManager() {
        const dialogRef = this.dialog.open(LotsManagerComponent, {
            data: {
                aoId: this.currentNotice.aoId,
                lots: this.noticeLots,
                readOnly: this.readOnly
            }

        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    openLieuxManager() {
        const dialogRef = this.dialog.open(LieuxManagerComponent, {
            data: {
                aoId: this.currentNotice.aoId,
                lieux: this.noticeLieux,
                readOnly: this.readOnly
            }

        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    ngOnInit() {
        this.currentNoticeId = this.missionService.currentNoticeId$;

        this.subscription = this.firstAOInternServicesService.editorSources().subscribe(sources => {
            this.allSources = sources;
        });

        this.subscription = this.currentNoticeId.subscribe(
            value => {
                if (this.currentNotice != null && this.currentNotice.aoId != null)
                    this.unlockCurrentNotice();
                if (this.currentHttpRequest != null)
                    this.currentHttpRequest.unsubscribe();
                this.buyersContacts = null;
                this.buyersAddresses = null;

                // Clear URLS
                this.urlDce = null;
                this.urlProfilAcheteur = null;
                this.urlInformation = null;
                this.urlParticipation = null;
                this.urlDocument = null;
                this.urlOutilLogiciel = null;
                this.urlWebsite = null;
                this.urlTechnique = null;
                this.urlEnvoiOffre = null;
                this.urlRenseignement = null;
                this.urlRfi = null;
                this.urlRfq = null;
                this.urlConfidentialite = null;

                clearTimeout(this.saveTimeOut);
                clearTimeout(this.noticeLockerTimeOut);
                this.incompletedFields = [];
                this.isdNoticeUnsaved = false;
                this.potentialDoublons = [];
                this.noticeSavingInProgress = false;
                this.noticeSavingFailed = false;
                this.isChekingDoublon = false;
                if (value != null) {
                    this.currentHttpRequest = this.firstAOInternServicesService.editorShowAoResponse(value)
                        .subscribe((notice) => {
                            this.readNotice(notice);
                        });
                }
            }
        )
    }

    readNotice(notice) {
        console.log(notice);
        clearTimeout(this.noticeLockerTimeOut);
        this.currentNotice = notice.body;
        this.selectedContactId = this.currentNotice.commanditaireContactId;
        this.selectedAddressId = this.currentNotice.commanditaireAdresseId;
        this.potentialDoublons = [];

        this.getAndSetBuyer(false);

        this.readOnly = !this.missionService.isNewNotice();

        this.noticeTitle = notice.body.titre;

        this.noticeCPVCodes = [];
        this.noticeDepartements = [];

        var self = this;
        notice.body.indexation.forEach(function (value) {
            if (value != null) {
                self.noticeCPVCodes.push(value);
            }
        });

        notice.body.departements.forEach(function (value) {
            if (value != null) {
                self.noticeDepartements.push(value);
            }
        });

        this.noticeStartDate = new Date(String(notice.body.publicationDate));

        this.noticeEndDate = new Date(String(notice.body.deadline));

        this.noticeBuyerName = "";
        this.noticeBuyerPhone = "";
        this.noticeCorps = notice.body.renseignementsEditoriales;

        this.noticeAttachement = [];
        notice.body.attachments.forEach(function (value) {
            if (value.filename !== "") {
                self.noticeAttachement.push(value);
            }
        });

        this.noticeLots = notice.body.lots;
        this.noticeLieux = notice.body.lieux;
        this.noticeSources = notice.body.sources;


        if (this.currentNotice.commanditaire != null) {
            this.noticeAcheteur = this.currentNotice.commanditaire.denomination;
            this.noticeBuyerProfilUrl = this.currentNotice.commanditaire.url;
        } else {
            this.noticeAcheteur = "Choisir un acheteur";
        }

        // URLS
        this.currentNotice.urls.forEach(function (value) {
            switch (value.type) {
                case "url_dce":
                    self.urlDce = value.url;
                    break;
                case "profil_acheteur":
                    self.urlProfilAcheteur = value.url;
                    break;
                case "url_information":
                    self.urlInformation = value.url;
                    break;
                case "url_participation":
                    self.urlParticipation = value.url;
                    break;
                case "url_document":
                    self.urlDocument = value.url;
                    break;
                case "url_outil_logiciel":
                    self.urlOutilLogiciel = value.url;
                    break;
                case "url":
                    self.urlWebsite = value.url;
                    break;
                case "url_technique":
                    self.urlTechnique = value.url;
                    break;
                case "url_envoi_offre":
                    self.urlEnvoiOffre = value.url;
                    break;
                case "url_renseignement":
                    self.urlRenseignement = value.url;
                    break;
                case "url_rfi":
                    self.urlRfi = value.url;
                    break;
                case "url_rfq":
                    self.urlRfq = value.url;
                    break;
                case "url_confidentialite":
                    self.urlConfidentialite = value.url;
                    break;
            }
        });

        if (this.processMode == true) {
            this.firstAOInternServicesService.editorCheckNotice(this.currentNotice.aoId).subscribe(value => {
                this.highlightIncompletedFields(value.messages);
                if (value.messages != null) {
                    const dialogRef = this.dialog.open(ErrorPublicationComponent, {
                        data: {
                            aoId: this.currentNotice.aoId,
                            messages: value.messages
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        this.highlightIncompletedFields(value.messages);
                    });
                }
            });
        }
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.firstAOInternServicesService.editorUnLockNotice(this.currentNotice.aoId).subscribe(value => {
        });
        this.missionService.changeCurrentNoticeId(null);
        this.subscription.unsubscribe();
        this.currentNotice = null;
    }

    editAO() {
        this.firstAOInternServicesService.editorLockNotice().subscribe(values => {

            if (this.isCurrentNoticeLocked(values)) {
                const dialogRef = this.dialog.open(MessageDisplayerComponent, {
                    data: {
                        title: "Article Bloqué",
                        message: "Cet article est bloqué, attendez 30 secondes et réessayez.",
                        type: "danger"
                    }
                });
            } else {
                this.readOnly = false;
                this.lockCurrentNotice();
                // this.firstAOInternServicesService.editorCheckNotice()
            }
        });
    }

    lockCurrentNotice() {
        this.firstAOInternServicesService.editorLockNotice(this.currentNotice.aoId).subscribe(value => {
            this.scheduleNoticeLockerTimeOut();
        });
    }

    unlockCurrentNotice() {
        clearTimeout(this.noticeLockerTimeOut);
        this.firstAOInternServicesService.editorUnLockNotice(this.currentNotice.aoId).subscribe(value => {
        });
    }

    titleUpdate() {
        this.scheduleSave();

        if (!this.isSearchingMode && !this.collectedMode) {
            this.scheduleCheckDoublons();
        }
    }

    checkDoublons(checkDoublonsButton) {
        this.isChekingDoublon = true;

        let params: EditorSearchByTitleNoticesParams;

        if (this.isPublic)
            params = {
                query: this.currentNotice.titre,
                type: "public"
            };
        else
            params = {
                query: this.currentNotice.titre,
                type: "private"
            };

        this.firstAOInternServicesService.editorSearchByTitleNotices(params).subscribe(potentialDoublons => {
            this.potentialDoublons = potentialDoublons.notices;
            this.isChekingDoublon = false;

            if (potentialDoublons.count > 0) {
                checkDoublonsButton.classList.add('doublonBG');
            } else {
                checkDoublonsButton.classList.add('notDoublonBG');
            }
        });
    }

    openDoublonsDisplayer() {
        if (this.potentialDoublons.length > 0) {
            this.dialog.open(DoublonsDisplayerComponent, {
                data: {
                    potentialDoublons: this.potentialDoublons
                }
            });
        }
    }

    publishAO(state) {
        this.firstAOInternServicesService.editorLockNotice().subscribe(values => {

            if (this.isCurrentNoticeLocked(values)) {
                const dialogRef = this.dialog.open(MessageDisplayerComponent, {
                    data: {
                        title: "Article Bloqué",
                        message: "Cet article est bloqué, attendez 30 secondes et réessayez.",
                        type: "danger"
                    }
                });
            } else {
                let params: EditorChangePublicationStateNoticeParams;

                if (state) {
                    params = {
                        noticeID: this.currentNotice.aoId,
                        publicationState: "issued"
                    };
                } else {
                    params = {
                        noticeID: this.currentNotice.aoId,
                        publicationState: "written"
                    };
                }

                this.firstAOInternServicesService.editorChangePublicationStateNotice(params).subscribe(value => {
                    if (value.status == "success") {
                        this.currentNotice.state = params.publicationState;
                    } else {
                        const dialogRef = this.dialog.open(ErrorPublicationComponent, {
                            data: {
                                aoId: this.currentNotice.aoId,
                                messages: value.messages
                            }
                        });
                        this.highlightIncompletedFields(value.messages);
                    }
                });
            }
        });
    }

    updateAo(endOfEdition, publishing) {
        if (endOfEdition) {
            clearTimeout(this.saveTimeOut);
            clearTimeout(this.noticeLockerTimeOut);
            this.unlockCurrentNotice();
        }

        this.noticeSavingInProgress = true;

        // Assignation
        this.currentNotice.indexation = this.noticeCPVCodes;

        this.currentNotice.departementsPublication = [];
        let self = this;
        this.noticeDepartements.forEach(function (value) {
            self.currentNotice.departementsPublication.push(value.zip);
        });

        const params: FirstAOInternServicesService.EditorEditNoticeParams = {
            actualisApiFacadeNoticeFacade: this.currentNotice,
            noticeID: this.currentNotice.aoId
        };
        this.firstAOInternServicesService.editorEditNotice(params).subscribe(
            value => {
                if (endOfEdition) {
                    this.readOnly = true;
                }
                this.isdNoticeUnsaved = false;
                this.noticeSavingInProgress = false;
                this.isChekingDoublon = false;
                if (publishing) {
                    let params: EditorChangePublicationStateNoticeParams = {
                        noticeID: this.currentNotice.aoId,
                        publicationState: "issued"
                    };

                    this.firstAOInternServicesService.editorChangePublicationStateNotice(params).subscribe(value => {
                        if (value.status != "success") {
                            const dialogRef = this.dialog.open(ErrorPublicationComponent, {
                                data: {
                                    aoId: this.currentNotice.aoId,
                                    messages: value.messages
                                }
                            });
                            this.highlightIncompletedFields(value.messages);
                        } else {
                            this.currentNotice.state = "issued";
                            this.missionService.reloadNoticeList();
                        }
                    });
                }
            });
    }

    checkAndAsignURL(fieldName: string, field: any) {
        if (field != null && field.length > 0) {
            let noticeUrl: NoticeUrl = {
                type: fieldName as URLTypes,
                url: field
            };
            this.noticeUrls.push(noticeUrl);
        }
    }

    onChangeStartDate() {
        this.currentNotice.publicationDate = this.noticeStartDate.toISOString();
        this.scheduleSave();
    }

    onChangeEndDate() {
        this.currentNotice.deadline = this.noticeEndDate.toISOString();
        this.scheduleSave();
    }

    scheduleCheckDoublons() {
        let checkDoublonsButton = document.getElementById('checkDoublonsButton');
        checkDoublonsButton.classList.remove('doublonBG');
        checkDoublonsButton.classList.remove('notDoublonBG');
        var self = this;
        clearTimeout(this.checkDoublonsTimeOut);
        this.checkDoublonsTimeOut = setTimeout(function () {
            self.checkDoublons(checkDoublonsButton);
        }, 1000);
    }

    scheduleSourceSave(source, sourceListId) {
        var self = this;
        clearTimeout(this.saveSourceTimeOut);
        this.saveSourceTimeOut = setTimeout(function () {
            self.updateSource(source, sourceListId);
        }, 1000);
    }

    scheduleUrlsSave() {
        var self = this;
        clearTimeout(this.saveUrlsTimeOut);
        this.saveUrlsTimeOut = setTimeout(function () {
            self.updateUrls();
        }, 1000);
    }

    scheduleNoticeLockerTimeOut() {
        var self = this;
        this.noticeLockerTimeOut = setTimeout(function () {
            self.lockCurrentNotice();
        }, 25000);
    }

    scheduleSave() {
        var self = this;
        clearTimeout(this.saveTimeOut);
        this.saveTimeOut = setTimeout(function () {
            self.updateAo(false, false);
        }, 3000);
        this.isdNoticeUnsaved = true;
    }

    public getAndSetBuyer(resetCAndA) {
        if (this.currentNotice.commanditaire.buyerID != null) {
            if (this.isPublic) {
                this.firstAOInternServicesService.getCommanditaireInformationsResponse(this.currentNotice.commanditaire.buyerID)
                    .subscribe(
                        (buyer) => {
                            this.buyersContacts = buyer.body.contacts;
                            this.buyersAddresses = buyer.body.adresses;

                            if (this.buyersContacts == null)
                                this.buyersContacts = [];
                            if (this.buyersAddresses == null)
                                this.buyersAddresses = [];

                            // this.setNoticeAddressFromId();
                            // this.setNoticeContactFromId();

                            if (resetCAndA) {
                                this.currentNotice.commanditaire_contact = this.buyersContacts[0];
                                this.currentNotice.commanditaireContactId = this.buyersContacts[0].id;
                                this.currentNotice.commanditaire_adresse = this.buyersAddresses[0];
                                this.currentNotice.commanditaireAdresseId = this.buyersAddresses[0].id;
                                this.showLoading = false;
                                this.scheduleSave();
                            }
                        });
            } else {
                this.firstAOInternServicesService.getEntrepriseInformationsResponse(this.currentNotice.commanditaire.buyerID)
                    .subscribe(
                        (buyer) => {
                            this.buyersContacts = buyer.body.contacts;
                            this.buyersAddresses = buyer.body.adresses;

                            if (this.buyersContacts == null)
                                this.buyersContacts = [];
                            if (this.buyersAddresses == null)
                                this.buyersAddresses = [];

                            if (resetCAndA) {
                                this.currentNotice.commanditaire_contact = this.buyersContacts[0];
                                this.currentNotice.commanditaireContactId = this.buyersContacts[0].id;
                                this.currentNotice.commanditaire_adresse = this.buyersAddresses[0];
                                this.currentNotice.commanditaireAdresseId = this.buyersAddresses[0].id;
                                this.showLoading = false;
                                this.scheduleSave();
                            }
                        });
            }
        }
    }

    showLoadingFrame(value) {
        this.showLoading = value;
    }

    /**
     * Sources Methods START
     */

    addNewSource() {
        const aoSource: AoSources = {
            aoId: this.currentNotice.aoId,
            sourcesListeId: 1,
        };

        const params: EditorAddNoticeSourceParams = {
            noticeID: this.currentNotice.aoId,
            actualisSharedBeanFirstecoAoSources: aoSource
        };

        this.firstAOInternServicesService.editorAddNoticeSource(params).subscribe((value => {
            this.currentNotice.sources.push(value);
        }));
    }

    updateSource(source, sourceListId) {
        const aoSource: AoSources = {
            sourcesListeId: source.sourceListeId,
            urlSource: source.urlSource,
            reference: source.reference
        };

        const params: EditorEditNoticeSourceParams = {
            noticeID: this.currentNotice.aoId,
            noticeSourceID: source.id,
            actualisSharedBeanFirstecoAoSources: aoSource
        };

        this.firstAOInternServicesService.editorEditNoticeSource(params).subscribe((value => {
        }));
    }

    removeSource(source) {
        const params: EditorDeleteNoticeSourceParams = {
            noticeID: this.currentNotice.aoId,
            noticeSourceID: source.id,
        };

        this.firstAOInternServicesService.editorDeleteNoticeSource(params).subscribe((value => {
            this.removeSourceArray(source);
        }));
    }

    createNewSource() {
        const dialogRef = this.dialog.open(SourceAdderComponent, {
            data: {
                // attachments: this.noticeAttachement,
                notice: this.currentNotice,
                urlDocument: this.getUrlDce(),
                readOnly: this.readOnly,
                isPublic: this.isPublic
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != "") {
                const params: AoSourcesListe = {
                    libelle: result.libelle,
                    status: true,
                    typeSource: 'web',
                    urlPrincipal: result.url,
                    checkInterval: 0
                };

                this.showLoading = true;

                this.firstAOInternServicesService.editorSourcesAdd(params).subscribe(source => {
                    this.showLoading = false;
                    this.allSources.push(source);
                });
            }
        });
    }

    /**
     * Sources Methods END
     */


    /**
     * URLS Methods START
     */

    updateUrls() {

        // URLS
        this.noticeUrls = [];
        this.checkAndAsignURL("url_dce", this.currentNotice.urlDce);
        this.checkAndAsignURL("profil_acheteur", this.urlProfilAcheteur);
        this.checkAndAsignURL("url_information", this.urlInformation);
        this.checkAndAsignURL("url_participation", this.urlParticipation);
        this.checkAndAsignURL("url_document", this.urlDocument);
        this.checkAndAsignURL("url_outil_logiciel", this.urlOutilLogiciel);
        this.checkAndAsignURL("url", this.urlWebsite);
        this.checkAndAsignURL("url_technique", this.urlTechnique);
        this.checkAndAsignURL("url_envoi_offre", this.urlEnvoiOffre);
        this.checkAndAsignURL("url_renseignement", this.urlRenseignement);
        this.checkAndAsignURL("url_rfi", this.urlRfi);
        this.checkAndAsignURL("url_rfq", this.urlRfq);
        this.checkAndAsignURL("url_confidentialite", this.urlConfidentialite);

        const params: EditorEditNoticeUrlsParams = {
            noticeID: this.currentNotice.aoId,
            noticeUrl: this.noticeUrls
        };

        this.firstAOInternServicesService.editorEditNoticeUrls(params).subscribe((value => {
            value.length;
        }));
    }

    /**
     * URLS Methods END
     */

    // setNoticeAddressFromId() {
    //     for (let i = 0; i < this.buyersAddresses.length; i++) {
    //
    //     }
    //     this.buyersAddresses;
    //
    // }
    //
    // setNoticeContactFromId() {
    //     let isSearching = this.buyersContacts != null;
    //     let index = 0;
    //
    //     while (isSearching) {
    //         if (index <= this.buyersContacts.length) {
    //             if (this.buyersContacts[index].id === this.currentNotice.commanditaireContactId) {
    //                 this. = this.buyersAddresses[index];
    //                 isSearching = false;
    //             }
    //         } else {
    //             isSearching = false;
    //         }
    //         index++;
    //     }
    // }

    highlightIncompletedFields(fields) {
        if (fields != null) {
            if (this.incompletedFields.length > 0) {
                for (let i = 0; i < this.incompletedFields.length; i++) {
                    this.incompletedFields[i].classList.remove('incompletedField');
                }
            }
            this.incompletedFields = [];
            fields.toString();
            for (let i = 0; i < fields.length; i++) {
                let field;
                switch (fields[i].content) {
                    case "L'acheteur est manquant.":
                        field = document.getElementById('buyerProfil');
                        break;
                    case "La nature de l'avis est manquante.":
                        field = document.getElementById('natureAvisSel');
                        break;
                    case "Le statut de l'avis est manquant.":
                        field = document.getElementById('statutSel');
                        break;
                    case "La famille de l'avis est manquante.":
                        field = document.getElementById('familleAvisSel');
                        break;
                    case "Le type de procédure de l'avis est manquant.":
                        field = document.getElementById('typeProcedureSel');
                        break;
                    case "Le type de marché de l'avis est manquant.":
                        field = document.getElementById('typeMarcheSel');
                        break;
                    case "Le contact du commanditaire est manquant.":
                        field = document.getElementById('buyerProfilContact');
                        break;
                    case "Les informations du contact du commanditaire sont incomplètes.":
                        field = document.getElementById('buyerProfilContact');
                        break;
                    case "L'adresse du commanditaire est manquante.":
                        field = document.getElementById('buyerProfilAddress');
                        break;
                    case "Les informations de l'adresse du commanditaire sont incomplètes.":
                        field = document.getElementById('buyerProfilAddress');
                        break;
                    case "La date limite de réponse est manquante.":
                        field = document.getElementById('deadlineDP');
                        break;
                    case "La date de publication est manquante.":
                        field = document.getElementById('publicationDateDP');
                        break;
                    case "La date de publication doit être inférieure à la date limite de réponse.":
                        field = document.getElementById('publicationDateDP');
                        break;
                    case "Le titre est manquant.":
                        field = document.getElementById('noticeTitle');
                        break;
                    case "L'objet complet est manquant.":
                        field = document.getElementById('noticeCorps');
                        break;
                    case "Le résumé est manquant.":
                        field = document.getElementById('noticeResumeEdito');
                        break;
                    case "Il faut au moins définir un département.":
                        field = document.getElementById('departementsId');
                        break;
                    case "L'indexation de l'avis est manquante.":
                        field = document.getElementById('cpvCodesId');
                        break;
                    case "Il faut au moins une source d'où vient l'avis.":
                        field = document.getElementById('sourcesContainer');
                        break;
                    case "La présence d'au moins une URL associée à l'avis de marché est requise.":
                        field = document.getElementById('urlsContainer');
                        break;
                    default:
                        if (fields[i].content.contains('lieux associés sont vides.')
                            || fields[i].content.contains('lieu associé est vide.')) {
                            field = document.getElementById('lieuxButton');
                        } else if (fields[i].content.contains('lot associé est vide.')
                            || fields[i].content.contains('lots associés sont vides.')) {
                            field = document.getElementById('lotsButton');
                        } else if (fields[i].content.contains('source associée est incorrect.')
                            || fields[i].content.contains('sources associées sont incorrectes.')) {
                            field = document.getElementById('sourcesContainer');
                        }
                        break;
                }
                if (field != null) {
                    field.classList.add('incompletedField');
                    this.incompletedFields.push(field);
                }
            }
        }
    }


    /**
     * Array Methods START
     */

    removeSourceArray($event) {
        let isSearching = this.currentNotice.sources != null;
        let index = 0;

        while (isSearching) {
            if (index <= this.currentNotice.sources.length) {
                if (this.currentNotice.sources[index].noticeSourceID == $event.noticeSourceID) {
                    this.currentNotice.sources.splice(index, 1);
                    isSearching = false;
                }
            } else {
                isSearching = false;
            }
            index++;
        }
        this.scheduleSave();
    }

    isCurrentNoticeLocked(values) {
        let isSearching: boolean = true;
        let index = 0;
        let locked = false;

        while (isSearching) {
            if (index < values.length) {
                if (values[index] == this.currentNotice.aoId) {
                    isSearching = false;
                    locked = true;
                }
            } else {
                isSearching = false;
            }
            index++;
        }

        return locked;
    }


    /**
     * Array Methods END
     * **/

    goToLink(url: string) {
        window.open(url, "_blank");
    }
}
