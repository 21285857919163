import {Component, OnInit} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../api/gen/services/first-aointern-services.service";
import {MissionService} from "../../mission.service";
import {QueryCriterions} from "../../api/gen/models/query-criterions";
import EditorSearchNoticesParams = FirstAOInternServicesService.EditorSearchNoticesParams;
import {DatePipe} from "@angular/common";
import {NoticeFacade} from "../../api/gen/models/notice-facade";
// import {BuyerSuggestion} from "../../api/gen/models/buyer-suggestion";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BuyersSuggestion} from "../../api/gen/models/buyers-suggestion";
import {BuyerCriterion} from "../../api/gen/models/buyer-criterion";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
    providers: [DatePipe]
})
export class SearchComponent implements OnInit {

    showLoading = false;
    showEditorLoading = false;
    isPublic = false;

    private currentHttpRequest;
    currentNoticeId = null;

    notices: NoticeFacade[] = [];
    search: string = "";
    private searchTimeOut;

    // Critères de recherche
    searchedAoId: number;
    searchedRefSource: string = "";
    searchedTitle: string = "";
    searchedCpvs: any[] = [];
    searchedDepartements: any[] = [];
    // searchedAcheteurs: BuyerSuggestion[] = [];
    searchedAcheteurs: BuyerCriterion[] = [];
    searchedSPublicationDate: Date;
    searchedEPublicationDate: Date;
    searchedSLimitDate: Date;
    searchedELimitDate: Date;
    searchedTypeMarche: string[] = [];
    searchedWithLots: boolean = false;
    searchedResume: string = "";
    searchedCorps: string = "";
    searchedWithLotsAllowed = false;

    currentPage: number = 0;
    totalNotice: number = 0;


    constructor(private datePipe: DatePipe,
                public dialog: MatDialog,
                private firstAOInternServicesService: FirstAOInternServicesService,
                private missionService: MissionService,
                private _snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    openNotice(event) {
        this.isPublic = event.typeAo == "public";
        this.currentNoticeId = event.aoId;
        this.missionService.changeCurrentNoticeId(event.aoId);
    }

    changePagination($event) {
        this.currentPage = $event.pageIndex + 1;
        this.searchNotices(false);
    }

    searchNotices(resetPagination : boolean) {
        this.currentNoticeId = null;
        this.notices = [];
        if (resetPagination)
            this.currentPage = 0;

        this.showEditorLoading = false;
        if (this.currentHttpRequest != null)
            this.currentHttpRequest.unsubscribe();

        this.showLoading = true;

        if (this.searchedAoId != null && this.isNumber(this.searchedAoId)) {
            this.firstAOInternServicesService.editorShowAo(Number(this.searchedAoId)).subscribe(value => {
                this.notices = [value];
                this.showLoading = false;
                this.totalNotice = 1;
            }, error => {
                this.showLoading = false;
                this._snackBar.open("Aucun Article trouvé pour l'identifiant " + this.searchedAoId, "Fermer", {duration: 5000});
            });
        } else {
            let queryCriterions: QueryCriterions = {};
            if (this.searchedCpvs.length > 0) {
                queryCriterions.activityArea = [];
                this.searchedCpvs.forEach(value => {
                    queryCriterions.activityArea.push(value.cpv);
                });
            }
            if (this.searchedDepartements.length > 0) {
                queryCriterions.localisation = [];
                this.searchedDepartements.forEach(value => {
                    queryCriterions.localisation.push(value.zip);
                });
            }
            if (this.searchedTypeMarche.length > 0)
                queryCriterions.marketType = this.searchedTypeMarche;

            let acheteursInlined = "";
            let firstAcheteur = true;
            this.searchedAcheteurs.forEach(acheteur => {
                if (firstAcheteur)
                    firstAcheteur = false;
                else
                    acheteursInlined += ",";
                acheteursInlined += acheteur.buyerID;
            });

            let params :EditorSearchNoticesParams = {
                queryCriterions: queryCriterions,
                page: this.currentPage,
                pageSize: 25
            };
            if (this.searchedWithLotsAllowed)
                params.alloti = this.searchedWithLots;
            if (this.searchedTitle.length > 0)
                params.title = this.searchedTitle;
            if (acheteursInlined.length > 0)
                params.buyers = acheteursInlined;
            if (this.searchedSPublicationDate != null)
                params.sdate = this.datePipe.transform(this.searchedSPublicationDate, "yyyy-MM-dd");
            else {
                let dateFormOneYear: Date = new Date(-365);
                params.sdate = this.datePipe.transform(dateFormOneYear, "yyyy-MM-dd");
            }
            if (this.searchedEPublicationDate != null)
                params.edate = this.datePipe.transform(this.searchedEPublicationDate, "yyyy-MM-dd");
            if (this.searchedSLimitDate != null)
                params.sdeadlinedate = this.datePipe.transform(this.searchedSLimitDate, "yyyy-MM-dd");
            if (this.searchedELimitDate != null)
                params.edeadlinedate = this.datePipe.transform(this.searchedELimitDate, "yyyy-MM-dd");
            if (this.searchedRefSource.length > 0)
                params.reference = this.searchedRefSource;
            if (this.searchedResume.length > 0)
                params.resume = this.searchedResume;
            if (this.searchedCorps.length > 0)
                params.content = this.searchedCorps;

            this.firstAOInternServicesService.editorSearchNotices(params).subscribe(values => {
                if (values != null && values.count > 0) {
                    this.notices = values.notices;
                    this.totalNotice = values.count;
                } else {
                    this._snackBar.open("Aucun article trouvé pour les critères de recherche donnés", "Fermer", {duration: 5000});
                }
                this.showLoading = false;
            });
        }
    }

    isNumber(search): boolean {
        return !isNaN(search);
    }

    resetSearchParams() {
        this.notices = [];
        this.currentPage = 0;
        this.totalNotice = 0;
        this.searchedAoId = null;
        this.searchedRefSource = "";
        this.searchedTitle = "";
        this.searchedCpvs = [];
        this.searchedDepartements = [];
        this.searchedAcheteurs = [];
        this.searchedSPublicationDate = null;
        this.searchedEPublicationDate = null;
        this.searchedSLimitDate = null;
        this.searchedELimitDate = null;
        this.searchedTypeMarche = [];
        this.searchedWithLots = false;
        this.searchedWithLotsAllowed = false;
        this.searchedResume = "";
        this.searchedCorps = "";
        this.currentNoticeId = null;
    }
}
