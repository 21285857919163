import { Injectable, Input, Directive } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {FirstAOCustomerServicesService} from "../api/gen/services/first-aocustomer-services.service";
import {MissionService} from "../mission.service";
import {UserSession} from "../commons/user-session";
// import {AppData} from '../shared/services/fao-data.service';
// import {ApiService} from '../shared/services/fao.api.service';
// import {AppLoaderService} from '../shared/services/app-loader/app-loader.service';

@Directive()
@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    // @Input() _appData: AppData;

    constructor(
        private router: Router,
        // private appData: AppData,
        // private apiService: ApiService,
        private route: ActivatedRoute,
        private api: FirstAOCustomerServicesService,
        private missionService: MissionService
        // private loaderService: AppLoaderService
    ) {
        // this._appData = appData;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return !this.needToRelog(state);
        // if (this.needToRelog(state)) {
        //     this.router.navigate(['/login']);
        //     return false;
        // } else {
        //     return true;
        // }
    }

    needToRelog(state: RouterStateSnapshot) {
        let session = JSON.parse(localStorage.getItem('redacSession'));
        if (session && session["timeLimit"]) {
            if (new Date().getTime() < session["timeLimit"] && session && this.missionService.userSession) {
                if (this.missionService.userSession.gotAllRights)
                    return false
                else {
                    if (state.url == "/" || state.url == "/ao" || state.url == "/login")
                        return false;
                    else {
                        this.router.navigate(['/']);
                        return true;
                    }
                }
            } else {
                this.api.getUserSession().subscribe(value => {
                    let redacSession = {"token" : session["token"], "timeLimit" : new Date().getTime() + 3600000};
                    this.missionService.userSession = new UserSession();
                    this.missionService.userSession.gotAllRights = !value.user.is_isahit;
                    this.missionService.userSession.userName = value.user.firstname;
                    localStorage.setItem('redacSession', JSON.stringify(redacSession));
                    if (this.missionService.userSession.gotAllRights) {
                        return false
                    } else {
                        if (state.url == "/" || state.url == "/ao" || state.url == "/login")
                            return false;
                        else {
                            this.router.navigate(['/']);
                            return true;
                        }
                    }
                }, error => {
                    this.router.navigate(['/login']);
                    return true;
                });
            }
        } else {
            this.router.navigate(['/login']);
            return true;
        }
    }
}
