import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MissionService} from "../../mission.service";
import {FirstAOInternServicesService} from "../../api/gen/services";
import {NoticeFacade} from "../../api/gen/models/notice-facade";
import { MatDialog } from "@angular/material/dialog";
import {AoListComponent} from "../../widgets/ao-list/ao-list.component";

@Component({
    selector: 'app-public-redaction',
    templateUrl: './public-redaction.component.html',
    styleUrls: ['./public-redaction.component.css']
})
export class PublicRedactionComponent implements OnInit {

    subscription: Subscription;
    currentNoticeId = null;

    @ViewChild(AoListComponent)
    private aoList: AoListComponent;

    opened = true;

    constructor(private missionService: MissionService,
                private firstAOInternServicesService: FirstAOInternServicesService,
                public dialog: MatDialog) {
        this.subscription = missionService.currentNoticeId$.subscribe(
            value => {
                this.currentNoticeId = value;
            });
    }

    ngOnInit() {
    }

    changePagination(event) {
        this.missionService.changePagination(event.pageSize, event.pageIndex, true);
    }

    addNewPublicAO() {
        const param: NoticeFacade = {
            titre: "Nouvel Appel d'Offres",
            typeAo: "public",
            familleAvis: "fns"
        };

        this.firstAOInternServicesService.editorAddNotice(param).subscribe(value => {
            this.currentNoticeId = value.aoId;
            this.aoList.reloadNoticesAndOpenNotice(this.currentNoticeId);
        });
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.subscription.unsubscribe();
    }
}
