import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {SnackbarService} from "./snackbar.service";
import {FirstAOCustomerServicesService} from "../api/gen/services";
import {AppLoaderService} from "./app-loader/app-loader.service";

@Injectable()
export class AuthService {

    constructor(private loaderService: AppLoaderService,
                private router: Router,
                private snackbarService: SnackbarService,
                private api: FirstAOCustomerServicesService) {

    }

    /**
     * Function to log an user
     * @param username
     * @param password
     */
    login(username: string, password: string) {
        // Create token
        const token = 'Basic ' + btoa(username + ':' + password);

        // Check if token is valid
        let redacSession = {"token" : token};
        localStorage.setItem('redacSession', JSON.stringify(redacSession));
        const check = this.api.getUserSession();
        if (check !== undefined) {
            // Set token to te sessionStorage

            this.loaderService.open();
            check.subscribe(
                () => {   // Valide token
                    let redacSession = {"token" : token, "timeLimit" : new Date().getTime() + 60000};
                    localStorage.setItem('redacSession', JSON.stringify(redacSession));
                    this.router.navigate(['/']);
                    this.loaderService.close();
                    // Redirect user if wizard is finished
                    // this.appData.load().then(() => {
                    //     if (this.appData.session.user.main_filter === null) {
                    //         console.log('redirest to preshapping');
                    //         this.router.navigate(['pre-shaping/activity-area']).then(() => {
                    //         });
                    //     } else {
                    //         console.log('redirect to actuality');
                    //         this.router.navigate(['a-o-actuality']).then(() => {
                    //         });
                    //     }
                    // });
                },
                error => {
                    this.snackbarService.openSnackBar('Compte/mot de passe erroné', 'danger', '', 5000);
                    this.loaderService.close();
                }
            );
        } else {

        }
    }



    logout() {
        localStorage.removeItem('redacToken');
    }

    /**
     * Function resetToCustomerProfile user data
     * @param username
     * @param password
     */
    resetUser(username: string, password: string) {
        // Create token
        const token = 'Basic ' + btoa(username + ':' + password);
        // Set token to te sessionStorage
        localStorage.setItem('redacToken', token);

        // Check if token is valid
        const resetFilterMain = this.api.editMainFilter(new class implements FirstAOCustomerServicesService.EditMainFilterParams {
            queryCriterions = null;
            reset = true;
        });
        if (resetFilterMain !== undefined) {
            resetFilterMain.subscribe(filter => {
                    // this.appData.setCustomerProfile(filter);

                    this.snackbarService.openSnackBar('Utilisateur a été ré-initialisé');
                    // Reset token
                    // this.apiService.setToken(null);
                }
            );
        }
    }
}
