import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommanditaireContactFacade} from "../../../api/gen/models/commanditaire-contact-facade";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FirstAOInternServicesService} from "../../../api/gen/services/first-aointern-services.service";
import {CommanditaireContact} from "../../../api/gen/models/commanditaire-contact";

@Component({
    selector: 'app-contact-manager',
    templateUrl: './contact-manager.component.html',
    styleUrls: ['./contact-manager.component.css']
})
export class ContactManagerComponent implements OnInit {

    // private contact: CommanditaireContactFacade;
    private title: string;
    private buyerId: number;
    showLoading: boolean;
    private isCreating: boolean;
    private isPublic: boolean;

    private contactId;
    private nom;
    private email;
    private telephone;
    private fax;
    private service;
    private linkedin_profil;
    private viadeo_profil;

    constructor(private dialogRef: MatDialogRef<ContactManagerComponent>,
                private firstAOInternServicesService: FirstAOInternServicesService,
                @Inject(MAT_DIALOG_DATA) public data) {
        if (data.contact != null)
        {
            this.contactId = data.contact.id;
            this.nom = data.contact.nom;
            this.email = data.contact.email;
            this.telephone = data.contact.telephone;
            this.fax = data.contact.fax;
            this.service = data.contact.service;
            this.linkedin_profil = data.contact.linkedin_profil;
            this.viadeo_profil = data.contact.viadeo_profil;
        }
        this.isPublic = data.isPublic;
        this.title = data.title;
        this.buyerId = data.buyerId;
        this.isCreating = data.isCreating;
        this.showLoading = false;
    }

    ngOnInit() {
    }

    closeAndSave() {
        this.dialogRef.disableClose = true;
        this.showLoading = true;

        const commanditaireContact: CommanditaireContact = {
            email: this.email,
            fax: this.fax,
            service: this.service,
            linkedin_profil: this.linkedin_profil,
            viadeo_profil: this.viadeo_profil,
            nom: this.nom,
            telephone: this.telephone,
            status: 1
        };

        if (this.isCreating) {
            if (this.isPublic) {
                const params: FirstAOInternServicesService.CreateCommanditaireContactsParams = {
                    commanditaireContact: commanditaireContact,
                    buyerID: this.buyerId
                };

                this.firstAOInternServicesService.createCommanditaireContacts(params).subscribe(value => {
                    this.dialogRef.close({
                        contact: value
                    });
                });
            } else {
                const params: FirstAOInternServicesService.CreateEntrepriseContactsParams = {
                    commanditaireContact: commanditaireContact,
                    buyerID: this.buyerId
                };

                this.firstAOInternServicesService.createEntrepriseContacts(params).subscribe(value => {
                    this.dialogRef.close({
                        contact: value
                    });
                });
            }
        } else {
            if (this.isPublic) {
                const params: FirstAOInternServicesService.EditCommanditaireContactsParams = {
                    contactID: this.contactId,
                    commanditaireContact: commanditaireContact,
                    buyerID: this.buyerId
                };

                this.firstAOInternServicesService.editCommanditaireContacts(params).subscribe(value => {
                    this.dialogRef.close({
                        contact: value
                    });
                });
            } else {
                const params: FirstAOInternServicesService.EditEntrepriseContactsParams = {
                    contactID: this.contactId,
                    commanditaireContact: commanditaireContact,
                    buyerID: this.buyerId
                };

                this.firstAOInternServicesService.editEntrepriseContacts(params).subscribe(value => {
                    this.dialogRef.close({
                        contact: value
                    });
                });
            }
        }
    }

}
