<div style="overflow: auto; width: fit-content; max-height: 800px;">
    <h2 style="margin-top: 0; font-weight: bold;">Doublons potentiels :</h2>

    <div class="doublonLine" [class.even]="even" [class.odd]="!even"
         *ngFor="let potentialDoublon of data.potentialDoublons">
        <div>{{potentialDoublon.title}}</div>
        <div style="margin-top: 10px;"><span style="font-weight: bold;">ID :</span> {{potentialDoublon.aoId}}  -  <span style="font-weight: bold;">
            Date de publication : </span> {{potentialDoublon.publicationDate}}  -  <span style="font-weight: bold;">
            Commanditaire :</span> {{potentialDoublon.commanditaire_name}}
            <button style="padding: 3px 7px; margin-left: 5px;" (click)="openDoublon(potentialDoublon.aoId)">Ouvrir</button>
        </div>
        {{updateEven()}}

    </div>
</div>
