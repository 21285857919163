import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {MissionService} from "../mission.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private route: ActivatedRoute,
                private missionService: MissionService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Apply the headers
        let responseType: ResponseType;

        if (req.url.includes('/attachments/') && req.method == 'GET') {
            responseType = 'blob';
        } else {
            responseType = 'json';
        }

        let session = JSON.parse(localStorage.getItem('redacSession'));
        if (session["token"] != null) {
            let token: string = session["token"];
        // let token = 'Basic anVsaWUuZ3VpbGxvbkBmaXJzdGFvLmZyOmFjdHVhbGlz';
            let searchedValue = "//api";
            let replacedValue = "https://api";
            if (req.url.includes("stage")) {
                searchedValue = "//stage";
                replacedValue = "https://stage"
            }

            req = req.clone({
                url: req.url.replace(searchedValue, replacedValue),
                responseType: responseType,
                setHeaders: {
                    // 'Content-Type': 'application/json',
                    'Authorization': token,
                    'Access-Control-Allow-Origin': '*'
                }
            });

            // Also handle errors globally
            return next.handle(req).pipe(
                tap(x => x, err => {
                    // Handle this err
                    console.error(`Error performing request, status code = ${err.status}`);
                })
            );

        } else
            return next.handle(req).pipe(
                tap(x => x, err => {
                    // Handle this err
                    console.error(`Error performing request, status code = ${err.status}`);
                }));
    }
}

export type ResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

// Plus d'informations : https://www.npmjs.com/package/ng-swagger-gen
