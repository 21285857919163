import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-notice-adder',
    templateUrl: './notice-adder.component.html',
    styleUrls: ['./notice-adder.component.css']
})
export class NoticeAdderComponent implements OnInit {

    noticeTitle;

    constructor(public dialogRef: MatDialogRef<NoticeAdderComponent>) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close({
            title: this.noticeTitle,
        });
    }

}
