import {Component, Input, OnInit} from '@angular/core';
import {AoApiService} from '../../api/ao-api.service';
import {MissionService} from "../../mission.service";
import {Observable, Subscription} from "rxjs";
import {FirstAOInternServicesService} from "../../api/gen/services/first-aointern-services.service";
import EditorSearchNoticesParams = FirstAOInternServicesService.EditorSearchNoticesParams;
import EditorNoticesLastAddedParams = FirstAOInternServicesService.EditorNoticesLastAddedParams;
import {NoticeFacade} from "../../api/gen/models/notice-facade";
import EditorNoticesToProcessParams = FirstAOInternServicesService.EditorNoticesToProcessParams;
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-ao-list',
    templateUrl: './ao-list.component.html',
    styleUrls: ['./ao-list.component.css'],
    providers: [DatePipe]
})
export class AoListComponent implements OnInit {
    // products = products;

    private _processMode: boolean = true;

    get processMode(): boolean {
        return this._processMode;
    }

    @Input("processMode")
    set processMode(val: boolean) {
        if (this.collectedMode) {
            this._processMode = val;
            this.loadNotices();
        }
    }

    @Input('currentTab') currentTab: string;
    @Input('typeMode') typeMode: string;
    @Input('collectedMode') collectedMode: boolean = false;
    @Input('showDCE') showDCE: boolean;

    notices;
    subscription: Subscription;
    highlightNewNotice: boolean;
    noticeIdAdded;

    startDate = new Date();
    endDate;

    constructor(private datePipe: DatePipe,
                private apiService: AoApiService,
                private missionService: MissionService,
                private firstAOInternServicesService: FirstAOInternServicesService) {
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate() + 1);
    }

    ngOnInit() {
        this.subscription = this.missionService.paginationData$.subscribe(
            value => {
                if(value.isFromUser())
                    this.loadNotices();
            });
        this.subscription = this.missionService.currentNoticeId$.subscribe(
            value => {
                let elems = document.getElementsByClassName('notice');

                [].forEach.call(elems, function(el) {
                    el.classList.remove("selectedNotice");
                });

                if (value != null) {
                    let noticeToHighlight = document.getElementById('not' + value);
                    if (noticeToHighlight != null)
                        noticeToHighlight.classList.add('selectedNotice');
                }
            }
        );
        this.missionService.reloadNoticeList$.subscribe(
            value => {
                this.loadNotices();
            }
        );
        this.loadNotices();
    }

    public loadNotices() {

        let currentPage = this.missionService.getPaginationData().getPageIndex();
        let currentPageSize = this.missionService.getPaginationData().getPageSize();

        if (this.collectedMode) {
            let params: EditorNoticesToProcessParams = {
                page: currentPage,
                pageSize: currentPageSize,
                redactionToProcess: this.processMode,
                sdate: this.datePipe.transform(this.startDate, "yyyy-MM-dd"),
                edate: this.datePipe.transform(this.endDate, "yyyy-MM-dd")
            };

            this.firstAOInternServicesService.editorNoticesToProcess(params).subscribe( (notices) => {
                this.notices = notices.notices;
            });
        } else {
            this.firstAOInternServicesService.editorNoticesLastAdded(
                this.getNoticeLastAddedParams(currentPage, currentPageSize)).subscribe((notices) => {
                this.notices = notices.notices;
            });
        }
    }

    public reloadNoticesAndOpenNotice(noticeId) {
        this.noticeIdAdded = noticeId;
        let currentPage = this.missionService.getPaginationData().getPageIndex();
        let currentPageSize = this.missionService.getPaginationData().getPageSize();

        this.missionService.setIsNewNotice(true);

        if (this.typeMode != null) {
            this.firstAOInternServicesService.editorNoticesLastAdded(
                this.getNoticeLastAddedParams(currentPage, currentPageSize)).subscribe((notices) => {
                this.notices = notices.notices;
                this.highlightNewNotice = true;
                this.openNotice(noticeId);
            });
        } else {
            let params: EditorSearchNoticesParams = {
                page: currentPage,
                pageSize: currentPageSize
            };

            this.firstAOInternServicesService.editorNoticesToProcess(params).subscribe( (notices) => {
                this.notices = notices.notices;
                this.highlightNewNotice = true;
                this.openNotice(noticeId);
            });
        }
    }

    openNotice(event) {
        this.missionService.changeCurrentNoticeId(event);
    }

    highlightNotice() {
        if (this.noticeIdAdded != null) {
            let noticeToHighlight = document.getElementById('not' + this.noticeIdAdded);
            if (noticeToHighlight != null) {
                noticeToHighlight.classList.add('selectedNotice');
                this.noticeIdAdded = null;
                // this.highlightNewNotice = false;
            }
        }
    }

    checkIfUrlDceExists(notice: NoticeFacade) {
        if (notice.urls != null) {
            for (let i = 0; i < notice.urls.length; i++) {
                if (notice.urls[i].type == "url_dce" && notice.urls[i].libelle != null && notice.urls[i].libelle.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    getNoticeLastAddedParams(currentPage, currentPageSize) {
        if (this.typeMode == "public") {
            const editorNoticesLastAddedParams: EditorNoticesLastAddedParams = {
                page: currentPage,
                pageSize: currentPageSize,
                type: 'public',
                sdate: this.datePipe.transform(this.startDate, "yyyy-MM-dd"),
                edate: this.datePipe.transform(this.endDate, "yyyy-MM-dd")
            };
            return editorNoticesLastAddedParams;
        } else {
            const editorNoticesLastAddedParams: EditorNoticesLastAddedParams = {
                page: currentPage,
                pageSize: currentPageSize,
                type: 'private',
                sdate: this.datePipe.transform(this.startDate, "yyyy-MM-dd"),
                edate: this.datePipe.transform(this.endDate, "yyyy-MM-dd")
            };

            return editorNoticesLastAddedParams;
        }
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.subscription.unsubscribe();
    }
}


