<div class="formContainer">
    <form [formGroup]="signupForm"
          class="signup-form"
          fxLayout="column"
          (ngSubmit)="onSignIn()">
        <!-- headline -->
        <div class="form-headline text-center mb-32">
            <h1 class="font-weight-light">Connectez-vous à votre compte</h1>
        </div>
        <div class="mb-48" fxLayout="row wrap" fxLayoutAlign="center center">
            <img
                    width="200px"
                    src="assets/images/illustrations/breaking_barriers.svg"
                    alt=""
            />
        </div>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input
                    matInput
                    formControlName="email"
                    type="email"
                    name="email"
                    placeholder="Email"
            />
            <mat-error *ngIf="submitted && f.email.errors">
                <div *ngIf="f.email.errors.required">L'email est requis</div>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Mot de passe</mat-label>
            <input
                    matInput
                    formControlName="password"
                    type="password"
                    name="password"
                    placeholder="********"
            />
            <mat-error *ngIf="submitted && f.password.errors">
                <div *ngIf="f.password.errors.required">Le mot de passe est requis</div>
            </mat-error>
        </mat-form-field>

        <button color="primary" class="mt-24 loginFormBtn">Se connecter</button>

<!--        <mat-divider class="my-32"></mat-divider>-->

        <!--        <div class="mb-24" fxLayout="column" fxLayoutGap="16px">-->
        <!--            <button mat-raised-button-->
        <!--                    color="warn"-->
        <!--                    type="button"-->
        <!--                    (click)="clear_user_test()">-->
        <!--                Re-initialiser le compte-->
        <!--            </button>-->
        <!--        </div>-->
    </form>
</div>

