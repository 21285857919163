<div *ngIf="!showLoading else loading">

    <h2 style="margin-top: 0; font-weight: bold;">{{title}}</h2>
    <div>
        <mat-form-field class="inputContainer margR10">
            <input matInput placeholder="Nom Prénom" type="text" [(ngModel)]="nom">
        </mat-form-field>

        <mat-form-field class="inputContainer">
            <input matInput placeholder="Adresse mail" type="text" [(ngModel)]="email">
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="inputContainer margR10">
            <input matInput placeholder="Téléphone" type="text" [(ngModel)]="telephone">
        </mat-form-field>

        <mat-form-field *ngIf="isPublic" class="inputContainer">
            <input matInput placeholder="Fax" type="text" [(ngModel)]="fax">
        </mat-form-field>

        <mat-form-field *ngIf="!isPublic" class="inputContainer">
            <input matInput placeholder="Fonction" type="text" [(ngModel)]="service">
        </mat-form-field>
    </div>

    <div>
        <mat-form-field *ngIf="!isPublic" class="inputContainer margR10">
            <input matInput placeholder="Lien LinkedIn" type="text" [(ngModel)]="linkedin_profil">
        </mat-form-field>

        <mat-form-field *ngIf="!isPublic" class="inputContainer">
            <input matInput placeholder="Lien Viadeo" type="text" [(ngModel)]="viadeo_profil">
        </mat-form-field>
    </div>

    <div>
        <button class="saveBtn" (click)="closeAndSave()" id="closeAndSaveBuyerButton">Enregistrer les modifications
        </button>
        <button class="cancelBtn" mat-dialog-close id="closeAndCancelBuyerButton">Annuler les modifications</button>
    </div>

</div>

<ng-template #loading>
    <div style="height: 100%">
        <mat-spinner style="margin: auto;"></mat-spinner>
    </div>
</ng-template>