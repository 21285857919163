<div>
    <mat-label style="display: block; font-size: 25px; margin-bottom: 10px; text-align: center;">
        Cette Action est irrémédiable !
    </mat-label>
    <mat-label style="display: block; font-size: 20px; margin-bottom: 20px; text-align: center;">
        Voulez vous valider ce choix ?
    </mat-label>

    <div>
        <button (click)="cancel()" style="background: darkred; float: right;">Annuler</button>
        <button (click)="accept()" style="background: green; float: left;">Accepter</button>
    </div>
</div>
