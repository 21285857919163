<mat-form-field class="example-chip-list" style="width: 100%;">
  <mat-chip-list #chipList>
    <mat-chip
            *ngFor="let nutsCode of nutsCodes"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(nutsCode)">
      {{nutsCode.libelle}} ({{nutsCode.nutsCode}} / {{nutsCode.type}})
      <mat-icon matChipRemove *ngIf="!readOnly">cancel</mat-icon>
    </mat-chip>
    <input [readonly]="readOnly"
           placeholder="Codes Nuts"
           [(ngModel)]="search"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           #nutsCodesInput
           (ngModelChange)="scheduleSearch()">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let nutsCode of nutsCodesSearched" [value]="nutsCode">
      {{nutsCode.libelle}} ({{nutsCode.nutsCode}} / {{nutsCode.type}})
    </mat-option>
    <mat-option *ngIf="isSearching" style="height: 125px;"><mat-spinner style="margin: auto;"></mat-spinner></mat-option>
  </mat-autocomplete>
</mat-form-field>
