<div class="sourceMainContainer">
    <div class="sourcesList">
        <div class="sourceItemContainer">
            <div id="source-{{source.id}}" class="noselect source" *ngFor="let source of allSources; let last = last">
                <a  (click)="openSource(source)">
                    <div class="noselect">{{source.libelle}}</div>
                </a>
                <ng-container *ngIf="last">
                {{ highlightSelectedSource() }}
            </ng-container>
            </div>

        </div>
        <button class="addBtn" (click)="addNewSource()">Ajouter une source</button>
    </div>

    <div class="sourceEditorContainer">

        <div class="sourceEditor" *ngIf="currentSource && !showLoading">

            <button class="editBtn" *ngIf="readonly" (click)="editSource()">Modifier</button>
            <button class="saveBtn" *ngIf="!readonly" (click)="saveSource()">Enregistrer</button>

            <div class="editorLine">
                <div class="editorLineLibelle">Libellé</div>
                <div class="editorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="readonly" matInput [(ngModel)]="currentSource.libelle">
                    </mat-form-field>
                </div>
            </div>

            <div class="editorLine">
                <div class="editorLineLibelle">URL</div>
                <div class="editorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="readonly" matInput [(ngModel)]="currentSource.urlPrincipal">
                    </mat-form-field>
                </div>
            </div>

            <div class="editorLine" style="margin-top: 60px;">
                <div class="editorLineLibelle">Statut</div>
                <div class="editorLineInput">
                    <mat-slide-toggle [disabled]="readonly" matInput [(ngModel)]="currentSource.status"></mat-slide-toggle>
                </div>
            </div>

        </div>

        <div *ngIf="showLoading">
            <div style="height: 100%">
                <mat-spinner style="margin: auto;"></mat-spinner>
            </div>
        </div>

    </div>
</div>