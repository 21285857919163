<div class="lieuxManager">

    <h2 mat-dialog-title>Gestion des lieux</h2>

    <div class="lieuxManagerContent">

        <div class="leftLieuxPart">

            <div class="lieuxList">
                <div class="noselect" *ngFor="let lieu of data.lieux">
                    <a (click)="openLieu(lieu)">
                        <div id="lieu{{lieu.noticeLieuID}}" class="notice noselect">{{lieu.city}}</div>
                    </a>
                </div>
            </div>

            <button id="addLieuButton" *ngIf="!readOnly" (click)="addNewLieu()">
                <mat-icon aria-hidden="false" class="addCircle">add_circle</mat-icon>
                Ajouter un lieu
            </button>

        </div>

        <div class="rightLieuxPart" *ngIf="currentLieu != null">
            <!--<app-cpv-code-chooser [cpvCodes]="currentCPVCodes" [readonly]="!lieuEditing"></app-cpv-code-chooser>-->

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Adresse :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!lieuEditing" matInput [(ngModel)]="currentLieu.adresse">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Ville :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!lieuEditing" matInput [(ngModel)]="currentLieu.city">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">ZIP :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!lieuEditing" matInput [(ngModel)]="currentLieu.zip">
                    </mat-form-field>
                </div>
            </div>

            <div class="rightLieuLine">
                <div class="lieuEditorLineLibelle">Code NUTS :</div>
                <div class="lieuEditorLineInput">
                    <mat-form-field style="width: 100%;">
                        <input [readonly]="!lieuEditing" matInput [(ngModel)]="currentLieu.codeNuts">
                    </mat-form-field>
                </div>
            </div>


            <div class="rightLieuLine" *ngIf="!readOnly" style="display: block;">

                <button *ngIf="!lieuEditing" (click)="editLieu()" id="editLieuButton">
                    Modifier le lieu
                </button>
                <button class="saveBtn" *ngIf="lieuEditing" (click)="saveLieuModification()" id="saveLieuButton">
                    Enregistrer
                </button>
                <button class="cancelButton" style="float: right;" (click)="deleteLieu()" id="deleteLieuButton">
                    Supprimer le lieu
                </button>


            </div>

            <!--codeNuts: "FR53,FR58"-->
            <!--cpv: "29926778,23074284,81666903,32245596"-->

        </div>

    </div>

</div>