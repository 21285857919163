import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FirstAOCustomerServicesService} from "../../../api/gen/services/first-aocustomer-services.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import GetNomenclatureLocalisationsParams = FirstAOCustomerServicesService.GetNomenclatureLocalisationsParams;

@Component({
  selector: 'app-nuts-code-chooser',
  templateUrl: './nuts-code-chooser.component.html',
  styleUrls: ['./nuts-code-chooser.component.css']
})
export class NutsCodeChooserComponent implements OnInit {

    selectable = true;
    removable = true;
    isSearching: boolean = false;
    private searchTimeOut;
    search: string = "";
    nutsCodesSearched = [];

    @Output() nutsCodesChanged = new EventEmitter<any>();

    @ViewChild('nutsCodesInput') departementInput: ElementRef<HTMLInputElement>;

    private _nutsCodes;

    get nutsCodes() {
        return this._nutsCodes;
    }

    @Input("nutsCodes")
    set nutsCodes(val) {
        this._nutsCodes = val;
    }

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input("readonly")
    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    constructor(private firstAOCustomerServicesService: FirstAOCustomerServicesService) { }

    ngOnInit() {
    }

    remove(nutsCode): void {
        const index = this.nutsCodes.indexOf(nutsCode);

        if (index >= 0) {
            this.nutsCodes.splice(index, 1);
            this.nutsCodesChanged.emit();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        let alreadyAdded: boolean = false;
        if (this.nutsCodes == null)
            this.nutsCodes = [];
        this.nutsCodes.forEach(function (value) {
            if (event.option.value.nutsCode == value.nutsCode)
                alreadyAdded = true;
        });
        if (!alreadyAdded) {
            const departement = {
                nutsCode: event.option.value.nutsCode,
                zip: event.option.value.zip,
                libelle: event.option.value.libelle,
                type: event.option.value.type
            };
            this.nutsCodes.push(departement);
            this.nutsCodesChanged.emit();
        }
        this.departementInput.nativeElement.value = '';
        this.search = "";
        this.nutsCodesSearched = [];
    }

    scheduleSearch() {
        this.nutsCodesSearched = [];
        var self = this;
        clearTimeout(this.searchTimeOut);
        if (this.search != null && this.search.length >= 1) {
            this.searchTimeOut = setTimeout(function () {
                self.searchNutsCodes();
            }, 500);
        }
    }

    searchNutsCodes() {
        this.isSearching = true;
        const params: GetNomenclatureLocalisationsParams = {
            query: this.search.trim()
        };

        this.firstAOCustomerServicesService.getNomenclatureLocalisations(params)
            .subscribe((nutsCodes) => {
                var self = this;

                if (nutsCodes != null) {
                    if (nutsCodes.length > 10) {
                        for (let i = 0; i < 10; i++) {
                            self.nutsCodesSearched.push(nutsCodes[i])
                        }
                    } else {
                        nutsCodes.forEach(function (value) {
                            self.nutsCodesSearched.push(value);
                        });
                    }
                }
                this.isSearching = false;
            });
    }
}
