import {Component, OnInit} from '@angular/core';

import { MissionService} from "../../mission.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-dce-edition',
    templateUrl: './dce-edition.component.html',
    styleUrls: ['./dce-edition.component.css'],
    providers: [MissionService]
})
export class DceEditionComponent implements OnInit {

    currentTab = "todo";
    subscription: Subscription;
    currentNoticeId = null;

    constructor(private missionService: MissionService) {
        this.subscription = missionService.currentNoticeId$.subscribe(
            value => {
                // if (value != null) {
                //     document.getElementById('aoEditor').classList.remove('displayNone');
                // } else {
                //     if (document.getElementById('aoEditor') != null)
                //         document.getElementById('aoEditor').classList.add('displayNone');
                // }
                this.currentNoticeId = value;
            });
    }

    ngOnInit() {
    }

    changeListTab(event) {
        this.missionService.changeAlreadyDoneMode(event.index != 0);
    }

    changePagination(event) {
        this.missionService.changePagination(event.pageSize, event.pageIndex, true);
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.subscription.unsubscribe();
    }

}
