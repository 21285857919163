<mat-form-field class="{{searchBarClass}}">
    <input type="text" [(ngModel)]="search" (ngModelChange)="scheduleSearch()" placeholder="{{placeHolder}}"
           matInput [matAutocomplete]="auto" class="aoSearchInput" [readonly]="readOnly">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let result of results" (click)="actionOnClick(result.item)">
            {{result.libelle}}
        </mat-option>
        <mat-option style="background: green" *ngIf="!isSearching" (click)="newItem.emit()">

        <span style="display: flex; color: white;">
            <i class="material-icons" style="margin: auto 10px auto 0; color: white;">add_circle_outline</i>{{addItemLabel}}
        </span>

        </mat-option>
        <mat-option *ngIf="isSearching" style="height: 125px;">
            <mat-spinner style="margin: auto;"></mat-spinner>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>