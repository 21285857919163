import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FirstAOCustomerServicesService} from "../../../api/gen/services/first-aocustomer-services.service";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
    selector: 'app-acheteur-chooser',
    templateUrl: './acheteur-chooser.component.html',
    styleUrls: ['./acheteur-chooser.component.css']
})
export class AcheteurChooserComponent implements OnInit {

    selectable = true;
    removable = true;
    isSearching: boolean = false;
    private searchTimeOut;
    search: string = "";
    acheteursSearched = [];

    @Output() acheteursChanged = new EventEmitter<any>();

    @ViewChild('acheteursInput') acheteursInput: ElementRef<HTMLInputElement>;

    private _acheteurs;

    get acheteurs() {
        return this._acheteurs;
    }

    @Input("acheteurs")
    set acheteurs(val) {
        this._acheteurs = val;
    }


    constructor(private firstAOCustomerServicesService: FirstAOCustomerServicesService) {
    }

    ngOnInit() {
    }

    remove(acheteur): void {
        const index = this.acheteurs.indexOf(acheteur);

        if (index >= 0) {
            this.acheteurs.splice(index, 1);
            this.acheteursChanged.emit();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        let alreadyAdded: boolean = false;
        if (this.acheteurs == null)
            this.acheteurs = [];
        this.acheteurs.forEach(function (value) {
            if (event.option.value.buyerID == value.buyerID)
                if (event.option.value.denomination == value.denomination)
                    alreadyAdded = true;
        });
        if (!alreadyAdded) {
            const acheteur = {
                buyerID: event.option.value.buyerID,
                denomination: event.option.value.denomination,
            };
            this.acheteurs.push(acheteur);
            this.acheteursChanged.emit();
        }
        this.acheteursInput.nativeElement.value = '';
        this.search = "";
        this.acheteursSearched = [];
    }

    scheduleSearch() {
        this.acheteursSearched = [];
        var self = this;
        clearTimeout(this.searchTimeOut);
        if (this.search != null && this.search.length >= 1) {
            this.searchTimeOut = setTimeout(function () {
                self.searchAcheteurs();
        }, 500);
        }
    }

    searchAcheteurs() {
        this.isSearching = true;

        this.firstAOCustomerServicesService.showSearchBuyerSuggest(this.search).subscribe( acheteurs => {
            if (acheteurs != null) {
                if (acheteurs.prives != null)
                    acheteurs.prives.forEach(value => {
                        this.acheteursSearched.push(value);
                    });
                if (acheteurs.publics != null)
                    acheteurs.publics.forEach(value => {
                        this.acheteursSearched.push(value);
                    });
            }

            this.isSearching = false;
        });
    }

}
