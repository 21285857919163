import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-qualification-confirmation',
  templateUrl: './qualification-confirmation.component.html',
  styleUrls: ['./qualification-confirmation.component.css']
})
export class QualificationConfirmationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<QualificationConfirmationComponent>) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close({
      accept: false
    });
  }

  accept() {
    this.dialogRef.close({
      accept: true
    });
  }
}
