<div fxLayout="row wrap" fxFlex="1 1 0" fxFlexFill="1" fxLayoutAlign="start">
    <mat-sidenav-container style="margin-right: 0 !important;">
        <mat-sidenav #sidenav mode="side" [(opened)]="opened">
            <div fxLayout="column" fxLayoutGap="10px" fxFlex="400px" style="height: 100%;">
                <app-ao-list [typeMode]="'private'" class="aoList"></app-ao-list>
                <button class="addNewAOButton" (click)="addNewPrivateAO()">Ajouter un appel d'offres privé</button>
                <mat-paginator [length]="2000"
                               [pageSize]="10"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               (page)="changePagination($event)">
                </mat-paginator>
            </div>
        </mat-sidenav>
    </mat-sidenav-container>
    <div class="sideNavButtonContainer">
        <button class="sideNavButton" *ngIf="opened" (click)="sidenav.toggle()"><<</button>
        <button class="sideNavButton" *ngIf="!opened" (click)="sidenav.toggle()">>></button>
    </div>

    <div fxLayout="column" fxFlex="1 1 0" fxLayoutGap="10px" style="height: 100%;">
        <app-ao-editor *ngIf="currentNoticeId" style="height: 100%; position: relative;" [isPublic]="false">

        </app-ao-editor>
    </div>
</div>
