<h2><b>Sources</b></h2>
<div class="sourcesContainer">
    <div *ngFor="" class="source">
        <mat-form-field class="flexGrow1 margR10">
            <input matInput placeholder="Intitulé" type="text" [(ngModel)]="contactName">
        </mat-form-field>

        <mat-form-field class="flexGrow1">
            <input matInput placeholder="URL" type="text" [(ngModel)]="contactMail">
        </mat-form-field>
    </div>
    <button class="saveBtn" style="width: 100%; display: block;" (click)="addNewSource()">Ajouter une nouvelle source</button>
</div>