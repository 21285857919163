<div style="min-width: 700px">
    <mat-label style="display: block; font-size: 25px; margin-bottom: 20px; text-align: center;">
        Associté cette société
    </mat-label>
    <mat-label style="display: block; font-size: 20px; text-align: center;">
        Pour rappel :
    </mat-label>
    <mat-label style="display: block; font-size: 20px; margin-bottom: 20px; text-align: center;">
        Attributaire : {{contractor.name}}  -  Entreprise : {{suggestion.name}}
    </mat-label>
    <mat-label style="display: block; font-size: 20px; margin-bottom: 60px; text-align: center;">
        Voulez vous valider ce choix ?
    </mat-label>

    <div>
        <button (click)="cancel()" style="background: darkred; float: right;">Annuler</button>
        <button (click)="accept()" style="background: green; float: left;">Accepter</button>
    </div>
</div>
