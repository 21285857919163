import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-enterprise-adder',
    templateUrl: './enterprise-adder.component.html',
    styleUrls: ['./enterprise-adder.component.css']
})
export class EnterpriseAdderComponent implements OnInit {

    enterpriseName;
    enterpriseSiren;
    enterpriseAPE;

    constructor(public dialogRef: MatDialogRef<EnterpriseAdderComponent>) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close({
            name: this.enterpriseName,
            siren: this.enterpriseSiren,
            ape: this.enterpriseAPE
        });
    }
}
