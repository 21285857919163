import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AoContractorFacade} from "../../../api/gen/models/ao-contractor-facade";
import {Entreprise} from "../../../api/gen/models/entreprise";
import {QualificationConfirmationComponent} from "../qualification-confirmation/qualification-confirmation.component";
import {ContractorQualification} from "../../../api/gen/models/contractor-qualification";

@Component({
  selector: 'app-pre-qualification-confirmation',
  templateUrl: './pre-qualification-confirmation.component.html',
  styleUrls: ['./pre-qualification-confirmation.component.css']
})
export class PreQualificationConfirmationComponent implements OnInit {

  contractor: AoContractorFacade;
  suggestion: Entreprise;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<PreQualificationConfirmationComponent>) {
    this.contractor = this.data.contractor;
    this.suggestion = this.data.suggestion;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close({
      accept: false
    });
  }

  accept() {
    const dialogRef = this.dialog.open(QualificationConfirmationComponent, {
      data: {
        contractor: this.data.contractor,
        suggestion: this.data.suggestion
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result.accept) {
        this.dialogRef.close({
          accept: true,
        });
      }
    });
  }
}
