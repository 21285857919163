<!--<div fxLayout="column wrap" class="acheteurEdition" fxLayoutAlign="start center" fxLayoutGap="10px">-->
<!--<mat-form-field class="searchContainer">-->
<!--<input #searchBar name="searchBar" matInput placeholder="Rechercher un profil acheteur" [(ngModel)]="search"-->
<!--(keyup)="searchBuyers()">-->
<!--<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">-->
<!--</mat-autocomplete>-->
<!--</mat-form-field>-->
<!--<div class="mainContainer flexGrow1">-->
<!--<div class="buyersProfilContainer flexGrow1">-->
<!--<h2>Acheteurs privés</h2>-->
<!--<div class="buyerProfilLine" *ngFor="let privateBuyer of privateBuyers">-->
<!--<a (click)="openBuyerProfil(privateBuyer, false)">-->
<!--<div id="not{{privateBuyer}}" class="notice noselect">{{privateBuyer.denomination}}</div>-->
<!--</a>-->
<!--</div>-->
<!--<h2>Acheteurs publics</h2>-->
<!--<div class="buyerProfilLine" *ngFor="let publicBuyer of publicBuyers">-->
<!--<a (click)="openBuyerProfil(publicBuyer, true)">-->
<!--<div id="not{{publicBuyer}}" class="notice noselect">{{publicBuyer.denomination}}</div>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->


<div class="buyerProfilMainContainer">
    <div class="buyerProfilsList">
        <mat-form-field id="buyerSearchBar" class="searchContainer">
            <input name="searchBar" matInput placeholder="Rechercher un profil acheteur" [(ngModel)]="search"
                   (ngModelChange)="scheduleSearch()">
            <mat-icon style="margin-right: 5px;" matSuffix>search</mat-icon>
        </mat-form-field>


        <mat-tab-group animationDuration="0ms" id="buyerTabGroup" (selectedTabChange)="setBuyerType($event)">
            <mat-tab class="buyerOnglet" style="width: 50%;" label="Acheteurs publics">

                <div class="buyerProfilItemsContainer">
                    <div id="publicBuyerProfil-{{publicBuyerProfil.buyerID}}" class="noselect buyerProfil"
                         (click)="openBuyerProfil(publicBuyerProfil.buyerID)"
                         *ngFor="let publicBuyerProfil of publicBuyerProfils; let last = last">
                        <div class="noselect">{{publicBuyerProfil.denomination}}</div>
                        <ng-container *ngIf="last">
                            {{ highlightSelectedBuyerProfil() }}
                        </ng-container>
                    </div>
                </div>

                <div class="messageContainer" *ngIf="search?.length < 3 && !showLoading">
                    Pour afficher des résultats, veuillez commencer votre recherche (Mininmum 3 caractères)
                </div>

                <div class="messageContainer"
                     *ngIf="search?.length > 2 && searchEnded && !(publicBuyerProfils?.length > 0)">
                    Aucun résultat pour la recherche "{{search}}"
                </div>

                <div *ngIf="showLoading">
                    <div style="height: 100%">
                        <mat-spinner style="margin: auto;"></mat-spinner>
                    </div>
                </div>
            </mat-tab>


            <mat-tab class="buyerOnglet" label="Acheteurs privés">
                <div class="buyerProfilItemsContainer">
                    <div id="priveBuyerProfil-{{privateBuyerProfil.buyerID}}" class="noselect buyerProfil"
                         (click)="openBuyerProfil(privateBuyerProfil.buyerID)"
                         *ngFor="let privateBuyerProfil of privateBuyerProfils; let last = last">
                        <div class="noselect">{{privateBuyerProfil.denomination}}</div>
                        <ng-container *ngIf="last">
                            {{ highlightSelectedBuyerProfil() }}
                        </ng-container>
                    </div>
                </div>

                <div class="messageContainer" *ngIf="search?.length < 3 && !showLoading">
                    Pour afficher des résultats, veuillez commencer votre recherche (Mininmum 3 caractères)
                </div>

                <div class="messageContainer"
                     *ngIf="search?.length > 2 && searchEnded && !(privateBuyerProfils?.length > 0)">
                    Aucun résultat pour la recherche "{{search}}"
                </div>

                <div *ngIf="showLoading">
                    <div style="height: 100%">
                        <mat-spinner style="margin: auto;"></mat-spinner>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

        <button class="addBtn" (click)="addNewBuyerProfil()">Ajouter un profil Acheteur</button>
    </div>


    <div class="buyerProfilEditorContainer">

        <div class="buyerProfilEditor" *ngIf="currentBuyerProfil && !showEditorLoading">

            <button class="editBtn" *ngIf="readonly" (click)="editBuyerProfil()">Modifier</button>
            <button class="saveBtn" *ngIf="!readonly" (click)="saveBuyerProfil()">Enregistrer</button>

            <mat-card class="buyerCards buyerBlocContainer">
                <mat-card-title class="buyerCardTitle">
                    Acheteur
                </mat-card-title>

                <div class="editorLine">

                    <div class="editorCol6">
                        <div class="editorLineInput" style="margin-right: 5px;">
                            <mat-form-field style="width: 100%;">
                                <input [readonly]="readonly" matInput placeholder="Libellé"
                                       [(ngModel)]="currentBuyerProfil.denomination">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="editorCol6" style="margin-left: 5px;">
                        <div class="editorLineInput">
                            <mat-form-field style="width: 100%;">
                                <input [readonly]="readonly" matInput placeholder="Type d'organisme"
                                       [(ngModel)]="currentBuyerProfil.typeOrganisme">
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="editorLine">
                    <div class="editorLineLibelle">Statut</div>
                    <div class="editorLineInput">
                        <mat-slide-toggle [disabled]="readonly" matInput
                                          [(ngModel)]="currentBuyerProfil.status"></mat-slide-toggle>
                    </div>
                </div>

            </mat-card>


            <mat-card class="buyerCards contactBlocContainer">
                <mat-card-title class="contactCardTitle">
                    Contacts
                </mat-card-title>


                <div class="editorLine">

                    <div style="display: flex; flex-direction: column; width: fit-content;">
                        <mat-form-field class="displayB" style="margin-bottom: 20px;">
                            <mat-label>Choisir un contact</mat-label>
                            <mat-select (valueChange)="loadContact($event)" required>
                                <mat-option *ngFor="let contact of buyersContacts" [value]="contact.id">
                                    {{contact.nom}} ({{contact.email}})
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="margR20" style="display: flex">
                            <button class="saveBtn margR20 flexGrow1" (click)="addNewContact()">Ajouter un
                                contact
                            </button>
                            <button *ngIf="!contactEditing" class="flexGrow1" (click)="editContact()">Modifier
                                le contact
                            </button>
                            <button class="saveBtn flexGrow1" *ngIf="contactEditing" (click)="saveContact()">
                                Enregistrer
                            </button>
                        </div>
                    </div>

                    <div class="editorCol8" style="margin-left: 30px;">

                        <div class="buyerContactPart padR20">
                            <mat-form-field class="w100">
                                <input matInput placeholder="Nom Prénom" type="text" [readonly]="!contactEditing"
                                       id="contacName" [ngModel]="currentContact?.nom">
                            </mat-form-field>

                            <mat-form-field class="w100">
                                <input matInput placeholder="Adresse mail" type="text" [readonly]="!contactEditing"
                                       id="contactMail" [ngModel]="currentContact?.email">
                            </mat-form-field>
                        </div>

                        <div class="buyerContactPart padR20">
                            <mat-form-field class="w100">
                                <input matInput placeholder="Téléphone" type="text" [readonly]="!contactEditing"
                                       id="contactPhone" [ngModel]="currentContact?.telephone">
                            </mat-form-field>

                            <mat-form-field class="w100">
                                <input matInput placeholder="Fax" type="text" [readonly]="!contactEditing"
                                       id="contactFax" [ngModel]="currentContact?.fax">
                            </mat-form-field>
                        </div>

                        <div class="buyerContactPart">
                            <mat-form-field class="w100">
                                <input matInput placeholder="Lien LinkedIn" type="text" [readonly]="!contactEditing"
                                       [ngModel]="currentContact?.linkedin_profil">
                            </mat-form-field>

                            <mat-form-field class="w100">
                                <input matInput placeholder="Lien Viadeo" type="text" [readonly]="!contactEditing"
                                       [ngModel]="currentContact?.viadeo_profil">
                            </mat-form-field>
                        </div>

                    </div>

                </div>


            </mat-card>


            <mat-card class="buyerCards addressBlocContainer">
                <mat-card-title class="addressCardTitle">
                    Adresses
                </mat-card-title>

                <div class="editorLine">

                    <div class="editorCol6">
                        <div class="editorLineInput" style="margin-right: 5px;">
                            <mat-form-field style="width: 100%;">
                                <input [readonly]="readonly" matInput placeholder="Libellé"
                                       [(ngModel)]="currentBuyerProfil.denomination">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="editorCol6" style="margin-left: 5px;">
                        <div class="editorLineInput">
                            <mat-form-field style="width: 100%;">
                                <input [readonly]="readonly" matInput placeholder="Type d'organisme"
                                       [(ngModel)]="currentBuyerProfil.typeOrganisme">
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="editorLine">
                    <div class="editorLineLibelle">Statut</div>
                    <div class="editorLineInput">
                        <mat-slide-toggle [disabled]="readonly" matInput
                                          [(ngModel)]="currentBuyerProfil.status"></mat-slide-toggle>
                    </div>
                </div>

            </mat-card>

        </div>

        <div *ngIf="showEditorLoading">
            <div style="height: 100%">
                <mat-spinner style="margin: auto;"></mat-spinner>
            </div>
        </div>

    </div>
</div>
