import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoticeFacade} from "../../api/gen/models/notice-facade";

@Component({
    selector: 'app-notice-resume',
    templateUrl: './notice-resume.component.html',
    styleUrls: ['./notice-resume.component.css']
})
export class NoticeResumeComponent implements OnInit {

    private _notice: NoticeFacade;

    get notice(): NoticeFacade {
        return this._notice;
    }

    @Input("notice")
    set notice(val: NoticeFacade) {
        this._notice = val;
    }

    @Output() noticeClicked = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    openNotice() {
        this.noticeClicked.emit({
            aoId: this.notice.aoId,
            typeAo: this.notice.typeAo
        });
    }

}
