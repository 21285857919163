<div fxLayout="row wrap" fxFlex="1 1 0" fxFlexFill="1" fxLayoutAlign="start" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutGap="10px" fxFlex="400px">
        <mat-tab-group (selectedTabChange)="changeListTab($event)">
            <mat-tab  label="À traiter">
            </mat-tab>
            <mat-tab label="Déjà traité">
            </mat-tab>

        </mat-tab-group>
        <app-ao-list class="aoList" [showDCE]="true"></app-ao-list>
        <mat-paginator [length]="2000"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="changePagination($event)">
        </mat-paginator>
    </div>
    <div fxLayout="column" fxFlex="1 1 0" fxLayoutGap="10px">
        <app-ao-editor [isPublic]="true" *ngIf="currentNoticeId" style="height: 100%;" [isPublic]="true">

        </app-ao-editor>
    </div>
</div>
