import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {MissionService} from "../../../mission.service";
import {FirstAOInternServicesService} from "../../../api/gen/services";
import {AoLots} from "../../../api/gen/models/ao-lots";
import EditorAddNoticeLotsParams = FirstAOInternServicesService.EditorAddNoticeLotsParams;
import EditorEditNoticeLotsParams = FirstAOInternServicesService.EditorEditNoticeLotsParams;
import EditorDeleteNoticeLotsParams = FirstAOInternServicesService.EditorDeleteNoticeLotsParams;

@Component({
    selector: 'app-lots-manager',
    templateUrl: './lots-manager.component.html',
    styleUrls: ['./lots-manager.component.css']
})
export class LotsManagerComponent implements OnInit {

    private _readOnly: boolean;

    get readOnly(): boolean {
        return this._readOnly;
    }

    set readOnly(val: boolean) {
        this._readOnly = val;
    }

    currentLot;
    currentCPVCodes;
    currentNutsCodes;
    lotEditing: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                private missionService: MissionService,
                private firstAOInternServicesService: FirstAOInternServicesService) {
        this.readOnly = data.readOnly;
    }

    ngOnInit() {
    }

    editContact() {
        this.lotEditing = true;
    }

    saveLotModification() {
        const param: EditorEditNoticeLotsParams = {
            noticeLotID: this.currentLot.id,
            noticeID: this.data.aoId,
            actualisSharedBeanFirstecoAoLots: this.getCurrentLotInformation()
        };

        this.currentLot.cpv = this.cpvStringArrayToString(this.currentCPVCodes);
        this.currentLot.indexation = this.currentCPVCodes;

        this.firstAOInternServicesService.editorEditNoticeLots(param).subscribe(value => {
            this.lotEditing = false;
        });
    }

    openLot(lot, index) {
        this.lotEditing = false;
        this.currentLot = lot;

        this.currentCPVCodes = this.currentLot.indexation;
        if (this.currentCPVCodes === null || this.currentCPVCodes === undefined)
            this.currentCPVCodes = [];

        // this.currentNutsCodes = this.currentLot.codeNuts;
        if (this.currentNutsCodes === null || this.currentNutsCodes === undefined)
            this.currentNutsCodes = [];

        this.removeAllSelected();

        if (lot.numero != null) {
            document.getElementById('lot' + index).classList.add('selectedLot');
        }
    }

    removeAllSelected() {
        let elems = document.getElementsByClassName('noselect');

        [].forEach.call(elems, function (el) {
            el.classList.remove("selectedLot");
        });
    }

    addNewLot() {
        let lotNum;

        if (this.data.lots != null) {
            lotNum = this.data.lots.length + 1;
        } else {
            lotNum = 1;
        }

        const aoLots: AoLots = {
            aoId: this.data.aoId,
            intitule: "Nouveau Lot",
            numero: lotNum
        };

        const param: EditorAddNoticeLotsParams = {
            noticeID: this.data.aoId,
            actualisSharedBeanFirstecoAoLots: aoLots
        };

        this.firstAOInternServicesService.editorAddNoticeLots(param).subscribe(value => {
            this.data.lots.push(value);
        });
    }

    deleteLot() {
        const param: EditorDeleteNoticeLotsParams = {
            noticeLotID: this.currentLot.id,
            noticeID: this.data.aoId,
        };

        this.firstAOInternServicesService.editorDeleteNoticeLots(param).subscribe(value => {
            this.lotEditing = false;


            const index: number = this.data.lots.indexOf(this.currentLot);
            if (index !== -1) {
                this.data.lots.splice(index, 1);
            }

            this.currentLot = null;

        });
    }

    cpvStringToStringArray(cpvCodes: string) {
        let stringArray: string[] = [];

        if (cpvCodes != null) {
            cpvCodes.split(',').forEach(function (value) {
                if (value.length > 0)
                    stringArray.push(value);
            });
        }

        return stringArray;
    }

    cpvStringArrayToString(cpvCodes: any[]) {
        let cpvString: string = "";
        let first: boolean = true;

        if (cpvCodes != null) {
            cpvCodes.forEach(value => {
                if (first) {
                    first = false;
                } else {
                    cpvString += ",";
                }
                cpvString += value.cpv;
            });
        }

        return cpvString;
    }

    nutsStringArrayToString(nutsCodes: any[]) {
        let nutsString: string = "";
        let first: boolean = true;

        if (nutsCodes != null) {
            nutsCodes.forEach(value => {
                if (first) {
                    first = false;
                } else {
                    nutsString += ",";
                }
                nutsString += value.nutsCode;
            });
        }

        return nutsString;
    }

    getCurrentLotInformation() {
        return {
            dureeMois: this.currentLot.dureeMois,
            aoId: this.data.aoId,
            cpv: this.cpvStringArrayToString(this.currentCPVCodes),
            dateDebut: this.currentLot.dateDebut,
            dateExecution: this.currentLot.dateExecution,
            dateFin: this.currentLot.dateFin,
            dateLancement: this.currentLot.dateLancement,
            dateLivraison: this.currentLot.dateLivraison,
            description: this.currentLot.description,
            dureeJours: this.currentLot.dureeJours,
            codeNuts: this.nutsStringArrayToString(this.currentNutsCodes),
            id: this.currentLot.id,
            intitule: this.currentLot.intitule,
            numero: this.currentLot.numero,
            quantite: this.currentLot.quantite,
            valeur: this.currentLot.valeur,
            valeurMax: this.currentLot.valeurMax,
            valeurMin: this.currentLot.valeurMin,
            withRenouvellement: this.currentLot.withRenouvellement,
            withVariantes: this.currentLot.withRenouvellement
        }
    }
}
