<div class="lotsManager">

    <h2 mat-dialog-title>Gestion des lots</h2>

    <div class="lotsManagerContent">

        <div class="leftLotsPart">

            <div class="lotsList">
                <div class="noselect" *ngFor="let lot of data.lots; let index = index">
                    <a (click)="openLot(lot, index)">
                        <div id="lot{{index}}" class="notice noselect">{{lot.intitule}}</div>
                    </a>
                </div>
            </div>

            <button id="addLotButton" *ngIf="!readOnly" (click)="addNewLot()">
                <mat-icon aria-hidden="false" class="addCircle">add_circle</mat-icon>
                Créer un lot
            </button>

        </div>

        <div class="rightLotsPart" *ngIf="currentLot != null">
            <p style="margin-top: 0;">Lot numéro <input style="border: 1px solid; width: 600px; padding: 0 2px;" matInput
                                                        [readonly]="!lotEditing" [(ngModel)]="currentLot.numero"></p>
            <mat-form-field style="width: 100%;">
                <input matInput [readonly]="!lotEditing" [(ngModel)]="currentLot.intitule" placeholder="Titre du lot">
            </mat-form-field>

            <app-cpv-code-chooser [cpvCodes]="currentCPVCodes" [readonly]="!lotEditing"></app-cpv-code-chooser>
            <app-nuts-code-chooser (nutsCodesChanged)="editContact()" [nutsCodes]="currentNutsCodes" [readonly]="!lotEditing"></app-nuts-code-chooser>


            <div class="rightLotLine">

                <!-- Date de début -->
                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" [matDatepicker]="startPicker"
                           [(ngModel)]="currentLot.dateDebut"
                           placeholder="Date de début">
                    <mat-datepicker-toggle matSuffix [disabled]="!lotEditing"
                                           [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>

                <!-- Date d'éxécution -->
                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" [matDatepicker]="executionPicker"
                           [(ngModel)]="currentLot.dateExecution"
                           placeholder="Date d'éxécution">
                    <mat-datepicker-toggle matSuffix [disabled]="!lotEditing"
                                           [for]="executionPicker"></mat-datepicker-toggle>
                    <mat-datepicker #executionPicker></mat-datepicker>
                </mat-form-field>

                <!-- Date de fin -->
                <mat-form-field class="flexGrow1">
                    <input matInput [readonly]="!lotEditing" [matDatepicker]="endPicker"
                           [(ngModel)]="currentLot.dateFin"
                           placeholder="Date de fin">
                    <mat-datepicker-toggle matSuffix [disabled]="!lotEditing" [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>

            </div>


            <div class="rightLotLine">

                <!--Date de lancement-->
                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" [matDatepicker]="lancementPicker"
                           [(ngModel)]="currentLot.dateLancement"
                           placeholder="Date de lancement">
                    <mat-datepicker-toggle matSuffix [disabled]="!lotEditing"
                                           [for]="lancementPicker"></mat-datepicker-toggle>
                    <mat-datepicker #lancementPicker></mat-datepicker>
                </mat-form-field>

                <!--Date de livraison-->
                <mat-form-field class="flexGrow1">
                    <input matInput [readonly]="!lotEditing" [matDatepicker]="livraisonPicker"
                           [(ngModel)]="currentLot.dateLivraison"
                           placeholder="Date de livraison">
                    <mat-datepicker-toggle matSuffix [disabled]="!lotEditing"
                                           [for]="livraisonPicker"></mat-datepicker-toggle>
                    <mat-datepicker #livraisonPicker></mat-datepicker>
                </mat-form-field>

            </div>


            <mat-form-field class="lotDescriptionContainer">
                <textarea id="lotDescription" matInput [readonly]="!lotEditing" [(ngModel)]="currentLot.description"
                          placeholder="Description"></textarea>
            </mat-form-field>


            <div class="rightLotLine">

                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" placeholder="Durée en Jours"
                           [(ngModel)]="currentLot.dureeJours">
                </mat-form-field>

                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" placeholder="Durée en Mois"
                           [(ngModel)]="currentLot.dureeMois">
                </mat-form-field>

                <mat-form-field class="flexGrow1">
                    <input matInput [readonly]="!lotEditing" placeholder="Quantité" [(ngModel)]="currentLot.quantite">
                </mat-form-field>

            </div>


            <div class="rightLotLine">

                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" placeholder="Valeur" [(ngModel)]="currentLot.valeur">
                </mat-form-field>

                <mat-form-field class="flexGrow1 margR20">
                    <input matInput [readonly]="!lotEditing" placeholder="Valeur Max"
                           [(ngModel)]="currentLot.valeurMax">
                </mat-form-field>

                <mat-form-field class="flexGrow1">
                    <input matInput [readonly]="!lotEditing" placeholder="Valeur Min"
                           [(ngModel)]="currentLot.valeurMin">
                </mat-form-field>

            </div>


            <div class="rightLotLine">

                <mat-slide-toggle class="flexGrow1" [disabled]="!lotEditing"
                                  [(ngModel)]="currentLot.withRenouvellement">Avec renouvellement
                </mat-slide-toggle>
                <mat-slide-toggle class="flexGrow1" [disabled]="!lotEditing" [(ngModel)]="currentLot.withVariantes">Avec
                    variante
                </mat-slide-toggle>

            </div>


            <div class="rightLotLine" *ngIf="!readOnly" style="display: block;">

                <button *ngIf="!lotEditing" (click)="editContact()" id="editLotButton">
                    Modifier le lot
                </button>
                <button class="saveBtn" *ngIf="lotEditing" (click)="saveLotModification()" id="saveLotButton">
                    Enregistrer
                </button>
                <button class="cancelButton" style="float: right;" (click)="deleteLot()" id="deleteLotButton">
                    Supprimer le lot
                </button>


            </div>

            <!--codeNuts: "FR53,FR58"-->
            <!--cpv: "29926778,23074284,81666903,32245596"-->

        </div>

    </div>

</div>
