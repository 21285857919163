export class UserSession {

    get gotAllRights(): boolean {
        return this._gotAllRights;
    }

    set gotAllRights(value: boolean) {
        this._gotAllRights = value;
    }

    private _gotAllRights: boolean;


    get userName(): string {
        return this._userName;
    }

    set userName(value: string) {
        this._userName = value;
    }

    private _userName: string;

    constructor() {
    }
}
