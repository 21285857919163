import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ao-date-picker',
  templateUrl: './ao-date-picker.component.html',
  styleUrls: ['./ao-date-picker.component.css']
})
export class AoDatePickerComponent implements OnInit {

  @Input('holderText') holderText: string;
  @Input('currentDate') currentDate: Date;

  constructor() {
  }

  ngOnInit() {
  }

}
