<div class="buyersManager" *ngIf="currentBuyer else loading">
    <app-ao-search-bar [placeHolder]="'Rechercher un acheteur'" [addItemLabel]="'Ajouter un nouvel acheteur'"
                       [searchBarType]="'buyer'" (itemSelected)="onItemSelected($event)" (newItem)="addNewBuyer()">
    </app-ao-search-bar>
    <h2>
        <b class="margR20">Acheteur</b>
        <button *ngIf="!buyerEditing" (click)="editBuyer()" id="editBuyerButton">Modifier l'acheteur</button>
        <button class="saveBtn" *ngIf="buyerEditing" (click)="saveBuyer()" id="saveBuyerButton">Enregistrer</button>
    </h2>
    <div class="buyerPart">
        <mat-form-field class="flexGrow1 margR10">
            <input [readonly]="!buyerEditing" matInput placeholder="Collectivité" type="text"
                   id="buyerCollectivite"
                   [(ngModel)]="currentBuyer.denomination">
        </mat-form-field>
        <mat-form-field class="flexGrow1 margR10">
            <input [readonly]="!buyerEditing" matInput placeholder="Typologie du commanditaire" type="text"
                   id="buyerTypologie"
                   [(ngModel)]="currentBuyer.typeOrganisme">
        </mat-form-field>
        <mat-form-field class="flexGrow1">
            <input [readonly]="!buyerEditing" matInput placeholder="URL du site internet" type="text"
                   id="buyerWebSite"
                   [(ngModel)]="buyerUrl">
        </mat-form-field>
    </div>
    <h2><b>Contacts</b></h2>
    <div class="contactsPart">
        <div class="contactsSubPart margR20">
            <mat-form-field [formControl]="contactsControl" class="displayB">
                <mat-label>Choisir un contact</mat-label>
                <mat-select (ngModelChange)="loadContact($event)" [(ngModel)]="selectedContactId"
                            required>
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let contact of buyersContacts" [value]="contact.id">
                        {{contact.nom}} ({{contact.email}})
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="contactsControl.hasError('required')">Choisissez un contact</mat-error>
            </mat-form-field>
            <div class="displayB">
                <button class="margR20" (click)="addNewContact()" id="addNewBuyerContactButton">Ajouter un contact
                </button>
                <button *ngIf="!contactEditing" (click)="editContact()" id="editBuyerContactButton">Modifier le contact
                </button>
                <button class="saveBtn" *ngIf="contactEditing" (click)="saveContact()" id="saveBuyerContactButton">
                    Enregistrer
                </button>
            </div>
        </div>

        <div class="contactsSubPart margR20">
            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Nom Prénom" type="text" [readonly]="!contactEditing"
                       id="contacName" [(ngModel)]="contactName">
            </mat-form-field>

            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Adresse mail" type="text" [readonly]="!contactEditing"
                       id="contactMail" [(ngModel)]="contactMail">
            </mat-form-field>
        </div>

        <div class="contactsSubPart">
            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Téléphone" type="text" [readonly]="!contactEditing"
                       id="contactPhone" [(ngModel)]="contactPhone">
            </mat-form-field>

            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Fax" type="text" [readonly]="!contactEditing"
                       id="contactFax" [(ngModel)]="contactFax">
            </mat-form-field>
        </div>
    </div>
    <h2><b>Adresses</b></h2>
    <div class="contactsPart">
        <div class="contactsSubPart margR20">
            <mat-form-field [formControl]="addressesControl" class="displayB">
                <mat-label>Choisir une adresse</mat-label>
                <mat-select (ngModelChange)="loadAddress($event)" [(ngModel)]="selectedAddressId"
                            required>
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let address of buyersAddresses" [value]="address.id">
                        {{address.libelle}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="addressesControl.hasError('required')">Choisissez une addresse</mat-error>
            </mat-form-field>
            <div class="displayB">
                <button class="margR20" (click)="addNewAddress()" id="addNewAddressContactButton">Ajouter une adresse
                </button>
                <button *ngIf="!addressEditing" (click)="editAddress()" id="editAddressContactButton">Modifier l'adresse
                </button>
                <button class="saveBtn" *ngIf="addressEditing" (click)="saveAddress()" id="saveAddressContactButton">
                    Enregistrer
                </button>
            </div>
        </div>

        <div class="contactsSubPart margR20">
            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Nom adresse" type="text" [readonly]="!addressEditing"
                       id="addressName" [(ngModel)]="addressName">
            </mat-form-field>

            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Ville" type="text" [readonly]="!addressEditing"
                       id="addressCity" [(ngModel)]="addressCity">
            </mat-form-field>
        </div>

        <div class="contactsSubPart">
            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Rue" type="text" [readonly]="!addressEditing"
                       id="addressPath" [(ngModel)]="addressPath">
            </mat-form-field>

            <mat-form-field class="flexGrow1 displayB">
                <input matInput placeholder="Code Postal" type="text" [readonly]="!addressEditing"
                       id="addressZip" [(ngModel)]="addressZip">
            </mat-form-field>
        </div>
    </div>

    <div class="closeModalPart">
        <button class="saveBtn" (click)="closeAndSave()" id="closeAndSaveBuyerButton">Enregistrer les modifications</button>
        <button class="cancelButton" mat-dialog-close id="closeAndCancelBuyerButton">Annuler les modifications</button>
    </div>
</div>

<ng-template #loading>
    <div style="height: 100%">
        <mat-spinner style="margin: auto;"></mat-spinner>
    </div>
</ng-template>