<!--<div fxLayout="column wrap" class="acheteurEdition" fxLayoutAlign="start center" fxLayoutGap="10px">-->
<!--<mat-form-field class="searchContainer">-->
<!--<input #searchBar name="searchBar" matInput placeholder="Rechercher un profil acheteur" [(ngModel)]="search"-->
<!--(keyup)="searchBuyers()">-->
<!--<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">-->
<!--</mat-autocomplete>-->
<!--</mat-form-field>-->
<!--<div class="mainContainer flexGrow1">-->
<!--<div class="buyersProfilContainer flexGrow1">-->
<!--<h2>Acheteurs privés</h2>-->
<!--<div class="buyerProfilLine" *ngFor="let privateBuyer of privateBuyers">-->
<!--<a (click)="openBuyerProfil(privateBuyer, false)">-->
<!--<div id="not{{privateBuyer}}" class="notice noselect">{{privateBuyer.denomination}}</div>-->
<!--</a>-->
<!--</div>-->
<!--<h2>Acheteurs publics</h2>-->
<!--<div class="buyerProfilLine" *ngFor="let publicBuyer of publicBuyers">-->
<!--<a (click)="openBuyerProfil(publicBuyer, true)">-->
<!--<div id="not{{publicBuyer}}" class="notice noselect">{{publicBuyer.denomination}}</div>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->


<div class="buyerProfilMainContainer">
    <div class="buyerProfilsList">
<!--        <mat-form-field id="buyerSearchBar" class="searchContainer">-->
<!--            <input name="searchBar" matInput placeholder="Rechercher un profil attributaire" [(ngModel)]="search"-->
<!--                   (ngModelChange)="scheduleSearch()">-->
<!--            <mat-icon style="margin-right: 5px;" matSuffix>search</mat-icon>-->
<!--        </mat-form-field>-->

        <div class="buyerProfilItemsContainer">
            <div id="attProfil-{{qualificationRequest.contractor.contractorId}}"
                 class="noselect attProfil" (click)="openContractor(qualificationRequest)"
                 *ngFor="let qualificationRequest of qualificationRequests; let last = last">
                <div class="noselect">
                    <div style="font-weight: bold; color: #1c1c1c;">{{qualificationRequest.contractor.name}}</div>
                    <div style="display: flex; font-size: 14px;">
                        <div style="flex: 1; color: #1c1c1c;">
                        Avis d'attribution n°{{qualificationRequest.notice.aoId}}
                        </div>
                        <div style="font-style: italic; color: #1c1c1c;">
                            Date de la demande : {{datepipe.transform(qualificationRequest.request_date, "dd/MM/yyyy")}}
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="last">
                    {{ highlightSelectedBuyerProfil() }}
                </ng-container>
            </div>
        </div>
        <mat-paginator #qualificationsPaginator [length]="2000" [pageSize]="25" [pageSizeOptions]="[25, 50, 75, 100]"
                       (page)="loadDemandeAttributaires($event)"></mat-paginator>

<!--        <div class="messageContainer" *ngIf="search?.length < 3 && !showLoading">-->
<!--            Pour afficher des résultats, veuillez commencer votre recherche (Mininmum 3 caractères)-->
<!--        </div>-->

<!--        <div class="messageContainer"-->
<!--             *ngIf="search?.length > 2 && searchEnded && !(privateBuyerProfils?.length > 0)">-->
<!--            Aucun résultat pour la recherche "{{search}}"-->
<!--        </div>-->

<!--        <div *ngIf="showLoading">-->
<!--            <div style="height: 100%">-->
<!--                <mat-spinner style="margin: auto;"></mat-spinner>-->
<!--            </div>-->
<!--        </div>-->

<!--        <button class="addBtn" (click)="addNewBuyerProfil()">Ajouter un profil Acheteur</button>-->
    </div>


    <div class="buyerProfilEditorContainer">

        <div class="buyerProfilEditor" *ngIf="currentQualificationRequest && !showEditorLoading"
             style="height: 100%; overflow-y: auto;">
            <div style="margin-left: auto; width: fit-content">
                <button *ngIf="currentEntreprise" class="suggestionBtn" (click)="openQualificationPreConfirmation()">Qualifier avec l'entreprise courante</button>
                <button style="margin-left: 10px; display: inline-block; background: #ab1717;" (click)="openQualifyingModal()">Choisir une entreprise</button>
            </div>


            <mat-accordion>
                <mat-expansion-panel [expanded]="true" style="background: #e5f4ff; margin-top: 20px;">
                    <mat-expansion-panel-header style="font-size: 22px; border-bottom: 1px solid #1c1c1c; height: auto; padding: 5px 20px;">
                        <div style="margin-right: 5px;">
                            <span style="font-size: 18px; font-weight: bold; font-style: italic;">
                            Avis n°{{currentQualificationRequest.notice.aoId}}</span>
                            - {{currentQualificationRequest.notice.titre}}
                        </div>
                    </mat-expansion-panel-header>

                    <div class="editorLine flexCol" style="margin-top: 10px;">
                        <mat-label style="display: block;" class="awardDescLabel">Résumé :</mat-label>
                        <mat-label class="awadrdTitle">{{currentQualificationRequest.notice.objetComplet}}</mat-label>
                    </div>

                    <div style="display: flex;">
                        <div class="editorCol6">
                            <span class="awardDescLabel margR5">Date de publication :</span>
                            <span class="awardDate">{{currentQualificationRequest.notice.publicationDateString}}</span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel [expanded]="true" style="background: #e5f8ea; margin-top: 20px;">
                    <mat-expansion-panel-header style="font-size: 24px; border-bottom: 1px solid #1c1c1c; height: auto; padding: 5px 20px;">
                        Attributaire
                    </mat-expansion-panel-header>
                    <div class="editorLine" style="margin-top: 10px; margin-bottom: 0;">
                        <div class="editorCol6">
                            <div class="editorLineInput" style="margin-right: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Libellé"
                                           [(ngModel)]="currentQualificationRequest.contractor.name">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="SIREN"
                                           [(ngModel)]="currentSiren">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="SIRET"
                                           [(ngModel)]="currentQualificationRequest.contractor.siren">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="editorLine" style="margin-bottom: 0; margin-top: -10px;">
                        <div class="editorCol6">
                            <div class="editorLineInput" style="margin-right: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Adresse"
                                           [(ngModel)]="currentQualificationRequest.contractor.address">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Ville"
                                           [(ngModel)]="currentQualificationRequest.contractor.city">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="CP"
                                           [(ngModel)]="currentQualificationRequest.contractor.zip">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="editorLine" style="margin-bottom: 0; margin-top: -10px;">
                        <div class="editorCol6">
                            <div class="editorLineInput" style="margin-right: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Téléphone"
                                           [(ngModel)]="currentQualificationRequest.contractor.entrepriseId">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Email"
                                           [(ngModel)]="currentQualificationRequest.contractor.email">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Site Web"
                                           [(ngModel)]="currentQualificationRequest.contractor.url">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>




            <mat-accordion *ngIf="currentEntreprise">
                <mat-expansion-panel [expanded]="true" style="background: #efefef; margin-top: 20px;">
                    <mat-expansion-panel-header style="font-size: 24px; border-bottom: 1px solid #1c1c1c; height: auto; padding: 5px 20px;">
                        <span>Société "{{currentEntreprise.name}}"</span>
                        <button class="editBtn" style="margin: 0 10px 0 auto;" *ngIf="readonly"
                                (click)="editBuyerProfil(); $event.stopPropagation();">Modifier</button>
                        <button class="saveBtn" style="margin: 0 10px 0 auto;" *ngIf="!readonly"
                                (click)="saveEntrepriseModifications(); $event.stopPropagation();">Enregistrer</button>
                    </mat-expansion-panel-header>


                    <div class="editorLine" style="margin-top: 10px; margin-bottom: 0;">
                        <div class="editorCol6">
                            <div class="editorLineInput" style="margin-right: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="SIREN"
                                           [(ngModel)]="currentEntreprise.siren">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="SIRET"
                                           [(ngModel)]="currentEntreprise.siret">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Statut juridique"
                                           [(ngModel)]="currentEntreprise.legalStatus">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="editorLine" style="margin-top: -10px; margin-bottom: 0;">
                        <div class="editorCol6">
                            <div class="editorLineInput" style="margin-right: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Date de création"
                                           [(ngModel)]="currentEntreprise.dateCreation">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Capital social"
                                           [(ngModel)]="currentEntreprise.capital">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Effectif (en personne)"
                                           [(ngModel)]="currentEntreprise.effectif">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="editorLine" style="margin-top: -10px; margin-bottom: 0;">
                        <div class="editorCol6">
                            <div class="editorLineInput" style="margin-right: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Adresse"
                                           [(ngModel)]="currentEntreprise.adresse">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="Ville"
                                           [(ngModel)]="currentEntreprise.ville">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <input [readonly]="true" matInput placeholder="CP"
                                           [(ngModel)]="currentEntreprise.cp">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="editorLine" style="margin-top: -10px; margin-bottom: 0;">
                        <div class="editorCol6" style="margin-left: 5px;">
                            <div class="editorLineInput">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Description</mat-label>
                                    <textarea [readonly]="true" matInput [(ngModel)]="currentEntreprise.descriptif"
                                              placeholder="Descriptif" [rows]="4"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div style=" padding: 10px; background: #f7dbdb; margin: 0 -10px">
                        <div class="editorLine" style="margin-top: -5px; margin-bottom: 0;">
                            <mat-label style="text-align: center; margin: auto auto 10px; font-size: 18px; font-weight: bold; text-decoration: underline;">
                                Données éditables
                            </mat-label>
                        </div>
                        <div class="editorLine" style="margin-top: -10px; margin-bottom: -10px;">
                            <div class="editorCol6">
                                <div class="editorLineInput" style="margin-right: 5px;">
                                    <mat-form-field style="width: 100%;">
                                        <input [readonly]="readonly" matInput placeholder="Téléphone"
                                               [(ngModel)]="currentEntreprise.telephone">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="editorCol6" style="margin-left: 5px;">
                                <div class="editorLineInput">
                                    <mat-form-field style="width: 100%;">
                                        <input [readonly]="readonly" matInput placeholder="Mail générique"
                                               [(ngModel)]="currentEntreprise.mail">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="editorCol6" style="margin-left: 5px;">
                                <div class="editorLineInput">
                                    <mat-form-field style="width: 100%;">
                                        <input [readonly]="readonly" matInput placeholder="Site internet"
                                               [(ngModel)]="currentEntreprise.website">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>


                        <div class="editorLine" style="margin-top: -10px; margin-bottom: -10px;">
                            <div class="editorCol6">
                                <div class="editorLineInput" style="margin-right: 5px;">
                                    <mat-form-field style="width: 100%;">
                                        <input [readonly]="readonly" matInput placeholder="LinkedIn"
                                               [(ngModel)]="currentEntreprise.linkedin">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="editorCol6" style="margin-left: 5px;">
                                <div class="editorLineInput">
                                    <mat-form-field style="width: 100%;">
                                        <input [readonly]="readonly" matInput placeholder="Twitter"
                                               [(ngModel)]="currentEntreprise.twitter">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="editorCol6" style="margin-left: 5px;">
                                <div class="editorLineInput">
                                    <mat-form-field style="width: 100%;">
                                        <input [readonly]="readonly" matInput placeholder="Facebook"
                                               [(ngModel)]="currentEntreprise.facebook">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="editorLine" style="margin-top: 0px; margin-bottom: 0; flex-direction: column;">
                        <div style="border-bottom: black solid 1px; text-align: center; margin: 20px 0 5px;">
                            <mat-label style="font-size: 24px; font-weight: bold;">Contacts</mat-label>
                        </div>
                        <div *ngFor="let contact of currentEntreprise.contacts"
                             style="display: flex; padding: 5px 0; border-bottom: 2px solid #696969;">
                            <ng-container *ngIf="{isEditing: false} as varMain" style="display: flex;">
                                <ng-container *ngIf="{cName: getSAsValue(contact.name), cFunction: getSAsValue(contact.function),
                                cMail: getSAsValue(contact.mail), cLinkedIn: getSAsValue(contact.linkedin)} as varSub" style="display: flex;">
                                    <div *ngIf="!varMain.isEditing" style="display: flex; flex: 1; height: 50px;">
                                       <div style="display: flex; flex: 1; margin: auto; flex-direction: column;">
                                           <div>
                                               <span style="font-weight: bold">Nom & fonction : </span>
                                               <span>{{contact.name}}</span>
                                               <span>, {{contact.function}}</span>
                                           </div>
                                           <div *ngIf="(contact.mail && contact.mail.length > 0) || (contact.linkedin && contact.linkedin.length > 0)">
                                               <span *ngIf="contact.mail && contact.mail.length > 0"><span style="font-weight: bold">Mail : </span>{{contact.mail}}</span>
                                               <span *ngIf="contact.mail && contact.mail.length > 0 && contact.linkedin && contact.linkedin.length > 0"> / </span>
                                               <span *ngIf="contact.linkedin && contact.linkedin.length > 0"><span style="font-weight: bold">LinkedIn : </span>{{contact.linkedin}}</span>
                                           </div>
                                       </div>
                                    </div>

                                    <div *ngIf="varMain.isEditing" style="height: 55px; margin-top: -5px; flex: 1; display: flex;"
                                         class="editContactContainer">
                                        <mat-form-field class="inputContactEdit">
                                            <input matInput placeholder="Nom" [(ngModel)]="contact.name">
                                        </mat-form-field>
                                        <mat-form-field class="inputContactEdit">
                                            <input matInput placeholder="Fonction" [(ngModel)]="contact.function">
                                        </mat-form-field>
                                        <mat-form-field class="inputContactEdit">
                                            <input matInput placeholder="Mail" [(ngModel)]="contact.mail">
                                        </mat-form-field>
                                        <mat-form-field class="inputContactEdit">
                                            <input matInput placeholder="LinkedIn" [(ngModel)]="contact.linkedin">
                                        </mat-form-field>
                                    </div>

                                    <div style="display: flex; margin: auto;">
                                        <button *ngIf="!varMain.isEditing" style="margin-left: 10px;"
                                                (click)="varMain.isEditing = true;">Editer</button>
                                        <button *ngIf="varMain.isEditing" style="background: darkred;"
                                                (click)="varMain.isEditing = false; contact.name = varSub.cName; contact.mail = varSub.cMail;
                                                contact.function = varSub.cFunction; contact.linkedin = varSub.cLinkedIn;">Annuler</button>
                                        <button *ngIf="varMain.isEditing" style="background: green; margin-left: 10px;"
                                                (click)="saveContact(contact); varMain.isEditing = false;">Enregistrer</button>
                                    </div>
                                </ng-container>
                            </ng-container>

                        </div>
                        <button style="margin: 10px auto 0; background: green"
                                (click)="createContact();">Ajouter un contact</button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>

        <div *ngIf="showEditorLoading">
            <div style="height: 100%">
                <mat-spinner style="margin: auto;"></mat-spinner>
            </div>
        </div>

    </div>
</div>
